export const CognitoOidcConfig = {
    callbackUri: window.location.origin + "/callback",
    userPoolId: 'us-east-1_QVXsIAsJH',
    clientId: process.env.REACT_APP_COGNITO_OIDC_CLIENT_ID ?? '29ng5gmu527bh7k0sgnabehp9n',
    //clientId: '1u115vrkuq5ci73aheajjshppf',
    clientSecret: undefined,
    // clientId: '2ndnrfs29atg30paemvopqcpnb',
    // clientSecret: '98ipaq5asc3cto0nnc73sv8ccchn27ev59aof3r21aof0qv9ujn',
    state: 'Z2FsbHVzYW5hbHl0aWNz',
    authorizationTokenEndpoint: 'https://auth.gallusanalytics.com/oauth2/authorize',
    tokenEndpoint: 'https://auth.gallusanalytics.com/oauth2/token',
    logoutEndpoint: 'https://auth.gallusanalytics.com/logout'
  };
  