import { faArrowAltCircleLeft, faCaretDown, faEnvelope, faLock, faLockOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useAppSelector } from "../app/hooks";
import { environmentConfig } from "../environment";
import { selectUserAuthContext } from "../features/console/consoleSlice";
import { getUserSessionItem, refreshToken } from "..";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

interface UserProfileProps {
    contactUsPath?: string;
    signOutPath?: string;
}

/**
 * Shows the username
 * @returns 
 */
export const UserProfile = (props: UserProfileProps) => {
    const userAuthContext = useAppSelector(selectUserAuthContext);
    let userName = getUserSessionItem('userName');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userHasMFAEnabled, setUserHasMFAEnabled] = useState<boolean>(false);
    const [showMFATools, setShowMFATools] = useState<boolean>(true);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    let isAdmin = userAuthContext?.idTokenPayload["cognito:groups"]?.includes("Administrator") ?? false;
    const navigate = useNavigate();

    const GetMFAMethod = async () => {
        try {
            await refreshToken();
            const user = await Auth.currentAuthenticatedUser();
            const preferredMFA: string = await Auth.getPreferredMFA(
                user
            );
            (preferredMFA === "NOMFA") ? setUserHasMFAEnabled(false) : setUserHasMFAEnabled(true);
        } catch (error) {
            console.log("Auth.currentAuthenticatedUser() error: " + error);
            setShowMFATools(false);
        }
    }

    useEffect(() => {
        GetMFAMethod();
    }, [])

    return (
        <div className='me-3 d-flex flex-row align-items-center px-2 py-1'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="outline-light">
                    <FontAwesomeIcon icon={faUser} size="lg" fixedWidth className="ms-1" />
                    <span className="ms-2 me-2">{userAuthContext?.username ?? userName}</span>
                    <FontAwesomeIcon icon={faCaretDown} fixedWidth className="me-1" />
                </DropdownToggle>
                <DropdownMenu>
                    {isAdmin && <DropdownItem onClick={() => window.open(environmentConfig.adminConsoleEndpoint, "_blank")}>Admin Console</DropdownItem>}
                    {showMFATools && !userHasMFAEnabled && <DropdownItem onClick={() => navigate('/mfasetup')}><FontAwesomeIcon icon={faLock} className="me-2" />Enable MFA</DropdownItem>}
                    {showMFATools && userHasMFAEnabled && <DropdownItem onClick={() => navigate('/disablemfa')}><FontAwesomeIcon icon={faLockOpen} className="me-2" />Disable MFA</DropdownItem>}
                    <DropdownItem onClick={() => navigate(props.contactUsPath ?? '/contactus')}><FontAwesomeIcon icon={faEnvelope} className="me-2" />Contact Us</DropdownItem>
                    <DropdownItem onClick={() => navigate(props.signOutPath ?? '/signout')}><FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-2" />Log Out</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            {/* <div className="banner-user-profile-name ms-1">{userAuthContext?.idTokenPayload["cognito:username"] ?? userName}</div> */}
        </div>
    );
}
