import React, { createRef, useEffect, useRef, useState } from "react";
import '../Module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { exportPlotlyToPdf } from "../../../features/SaveAsPdf";
import { plotlyFormatter } from "../../../features/PlotlyFormatter";
import Plot from "react-plotly.js";
import { useXlsxProcessorQuery } from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

interface ProfitabilityWalkProps { }

interface ProfitabilityWalkState {
    chartsLoadedFlag: boolean;
    netWalkIncomeChartOptions: any[];
    partnerCMWalkChartOptions: any[];
    dtcCMWalkChartOptions: any[];
    errorFlag: boolean;
    errorCode: string;
    errorMessage: string;
    errorType: string;
}

export const ProfitabilityWalk = (props: ProfitabilityWalkProps) => {
    const [state, setState] = useState<ProfitabilityWalkState>({
        chartsLoadedFlag: false,
        netWalkIncomeChartOptions: [],
        partnerCMWalkChartOptions: [],
        dtcCMWalkChartOptions: [],
        errorFlag: false,
        errorCode: "",
        errorMessage: "",
        errorType: "",
    });

    const ChartData = useXlsxProcessorQuery({
        "DataSource": "https://docs.google.com/spreadsheets/d/1PWu461r9p_NonDSQLtkwSlRNFtAEWftp/",
        "InputSheetName": null,
        "Inputs": [],
        "OutputSheetName": "Webpage",
        "Outputs": [
            { "RowIndex": 134, "CellIndex": 36, "Value": null }, //Net Income Walk
            { "RowIndex": 135, "CellIndex": 36, "Value": null },
            { "RowIndex": 136, "CellIndex": 36, "Value": null },
            { "RowIndex": 137, "CellIndex": 36, "Value": null },
            { "RowIndex": 138, "CellIndex": 36, "Value": null },
            { "RowIndex": 139, "CellIndex": 36, "Value": null },
            { "RowIndex": 140, "CellIndex": 36, "Value": null },
            { "RowIndex": 141, "CellIndex": 36, "Value": null },
            { "RowIndex": 142, "CellIndex": 36, "Value": null },
            { "RowIndex": 143, "CellIndex": 36, "Value": null },
            { "RowIndex": 110, "CellIndex": 30, "Value": null }, //DTC CM Walk
            { "RowIndex": 111, "CellIndex": 30, "Value": null },
            { "RowIndex": 112, "CellIndex": 30, "Value": null },
            { "RowIndex": 113, "CellIndex": 30, "Value": null },
            { "RowIndex": 114, "CellIndex": 30, "Value": null },
            { "RowIndex": 115, "CellIndex": 30, "Value": null },
            { "RowIndex": 122, "CellIndex": 33, "Value": null }, //Partner CM Walk
            { "RowIndex": 123, "CellIndex": 33, "Value": null },
            { "RowIndex": 124, "CellIndex": 33, "Value": null },
            { "RowIndex": 125, "CellIndex": 33, "Value": null },
            { "RowIndex": 126, "CellIndex": 33, "Value": null },
            { "RowIndex": 127, "CellIndex": 33, "Value": null }
        ]
    })

    useEffect(() => {
        if (ChartData.isSuccess && ChartData.status === 'fulfilled') {

            setState({
                ...state,
                netWalkIncomeChartOptions: [
                    {
                        name: "Net Income Walk",
                        type: "waterfall",
                        orientation: "v",
                        measure: [
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "total"
                        ],
                        x: [
                            "Q2 2022 Net Income",
                            "Rev - Rate Var.",
                            "Rev - Vol Var.",
                            "Other Rev.",
                            "Exp - Rate Var.",
                            "Exp - Vol Var.",
                            "Unattributable Exp.",
                            "Change in MSR FV",
                            "Taxes",
                            "Q2 2023 Net Income" //total
                        ],
                        textposition: 'auto',
                        text: [
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[0].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[1].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[2].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[3].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[4].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[5].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[6].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[7].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[8].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[9].Value.toString()), "financials") + "</b>",
                        ],
                        y: [
                            Math.round(ChartData.data.Outputs[0].Value),
                            Math.round(ChartData.data.Outputs[1].Value),
                            Math.round(ChartData.data.Outputs[2].Value),
                            Math.round(ChartData.data.Outputs[3].Value),
                            Math.round(ChartData.data.Outputs[4].Value),
                            Math.round(ChartData.data.Outputs[5].Value),
                            Math.round(ChartData.data.Outputs[6].Value),
                            Math.round(ChartData.data.Outputs[7].Value),
                            Math.round(ChartData.data.Outputs[8].Value),
                            null,
                        ],
                        connector: {
                            line: {
                                color: "rgb(63, 63, 63)"
                            }
                        },
                        decreasing: {
                            marker: {
                                color: "red",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        increasing: {
                            marker: {
                                color: "rgb(124, 181, 236)",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        totals: {
                            marker: {
                                color: "#D3D3D3",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        cliponaxis: false
                    }
                ],
                dtcCMWalkChartOptions: [
                    {
                        name: "DTC CM Walk",
                        type: "waterfall",
                        orientation: "v",
                        measure: [
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "total"
                        ],
                        x: [
                            "Q2 2022 CM",
                            "Rev - Rate Var.",
                            "Rev - Vol Var.",
                            "Exp - Rate Var.",
                            "Exp - Vol Var.",
                            "Q2 2023 CM" //total
                        ],
                        textposition: 'auto',
                        text: [
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[10].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[11].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[12].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[13].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[14].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[15].Value.toString()), "financials") + "</b>"
                        ],
                        y: [
                            Math.round(ChartData.data.Outputs[10].Value),
                            Math.round(ChartData.data.Outputs[11].Value),
                            Math.round(ChartData.data.Outputs[12].Value),
                            Math.round(ChartData.data.Outputs[13].Value),
                            Math.round(ChartData.data.Outputs[14].Value),
                            0,
                        ],
                        connector: {
                            line: {
                                color: "rgb(63, 63, 63)"
                            }
                        },
                        decreasing: {
                            marker: {
                                color: "red",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        increasing: {
                            marker: {
                                color: "rgb(124, 181, 236)",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        totals: {
                            marker: {
                                color: "#D3D3D3",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        cliponaxis: false
                    }
                ],
                partnerCMWalkChartOptions: [{

                    name: "Partner CM Walk",
                    type: "waterfall",
                    orientation: "v",
                    measure: [
                        "relative",
                        "relative",
                        "relative",
                        "relative",
                        "relative",
                        "total"
                    ],
                    x: [
                        "Q2 2022 CM",
                        "Rev - Rate Var.",
                        "Rev - Vol Var.",
                        "Exp - Rate Var.",
                        "Exp - Vol Var.",
                        "Q2 2023 CM" //total
                    ],
                    textposition: 'auto',
                    text: [
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[16].Value.toString()), "financials") + "</b>",
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[17].Value.toString()), "financials") + "</b>",
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[18].Value.toString()), "financials") + "</b>",
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[19].Value.toString()), "financials") + "</b>",
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[20].Value.toString()), "financials") + "</b>",
                        "<b>" + plotlyFormatter(Math.round(ChartData.data.Outputs[21].Value.toString()), "financials") + "</b>"
                    ],
                    y: [
                        Math.round(ChartData.data.Outputs[16].Value),
                        Math.round(ChartData.data.Outputs[17].Value),
                        Math.round(ChartData.data.Outputs[18].Value),
                        Math.round(ChartData.data.Outputs[19].Value),
                        Math.round(ChartData.data.Outputs[20].Value),
                        0,
                    ],
                    connector: {
                        line: {
                            color: "rgb(63, 63, 63)"
                        }
                    },
                    decreasing: {
                        marker: {
                            color: "red",
                            line:
                            {
                                color: "black",
                                width: 1
                            }
                        }
                    },
                    increasing: {
                        marker: {
                            color: "rgb(124, 181, 236)",
                            line:
                            {
                                color: "black",
                                width: 1
                            }
                        }
                    },
                    totals: {
                        marker: {
                            color: "#D3D3D3",
                            line:
                            {
                                color: "black",
                                width: 1
                            }
                        }
                    },
                    cliponaxis: false
                }],
                chartsLoadedFlag: true,
                errorFlag: false,
            });
        } else if (ChartData.isError) {
            setState((prevState) => ({
                ...prevState,
                errorFlag: true,
                errorCode: "500",
                errorMessage: tryAgainLaterMessage,
                errorType: "InternalAPI"
            }));
        }
    }, [ChartData])

    const plotlyRefChart1 = useRef<Plot>();
    const plotlyRefChart2 = useRef<Plot>();
    const plotlyRefChart3 = useRef<Plot>();

    function exportToPdfWrapper() {
        exportPlotlyToPdf("Profitability Walk", [plotlyRefChart1, plotlyRefChart2, plotlyRefChart3], false);
    }

    return (
        <div className="module-master">
            {!state.chartsLoadedFlag &&
                <div className="module-loading">
                    <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                </div>
            }
            {state.errorFlag &&
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            }
            {state.chartsLoadedFlag && !state.errorFlag &&
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="module-title">
                            Profitability Walk
                        </div>
                        <div className="module-subtitle">
                            (in Millions)
                        </div>
                    </div>
                    <div className="btn-group w-100" style={{ paddingBottom: "5px" }}>
                        <button className="btn btn-primary" style={{ width: "100%" }} onClick={exportToPdfWrapper}>Export to PDF</button>
                    </div>
                    <div className="module-chart-container border-on-top">
                        <Plot
                            data={state.netWalkIncomeChartOptions}
                            layout={{
                                title: {
                                    text: "<b> <span style='text-decoration:underline;'>" + "Net Income Walk" + "</span> </b>",
                                    y: 0.97, x: 0.05
                                },
                                autosize: true,
                                xaxis: {
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    /*
                                    ticktext: generateTickVals(this.state.netWalkIncomeChartOptions[0].y).map(item => D3Format.format('($.2f')(item)),
                                    tickvals: generateTickVals(this.state.netWalkIncomeChartOptions[0].y),
                                    tickmode: 'array',
                                    */
                                    tickformat: '($',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="module-row">
                        <div className="module-chart-container border-on-top">
                            <Plot
                                data={state.dtcCMWalkChartOptions}
                                layout={{
                                    title: {
                                        text: "<b> <span style='text-decoration:underline;'>" + "DTC CM Walk" + "</span> </b>",
                                        y: 0.97, x: 0.05
                                    },
                                    autosize: true,
                                    xaxis: {
                                        title: {
                                            font: {
                                                size: 18,
                                            }
                                        }
                                    },
                                    yaxis: {
                                        tickformat: '($',
                                        title: {
                                            text: "",
                                            font: {
                                                size: 18,
                                            }
                                        }
                                    }
                                }}
                                useResizeHandler
                                config={{ responsive: true, displayModeBar: false, staticPlot: true }}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="module-chart-container border-on-top">
                            <Plot
                                data={state.partnerCMWalkChartOptions}
                                layout={{
                                    title: {
                                        text: "<b> <span style='text-decoration:underline;'>" + "Partner CM Walk" + "</span> </b>",
                                        y: 0.97, x: 0.05
                                    },
                                    autosize: true,
                                    xaxis: {
                                        title: {
                                            font: {
                                                size: 18,
                                            }
                                        }
                                    },
                                    yaxis: {
                                        tickformat: '($',
                                        title: {
                                            text: "",
                                            font: {
                                                size: 18,
                                            }
                                        }
                                    }
                                }}
                                useResizeHandler
                                config={{ responsive: true, displayModeBar: false, staticPlot: true }}
                                style={{ width: "100%" }}
                            />
                        </div>

                    </div>
                    <div style={{ display: "none" }}>
                        <Plot
                            ref={plotlyRefChart1 as any}
                            data={state.netWalkIncomeChartOptions}
                            layout={{
                                title: 'Net Income Walk',
                                autosize: true,
                                margin: {
                                    t: 50,
                                },
                                xaxis: {
                                    tickfont: { size: 8 },
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    /*
                                    ticktext: generateTickVals(this.state.netWalkIncomeChartOptions[0].y).map(item => D3Format.format('($.2f')(item)),
                                    tickvals: generateTickVals(this.state.netWalkIncomeChartOptions[0].y),
                                    tickmode: 'array',
                                    */
                                    tickformat: '($',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            style={{ width: "100%" }}
                        />

                        <Plot
                            ref={plotlyRefChart2 as any}
                            data={state.dtcCMWalkChartOptions}
                            layout={{
                                title: 'DTC CM Walk',
                                autosize: true,
                                margin: {
                                    t: 50,
                                },
                                xaxis: {
                                    tickfont: { size: 8 },
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    tickformat: '($',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            style={{ width: "100%" }}
                        />
                        <Plot
                            ref={plotlyRefChart3 as any}
                            data={state.partnerCMWalkChartOptions}
                            layout={{
                                title: 'Partner CM Walk',
                                autosize: true,
                                margin: {
                                    t: 50,
                                },
                                xaxis: {
                                    tickfont: { size: 8 },
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    tickformat: '($',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
