import { DataEncoding, tsDataObject } from "../services/types/gallus";
import { formatterWrapper, noDataSymbol } from "../features/PlotlyFormatter";
import { RowClassParams } from "ag-grid-community";

export type DisplayAsType = Array<{ nameOfColumn: string; encoding: DataEncoding }>;

export const columnWidthCalculation = (columnName: string) => {
    return Math.max((columnName.length * 16), 50);
}

export const fieldWidthCalculation = (fieldLength: number) => {
    return Math.max((fieldLength * 6), 100);
}

export type agGridColumnDefType = { field: string, headerName: string, headerClass?: string, minWidth?: number, maxWidth?: number, sortable?: boolean, pinned?: "left" | "right", cellClass?: string };

export type columnGroup = {
    headerName: string,
    headerClass: string,
    marryChildren: boolean,
    children: agGridColumnDefType[]
}

export const TSToAgGrid = (data: tsDataObject,
    smallColumns: boolean,
    sortByColumn?: string,
    inverseOrder?: boolean,
    pinColumns?: string[],
) => {

    var columnDefinitions: agGridColumnDefType[] = [];
    var columnDefinitionsWithGrouping: any[] = [];
    var dataForRows: { formatted: any, notFormatted: any }[] = [];
    var tmp: any = {};
    var tmpNoFormatting: any = {};
    var stylesOfRows: { rowIndex: number, rowStyle: string }[] = [];

    data.columnNames.forEach((nameOfColumn, indexOfColumn) => {
        var newNameField: string = nameOfColumn;
        var newNameHeaderName: string = ((data.columnDisplayName[indexOfColumn] === "RowName") ? "" : data.columnDisplayName[indexOfColumn]);
        var newColumn: agGridColumnDefType;
        if (data.columnShow[indexOfColumn] === true) {
            if (typeof (pinColumns) !== "undefined" && pinColumns.includes(newNameField)) {
                newColumn = { field: newNameField, headerName: newNameHeaderName, headerClass: data.columnHeaderClasses[indexOfColumn] ?? "", pinned: 'left', cellClass: (typeof(data.columnDataClasses) !== "undefined") ? data.columnDataClasses[indexOfColumn] : "" };
            } else {
                newColumn = { field: newNameField, headerName: newNameHeaderName, headerClass: data.columnHeaderClasses[indexOfColumn] ?? "", cellClass: (typeof(data.columnDataClasses) !== "undefined") ? data.columnDataClasses[indexOfColumn] : ""};
            }
            if (smallColumns && newNameHeaderName !== ""){
                newColumn["maxWidth"] = columnWidthCalculation(newNameHeaderName)
            }
            columnDefinitions.push(newColumn);
        }
    })

    var indexOfCSSClass = data.columnNames.indexOf("CSSClass");

    data.data.forEach((row, indexOfRow) => {
        data.columnNames.forEach((column, indexOfColumn) => {
            var tmpLongest: number = 0;
            if (data.columnShow[indexOfColumn] === true) {
                if (row[indexOfColumn] !== null && row[indexOfColumn] !== "") {
                    var value;
                    var encoding: DataEncoding;
                    if (Object.hasOwn(row[indexOfColumn], "overrideEncoding")){
                        value = row[indexOfColumn].value;
                        encoding = row[indexOfColumn].overrideEncoding;
                    } else {
                        value = row[indexOfColumn];
                        encoding = data.columnFormatting[indexOfColumn];
                    }
                    if (value.length > tmpLongest){
                        tmpLongest = value.length;
                    }
                    tmp[column] = formatterWrapper(value, encoding);
                    if (encoding === "string"){
                        tmpNoFormatting[column] = value.toString().replace(/,/g, '');
                    } else {
                        tmpNoFormatting[column] = value;
                    }
                } else {
                    if (row[indexOfCSSClass] === "Spacing" || row[indexOfCSSClass] === "Title") {
                        tmp[column] = null;
                    } else {
                        tmp[column] = noDataSymbol;
                    }
                    tmpNoFormatting[column] = null;
                }
            } else {
                if (column === "CSSClass") {
                    if (row[indexOfColumn] !== null) {
                        stylesOfRows.push({ rowIndex: indexOfRow, rowStyle: row[indexOfColumn].toString() });
                    }
                }
            }

            columnDefinitions.find((o, i) => {
                if (o.field === column) {
                    if (smallColumns && typeof(columnDefinitions[i]["maxWidth"]) !== "undefined"){
                        var currentMaxWidth = columnDefinitions[i]["maxWidth"];
                        columnDefinitions[i]["minWidth"] = Math.min(currentMaxWidth!, fieldWidthCalculation(tmpLongest));
                        columnDefinitions[i]["maxWidth"] = Math.max(currentMaxWidth!, fieldWidthCalculation(tmpLongest));
                    } else {
                        columnDefinitions[i]["minWidth"] = fieldWidthCalculation(tmpLongest);
                    }
                    return true; // stop searching
                }
            })
        })
        dataForRows.push({ formatted: tmp, notFormatted: tmpNoFormatting });
        tmp = {};
        tmpNoFormatting = {};
    })

    if (typeof (sortByColumn) !== "undefined") {
        dataForRows.sort(
            function (a, b) {
                if (a.notFormatted[sortByColumn] < b.notFormatted[sortByColumn]) return -1;
                if (a.notFormatted[sortByColumn] > b.notFormatted[sortByColumn]) return 1;
                return 0;
            })
    }

    if (inverseOrder) {
        dataForRows.reverse();
    }

    if (data.columnGroupName.length !== 0) {
        columnDefinitions.forEach((element) => {
            columnDefinitionsWithGrouping.push(element);
        })
        data.columnGroupName.forEach((group) => {
            var childrenOfGrouping: agGridColumnDefType[] = []
            columnDefinitions.forEach((column) => {
                if (group.columnsToGroup.includes(column.field)) {
                    childrenOfGrouping.push(column);
                }
            })
            for (var i = columnDefinitionsWithGrouping.length - 1; i >= 0; i--) {
                if (childrenOfGrouping.map((e => e.field)).includes(columnDefinitionsWithGrouping[i].field)) {
                    columnDefinitionsWithGrouping.splice(i, 1, { headerName: group.nameOfGroup, headerClass: group.headerClass, marryChildren: true, children: childrenOfGrouping });
                }
            }
        })

        columnDefinitionsWithGrouping = columnDefinitionsWithGrouping.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.headerName === value.headerName
            ))
        )
    } else {
        columnDefinitions.forEach((element) => {
            columnDefinitionsWithGrouping.push(element);
        })
    }
    return { columns: columnDefinitions, rows: dataForRows, columnsWithGrouping: columnDefinitionsWithGrouping, rowStyles: generateRowClassRules(stylesOfRows), title: data.tableTitle ?? null };
}

export const convertOldDisplayAs = (oldDisplayAs: DataEncoding[], listOfColumns: string[], columnsToSkip?: string[]) => {
    var result: DisplayAsType = [];
    if ((oldDisplayAs.length === listOfColumns.length)) {
        listOfColumns.forEach((column, index) => {
            if (!(typeof (columnsToSkip) !== "undefined" && columnsToSkip.includes(column))) {
                result.push({ nameOfColumn: column, encoding: oldDisplayAs[index] });
            }
        })
    } else {
        console.log("JSON Compatibility function error: length mismatch.")
    }

    return result;
}

const generateRowClassRules = (rowStyles: { rowIndex: number; rowStyle: string; }[]) => {
    var rowParams: any = {};

    var rowStylesFormatted: { rowStyle: string, rowIndexes: number[] }[] = [];

    var distinctStyles: string[] = rowStyles.map((e) => e.rowStyle).filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
    })

    distinctStyles.forEach((styleName) => {
        var applicableRows: number[] = [];
        rowStyles.forEach((element) => {
            if (element.rowStyle === styleName) {
                applicableRows.push(element.rowIndex);
            }
        })
        rowStylesFormatted.push({ rowStyle: styleName, rowIndexes: applicableRows });
    })

    rowStylesFormatted.forEach((row) => {
        rowParams[row.rowStyle] = function (params: RowClassParams) {
            if (typeof (params.node.rowIndex) !== "undefined" && params.node.rowIndex !== null) {
                return (row.rowIndexes.includes(params.node.rowIndex));
            } else {
                return false;
            }
        }
    })

    return rowParams;
}