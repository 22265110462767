import { useState } from "react";
import Slider from 'react-input-slider';
import './DCF.scss';

interface DCFProps {
    firstStartingValue: number;
    secondStartingValue: number;
    measure: string;
    symbol: string;
    title: string;
    firstLabel: string;
    secondLabel: string;
    showLabels: boolean;
    onValueChange: (firstValue: number, secondValue: number) => void;
}

interface DCFState {
    firstValue: number;
    secondValue: number;
}

export const DCF = (props: DCFProps) => {
    const [state, setState] = useState<DCFState>({
        firstValue: props.firstStartingValue,
        secondValue: props.secondStartingValue,
    });

    function handleChange(value: number, numberOfBoxSlider: number) {
        switch (numberOfBoxSlider) {
            case 1:
                setState({...state, firstValue: value });
                props.onValueChange(value, props.secondStartingValue);
                break;
            case 2:
                setState({...state, secondValue: value });
                props.onValueChange(props.firstStartingValue, value);
                break;
        }
    }

    return (
        <div className="dcf">
            <div className="dcf-title">
                {props.title}
            </div>
            <div className="dcf-input">
                <div className="dcf-label" style={{ visibility: (props.showLabels ? 'visible' : 'hidden') }}>
                    {props.firstLabel}
                </div>
                <div className="dcf-input-box">
                    <div className="input-group input-group-sm">
                        <input className="form-control projections-input-box-input dcf-input-box-input" type="number" value={state.firstValue} onChange={(e) => handleChange(e.target.valueAsNumber, 1)} />
                        <div className="input-group-text">{props.symbol}</div>
                    </div>
                    <div className="dcf-slider">
                        <Slider
                            styles={{
                                track: {
                                    backgroundColor: "#4bb5eb"
                                },
                                active: {
                                    backgroundColor: "#4bb5eb"
                                }
                            }}
                            axis="x"
                            x={state.firstValue}
                            onChange={({ x }) => { x = Math.round(x * 100) / 100; handleChange(x, 1) }}
                            xmin={1}
                            xmax={25}
                            xstep={0.5}
                        />
                    </div>
                </div>
            </div>
            <div className="dcf-input">
                <div className="dcf-label" style={{ visibility: (props.showLabels ? 'visible' : 'hidden') }}>
                    {props.secondLabel}
                </div>
                <div className="dcf-input-box">
                    <div className="input-group input-group-sm">
                        <input className="form-control projections-input-box-input dcf-input-box-input" type="number" value={state.secondValue} onChange={(e) => handleChange(e.target.valueAsNumber, 2)} />
                        <div className="input-group-text">{props.measure}</div>
                    </div>
                    <Slider
                        styles={{
                            track: {
                                backgroundColor: "#4bb5eb"
                            },
                            active: {
                                backgroundColor: "#4bb5eb"
                            },
                        }}
                        axis="x"
                        x={state.secondValue}
                        onChange={({ x }) => { x = Math.round(x * 100) / 100; handleChange(x, 2) }}
                        xmin={0}
                        xmax={100}
                        xstep={5}
                    />
                </div>
            </div>
        </div>
    );
}
