import React, { useEffect, useState } from "react";
import { Auth } from 'aws-amplify';
import { checkValidUsername, checkEmailAddress, checkMatchingPasswords, validatePasswordLength, validateLowercase, validateUppercase, validateSpecial, validateNumber, passwordErrors } from '../UserValidationFunctions';
import { Link } from 'react-router-dom';
import '../UserValidation.scss';

interface SignUpProps { }

export const SignUp = (props: SignUpProps) => {
    /**
     * @params firstName: First name of user.
     * @params lastName: Last name of user.
     * @params company: Company of user.
     * @params jobTitle: Job title of user.
     * @params username: Username of user.
     * @params emailAddress: Email Address of user.
     * @params password: Password of user.
     * @params passwordConfirm: Second input of password, for confirmation purposes.
     * @params passwordMatch: Flag for matching passwords.
     * @params submitButtonState: Flag that enables or disables the submit button.
     * @params errorUsername: Flag for username error.
     * @params errorEmail: Flag for email error.
     * @params errorEmpty: Flag for empty fields.
     * @params errorEmptySubmit: this has the same value of errorEmpty, but unlike ErrorEmpty, doesn't wait until the last field to see if everything is full or not. I needed this one to properly disable the signup button, without assaulting the user with an error sign before he did anything.
     * @params errorPassword: Flag for password error.
     * @params lastField: Flag that turns on if the user is in the last field of the form. If he is, the Empty Fields message will pop in
     * @params validPasswordLength: Flag that represents that the password has enough characters.
     * @params validPasswordUppercase: Flag that represents that the password has uppercase letters.
     * @params validPasswordLowercase: Flag that represents that the password has lowercase letters.
     * @params validPasswordNumber: Flag that represents that the password has numbers.
     * @params validPasswordSpecial: Flag that represents that the password has special symbols.
     * @params signupErrorName: Name of error during sign up.
     * @params signupErrorMessage: Message of error during sign up.
     * @params confirmationCode: The confirmation code entered by the user during the "email confirmation" step.
     * @params lifecycleFlag: Represents the state of the signup process. There are two states, "SignUp" (user is inputing their information) and "Confirmation" (the user is confirming their email address).
     * @params errorFlag: Flag that represents that the user has encountered an error.
     */
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
    const [submitButtonState, setSubmitButtonState] = useState<boolean>(false);
    const [errorUsername, setErrorUsername] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorEmpty, setErrorEmpty] = useState<boolean>(false);
    const [errorEmptySubmit, setErrorEmptySubmit] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [lastField, setLastField] = useState<boolean>(false);
    const [validPasswordLength, setValidPasswordLength] = useState<boolean>(false);
    const [validPasswordUppercase, setValidPasswordUppercase] = useState<boolean>(false);
    const [validPasswordLowercase, setValidPasswordLowercase] = useState<boolean>(false);
    const [validPasswordNumber, setValidPasswordNumber] = useState<boolean>(false);
    const [validPasswordSpecial, setValidPasswordSpecial] = useState<boolean>(false);
    const [signupErrorName, setSignupErrorName] = useState<string>('');
    const [signupErrorMessage, setSignupErrorMessage] = useState<string>('');
    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [lifecycleFlag, setLifecycleFlag] = useState<string>('SignUp');
    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const [fromSearchParameter, setFromSearchParameter] = useState<string | null>('');
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setFromSearchParameter(params.get("from"));
    }, []);

    /**
     * Sends sign-up request.
     */
    async function signUpFunction() {
        try {
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email: emailAddress,
                    given_name: firstName,
                    family_name: lastName,
                    "custom:company_name": company,
                    "custom:job_title2": jobTitle,
                    // other custom attributes 
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: true,
                }
            });
            setLifecycleFlag("Confirmation");
        }
        catch (error) {
            handleError(error);
        }
    }

    /**
     * Calls the Confirm Sign Up function.
     */
    async function confirmationFunction() {
        try {
            const { result } = await Auth.confirmSignUp(username, confirmationCode);
            setLifecycleFlag("ToLoginPage");
        } catch (error) {
            handleError(error);
        }
    }

    /**
     * Re-sends the confirmation email.
     */
    async function resendConfirmationFunction() {
        try {
            await Auth.resendSignUp(username);
        } catch (error) {
            handleError(error);
        }
        setLifecycleFlag("Confirmation");
    }

    /**
     * Displays the error name and message, and changes the flag "errorFlag" to true.
     * @param error 
     */
    function handleError(error: unknown) {
        //error lists here: 
        //https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
        //https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html#API_ConfirmSignUp_Errors

        let errorName = 'Unknown Error';

        if (error instanceof Error) {
            errorName = error.name;
        }
        else {
            errorName = String(error);
        }

        switch (errorName) {
            case "UsernameExistsException":
                setSignupErrorMessage("Username is already taken. Please input a different username.");
                break;
            case "InvalidEmailRoleAccessPolicyException":
                setSignupErrorMessage("The email address provided is invalid. Please, provide a different email address.");
                break;
            case "InvalidPasswordException":
                setSignupErrorMessage("The password provided is invalid. Please, provide a different password.");
                break;
            case "InvalidParameterException":
                setSignupErrorMessage("InvalidParameterException");
                break;
            case "CodeMismatchException":
                setSignupErrorMessage("Please check the entered data and try again.");
                break;
            case "ExpiredCodeException":
                setSignupErrorMessage("The provided code has expired. Please, sign up again.");
                break;
            default:
                setSignupErrorMessage("The application has encountered an internal error. Please try again later.");
        }

        setErrorFlag(true);
    }

    useEffect(() => {
        setErrorUsername(!checkValidUsername(username));
        setErrorEmail(!checkEmailAddress(emailAddress));
        setErrorEmpty(!checkIfEmpty() && lastField);
        setErrorEmptySubmit(!checkIfEmpty());
        setErrorPassword(!checkPassword(password, passwordConfirm));
    }, [username,
        emailAddress,
        lastField,
        firstName,
        lastName,
        company,
        jobTitle,
        password,
        passwordConfirm]);

    useEffect(() => {
        if (!errorUsername && !errorEmail && !errorEmptySubmit && !errorPassword && passwordMatch) {
            setSubmitButtonState(true);
        } else {
            setSubmitButtonState(false);
        }
    }, [errorUsername, errorEmail, errorEmptySubmit, errorPassword, passwordMatch]);

    /**
     * Checks if all fields are empty
     * @returns Boolean, if false, there's at least one empty field.
     */
    const checkIfEmpty = () => {
        if (firstName.length !== 0 &&
            lastName.length !== 0 &&
            company.length !== 0 &&
            jobTitle.length !== 0 &&
            username.length !== 0 &&
            emailAddress.length !== 0 &&
            password.length !== 0 &&
            passwordConfirm.length !== 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Validates password
     * @param password 
     * @returns Boolean, if true, the password is valid.
     */
    const checkPassword = (password: string, passwordConfirm: string) => {
        if (password.length !== 0) { //only validate if there's a password. If there's not, it won't validate, but it's ok since checkEmpty does it
            let isValidPasswordLength = validatePasswordLength(password);
            setValidPasswordLength(isValidPasswordLength);

            let isValidPasswordLowercase = validateLowercase(password);
            setValidPasswordLowercase(isValidPasswordLowercase);

            let isValidPasswordUppercase = validateUppercase(password);
            setValidPasswordUppercase(isValidPasswordUppercase);

            let isValidPasswordNumber = validateNumber(password);
            setValidPasswordNumber(isValidPasswordNumber);

            let isValidPasswordSpecial = validateSpecial(password);
            setValidPasswordSpecial(isValidPasswordSpecial);

            let isPasswordMatch = checkMatchingPasswords(password, passwordConfirm);
            setPasswordMatch(isPasswordMatch);

            if (isValidPasswordLength && isValidPasswordLowercase && isValidPasswordNumber
                && isValidPasswordSpecial && isValidPasswordUppercase && isPasswordMatch) {
                return true;
            } else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    /**
     * Handles the behavior of the submit button, based on the current state of the page.
     * @param event The "click sign-up" event.
     */
    const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (errorFlag) {
            setErrorFlag(false);
            setSignupErrorMessage('');
            setSignupErrorName('');
        } else {
            switch (lifecycleFlag) {
                case "SignUp": {
                    signUpFunction();
                    break;
                }
                case "Confirmation": {
                    confirmationFunction();
                    break;
               }
                case "ToLoginPage": {
                    //don't do anything here, let the Link take the user to the login page                    
                    break;
                }
                default: {
                    setErrorFlag(false);
                    setSignupErrorMessage('');
                    setSignupErrorName('');
                    setLifecycleFlag("SignUp");
                    break;
                }
            }
        }
    }

    return (
        <form className="col-md-4 mb-2 container-fluid roundedCorners" onSubmit={handleSubmit}>
            <div className="row mb-2">
                <div className="col-12 signup-text text-center-for-titles">
                    {(fromSearchParameter !== "hmda") ? "Sign Up" : "Sign up for HMDA 2022 Free Trial"}
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <label>First Name</label><br></br>
                    <input type="text" className="form-control formSignUpFirst" value={firstName}
                        onChange={(e) => { setFirstName(e.target.value) }} />
                </div>
                <div className="col-12">
                    <label>Last Name</label><br></br>
                    <input type="text" className="form-control formSignUpLast" value={lastName}
                        onChange={(e) => { setLastName(e.target.value) }} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <label>Company</label><br></br>
                    <input type="text" className="form-control formSignUpCompany" value={company}
                        onChange={(e) => { setCompany(e.target.value) }} />
                </div>
                <div className="col-12">
                    <label>Job Title</label><br></br>
                    <input type="text" className="form-control formSignUpJob" value={jobTitle}
                        onChange={(e) => { setJobTitle(e.target.value) }} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <label>Username</label><br></br>
                    <input type="text" className="form-control formSignUpUsername" value={username}
                        onChange={(e) => { setUsername(e.target.value) }} />
                </div>
                <div className="col-12">
                    <label>Email address</label><br></br>
                    <input type="email" className="form-control formSignUpEmail" aria-describedby="emailHelp" value={emailAddress}
                        onChange={(e) => { setEmailAddress(e.target.value) }} />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12">
                    <label>Password</label><br></br>
                    <input value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" name="formSignUpPassword" className="form-control" />
                </div>
                <div className="col-12">
                    <label>Confirm Password</label><br></br>
                    <input value={passwordConfirm} onChange={(e) => { setPasswordConfirm(e.target.value); setLastField(true); }} type="password" name="formSignUpPassword" className="form-control" />
                </div>
            </div>
            <div className="signup-button">
                <button className="btnSignUp btn btn-primary btn-dark-rounded" type="submit" disabled={!submitButtonState}>Sign up</button>
            </div>
            <div className="signup-errors" style={{ display: ((errorEmail || errorUsername || (errorPassword && lastField) || (errorEmpty && lastField) || (!passwordMatch && lastField)) ? 'block' : 'none') }}>
                Please correct the following errors:
                <div className="signup-error-generic" style={{ display: (errorEmail ? 'block' : 'none'), padding: 5 }}>
                    Your account should be created using a company email address.
                </div>
                <div className="signup-error-generic" style={{ display: (errorUsername ? 'block' : 'none'), padding: 5 }}>
                    Your username should only consist of letters and numbers.
                </div>
                <div style={{ display: (errorPassword ? 'block' : 'none') }}>
                    {passwordErrors(lastField, validPasswordLength, validPasswordUppercase, validPasswordLowercase, validPasswordNumber, validPasswordSpecial, passwordMatch)}
                </div>
                <div className="signup-error-generic" style={{ display: ((errorEmpty && lastField) ? 'block' : 'none'), padding: 5 }}>
                    Please complete all empty fields before signing up.
                </div>
            </div>
            <div className={errorFlag ? "modal display-block" : "modal display-none"}>
                <div>
                    {signupErrorMessage}
                </div>
                <div className="error-popup-button mt-2">
                    <button>Close</button>
                </div>
            </div>
            <div className={lifecycleFlag === "Confirmation" && !errorFlag ? "modal confirmation-block" : "modal confirmation-none"}>
                <div className="signup-popup-message">
                    <div className="signup-popup-big-text">
                        <div className="signup-popup-title">
                            Confirm Email Address
                        </div>
                        <div className="signup-popup-text">
                            Please enter the confirmation code delivered to your email address. <br /> If you can't find it, please check your Spam folder.
                        </div>
                    </div>
                    <div className="signup-popup-input">
                        <label>Confirmation Code</label><br />
                        <input onChange={(e) => { setConfirmationCode(e.target.value) }} />
                        <button className="btnConfirm" type="submit">Confirm</button>
                    </div>
                    <div className="signup-popup-resend">
                        Didn't get your code? <br />
                        <button className="btn btn-link" onClick={(e) => resendConfirmationFunction()}>Resend</button>
                    </div>
                </div>
            </div>
            <div className={lifecycleFlag === "ToLoginPage" && !errorFlag ? "modal confirmation-block" : "modal confirmation-none"}>
                <div className="signup-popup-message">
                    <div className="signup-popup-big-text">
                        <div className="signup-popup-title">
                            User has been created successfully!
                        </div>
                        <div className="signup-popup-text">
                            Please press the button below to login to your account.
                        </div>
                        <div className="signup-button">
                            <Link to="/landingpage"><button className="btnConfirm" type="submit">Confirm</button></Link>
                            {/* <Link to="/login"><button className="btnConfirm" type="submit">Confirm</button></Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
