import { BaseQueryFn, createApi, fetchBaseQuery, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { API } from "aws-amplify";
import { CognitoOidcConfig } from "../CognitoOidcConfig";
import { XlsxProcessorRequest } from "./types/gallus";
import { getUserSessionItem } from "..";
import Bugsnag from "@bugsnag/js";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";

export const serverlessQuery: BaseQueryFn<
    [string, any], // Args
    unknown, // Result
    { reason: any }, // Error
    {}, // DefinitionExtraOptions
    {} // Meta
> = async (args, api, extraOptions) => {
    let responseData: any;
    try {
        const response = await API.post("ServerlessAPI", args[0], {
            body: args[1],
        });
        responseData = response
    }
    catch {
        if (args[0] === "/thoughtspot/token"){
            const newcall = await baseFallbackQueryWithErrorManagement({ url: args[0], body: args[1], method: "post" }, api, extraOptions);
            responseData = newcall
        }
    }
    return { data: responseData};

}

export const serverlessApi = createApi({
    reducerPath: 'serverlessApi',
    baseQuery: serverlessQuery,
    endpoints: (builder) => ({
        getTSAccessToken: builder.query<{ token: string }, number>({
            query: (orgId) =>  {
                const accessToken = getUserSessionItem('accessToken');
                const username = getUserSessionItem('userName');
                return ['/thoughtspot/token', {
                    username: username,
                    orgId: orgId,
                    accessToken: accessToken,
                    clientId: CognitoOidcConfig.clientId
                }];
            }
        }),
        xlsxProcessor: builder.query({
            query: (body: XlsxProcessorRequest) =>  {
                return ['/xlsx/', {
                    "AccessToken": getUserSessionItem('accessToken'),
                    ...body
                }];
            }
        })
    }),
});

export const { 
    useGetTSAccessTokenQuery,
    useLazyGetTSAccessTokenQuery,
    useXlsxProcessorQuery,
    useLazyXlsxProcessorQuery
} = serverlessApi;

const fallbackApiBaseURL: FetchBaseQueryArgs = {
    baseUrl: "https://gallus-api-v3.gallusanalytics.com"
};
const baseFallbackQuery = fetchBaseQuery(fallbackApiBaseURL);

const baseFallbackQueryWithErrorManagement: BaseQueryFn<
    string | FetchArgs,
    unknown
> = async (args, api, extraOptions) => {
    try {
        let result = await baseFallbackQuery(args, api, extraOptions);
        return result;
    }
    catch (error){
        Bugsnag.notify(String(error), function (event) {
            event.severity = 'error';
            event.context = 'Serverless Fallback API Error';
            event.setUser(getUserSessionItem("userName") ?? "User not in UserSession");
            event.addMetadata('API Arguments', {
                endpoint: api.endpoint,
                args: JSON.stringify(args),
            });
        })
        return {
            error: {
                reason: error
            }
        };
    }
}

export const serverlessFallbackApi = createApi({
    reducerPath: 'serverlessFallbackApi',
    baseQuery: baseFallbackQueryWithErrorManagement,
    endpoints: (builder) => ({
        getTSAccessTokenFallback: builder.query<{ token: string }, {orgID: Number}>({
            query: (args) =>  {
                const accessToken = getUserSessionItem('accessToken');
                const username = getUserSessionItem('userName');
                return {
                    url: '/thoughtspot/token',
                    method: "post",
                    body: {
                        username: username,
                        orgId: args.orgID,
                        accessToken: accessToken,
                        clientId: CognitoOidcConfig.clientId
                    }
                }
            }
        })
    }),
});

export const { 
    useLazyGetTSAccessTokenFallbackQuery
} = serverlessFallbackApi;
/*
export const thoughtSpotAccessTokenQuery: BaseQueryFn<
    number, // Args
    unknown, // Result
    { reason: any }, // Error
    {}, // DefinitionExtraOptions
    {} // Meta
> = async (arg, api, extraOptions) => {
    let accessToken = getUserSessionItem('accessToken');
    let username = getUserSessionItem("userName");
    try {
        const response = await API.post("ServerlessAPI", '/thoughtspot/token', {
            body: {
                username: username,
                orgId: arg,
                accessToken: accessToken,
                clientId: CognitoOidcConfig.clientId
            },
        });
        return { data: response.token };
    }
    catch (ex) {
        return { 
            error: {
                reason: ex
            }
        };
    }
}
*/