import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { embedQSearchBar } from "amazon-quicksight-embedding-sdk";
import React from "react";
import './QuickSightQEmbedWrapper.scss';
import { API } from "aws-amplify";

interface QuickSightQEmbedWrapperProps {
    accountId?: string;
    initialTopicId?: string;
    authorizedResourceArns?: string[];
}

export const QuickSightQEmbedWrapper = (props: QuickSightQEmbedWrapperProps) => {
    React.useEffect(() => {
        const getUrl = async () => {
            let data = await API.post("ServerlessAPI", "/embed/quicksight/topic", {
                body: {
                    "accessToken": "",
                    "accountId": props.accountId ?? "482021788001",
                    "region": "us-east-1",
                    "initialTopicId": props.initialTopicId ?? "J46c6BSo4HFiacgDK23JyxS1dQ6NhUFu",
                    "namespace": "default",
                    "authorizedResourceArns": props.authorizedResourceArns ?? ["arn:aws:quicksight:us-east-1:482021788001:topic/J46c6BSo4HFiacgDK23JyxS1dQ6NhUFu"]
                }
            });
            return Promise.resolve(data);
        }

        getUrl().then((data) => {
            console.log(data);
            var containerDiv = document.getElementById("embeddingContainer")!;
            var options = {
                url: data.EmbedUrl,
                container: containerDiv,
                width: "80%",
                locale: "en-US",
                qSearchBarOptions: {
                    //expandCallback: onOpen,
                    //collapseCallback: onClose,
                    iconDisabled: false,
                    topicNameDisabled: false,
                    themeId: 'theme12345',
                    allowTopicSelection: true
                }
            };
            embedQSearchBar(options);
        });
    }, []);
    
    return (
        <>
            <div style={{ height: "100%", width: "100%" }}>
                {false ? (
                    <div className="loading-div">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            size="2x"
                            spin={true}
                            style={{ margin: "0 auto" }}
                        />
                    </div>
                ) : (
                    <div id="embeddingContainer" className="quicksight-q-embed-container"></div>
                )}
            </div>
        </>
    );
}
