import { useNavigate } from "react-router-dom";

interface ButtonAndDescription {
    title: string;
    titleSettings?: TitleSettings;
    backgroundImageName?: string;
    headOfSubcategory?: boolean;
    link?: string;
    description?: string;
    internal?: boolean;
    overrideParentURL?: boolean;
}

interface TitleSettings {
    isTitle: boolean;
    isBigTitle: boolean;
}

interface Extra {
    internal: boolean;
    title: string;
    link: string;
}

interface TSLandingPageProps {
    /**
     * parentURL is the "suffix" of the link
     */
    parentURL: string;

    /**
     * each ButtonAndDescription[] array is a column, each ButtonAndDescription, a button
     */
    buttons: ButtonAndDescription[][];

    /**
     * 
     */
    smallButtons: boolean;

    extraButton?: Extra;
}

export const TSLandingPage = (props: TSLandingPageProps) => {
    const navigate = useNavigate();

    const makeButtonSet = () => {
        let buttonSet: JSX.Element[] = [];
        props.buttons.forEach((element) => {
            buttonSet.push(makeButtonColumn(element));
        });
        return (
            <div className="responsive-centered-row">
                {buttonSet}
            </div>
        );
    }

    const makeButtonColumn = (buttonColumn: ButtonAndDescription[]) => {
        let column: JSX.Element[] = [];

        let size: string = (props.smallButtons ? "lp-pill-smallest" : "lp-pill-small");
        buttonColumn.forEach((element) => {

            if (element.titleSettings?.isTitle) {
                let fontSize: string = (element.titleSettings.isBigTitle ? "lp-title" : "lp-title-small");
                column.push(
                    <div className={fontSize} key={element.title}>
                        {element.title}
                    </div>
                )
            } else {
                column.push(
                    <div key={element.title}>
                        <div style={{ display: (element.headOfSubcategory) ? "none" : "block" }}>
                            <div className="lp-title-small" key={element.title} style={{ visibility: "hidden" }}>
                                -
                            </div>
                        </div>
                        <div className={size}>
                            <div>
                                <div className="lp-pill-name">
                                    {element.title}
                                </div>
                                <div className="lp-pill-description">
                                    {element.description}
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary lp-pill-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(element.internal === true) {
                                            if (element.overrideParentURL === true){
                                                navigate(element.link!)
                                            } else {
                                                navigate(props.parentURL + "/" + element.link)
                                                }
                                        } else {
                                            navigate(props.parentURL + "?productURL=" + element.link);
                                        }
                                    }}
                                >
                                    Launch
                                </button>
                            </div>
                            <div className="lp-pill-background lp-pill-low-opacity" style={{ backgroundImage: "url(" + element.backgroundImageName + ")" }}></div>
                        </div>
                    </div>
                )
            }
        });

        return (
            <div className="d-flex flex-column">
                {column}
            </div>
        );
    }

    const makeExtraButton = () => {
        let button: JSX.Element = <div></div>;

        if (props.extraButton) {
            button =
                <div>
                    <button type="button" className="lp-extra-button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (props.extraButton!.internal){
                                navigate(props.extraButton!.link)
                            } else {
                                navigate(props.parentURL + "?productURL=" + props.extraButton!.link)
                            }
                        }}
                    >
                        {props.extraButton!.title}
                    </button>
                </div>
        }

        return button;
    }

    return (
        <div>
            <div style={{width: "98%", marginLeft: "auto"}}>
                {makeExtraButton()}
            </div>
            <div className="d-flex justify-content-center">
                {makeButtonSet()}
            </div>
        </div>
    );
}
