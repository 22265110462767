import { Navigate, useLocation } from "react-router-dom";

interface RedirectProps {
    redirectTo: string;
}

export const Redirect = (props: RedirectProps) => {
    const location = useLocation();
    return (
        <Navigate to={props.redirectTo + location.search} />
    )
}
