import { DataEncoding, tsDataObject } from "../services/types/gallus";
import { formatterWrapper } from "./PlotlyFormatter";

export type DisplayAsType = Array<{ nameOfColumn: string; encoding: DataEncoding }>;

type heatmapResultType = { names: string[]; metrics: string[]; values: string[][]; normalizedValues: number[][]; rawValues: number[][] };

//plotly accepts three values in a heatmap: X, Y and Z. The values need to be ordered.

export const TSToHeatmap = (data: tsDataObject): heatmapResultType => {

    var metrics: { columnNameArray: string[], columnIndexArray: number[] } = { columnNameArray: [], columnIndexArray: [] };
    var namesArray: string[] = [];
    var indexOfRowName: number = -1;
    var rawValuesArray: number[][] = [];
    var normalizedValuesArray: number[][] = [];
    var valuesArray: string[][] = [];

    data.columnDisplayName.forEach((column, indexOfColumn) => {
        if ((data.columnShow[indexOfColumn] === true) && (data.columnNames[indexOfColumn] !== "RowName")) {
            metrics.columnNameArray.push(column);
            metrics.columnIndexArray.push(indexOfColumn);
        }
        if (data.columnNames[indexOfColumn] === "RowName") {
            indexOfRowName = indexOfColumn;
        }
    })

    data.data.forEach((row) => {
        namesArray.push(row[indexOfRowName]);
        var tmpValArray: string[] = [];
        var tmpRawValArray: number[] = [];
        row.forEach((value, indexOfValue) => {
            if (metrics.columnIndexArray.includes(indexOfValue) && indexOfRowName !== indexOfValue) {
                tmpRawValArray.push(value);
                tmpValArray.push(formatterWrapper(value, data.columnFormatting[indexOfValue]))
            }
        })
        rawValuesArray.push(tmpRawValArray)
        valuesArray.push(tmpValArray);
    })

    var transposedNormalizedValuesArray = transposeMatrix(rawValuesArray).map((valuesOfColumn: number[]) => {
        return normalizeArray(valuesOfColumn);
    })

    return { metrics: metrics.columnNameArray, names: namesArray, values: valuesArray, normalizedValues: transposeMatrix(transposedNormalizedValuesArray), rawValues: rawValuesArray };
}

export const normalizeArray = (numberSet: any[], overrideMin?: number, invert?: boolean) => {
    const numberSetMin = Math.min(...numberSet);
    const numberSetMax = Math.max(...numberSet);

    let normalizedArray: number[] = [];
    numberSet.map((element: any) => {
        let valueToPush: number = 0;
        if ((numberSetMax !== numberSetMin) && (numberSetMax !== overrideMin!)) {
            if (element !== null) {
                if (typeof (overrideMin) !== "undefined") {
                    if ((element < overrideMin!)) {
                        valueToPush = 0;
                    } else {
                        valueToPush = (element - overrideMin!) / (numberSetMax - overrideMin!) * 100;
                    }
                } else {
                    valueToPush = (element - numberSetMin) / (numberSetMax - numberSetMin) * 100;
                }
            } else {
                (invert) ? (valueToPush = 100) : (valueToPush = 0);
            }
        } else {
            (invert) ? (valueToPush = 100) : (valueToPush = 0);
        }

        (invert) ? normalizedArray.push(100 - valueToPush) : normalizedArray.push(valueToPush);

    })
    return normalizedArray;
}

export const transposeMatrix = (matrix: any[][]) => {
    return Object.keys(matrix[0]).map(function (c: any) {
        return matrix.map(function (r) { return r[c]; });
    });
}