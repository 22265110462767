import Bugsnag from "@bugsnag/js";
import { useRouteError } from "react-router-dom";
import { getUserSessionItem } from "..";

export const RouterError = () => {
    const error = useRouteError();
    Bugsnag.notify(JSON.stringify(error), function (event) {
      event.severity = 'warning';
      event.context = "Route error";
      event.setUser(getUserSessionItem("userName") ?? "User not in UserSession");
      event.addMetadata('Params', {
          url: window.location
      });
    })
    return (
        <main style={{ padding: "1rem" }}>
            <p>You have accessed an invalid URL. We are constantly working to improve our site, so the page you are looking for might have been moved around.</p>
            <p>Please contact us at <a href="mailto:info@gallusinsights.com">info@gallusinsights.com</a> if you have any questions.</p>
        </main>
    );
}