import { API } from "aws-amplify";
import * as React from "react";
import { getUserSessionItem, refreshToken } from "..";
import { useAppSelector } from "../app/hooks";
import { CognitoJwtPayload, selectUserAuthContext } from "../features/console/consoleSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { CognitoOidcConfig } from "../CognitoOidcConfig";
import { useLazyGetProductsQuery } from "../services/gallus";

/**
 * @param groups: An array containing the groups that can see this content.
 * @param element: The element to be displayed
 */
interface SecureRouteProps extends React.PropsWithChildren {
    /**
     * A string array containing the groups that can see this content.
     */
    groups?: string[];
    /**
     * The product name the element belongs to.
     */
    productName?: string;
    /**
     * The element to be displayed
     */
    element: JSX.Element;
}

/**
 * Component that verifies if the user is logged in and the token is valid, and makes sure the user has priviledges to see the component they are looking for
 * @param props groups: A string array containing the groups that can see this content.
 * @param props element: The element to be displayed
 * @returns Depending on the state of the user, it returns the requested module, a log in page, or an "access denied" page
 */
export default function SecureRoute(props: SecureRouteProps) {
    const userAuthContext = useAppSelector(selectUserAuthContext);
    const navigate = useNavigate();
    const [userSession, setUserSession] = React.useState(getUserSessionItem('user'));
    // let userSession = getUserSessionItem('user');
    let idToken = getUserSessionItem('idToken');
    const [isValidating, setIsValidating] = React.useState<boolean>(true);
    const [isProductEnabled, setIsProductEnabled] = React.useState<boolean>(true);
    const location = useLocation();
    const [getProductsQuery, getProductsQueryResult] = useLazyGetProductsQuery();

    const init = async () => {
        await refreshToken();
        setUserSession(getUserSessionItem('user'));
        idToken = getUserSessionItem('idToken');
        const validateToken = async (idToken: string): Promise<void> => {
            let data: { isValid: boolean; payload: any } = await API.post("ServerlessAPI", "/jwt/", {
                body: {
                    clientId: CognitoOidcConfig.clientId,
                    idToken: idToken,
                },
            });
            if (!data.isValid) {
                setUserSession(null);
            }
            setIsValidating(false);
        }

        if (idToken && idToken !== userAuthContext?.idToken) {
            setIsValidating(true);
            try {
                await validateToken(idToken);
            }
            catch (e) {
                console.error(e);
                setIsValidating(false);
                setUserSession(null);
            }
        }
        setIsValidating(false);
    };

    React.useEffect(() => {
        if (props.productName) {
            getProductsQuery();
        }
        else {
            setIsProductEnabled(true);
            init();
        }
    }, [props]);

    React.useEffect(() => {
        if (getProductsQueryResult.isSuccess && getProductsQueryResult.status === 'fulfilled') {
            let currentProduct = getProductsQueryResult.data.find((product) => product.Name === props.productName ?? "");
            if (currentProduct && !currentProduct.Enabled){
                setIsProductEnabled(false);
            }
        }
        init();
    }, [getProductsQueryResult]);

    const render = () => {
        if (!isValidating) {
            if (userSession) {
                //if user is logged in...
                let user = JSON.parse(userSession) as CognitoJwtPayload; //get user info
                if ((user["cognito:groups"]?.find((g) => props.groups === undefined || (props.groups && (props.groups.find((ag) => ag === g) ?? false))) ?? false) && isProductEnabled) {
                    //if user is in correct group...
                    return props.element; //give the user access
                }
                else {
                    return (
                        <Container>
                            <Alert color="danger" className="mt-2">
                                <FontAwesomeIcon icon={faBan} className="me-2" size="lg" />Access Denied.
                            </Alert>
                        </Container>
                    );
                }
            } else {
                //if user is not logged in...
                navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
            }
        }
        return <div></div>;
    };

    return render();
}
