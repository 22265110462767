import { AgGridReact } from 'ag-grid-react';
import { ColumnResizedEvent, RowClassParams } from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import { OptionsMenu } from '../../../features/OptionsMenu';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Select, { SingleValue } from 'react-select';
import { ReactSelectOption } from '../../../features/Interfaces';
import { useGetGRateDatesQuery, useLazyGetGRateTableDataQuery } from "../../../services/gallus";
import { DisplayAsType, TSToAgGrid, convertOldDisplayAs } from '../../../features/TSToAgGrid';
import { Error, tryAgainLaterMessage } from "../../../components/Error";

interface GrateTableProps {
    title: string;
    table: "Hedge" | "AggregateTable";
}

export const GrateTable = (props: GrateTableProps) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [table, setTable] = React.useState<JSX.Element>(<></>);
    const [period, setPeriod] = React.useState<ReactSelectOption>({ label: "-1", value: "-1" });
    const [availablePeriods, setAvailablePeriods] = React.useState<ReactSelectOption[]>([]);
    const GRateDates = useGetGRateDatesQuery({ table: props.table });
    const [GRateDataQuery, GRateDataResults] = useLazyGetGRateTableDataQuery();
    const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
    const [errorCode, setErrorCode] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [errorType, setErrorType] = React.useState<string>("");

    useEffect(() => {
        setPeriod(availablePeriods[1]);
    }, [availablePeriods]);

    useEffect(() => {
        if (typeof (period) !== "undefined") {
            GRateDataQuery({ table: props.table, period: period.value });
        }
    }, [period]);

    useEffect(() => {
        if (GRateDates.isSuccess && GRateDates.status === "fulfilled") {
            setLoading(true);
            var availablePeriodsTMP: ReactSelectOption[] = [];
            GRateDates.data.forEach((periodPlainText: string) => {
                availablePeriodsTMP.push({ label: periodPlainText, value: periodPlainText });
            })
            setAvailablePeriods(availablePeriodsTMP);
            setErrorFlag(false);
        } else if (GRateDates.isError) {
            setErrorFlag(true);
            setLoading(false);
            setErrorCode("500");
            setErrorMessage(tryAgainLaterMessage);
            setErrorType("InternalAPI");
        }
    }, [GRateDates])

    useEffect(() => {
        if (GRateDataResults.isSuccess && GRateDataResults.status === "fulfilled") {
            setLoading(true);
            var rowsWithFormat: any[] = [];
            var result;

            if (typeof (period) !== "undefined" && period.value !== "-1") {
                switch (props.table) {
                    case 'Hedge':
                        var DisplayAs: DisplayAsType = [];
                        //for (var i = 0; i < GRateDataResults.data.results.columnNames.length - 1; i++) {
                        //   DisplayAs.push("$-s");
                        //}
                        /*
                        result = TSToAgGrid(GRateDataResults.data.results, DisplayAs, false, undefined, undefined,
                            [{
                                nameOfGroup: "Activity as of " + period.value, headerClass: 'grate-group',
                                columnsToGroup: ["Today", "Reference Date", "Net"]
                            },
                            {
                                nameOfGroup: "Act. > " + period.value, headerClass: 'grate-group',
                                columnsToGroup: ["Post Ref Date", "Total Net"]
                            }],
                            [{ columnName: "RowName", className: "" }, { columnName: "Today", className: "grate-column" }, { columnName: "Reference Date", className: "grate-column" }, { columnName: "Net", className: "grate-column" }, { columnName: "Post Ref Date", className: "grate-column" }, { columnName: "Total Net", className: "grate-column" }],
                            undefined,
                            ["Id", "CSSClass", "Month(FileExtractDate)", "Fecha"]);


                        result.rows.forEach((element) => {
                            rowsWithFormat.push(element.formatted)
                        })

                        setTable(<div style={{ display: "100%" }}>
                            <AgGridReact
                                domLayout={"autoHeight"}
                                defaultColDef={{
                                    cellClass: 'grate-normal-text',
                                }}
                                rowClassRules={generateRowClassRules(result.rowStyles)}
                                rowData={rowsWithFormat}
                                columnDefs={result.columnsWithGrouping}
                            />
                        </div>)*/
                        break;
                    case 'AggregateTable':
                        var DisplayAs: DisplayAsType = convertOldDisplayAs(["$-s"], GRateDataResults.data.results.columnNames, ["Id", "CSSClass", "Month(FileExtractDate)", "Fecha"]);
                    /*
                                            result = TSToAgGrid(GRateDataResults.data.results, DisplayAs, false, undefined, undefined,
                                                [{
                                                    nameOfGroup: "Activity as of " + period.value, headerClass: 'grate-group',
                                                    columnsToGroup: ["Total ActivityTodayValue", "Total DateValue", "Total TodayNet"]
                                                }],
                                                [{ columnName: "RowName", className: "" }, { columnName: "Total ActivityTodayValue", className: "grate-column" }, { columnName: "Total DateValue", className: "grate-column" }, { columnName: "Total TodayNet", className: "grate-column" }],
                                                undefined,
                                                ["Id", "CSSClass", "Month(FileExtractDate)", "Fecha"]);
                    
                    
                                            result.rows.forEach((element) => {
                                                rowsWithFormat.push(element.formatted)
                                            })
                    
                                            setTable(<div style={{ display: "100%" }}>
                                                <AgGridReact
                                                    domLayout={"autoHeight"}
                                                    defaultColDef={{
                                                        cellClass: 'grate-normal-text',
                                                    }}
                                                    rowClassRules={generateRowClassRules(result.rowStyles)}
                                                    rowData={rowsWithFormat}
                                                    columnDefs={result.columnsWithGrouping}
                                                />
                                            </div>) */
                }

                setErrorFlag(false);
                setLoading(false);
            } 
        } else if (GRateDataResults.isError) {
                setErrorFlag(true);
                setLoading(false);
                setErrorCode("500");
                setErrorMessage(tryAgainLaterMessage);
                setErrorType("InternalAPI");
            }
    }, [GRateDataResults])

    return (
        <div className="module-master" style={{ paddingRight: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="module-title">
                    {props.title}
                </div>
            </div>
            <div>
                {loading &&
                    <div className="loading-div">
                        <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                    </div>
                }
                {errorFlag &&
                    <div>
                        <Error code={errorCode} type={errorType} message={errorMessage} />
                    </div>
                }
                {!loading && !errorFlag &&
                    <div>
                        <OptionsMenu
                            customObject={
                                <div style={{ display: "flex" }}>
                                    <div className="option-in-card">
                                        <div style={{ paddingRight: "5px" }}>
                                            Filter by Period:
                                        </div>
                                        <Select
                                            placeholder="Select Period"
                                            onChange={(selectedPeriod: SingleValue<ReactSelectOption>) => setPeriod({ label: selectedPeriod!.value, value: selectedPeriod!.value })}
                                            options={availablePeriods}
                                            className="basic-multi-select width-min-130"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            }
                        />
                        {table}
                    </div>
                }
            </div>
        </div>
    )
}
