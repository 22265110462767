import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import 'ag-grid-community/styles/ag-grid.css';
import { OptionsMenu } from "../../../features/OptionsMenu";
import { agGridObject } from "../../../features/ExportToCSV";
import Plot from "react-plotly.js"
import { TSToAgGrid } from "../../../features/TSToAgGrid";
import { AgGridReact } from 'ag-grid-react';
import { ColumnResizedEvent } from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import { tsDataObject } from "../../../services/types/gallus";
import { GrateMBAMoneyQ3, GrateMBABasisPointsQ3 } from "./static-mba-info-q3";
import { GrateMBAMoneyQ4, GrateMBABasisPointsQ4 } from "./static-mba-info-q4";
import { financialSymbolFormatter } from "../../../features/PlotlyFormatter";

interface MBABenchmarkProps {
    overrideTitle?: string;
    period: "Q3" | "Q4";
}

export const MBABenchmark = (props: MBABenchmarkProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [csvData, setCSVData] = React.useState<agGridObject<string>[]>([]);

    const [tableArray, setTableArray] = React.useState<JSX.Element[]>([]);

    const onGridReady = (params: ColumnResizedEvent) => {
        params.api.sizeColumnsToFit();
    };

    useEffect(() => {
        var tableArray: JSX.Element[] = []
        switch (props.period) {
            case "Q3":
                tableArray = [renderTable(GrateMBAMoneyQ3), renderTable(GrateMBABasisPointsQ3)];
                break;
            case "Q4":
                tableArray = [renderTable(GrateMBAMoneyQ4), renderTable(GrateMBABasisPointsQ4)];
                break;
        }

        setTableArray(tableArray);
        setLoading(false);
    }, [props.period])

    const PlotlyDefaultMargin = {
        margin: {
            l: 10,
            r: 10,
            b: 30
        }
    };

    const renderBarChart = (data: number[], title: string, label: string, symbolFormatting: boolean, decimals: boolean) => {
        var colorArray: string[] = [];
        data.forEach((value) => { (value > 0) ? colorArray.push("rgb(50, 80, 168)") : colorArray.push("rgb(168, 50, 50)") })
        return <div style={{ minHeight: "500px", minWidth: "50%" }}>
        <Plot
            data={[{
                x: ["G-Rate", "Total Industry", "Tot. Ind. >1B", "Retail / CD", "Ret. / CD > 500m"],
                y: data,
                type: 'bar',
                text: data.map((value) => { return financialSymbolFormatter(value, symbolFormatting, decimals, false, false) }),
                textfont: {
                    size: 16,
                },
                textposition: "inside",
                hoverinfo: 'none',
                name: label,
                marker: {
                    color: colorArray
                },
            }]}
            layout={{
                ...PlotlyDefaultMargin,
                barmode: "stack",
                title: {
                    text: "<b>" + title + "</b>",
                    y: 0.95, x: 0.50
                },
                legend: { "orientation": "h" },
                yaxis: {
                    visible: false,
                    showticklabels: false
                },
                autosize: true
            }}
            useResizeHandler
            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
            className="w-100"
        /></div> 
    }

    const renderTable = (data: tsDataObject) => {
        var rowsWithFormat: any[] = [];
        var rowsWithoutFormat: any[] = [];
        var result;

        result = TSToAgGrid(data, false);

        result.rows.forEach((element) => {
            rowsWithFormat.push(element.formatted)
        })

        result.rows.forEach((element) => {
            rowsWithoutFormat.push(element.notFormatted)
        })

        let tmpcsv = csvData;
        let newData = { title: "MBABenchmark", columnDefs: result.columns, rowDefs: rowsWithoutFormat };
        if (!csvData.find((e) => e === newData)) {
            tmpcsv.push(newData);
        }
        setCSVData(tmpcsv);
        console.log("Generated CSV");

        return <div style={{ paddingTop: "15px" }}>
            <div className="grid-title">
                {result.title}
            </div>
            <AgGridReact
                domLayout={"autoHeight"}
                rowClassRules={result.rowStyles}
                rowData={rowsWithFormat}
                columnDefs={result.columnsWithGrouping}
                onGridReady={onGridReady}
                containerStyle={{ paddingLeft: "15px", paddingBottom: "15px" }}
                suppressFieldDotNotation={true}
            />
        </div>
    }

    const barChartWrapper = () => {
        var chartNumbersArray: number[][] = [];
        switch (props.period) {
            case "Q3":
                chartNumbersArray = [[0, 10426, 9608, 10983, 11229],
                [0, 329, 300, 344, 343],
                [0, 7095, 6313, 7476, 7378],
                [0, 225, 198, 237, 225],
                [0, 11441, 9960, 11626, 10868],
                [0, 363, 313, 368, 334],
                [0, -1015, -352, -643, 361],
                [0, -34, -13, -24, 9]]
                break;
            case "Q4":
                chartNumbersArray = [[0, 10375.802531, 9734.7895895, 11052.712676, 11081.961956],
                    [0, 334.18042838, 304.59201804, 356.50674776, 349.21384697],
                    [0, 7465.1432231, 6690.02832023, 8047.28006702, 7707.09726551],
                    [0, 243.904238828, 213.636680205, 263.400193065, 245.038252441],
                    [0, 12485.17710443, 10286.57746586, 13089.36260401, 11705.16440879],
                    [0, 406.924662853, 328.234459078, 428.259673558, 376.994124763],
                    [0, -2109.37457343, -551.78787636, -2036.64992801, -623.202452790001],
                    [0, -72.744234473, -23.6424410379999, -71.752925798, -27.7802777929999]]
                break;
        }

        return <div>
            <div style={{ display: "flex" }}>
                {renderBarChart(chartNumbersArray[0], "Revenue Per Loan", "Revenue", true, true)}
                {renderBarChart(chartNumbersArray[1], "Revenue (basis points)", "Revenue", false, false)}
            </div>
            <div style={{ display: "flex" }}>
                {renderBarChart(chartNumbersArray[2], "Personnel Expense per Loan", "Personnel", true, true)}
                {renderBarChart(chartNumbersArray[3], "Personnel Expense (basis points)", "Personnel", false, false)}
            </div>
            <div style={{ display: "flex" }}>
                {renderBarChart(chartNumbersArray[4], "Total Expense per Loan", "Total Expense", true, true)}
                {renderBarChart(chartNumbersArray[5], "Total Expense (basis points)", "Total Expense", false, false)}
            </div>
            <div style={{ display: "flex" }}>
                {renderBarChart(chartNumbersArray[6], "Net Production Income per Loan", "Net Prod. Income", true, true)}
                {renderBarChart(chartNumbersArray[7], "Net Production Income (basis points)", "Net Prod. Income", false, false)}
            </div>
        </div>
    }

    return (
        <div className="module-master" style={{ padding: '10px 10px 0 20px' }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="module-title">
                    MBA Benchmark
                </div>
            </div>
            <div>
                {loading &&
                    (
                        <div className="loading-div">
                            <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                        </div>
                    )}
                {!loading && (
                    <div>
                        <OptionsMenu
                            exportToCSV={{
                                filename: `${typeof (props.overrideTitle) !== "undefined" ? (props.overrideTitle) : ("MBABenchmark")}`,
                                CSVData: csvData,
                            }}
                        />
                        <div style={{ height: "100%", width: "100%" }}>
                            {tableArray}
                        </div>
                        {barChartWrapper()}
                    </div>
                )}
            </div>
        </div>
    );
}
