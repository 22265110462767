import { PropsWithChildren, useEffect, useState } from "react";
import { Product } from "../services/types/gallus";
import { useGetProductsQuery, useLazyGetSubscriptionsQuery } from "../services/gallus";
import { useAppSelector } from "../app/hooks";
import { CognitoJwtPayload, selectUserAuthContext, updateUserAuthContext } from "../features/console/consoleSlice";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { SubscribeProductCard } from "./SubscribeProductCard";
import { Error } from "./Error";

interface ProductSubscriptionGuard {
    product: string;
} 

export const ProductSubscriptionGuard = (props: PropsWithChildren<ProductSubscriptionGuard>) => {
    const dispatch = useDispatch();
    const userAuthContext = useAppSelector(selectUserAuthContext);

    const [hasAccess, setHasAccess] = useState<boolean>();
    const [targetProduct, setTargetProduct] = useState<Product>();
    const [hasError, setHasError] = useState<boolean>(false);

    const productsQuery = useGetProductsQuery();
    const [subscriptionsQuery, subscriptionsQueryResult] = useLazyGetSubscriptionsQuery();

    useEffect(() => {
        const init = async () => {
            if (!userAuthContext) {
                const session = await Auth.currentSession();
                dispatch(updateUserAuthContext({
                    idTokenPayload: session.getIdToken().payload as CognitoJwtPayload,
                    idToken: session.getIdToken().getJwtToken(),
                    accessToken: session.getAccessToken().getJwtToken(),
                    refreshToken: session.getRefreshToken().getToken()
                }));
            }
        };
        init();
    }, []);

    useEffect(() => {
        const init = async () => {
            //const [products, subscribedProducts] = await Promise.all([getProducts(), getSubscriptions(userAuthContext!.idTokenPayload["sub"])]);
            subscriptionsQuery({cognitoSubject: userAuthContext!.idTokenPayload["sub"]})
            //if (products.retCode === 200 && subscribedProducts.retCode === 200) {
            if ((productsQuery.isSuccess && productsQuery.status === "fulfilled") && (subscriptionsQueryResult.isSuccess && subscriptionsQueryResult.status === "fulfilled")) {
                const targetProduct = productsQuery.data.find(product => product.Name === props.product);
                let hasValidSubscription = false;
                let isFreeProduct = false;
                hasValidSubscription = subscriptionsQueryResult.data.some((subscription) => {
                    return subscription.DateEnd === null || new Date(subscription.DateEnd) >= new Date();
                });
                if (targetProduct?.IsFree) {
                    isFreeProduct = true;
                }
                setHasError(false);
                setHasAccess(hasValidSubscription || isFreeProduct);
                setTargetProduct(targetProduct);
            }
            else {
                setHasError(true);    
            }
        }
        userAuthContext && init();
    }, [userAuthContext]);

    return (
        <>
            {hasError && (
                <Error code="404" type="Internal" message={`We could not find your subscription of ${props.product}. Please contact us.`} />
            )}
            {!hasError && hasAccess !== undefined && (
                <>
                    {hasAccess && <>{props.children}</>}
                    {!hasAccess && (
                        <Container fluid className="mt-2">
                            <Row>
                                <Col md={{offset: 4, size: 4}}>
                                    <div className="alert alert-info" role="alert">
                                       You do not have a subscription of {props.product} or your subscription of {props.product} has expired. Should you have any questions regarding your subscription, please contact us.
                                    </div>
                                    {targetProduct && <SubscribeProductCard product={targetProduct}/>}
                                </Col>
                            </Row>
                        </Container>
                        
                    )}
                </>
            )}
        </>
    );
}
