import { ButtonGroup } from "./Interfaces";

export interface button {
    name: string;
    onClick: () => void;
    disableCondition?: () => boolean;
    highlighted?: boolean;
}

interface ButtonGroupsProps {
    onClick: (id: string) => void;
    clickedButtonID: string;
    buttonGroups: ButtonGroup[];
}

export const ButtonGroups = (props: ButtonGroupsProps) => {
    const makeIndex = (arrayNumber: number, buttonNumber: number) => {
        return "BA" + arrayNumber.toString() + "-" + buttonNumber.toString();
    }

    const renderButtonGroup = (buttonGroup: ButtonGroup, buttonGroupIndex: number) => {
        return buttonGroup.buttons.map((element, buttonIndex) => {
            return (
                <button className={element.highlighted ? "btn clicked-button" : "btn"} 
                    key={makeIndex(buttonGroupIndex, buttonIndex)} 
                    onClick={() => { element.onClick(); props.onClick(makeIndex(buttonGroupIndex, buttonIndex)) }} 
                    disabled={(typeof (element.disableCondition) === 'function') ? element.disableCondition() : false}
                >
                    {element.name}
                </button>
            );
        });
    }

    const render = () => {
        return props.buttonGroups.map((buttonGroup, index) => {
            return (
                <div key={index} className="option-in-card">
                    <div style={{ paddingRight: "5px" }}>
                        {buttonGroup.title}
                    </div>
                    <div style={{ paddingLeft: "5px" }}>
                        {renderButtonGroup(buttonGroup, index)}
                    </div>
                </div>
            );
        });
    }

    return <>{render()}</>;
}
