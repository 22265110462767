import React, { useEffect, useState } from "react";
import axios from "axios";
import './IncomeStatement.scss';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { ColumnResizedEvent } from 'ag-grid-community';
//import { TrialBalance_Get, TrialBalance_GetFiscalYearFiscalQuarter } from "../../../api/Api";
import { useTrialBalance_GetFiscalYearFiscalQuarterQuery, useTrialBalance_GetQuery } from "../../../services/gallus";
import { Error } from "../../../components/Error";

const client = axios.create({
    baseURL: "https://api-v3.gallusanalytics.com/services/"
});

const memoNumberFormat = (value: string, type: string) => {
    let result: string = "";
    switch (type) {
        case "number":
            if (parseFloat(value) < 0) {
                result = "(" + Math.round(-1 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")";
            } else {
                result = Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            break;
        case "percentage":
            if (parseFloat(value) < 0) {
                result = "(" + Math.round(-100 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%)";
            } else {
                result = Math.round(100 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
            }
            break;
        case "ratio":
            result = Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "x";
            break;
        case "money":
            result = "$" + Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            break;
    }
    return result;
}

const tableNumberFormat = (data: string) => {
    let result;
    let dataNumber: number = parseFloat(data);
    let processedData: number = Math.round(dataNumber);
    if (processedData < 0) {
        result = "(" + (-1 * processedData).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")";
    } else {
        if (processedData === 0) {
            result = "-";
        } else {
            result = processedData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    return result;
}

interface IncomeStatementProps { }

interface IncomeStatementState {
    columns: gridColumnAccountType[];
    columnsMemo: gridColumnAccountType[];
    data: gridRowType[];
    memo: gridRowType[];
    loadedColumns: boolean;
    loadedData: boolean;
    errorFlag: boolean;
    errorCode: string;
    errorType: string;
    errorMessage: string;
}

interface accountQueryType { //this amounts to a row. An array is the entire data collection
    Name: string;
    Items: accountCellType[];
}

interface accountCellType {
    FiscalYear: string;
    FiscalQuarter: string;
    Value: string;
}

interface gridColumnAccountType {
    field: string;
}

interface gridRowType {
    [key: string]: string;
}

export const IncomeStatement = (props: IncomeStatementProps) => {
    const [state, setState] = useState<IncomeStatementState>({
        columns: [],
        columnsMemo: [],
        data: [],
        memo: [],
        loadedColumns: false,
        loadedData: false,
        errorFlag: false,
        errorCode: "0",
        errorType: "",
        errorMessage: "",
    });

    const TrialBalance_GetResult = useTrialBalance_GetQuery();
    const TrialBalance_GetFiscalYearFiscalQuarterResult = useTrialBalance_GetFiscalYearFiscalQuarterQuery();

    useEffect(() => {
        if (TrialBalance_GetFiscalYearFiscalQuarterResult.isSuccess && TrialBalance_GetFiscalYearFiscalQuarterResult.status === "fulfilled") {
            const response = TrialBalance_GetFiscalYearFiscalQuarterResult.data;
            const gridColumns: gridColumnAccountType[] = [{ field: 'Account' }];
            const gridColumnsMemo: gridColumnAccountType[] = [{ field: 'Memo Items' }];
            for (let i = 0; i < response.results.length; i++) {
                if ((response.results[i].FiscalYear !== "2019") && (response.results[i].FiscalYear !== "2020") && !(response.results[i].FiscalYear === "2022" && response.results[i].FiscalQuarter === "Q3")) {
                    gridColumns.push({ field: response.results[i].FiscalQuarter + "-" + response.results[i].FiscalYear.slice(2) });
                    gridColumnsMemo.push({ field: response.results[i].FiscalQuarter + "-" + response.results[i].FiscalYear.slice(2) });
                }
            }

            gridColumns.push({ field: "2021" });
            gridColumns.push({ field: "LTM Q2 2022" });

            setState({ ...state, columns: gridColumns, columnsMemo: gridColumnsMemo, loadedColumns: true,
                errorFlag: false });
        } else if (TrialBalance_GetFiscalYearFiscalQuarterResult.isError) {
            console.log('FAILED to initialize parameters');
            console.log(TrialBalance_GetFiscalYearFiscalQuarterResult.error);
            setState({
                ...state,
                errorFlag: true,
                errorCode: "500",
                errorType: "Internal",
                errorMessage: "FAILED to initialize parameters",
            });
        }
    }, [TrialBalance_GetFiscalYearFiscalQuarterResult])

    useEffect(() => {
        if (TrialBalance_GetResult.isSuccess && TrialBalance_GetResult.status === "fulfilled" && state.loadedColumns) {
            const response = TrialBalance_GetResult.data;
            var processedData: gridRowType[] = []; //the arrays that will be assigned to the state
            var processedMemo: gridRowType[] = [];
            var memoItemsNames: string[] = ["Revenue Growth", "Operating Margin", "EBITDA", "EBITDA Margin"];
            var LTMQ22022: string[] = ["Q3-21", "Q4-21", "Q1-22", "Q2-22"];
            var Total2021: number = 0;
            var TotalLTMQ22022: number = 0;

            response.results.forEach((element: accountQueryType) => {
                let processedValue: gridRowType = {}; //a row
                Total2021 = 0;
                TotalLTMQ22022 = 0;
                element.Items.forEach((cell: accountCellType) => {
                    if ((cell.FiscalYear !== "2019") && (cell.FiscalYear !== "2020") && !(cell.FiscalYear === "2022" && cell.FiscalQuarter === "Q3")) {
                        let columnName: string = cell.FiscalQuarter + "-" + cell.FiscalYear.slice(2);
                        if (memoItemsNames.includes(element.Name)) {
                            processedValue["Memo Items"] = element.Name;
                            if (element.Name === "EBITDA") {
                                processedValue[columnName] = memoNumberFormat(cell.Value, "number");
                            } else {
                                processedValue[columnName] = memoNumberFormat(cell.Value, "percentage");
                            }
                        } else {
                            processedValue["Account"] = element.Name;
                            processedValue[columnName] = tableNumberFormat(cell.Value);
                        }

                        if (cell.FiscalYear === "2021") {
                            Total2021 = Total2021 + parseFloat(cell.Value);
                        }

                        if (LTMQ22022.includes(cell.FiscalQuarter + "-" + cell.FiscalYear.slice(2))) {
                            TotalLTMQ22022 = TotalLTMQ22022 + parseFloat(cell.Value);
                        }
                    }
                });

                processedValue["2021"] = tableNumberFormat(Total2021.toString());
                processedValue["LTM Q2 2022"] = tableNumberFormat(TotalLTMQ22022.toString());
                if (memoItemsNames.includes(element.Name)) {
                    processedMemo.push(processedValue);
                } else {
                    processedData.push(processedValue);
                }

            });
            setState({ ...state, data: processedData, memo: processedMemo, loadedData: true,
                errorFlag: false });
        } else if (TrialBalance_GetResult.isError) {
            console.log('FAILED to initialize parameters');
            console.log(TrialBalance_GetResult.error);
            setState({
                ...state,
                errorFlag: true,
                errorCode: "500",
                errorType: "Internal",
                errorMessage: "FAILED to initialize parameters",
            });
        }
    }, [TrialBalance_GetResult, state.loadedColumns])

    const onGridReady = (params: ColumnResizedEvent) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <div className="income-statement-master">
            {!(state.loadedColumns && state.loadedData) &&
                <div className="loading-div">
                    <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                </div>
            }
            {state.errorFlag &&
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            }
            {state.loadedColumns && state.loadedData && !state.errorFlag &&
                <div className="income-statement-tables-div">
                    <div className="income-statement-table-title">
                        Income Statement
                    </div>
                    <div className="income-statement-table-subtitle">
                        (in Thousands)
                    </div>
                    <div className="income-statement-table">
                        <AgGridReact
                            domLayout={"autoHeight"}
                            defaultColDef={{
                                cellClass: 'text',
                                headerClass: 'column',
                                resizable: true,
                            }}
                            onGridReady={onGridReady}
                            rowClassRules={{
                                "highlight": function (params: any) {
                                    let differentColors: string[] = ["Total Revenue",
                                        "Total Expenses",
                                        "Operating Income",
                                        "Income Before Taxes",
                                        "Net Income"];
                                    return differentColors.includes(params.data.Account);
                                }
                            }}
                            rowData={state.data}
                            columnDefs={state.columns}
                        />
                    </div>

                    <div className="income-statement-table">
                        <AgGridReact
                            domLayout={"autoHeight"}

                            onGridReady={onGridReady}
                            defaultColDef={{
                                cellClass: 'text',
                                headerClass: 'column',
                                resizable: true,
                            }}
                            rowData={state.memo}
                            columnDefs={state.columnsMemo}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
