import "./UserValidation.scss"

/**
 * Checks if the username is valid.
 * @param username The username
 * @returns Boolean, true represents valid username.
 */
export function checkValidUsername(username: string) {
    return !(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(username));
}

/**
 * Checks if the user's email address is NOT from a public email provider.
 * @param email Email Address.
 * @returns Boolean, true represents valid email.
 */

export function checkEmailAddress(email: string) {
    var forbiddenEmails = [
        ".*@gmail",
        ".*@yahoo",
        ".*@live",
        ".*@aol",
        ".*@hotmail",
        ".*@outlook",
        ".*@icloud",
        ".*@proton.me"
    ];

    var joinForbiddenEmails = new RegExp(forbiddenEmails.join('|'), "i"); //case insensitive flag i

    return !joinForbiddenEmails.test(email);
}

export function checkMatchingPasswords(originalPassword: string, confirmationPassword: string) {
    return originalPassword === confirmationPassword;
}

export function validatePasswordLength(password: string) {
    return password.length >= 8;
}

export function validateLowercase(password: string) {
    return password.toUpperCase() !== password;
}

export function validateUppercase(password: string) {
    return password.toLowerCase() !== password;
}

export function validateSpecial(password: string) {
    return /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password);
}

export function validateNumber(password: string) {
    return /\d/.test(password);
}

export function passwordErrors(displayFlag: boolean, length: boolean, uppercase: boolean, lowercase: boolean, number: boolean, special: boolean, match: boolean) {
    return (
        <div className="signup-error-generic" style={{ display: ((displayFlag) ? 'block' : 'none') }}>
            <div style={{ display: ((!length) ? 'block' : 'none'), padding: 5 }}>
                * Your password must have at least 8 characters.
            </div>
            <div style={{ display: ((!uppercase) ? 'block' : 'none'), padding: 5 }}>
                Your password must have at least 1 uppercase letter.
            </div>
            <div style={{ display: ((!lowercase) ? 'block' : 'none'), padding: 5 }}>
                Your password must have at least 1 lowercase letter.
            </div>
            <div style={{ display: ((!number) ? 'block' : 'none'), padding: 5 }}>
                Your password must have at least 1 number.
            </div>
            <div style={{ display: ((!special) ? 'block' : 'none'), padding: 5 }}>
                Your password must have at least 1 special character.
            </div>
            <div style={{ display: ((!match) ? 'block' : 'none'), padding: 5 }}>
                The passwords don't match.
            </div>
        </div>
    )
}
