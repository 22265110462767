import { useEffect, useState } from "react";
import './RocketSheet.scss';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { ColumnResizedEvent } from 'ag-grid-community';
import { useXlsxProcessorQuery } from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

const numberFormat = (value: string, type: string) => {
    let result: string = "";
    switch (type) {
        case "number":
            if (parseFloat(value) < 0) {
                result = "(" + Math.round(-1 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")";
            } else {
                result = Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            break;
        case "percentage":
            if (parseFloat(value) < 0) {
                result = "-" + Math.round(-100 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
            } else {
                result = Math.round(100 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
            }
            break;
        case "ratio":
            result = Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "x";
            break;
        case "money":
            if (parseFloat(value) < 0) {
                result = "($" + Math.round(-1 * parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")";
            } else {
                result = "$" + Math.round(parseFloat(value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            break;
    }
    return result;
}

interface RocketIncomeStatementProps { }

interface RocketIncomeStatementState {
    columns: GridColumnAccountType[];
    data: GridRowType[];
    loadedData: boolean;
    errorFlag: boolean;
    errorCode: string;
    errorMessage: string;
    errorType: string;
}

interface GridColumnAccountType {
    field: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    suppressSizeToFit?: boolean;
}

interface GridRowType {
    [key: string]: string;
}

export const RocketIncomeStatement = (props: RocketIncomeStatementProps) => {
    const [state, setState] = useState<RocketIncomeStatementState>({
        columns: [{ field: "Accounts", width: 200, suppressSizeToFit: true }, { field: "Q4-22", width: 80, suppressSizeToFit: true }, { field: "Q3-22", width: 80, suppressSizeToFit: true }, { field: "Change % vs Q3-22" }, { field: "Change % vs Q4-21" }, { field: "Dec-22 YTD", width: 120, suppressSizeToFit: true }, { field: "Change % vs Dec-21 YTD", width: 220, suppressSizeToFit: true }],
        data: [],
        loadedData: false,
        errorFlag: false,
        errorCode: "",
        errorMessage: "",
        errorType: "",
    });

    const ChartData = useXlsxProcessorQuery({
        "DataSource": "https://docs.google.com/spreadsheets/d/1PWu461r9p_NonDSQLtkwSlRNFtAEWftp/",
        "InputSheetName": null,
        "Inputs": [],
        "OutputSheetName": "Webpage",
        "Outputs": [
            { "RowIndex": 7, "CellIndex": 3, "Value": null }, //Gain on sale of loans
            { "RowIndex": 7, "CellIndex": 4, "Value": null },
            { "RowIndex": 7, "CellIndex": 6, "Value": null },
            { "RowIndex": 7, "CellIndex": 7, "Value": null },
            { "RowIndex": 7, "CellIndex": 10, "Value": null },
            { "RowIndex": 7, "CellIndex": 12, "Value": null },
            { "RowIndex": 8, "CellIndex": 3, "Value": null }, //Servicing fee income
            { "RowIndex": 8, "CellIndex": 4, "Value": null },
            { "RowIndex": 8, "CellIndex": 6, "Value": null },
            { "RowIndex": 8, "CellIndex": 7, "Value": null },
            { "RowIndex": 8, "CellIndex": 10, "Value": null },
            { "RowIndex": 8, "CellIndex": 12, "Value": null },
            { "RowIndex": 9, "CellIndex": 3, "Value": null }, //Change in fair value of MSRs
            { "RowIndex": 9, "CellIndex": 4, "Value": null },
            { "RowIndex": 9, "CellIndex": 6, "Value": null },
            { "RowIndex": 9, "CellIndex": 7, "Value": null },
            { "RowIndex": 9, "CellIndex": 10, "Value": null },
            { "RowIndex": 9, "CellIndex": 12, "Value": null },
            { "RowIndex": 10, "CellIndex": 3, "Value": null }, //Interest income, net
            { "RowIndex": 10, "CellIndex": 4, "Value": null },
            { "RowIndex": 10, "CellIndex": 6, "Value": null },
            { "RowIndex": 10, "CellIndex": 7, "Value": null },
            { "RowIndex": 10, "CellIndex": 10, "Value": null },
            { "RowIndex": 10, "CellIndex": 12, "Value": null },
            { "RowIndex": 11, "CellIndex": 3, "Value": null }, //Other income
            { "RowIndex": 11, "CellIndex": 4, "Value": null },
            { "RowIndex": 11, "CellIndex": 6, "Value": null },
            { "RowIndex": 11, "CellIndex": 7, "Value": null },
            { "RowIndex": 11, "CellIndex": 10, "Value": null },
            { "RowIndex": 11, "CellIndex": 12, "Value": null },
            { "RowIndex": 12, "CellIndex": 3, "Value": null }, //Total Revenues
            { "RowIndex": 12, "CellIndex": 4, "Value": null },
            { "RowIndex": 12, "CellIndex": 6, "Value": null },
            { "RowIndex": 12, "CellIndex": 7, "Value": null },
            { "RowIndex": 12, "CellIndex": 10, "Value": null },
            { "RowIndex": 12, "CellIndex": 12, "Value": null },
            { "RowIndex": 15, "CellIndex": 3, "Value": null }, //Salaries & Compensation
            { "RowIndex": 15, "CellIndex": 4, "Value": null },
            { "RowIndex": 15, "CellIndex": 6, "Value": null },
            { "RowIndex": 15, "CellIndex": 7, "Value": null },
            { "RowIndex": 15, "CellIndex": 10, "Value": null },
            { "RowIndex": 15, "CellIndex": 12, "Value": null },
            { "RowIndex": 16, "CellIndex": 3, "Value": null }, //General & Admin Expenses
            { "RowIndex": 16, "CellIndex": 4, "Value": null },
            { "RowIndex": 16, "CellIndex": 6, "Value": null },
            { "RowIndex": 16, "CellIndex": 7, "Value": null },
            { "RowIndex": 16, "CellIndex": 10, "Value": null },
            { "RowIndex": 16, "CellIndex": 12, "Value": null },
            { "RowIndex": 17, "CellIndex": 3, "Value": null }, //Marketing & Advertising
            { "RowIndex": 17, "CellIndex": 4, "Value": null },
            { "RowIndex": 17, "CellIndex": 6, "Value": null },
            { "RowIndex": 17, "CellIndex": 7, "Value": null },
            { "RowIndex": 17, "CellIndex": 10, "Value": null },
            { "RowIndex": 17, "CellIndex": 12, "Value": null },
            { "RowIndex": 18, "CellIndex": 3, "Value": null }, //Other
            { "RowIndex": 18, "CellIndex": 4, "Value": null },
            { "RowIndex": 18, "CellIndex": 6, "Value": null },
            { "RowIndex": 18, "CellIndex": 7, "Value": null },
            { "RowIndex": 18, "CellIndex": 10, "Value": null },
            { "RowIndex": 18, "CellIndex": 12, "Value": null },
            { "RowIndex": 19, "CellIndex": 3, "Value": null }, //Total Expenses
            { "RowIndex": 19, "CellIndex": 4, "Value": null },
            { "RowIndex": 19, "CellIndex": 6, "Value": null },
            { "RowIndex": 19, "CellIndex": 7, "Value": null },
            { "RowIndex": 19, "CellIndex": 10, "Value": null },
            { "RowIndex": 19, "CellIndex": 12, "Value": null },
            { "RowIndex": 21, "CellIndex": 3, "Value": null }, //Pre Tax Income
            { "RowIndex": 21, "CellIndex": 4, "Value": null },
            { "RowIndex": 21, "CellIndex": 6, "Value": null },
            { "RowIndex": 21, "CellIndex": 7, "Value": null },
            { "RowIndex": 21, "CellIndex": 10, "Value": null },
            { "RowIndex": 21, "CellIndex": 12, "Value": null },
            { "RowIndex": 22, "CellIndex": 3, "Value": null }, //Taxes
            { "RowIndex": 22, "CellIndex": 4, "Value": null },
            { "RowIndex": 22, "CellIndex": 6, "Value": null },
            { "RowIndex": 22, "CellIndex": 7, "Value": null },
            { "RowIndex": 22, "CellIndex": 10, "Value": null },
            { "RowIndex": 22, "CellIndex": 12, "Value": null },
            { "RowIndex": 23, "CellIndex": 3, "Value": null }, //Net Income
            { "RowIndex": 23, "CellIndex": 4, "Value": null },
            { "RowIndex": 23, "CellIndex": 6, "Value": null },
            { "RowIndex": 23, "CellIndex": 7, "Value": null },
            { "RowIndex": 23, "CellIndex": 10, "Value": null },
            { "RowIndex": 23, "CellIndex": 12, "Value": null },
            { "RowIndex": 26, "CellIndex": 3, "Value": null }, //Adjusted EBITDA
            { "RowIndex": 26, "CellIndex": 4, "Value": null },
            { "RowIndex": 26, "CellIndex": 6, "Value": null },
            { "RowIndex": 26, "CellIndex": 7, "Value": null },
            { "RowIndex": 26, "CellIndex": 10, "Value": null },
            { "RowIndex": 26, "CellIndex": 12, "Value": null },
            { "RowIndex": 27, "CellIndex": 3, "Value": null }, //FV Mark, Net of Hedge
            { "RowIndex": 27, "CellIndex": 4, "Value": null },
            { "RowIndex": 27, "CellIndex": 6, "Value": null },
            { "RowIndex": 27, "CellIndex": 7, "Value": null },
            { "RowIndex": 27, "CellIndex": 10, "Value": null },
            { "RowIndex": 27, "CellIndex": 12, "Value": null },
            { "RowIndex": 28, "CellIndex": 3, "Value": null }, //Efficiency Ratio
            { "RowIndex": 28, "CellIndex": 4, "Value": null },
            { "RowIndex": 28, "CellIndex": 6, "Value": null },
            { "RowIndex": 28, "CellIndex": 7, "Value": null },
            { "RowIndex": 28, "CellIndex": 10, "Value": null },
            { "RowIndex": 28, "CellIndex": 12, "Value": null }
        ]
    })

    useEffect(() => {
        if (ChartData.isSuccess && ChartData.status === 'fulfilled') {
            setState({
                ...state,
                data: [
                    { "Accounts": "Revenues: ", "Q4-22": " ", "Q3-22": " ", "Change % vs Q3-22": " ", "Change % vs Q4-21": " ", "Dec-22 YTD": " ", "Change % vs Dec-21 YTD": " " },
                    { "Accounts": "Gain on sale of loans", "Q4-22": numberFormat(ChartData.data.Outputs[0].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[1].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[2].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[3].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[4].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[5].Value, "percentage") },
                    { "Accounts": "Servicing fee income", "Q4-22": numberFormat(ChartData.data.Outputs[6].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[7].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[8].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[9].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[10].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[11].Value, "percentage") },
                    { "Accounts": "Change in fair value of MSRs", "Q4-22": numberFormat(ChartData.data.Outputs[12].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[13].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[14].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[15].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[16].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[17].Value, "percentage") },
                    { "Accounts": "Interest income, net", "Q4-22": numberFormat(ChartData.data.Outputs[18].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[19].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[20].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[21].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[22].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[23].Value, "percentage") },
                    { "Accounts": "Other income", "Q4-22": numberFormat(ChartData.data.Outputs[24].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[25].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[26].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[27].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[28].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[29].Value, "percentage") },
                    { "Accounts": "Total Revenues", "Q4-22": numberFormat(ChartData.data.Outputs[30].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[31].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[32].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[33].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[34].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[35].Value, "percentage") },
                    { "Accounts": "Expenses: ", "Q4-22": " ", "Q3-22": " ", "Change % vs Q3-22": " ", "Change % vs Q4-21": " ", "Dec-22 YTD": " ", "Change % vs Dec-21 YTD": " " },
                    { "Accounts": "Salaries & Compensation", "Q4-22": numberFormat(ChartData.data.Outputs[36].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[37].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[38].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[39].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[40].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[41].Value, "percentage") },
                    { "Accounts": "General & Admin Expenses", "Q4-22": numberFormat(ChartData.data.Outputs[42].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[43].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[44].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[45].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[46].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[47].Value, "percentage") },
                    { "Accounts": "Marketing & Advertising", "Q4-22": numberFormat(ChartData.data.Outputs[48].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[49].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[50].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[51].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[52].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[53].Value, "percentage") },
                    { "Accounts": "Other", "Q4-22": numberFormat(ChartData.data.Outputs[54].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[55].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[56].Value, "percentage"), "Change % vs Q4-21": numberFormat("-0.69", "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[58].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[59].Value, "percentage") },
                    { "Accounts": "Total Expenses", "Q4-22": numberFormat(ChartData.data.Outputs[60].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[61].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[62].Value, "percentage"), "Change % vs Q4-21": numberFormat("-0.43", "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[64].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[65].Value, "percentage") },
                    { "Accounts": "Pre Tax Income", "Q4-22": numberFormat(ChartData.data.Outputs[66].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[67].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[68].Value, "percentage"), "Change % vs Q4-21": numberFormat("-1.59", "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[70].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[71].Value, "percentage") },
                    { "Accounts": "Taxes", "Q4-22": numberFormat(ChartData.data.Outputs[72].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[73].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[74].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[75].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[76].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[77].Value, "percentage") },
                    { "Accounts": "Net Income", "Q4-22": numberFormat(ChartData.data.Outputs[78].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[79].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[80].Value, "percentage"), "Change % vs Q4-21": numberFormat("-1.57", "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[82].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[83].Value, "percentage") },
                    { "Accounts": "Memo: ", "Q4-22": " ", "Q3-22": " ", "Change % vs Q3-22": " ", "Change % vs Q4-21": " ", "Dec-22 YTD": " ", "Change % vs Dec-21 YTD": " " },
                    { "Accounts": "Adjusted EBITDA", "Q4-22": numberFormat(ChartData.data.Outputs[84].Value, "money"), "Q3-22": numberFormat(ChartData.data.Outputs[85].Value, "money"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[86].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[87].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[88].Value, "money"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[89].Value, "percentage") },
                    { "Accounts": "FV Mark, Net of Hedge", "Q4-22": numberFormat(ChartData.data.Outputs[90].Value, "number"), "Q3-22": numberFormat(ChartData.data.Outputs[91].Value, "number"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[92].Value, "percentage"), "Change % vs Q4-21": numberFormat(ChartData.data.Outputs[93].Value, "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[94].Value, "number"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[95].Value, "percentage") },
                    { "Accounts": "Efficiency Ratio", "Q4-22": numberFormat(ChartData.data.Outputs[96].Value, "percentage"), "Q3-22": numberFormat(ChartData.data.Outputs[97].Value, "percentage"), "Change % vs Q3-22": numberFormat(ChartData.data.Outputs[98].Value, "percentage"), "Change % vs Q4-21": numberFormat("2.06", "percentage"), "Dec-22 YTD": numberFormat(ChartData.data.Outputs[100].Value, "percentage"), "Change % vs Dec-21 YTD": numberFormat(ChartData.data.Outputs[101].Value, "percentage") }],
                loadedData: true,
                errorFlag: false,
            })
        } else if (ChartData.isError) {
            setState((prevState) => ({
                ...prevState,
                errorFlag: true,
                errorCode: "500",
                errorMessage: tryAgainLaterMessage,
                errorType: "InternalAPI"
            }));
        }
    }, [ChartData]);

    const onGridReady = (params: ColumnResizedEvent) => {
        params.api.sizeColumnsToFit();
    };

    return (
        <div className="sheet-master">
            {!state.loadedData &&
                <div className="module-loading">
                    <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                </div>
            }
            {state.errorFlag &&
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            }
            {state.loadedData && !state.errorFlag &&
                <div className="sheet-tables-div">
                    <div className="sheet-table-container">
                        <div className="sheet-table-title">
                            Income Statement
                        </div>
                        <div className="sheet-table-subtitle">
                            (in Millions)
                        </div>
                        <div className="sheet-table">
                            <AgGridReact
                                domLayout={"autoHeight"}
                                defaultColDef={{
                                    cellClass: 'text',
                                    headerClass: 'column',
                                    resizable: true,
                                }}
                                onGridReady={onGridReady}
                                rowClassRules={{
                                    "highlight": function (params: any) {
                                        let differentColors: string[] = ["Net Income"];
                                        return differentColors.includes(params.data.Accounts);
                                    },
                                    "separator": function (params: any) {
                                        let differentColors: string[] = ["Revenues: ", "Expenses: ", "Memo: "];
                                        return differentColors.includes(params.data.Accounts);
                                    },
                                    "totals": function (params: any) {
                                        let differentColors: string[] = ["Total Revenues", "Total Expenses", "Pre Tax Income"];
                                        return differentColors.includes(params.data.Accounts);
                                    },
                                }}
                                rowData={state.data}
                                columnDefs={state.columns}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
