import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';

interface agGridDataPair {
    rows: any[];
    columns: any[];
    identifier: string;
}

export interface agGridExtraData {
    value: string;
    columnSize: number;
}

interface CustomAgGridTableProps { 
    title: string;
    tableData: agGridDataPair;
    rowClassRules?: any;
    customCellStyle: {field: string, cellStyle: any};
    extraTableData?: agGridExtraData[];
    containerStyle?: any;
    suppressHorizontalScroll?: boolean;
    height?: any;
    domLayout?: 'normal' | 'autoHeight' | 'print';
}

export const CustomAgGridTable = (props: CustomAgGridTableProps) => {
    const cellStyleFinal = props.customCellStyle.cellStyle;
    cellStyleFinal["textAlign"] = "center";

    const generateExtraTableDataColumns = () => {
        var extraTableColumns: any = [];
        var blankColumn: string = " ";

        if (typeof(props.extraTableData) !== "undefined"){
            props.extraTableData?.forEach((element) => {
                extraTableColumns.push({ field: blankColumn, maxWidth: element.columnSize });
                blankColumn = blankColumn + ' '.repeat(1);
            })
        }

        return extraTableColumns;        
    }

    const generateExtraTableDataRows = () => {
        var extraTableRows: any = {};
        var blankColumn: string = " ";

        if (typeof(props.extraTableData) !== "undefined"){
            for (var i = 0; i < props.extraTableData!.length; i++){
                extraTableRows[blankColumn] = props.extraTableData![i].value
                blankColumn = blankColumn + ' '.repeat(1);
            }
        }

        return [extraTableRows];  
    }

    return (
        <div className="save-to-pdf" style={{ padding: 5, borderTop: 0, borderBottom: 0, borderLeft: 1, borderRight: 1, borderStyle: "solid", ...props.containerStyle }}>
            <div className="table-title">
                {props.title}
            </div>
            <div style={{ paddingBottom: 5 }}>
                <AgGridReact
                    suppressHorizontalScroll={props.suppressHorizontalScroll}
                    className='custom-ag-container'
                    containerStyle={{ height: props.height ?? '100%' }}
                    domLayout={props.domLayout ?? 'autoHeight'}
                    defaultColDef={{
                        field: props.customCellStyle.field,
                        cellClass: 'text',
                        headerClass: 'column',
                        resizable: true,
                        cellStyle: cellStyleFinal
                    }}
                    rowClassRules={props.rowClassRules}
                    rowData={props.tableData.rows}
                    columnDefs={props.tableData.columns} 
                    suppressFieldDotNotation={true}
                    />
            </div>
            <div style={{display: ((typeof(props.extraTableData) !== "undefined")) ? "block" : "none" }}>
                <AgGridReact
                    domLayout={"print"}
                    defaultColDef={{
                        cellClass: 'text',
                        headerClass: 'column',
                        resizable: true,
                        cellStyle: { textAlign: 'center' }
                    }}
                    rowClassRules={{
                        "table-total": function (params: any) {
                            return true;
                        }
                    }}
                    rowData={generateExtraTableDataRows()}
                    columnDefs={generateExtraTableDataColumns()} />
            </div>
        </div>)
}
