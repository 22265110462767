import React, { RefObject, createRef, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { exportPlotlyToPdf } from "../../../features/SaveAsPdf";
import { ResultFromMultipleInputSliders } from "../../../components/generic-financial-model/MultipleInputSliders";
import Plot from "react-plotly.js"
import '../Module.scss';
import { useXlsxProcessorQuery, useLazyXlsxProcessorQuery } from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

const totalGenerator= (total: number) => {
    return (total > 0) ? ('<b>$' + (Math.round(total * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(total * -10) / 10).toFixed(1) + 'B</b>');
}

const percentageFormatter = (total: number) => {
    return (total > 0) ? ('<b>' + (Math.round(total * 10) / 10).toFixed(1) + '%</b>') : ('<b>-' + (Math.round(total * -10) / 10).toFixed(1) + '%</b>');
}

interface FinancialModelingProps { }

interface FinancialModelingState {
    inputValues: number[];
    initialInputValues: number[];
    firstCallFlag: boolean;
    secondCallFlag: boolean;
    initialDataLoadedFlag: boolean;
    chartsLoadedFlag: boolean;
    rocketLockVolumeChartOptions: any[],
    upbChartOptions: any[],
    msrFVChartOptions: any[],
    totalRevenueChartOptions: any[],
    totalExpensesChartOptions: any[],
    pretaxIncomeChartOptions: any[],
    roatceChartOptions: any[],
    assetsLiabilitiesEquityChartOptions: any[],
    isChartLoading: boolean;
    pdfValues: number[];
    errorFlag: boolean;
    errorCode: string;
    errorMessage: string;
    errorType: string;
}

const PlotlyDefaultMargin = {
    margin: {
        l: 10,
        r: 10,
        b: 30
    }
};

const DataSourceURL: string = "https://docs.google.com/spreadsheets/d/1OrYR92XTbUleRnH33XaQA9uX0ubYFQ3o/";

export const FinancialModeling = (props: FinancialModelingProps) => {
    const [state, setState] = useState<FinancialModelingState>({
        inputValues: [],
        initialInputValues: [],
        firstCallFlag: false,
        secondCallFlag: false,
        initialDataLoadedFlag: false,
        chartsLoadedFlag: false,
        rocketLockVolumeChartOptions: [],
        upbChartOptions: [],
        msrFVChartOptions: [],
        totalRevenueChartOptions: [],
        totalExpensesChartOptions: [],
        pretaxIncomeChartOptions: [],
        roatceChartOptions: [],
        assetsLiabilitiesEquityChartOptions: [],
        isChartLoading: false,
        pdfValues: [],
        errorFlag: false,
        errorCode: "",
        errorMessage: "",
        errorType: "",
    });

    const [chartDataQuery, chartDataResult] = useLazyXlsxProcessorQuery();

    const InitialChartData = useXlsxProcessorQuery({
        "DataSource": DataSourceURL,
        "InputSheetName": null,
        "Inputs": [],
        "OutputSheetName": "Model",
        "Outputs": [
            {
                "RowIndex": 201,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 201,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 202,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 202,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 214,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 214,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 215,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 215,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 239,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 239,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 245,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 245,
                "CellIndex": 46,
                "Value": null
            },
            {
                "RowIndex": 268,
                "CellIndex": 45,
                "Value": null
            },
            {
                "RowIndex": 268,
                "CellIndex": 46,
                "Value": null
            },
        ]
    })

    useEffect(() => {
        if (InitialChartData.isSuccess && InitialChartData.status === "fulfilled"){
            setState({
                ...state,
                initialInputValues: [
                    Math.round(InitialChartData.data.Outputs[0].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[2].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[1].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[3].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[4].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[6].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[5].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[7].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[8].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[10].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[12].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[9].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[11].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[13].Value * 10000) / 100,
                ],
                inputValues: [
                    Math.round(InitialChartData.data.Outputs[0].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[2].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[1].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[3].Value * 10000) / 10000,
                    Math.round(InitialChartData.data.Outputs[4].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[6].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[5].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[7].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[8].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[10].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[12].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[9].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[11].Value * 10000) / 100,
                    Math.round(InitialChartData.data.Outputs[13].Value * 10000) / 100,
                ],
                firstCallFlag: true,
                initialDataLoadedFlag: true,
                errorFlag: false,
            });
        } else if (chartDataResult.isError) {
            setState((prevState) => ({
                ...prevState,
                errorFlag: true,
                errorCode: "500",
                errorMessage: tryAgainLaterMessage,
                errorType: "InternalAPI"
            }));
        }
    }, [InitialChartData]);

    useEffect(()=>{
        if (chartDataResult.isSuccess && chartDataResult.status === "fulfilled"){
            const gsData = chartDataResult.data.Outputs;
            // chart 1 – rate lock volume
            
            const rocketLockVolumeChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: [gsData[4].Value / 1000, gsData[5].Value / 1000,
                gsData[6].Value / 1000, gsData[7].Value / 1000],
                type: 'bar',
                text: [Math.round(gsData[4].Value / 100) / 10, Math.round(gsData[5].Value / 100) / 10,
                Math.round(gsData[6].Value / 100) / 10, Math.round(gsData[7].Value / 100) / 10].map(number => (number > 0) ? ('<b>$' + number.toFixed(1) + 'B</b>') : ('<b>-$' + (number * -1).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                textposition: "inside",
                insidetextanchor: "middle",
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(82, 82, 82)', 'rgb(82, 82, 82)', 'rgb(82, 82, 82)', 'rgb(82, 82, 82)']
                },
                name: "Partner"
            }, {
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: [gsData[0].Value / 1000, gsData[1].Value / 1000,
                gsData[2].Value / 1000, gsData[3].Value / 1000],
                type: 'bar',
                text: [Math.round(gsData[0].Value / 100) / 10, Math.round(gsData[1].Value / 100) / 10,
                Math.round(gsData[2].Value / 100) / 10, Math.round(gsData[3].Value / 100) / 10].map(number => (number > 0) ? ('<b>$' + number.toFixed(1) + 'B</b>') : ('<b>-$' + (number * -1).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                insidetextanchor: "middle",
                textposition: 'inside',
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(49, 108, 244)', 'rgb(49, 108, 244)', 'rgb(49, 108, 244)', 'rgb(49, 108, 244)']
                },
                name: "DTC"
            }];

            // chart 2 - UPB
            const upbData = [];
            for (let i = 12; i < 16; i++) {
                upbData.push(gsData[i].Value / 1000);
            }

            const upbChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: upbData,
                mode: 'lines+markers+text',
                type: 'scatter',                    
            }]; //(number => (number>0) ? ('<b>$' + Math.round(number*10)/10 + 'B</b>') : ('<b>-$' + Math.round(number*-10)/10 + 'B</b>')),

            // chart 3 - MSR FV
            const msrData = [];
            for (let i = 16; i < 20; i++) {
                msrData.push(gsData[i].Value / 1000);
            }
            //msrData.push(0);

            const msrFVChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: msrData,
                mode: 'lines+markers+text',
                type: 'scatter',
            }];
            
            // chart 4 - total revenue
            const totalRevenueData = [];
            for (let i = 20; i < 24; i++) {
                totalRevenueData.push(gsData[i].Value / 1000);
            }

            const totalRevenueChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: totalRevenueData,
                name: "Actual",
                type: 'bar',
                text: totalRevenueData.map(number => (number > 0) ? ('<b>$' + (Math.round(number * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(number * -10) / 10).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(28, 64, 202)'
                }
            }];

            // chart 5 - total expenses
            const totalExpensesData = [];
            for (let i = 24; i < 28; i++) {
                totalExpensesData.push(gsData[i].Value / 1000);
            }

            const totalExpensesChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: totalExpensesData,
                name: "Actual",
                type: 'bar',
                text: totalExpensesData.map(number => (number > 0) ? ('<b>$' + (Math.round(number * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(number * -10) / 10).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(28, 64, 202)'
                }
            }];

            // chart 6 - net income
            const preTaxData = [];
            for (let i = 28; i < 32; i++) {
                preTaxData.push(gsData[i].Value / 1000);
            }

            const pretaxIncomeChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: preTaxData,
                name: "Actual",
                type: 'bar',
                text: preTaxData.map(number => (number > 0) ? ('<b>$' + (Math.round(number * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(number * -10) / 10).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(28, 64, 202)'
                }
            }];

            // chart 7 - ROATCE
            const roatceData = [];
            for (let i = 36; i < 40; i++) {
                roatceData.push(Math.round(gsData[i].Value * 1000) / 10);
            }
            //roatceData.push(0);

            const roatceChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: roatceData,
                mode: 'lines+markers+text',
                type: 'scatter',
            }];

            // chart 8 - liabilities/assets/equity
            const liabilitiesData = [];
            const equityData = [];
            for (let i = 40; i < 44; i++) {
                liabilitiesData.push(gsData[i].Value / 1000);
                equityData.push(gsData[i + 4].Value / 1000);
            }
            //liabilitiesData.push(0);
            //equityData.push(0);

            const assetsLiabilitiesEquityChartOptions = [{
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: equityData,
                type: 'bar',
                text: equityData.map(number => (number > 0) ? ('<b>$' + (Math.round(number * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(number * -10) / 10).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                textposition: "inside",
                insidetextanchor: "middle",
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(82, 82, 82)', 'rgb(82, 82, 82)', 'rgb(82, 82, 82)', 'rgb(82, 82, 82)']
                },
                name: "Equity"
            }, {
                x: ["Q1'23", "Q2'23", "Q3'23", "Q4'23"],
                y: liabilitiesData,
                type: 'bar',
                text: liabilitiesData.map(number => (number > 0) ? ('<b>$' + (Math.round(number * 10) / 10).toFixed(1) + 'B</b>') : ('<b>-$' + (Math.round(number * -10) / 10).toFixed(1) + 'B</b>')),
                textfont: {
                    size: 16,
                },
                insidetextanchor: "middle",
                textposition: 'inside',
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(49, 108, 244)', 'rgb(49, 108, 244)', 'rgb(49, 108, 244)', 'rgb(49, 108, 244)']
                },
                name: "Liabilities"
            }];

            setState({
                ...state,
                rocketLockVolumeChartOptions,
                upbChartOptions,
                msrFVChartOptions,
                totalRevenueChartOptions,
                totalExpensesChartOptions,
                pretaxIncomeChartOptions,
                roatceChartOptions,
                assetsLiabilitiesEquityChartOptions,
                chartsLoadedFlag: true,
                isChartLoading: false ,
                errorFlag: false,
            });
        } else if (chartDataResult.isError) {
            setState((prevState) => ({
                ...prevState,
                errorFlag: true,
                errorCode: "500",
                errorMessage: tryAgainLaterMessage,
                errorType: "InternalAPI"
            }));
        }
    }, [chartDataResult])

    async function sendDataBuildCharts() {
        setState({
            ...state,
            secondCallFlag: true,
            isChartLoading: true,        
            pdfValues: [
                state.inputValues[0],
                state.inputValues[1],
                state.inputValues[2],
                state.inputValues[3],
                state.inputValues[4],
                state.inputValues[5],
                state.inputValues[6],
                state.inputValues[7],
                state.inputValues[8],
                state.inputValues[9],
                state.inputValues[10],
                state.inputValues[11],
                state.inputValues[12],
                state.inputValues[13]
            ]
        });
        chartDataQuery({
            "DataSource": DataSourceURL,
            "InputSheetName": "Model",
            "Inputs": [
                {
                    "RowIndex": 201,
                    "CellIndex": 45,
                    "Value": state.inputValues[0] + .000001
                },
                {
                    "RowIndex": 201,
                    "CellIndex": 46,
                    "Value": state.inputValues[2] + .000001
                },
                {
                    "RowIndex": 202,
                    "CellIndex": 45,
                    "Value": state.inputValues[1] + .000001
                },
                {
                    "RowIndex": 202,
                    "CellIndex": 46,
                    "Value": state.inputValues[3] + .000001
                },
                {
                    "RowIndex": 214,
                    "CellIndex": 45,
                    "Value": state.inputValues[4] / 100 + .000001
                },
                {
                    "RowIndex": 214,
                    "CellIndex": 46,
                    "Value": state.inputValues[6] / 100 + .000001
                },
                {
                    "RowIndex": 215,
                    "CellIndex": 45,
                    "Value": state.inputValues[5] / 100 + .000001
                },
                {
                    "RowIndex": 215,
                    "CellIndex": 46,
                    "Value": state.inputValues[7] / 100 + .000001
                },
                {
                    "RowIndex": 239,
                    "CellIndex": 45,
                    "Value": state.inputValues[8] / 100 + .000001
                },
                {
                    "RowIndex": 239,
                    "CellIndex": 46,
                    "Value": state.inputValues[11] / 100 + .000001
                },
                {
                    "RowIndex": 245,
                    "CellIndex": 45,
                    "Value": state.inputValues[9] / 100 + .000001
                },
                {
                    "RowIndex": 245,
                    "CellIndex": 46,
                    "Value": state.inputValues[12] / 100 + .000001
                },
                {
                    "RowIndex": 268,
                    "CellIndex": 45,
                    "Value": state.inputValues[10] / 100 + .000001
                },
                {
                    "RowIndex": 268,
                    "CellIndex": 46,
                    "Value": state.inputValues[13] / 100 + .000001
                }
            ],
            "OutputSheetName": "Model",
            "Outputs": [
                // rate lock volume - 0…11
                {
                    "RowIndex": 237,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 237,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 237,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 237,
                    "CellIndex": 46,
                    "Value": null
                },
                {
                    "RowIndex": 243,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 243,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 243,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 243,
                    "CellIndex": 46,
                    "Value": null
                },
                {
                    "RowIndex": 249,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 249,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 249,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 249,
                    "CellIndex": 46,
                    "Value": null
                },
                // UPB - 12…15
                {
                    "RowIndex": 262,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 262,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 262,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 262,
                    "CellIndex": 46,
                    "Value": null
                },
                // MSR FV - 16…19
                {
                    "RowIndex": 280,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 280,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 280,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 280,
                    "CellIndex": 46,
                    "Value": null
                },
                // total revenue - 20…23
                {
                    "RowIndex": 11,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 11,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 11,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 11,
                    "CellIndex": 46,
                    "Value": null
                },
                // total expenses - 24…27
                {
                    "RowIndex": 14,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 14,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 14,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 14,
                    "CellIndex": 46,
                    "Value": null
                },
                // pre-tax profit - 28…31
                {
                    "RowIndex": 16,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 16,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 16,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 16,
                    "CellIndex": 46,
                    "Value": null
                },
                // adjusted pre-tax margin - 32…35
                // net income ***
                {
                    "RowIndex": 23,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 23,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 23,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 23,
                    "CellIndex": 46,
                    "Value": null
                },
                // ROATCE - 36…39
                {
                    "RowIndex": 198,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 198,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 198,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 198,
                    "CellIndex": 46,
                    "Value": null
                },
                // liabilities - 40…43
                {
                    "RowIndex": 177,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 177,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 177,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 177,
                    "CellIndex": 46,
                    "Value": null
                },
                // assets - 44…47
                {
                    "RowIndex": 193,
                    "CellIndex": 43,
                    "Value": null
                },
                {
                    "RowIndex": 193,
                    "CellIndex": 44,
                    "Value": null
                },
                {
                    "RowIndex": 193,
                    "CellIndex": 45,
                    "Value": null
                },
                {
                    "RowIndex": 193,
                    "CellIndex": 46,
                    "Value": null
                },
            ]
        });
    }

    const handleChange = (newValue: number[], box: number) => {
        let newInputValuesTMP: number[] = [];
        newInputValuesTMP = state.inputValues;
        switch (box) {
            case 1:
                newInputValuesTMP[0] = newValue[0];
                newInputValuesTMP[1] = newValue[1];
                break;

            case 2:
                newInputValuesTMP[2] = newValue[0];
                newInputValuesTMP[3] = newValue[1];
                break;

            case 3:
                newInputValuesTMP[4] = newValue[0];
                newInputValuesTMP[5] = newValue[1];
                break;

            case 4:
                newInputValuesTMP[6] = newValue[0];
                newInputValuesTMP[7] = newValue[1];
                break;

            case 5:
                newInputValuesTMP[8] = newValue[0];
                newInputValuesTMP[9] = newValue[1];
                newInputValuesTMP[10] = newValue[2];
                break;

            case 6:
                newInputValuesTMP[11] = newValue[0];
                newInputValuesTMP[12] = newValue[1];
                newInputValuesTMP[13] = newValue[2];
                break;
        }
        setState({ ...state, inputValues: newInputValuesTMP });

    };

    function exportToPdfWrapper() {
        exportPlotlyToPdf("Rocket Companies Financial Modeling", [plotlyRefChart1, plotlyRefChart2, plotlyRefChart3, plotlyRefChart4, plotlyRefChart5, plotlyRefChart6, plotlyRefChart7, plotlyRefChart8], true, plotlyRefChart9);
    }

    function genericPlot(ref: RefObject<Plot>, data: any[], title: string, annotations: any[]) {
        return <Plot
            ref={ref}
            data={data}
            layout={{
                ...PlotlyDefaultMargin,
                barmode: "stack",
                title: {
                    text: "<b>" + title + "</b>",
                    y: 0.95, x: 0.05
                },
                legend: { "orientation": "h" },
                yaxis: {
                    visible: false,
                    showticklabels: false
                },
                annotations: annotations,
                autosize: true
            }}
            useResizeHandler
            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
            className="w-100"
        />
    }

    const plotlyRefChart1 = useRef<Plot>();
    const plotlyRefChart2 = useRef<Plot>();
    const plotlyRefChart3 = useRef<Plot>();
    const plotlyRefChart4 = useRef<Plot>();
    const plotlyRefChart5 = useRef<Plot>();
    const plotlyRefChart6 = useRef<Plot>();
    const plotlyRefChart7 = useRef<Plot>();
    const plotlyRefChart8 = useRef<Plot>();
    const plotlyRefChart9 = useRef<Plot>();

    return (
        <div className="module-master" style={{ paddingRight: 0 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="module-title">
                    Financial Modeling
                </div>
            </div>
            <div className="module-row" style={{display: state.errorFlag ? "block" : "none"}}>
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            </div>
            <div className="module-row" style={{display: state.errorFlag ? "none" : "block"}}>
                {!state.initialDataLoadedFlag ? (
                    <div className="loading-div">
                        <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                    </div>

                ) : (
                    <div>
                        <div className="w-100" style={{display: "flex" ,paddingBottom: "5px" }}>
                            <button className="btn btn-primary" style={{ width: "100%" }} onClick={sendDataBuildCharts}>Run Model</button>
                            <button className="btn btn-primary" style={{ width: "100%" }} disabled={!state.chartsLoadedFlag} onClick={exportToPdfWrapper}>Export to PDF</button>
                        </div>
                        <div>
                            <div>
                                <div className="module-financial-model-title">
                                    Market Size
                                </div>
                                <div className="module-row">
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[0],
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Purch."
                                        }, {
                                            startingValue: state.initialInputValues[1],
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Refi."
                                        }]}
                                        resultResolver={(values) => Math.round((values[0] + values[1]) * 10000) / 10000}
                                        showLabels={true}
                                        result={{
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Total"
                                        }}
                                        showResult={true}
                                        onNewValuesChange={(values) => handleChange(values, 1)}
                                        columnlabel={"Q3'23"}
                                        vertical={true}
                                    />
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[2],
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Purch."
                                        }, {
                                            startingValue: state.initialInputValues[3],
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Refi."
                                        }]}
                                        resultResolver={(values) => Math.round((values[0] + values[1]) * 10000) / 10000}
                                        showLabels={false}
                                        result={{
                                            firstSymbol: "$",
                                            secondSymbol: "B",
                                            label: "Total"
                                        }}
                                        showResult={true}
                                        onNewValuesChange={(values) => handleChange(values, 2)}
                                        columnlabel={"Q4'23"}
                                        vertical={true}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="module-financial-model-title">
                                    Market Share
                                </div>
                                <div className="module-row">
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[4],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "DTC"
                                        }, {
                                            startingValue: state.initialInputValues[5],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Partner"
                                        }]}
                                        resultResolver={(values) => Math.round((values[0] + values[1]) * 10000) / 10000}
                                        showLabels={true}
                                        result={{
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Total"
                                        }}
                                        showResult={true}
                                        onNewValuesChange={(values) => handleChange(values, 3)}
                                        columnlabel={"Q3'23"}
                                        vertical={true}
                                    />
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[6],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "DTC"
                                        }, {
                                            startingValue: state.initialInputValues[7],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Partner"
                                        }]}
                                        resultResolver={(values) => Math.round((values[0] + values[1]) * 1000) / 1000}
                                        showLabels={false}
                                        result={{
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Total"
                                        }}
                                        showResult={true}
                                        onNewValuesChange={(values) => handleChange(values, 4)}
                                        columnlabel={"Q4'23"}
                                        vertical={true}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="module-financial-model-title">
                                    GoS
                                </div>
                                <div className="module-row">
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[8],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "DTC"
                                        }, {
                                            startingValue: state.initialInputValues[9],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Partner"
                                        }, {
                                            startingValue: state.initialInputValues[10],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "PO/Amm"
                                        }]}
                                        resultResolver={(values) => 0}
                                        showLabels={true}
                                        result={{
                                            firstSymbol: "",
                                            secondSymbol: "",
                                            label: ""
                                        }}
                                        showResult={false}
                                        onNewValuesChange={(values) => handleChange(values, 5)}
                                        columnlabel={"Q3'23"}
                                        vertical={true}
                                    />
                                    <ResultFromMultipleInputSliders
                                        data={[{
                                            startingValue: state.initialInputValues[11],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "DTC"
                                        }, {
                                            startingValue: state.initialInputValues[12],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "Partner"
                                        }, {
                                            startingValue: state.initialInputValues[13],
                                            firstSymbol: "",
                                            secondSymbol: "%",
                                            label: "PO/Amm"
                                        }]}
                                        resultResolver={(values) => 0}
                                        showLabels={false}
                                        result={{
                                            firstSymbol: "",
                                            secondSymbol: "",
                                            label: ""
                                        }}
                                        showResult={false}
                                        onNewValuesChange={(values) => handleChange(values, 6)}
                                        columnlabel={"Q4'23"}
                                        vertical={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }

                {(!state.chartsLoadedFlag || state.isChartLoading) ? (
                    <div style={{ width: "100%", height: "100%" }}>
                        {state.isChartLoading && (
                            <div className="loading-div">
                                <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ width: "100%", height: "100%" }} className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart1 as any, state.rocketLockVolumeChartOptions, "Lock Volume", [
                                        {
                                            x: "Q1'23",
                                            y: state.rocketLockVolumeChartOptions[0].y[0] + state.rocketLockVolumeChartOptions[1].y[0],
                                            text: totalGenerator(state.rocketLockVolumeChartOptions[0].y[0] + state.rocketLockVolumeChartOptions[1].y[0]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q2'23",
                                            y: state.rocketLockVolumeChartOptions[0].y[1] + state.rocketLockVolumeChartOptions[1].y[1],
                                            text: totalGenerator(state.rocketLockVolumeChartOptions[0].y[1] + state.rocketLockVolumeChartOptions[1].y[1]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q3'23",
                                            y: state.rocketLockVolumeChartOptions[0].y[2] + state.rocketLockVolumeChartOptions[1].y[2],
                                            text: totalGenerator(state.rocketLockVolumeChartOptions[0].y[2] + state.rocketLockVolumeChartOptions[1].y[2],),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q4'23",
                                            y: state.rocketLockVolumeChartOptions[0].y[3] + state.rocketLockVolumeChartOptions[1].y[3],
                                            text: totalGenerator(state.rocketLockVolumeChartOptions[0].y[3] + state.rocketLockVolumeChartOptions[1].y[3]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        }
                                    ])
                                }
                            </div>
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart2 as any, state.upbChartOptions, "UPB", [
                                        {
                                            x: "Q1'23",
                                            y: state.upbChartOptions[0].y[0],
                                            text: totalGenerator(state.upbChartOptions[0].y[0]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q2'23",
                                            y: state.upbChartOptions[0].y[1],
                                            text: totalGenerator(state.upbChartOptions[0].y[1]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q3'23",
                                            y: state.upbChartOptions[0].y[2],
                                            text: totalGenerator(state.upbChartOptions[0].y[2]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q4'23",
                                            y: state.upbChartOptions[0].y[3],
                                            text: totalGenerator(state.upbChartOptions[0].y[3]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        }
                                    ])
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart3 as any, state.msrFVChartOptions, "MSR FV", [
                                        {
                                            x: "Q1'23",
                                            y: state.msrFVChartOptions[0].y[0],
                                            text: totalGenerator(state.msrFVChartOptions[0].y[0]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q2'23",
                                            y: state.msrFVChartOptions[0].y[1],
                                            text: totalGenerator(state.msrFVChartOptions[0].y[1]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q3'23",
                                            y: state.msrFVChartOptions[0].y[2],
                                            text: totalGenerator(state.msrFVChartOptions[0].y[2]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q4'23",
                                            y: state.msrFVChartOptions[0].y[3],
                                            text: totalGenerator(state.msrFVChartOptions[0].y[3]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        }
                                    ])
                                }
                            </div>
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart4 as any, state.totalRevenueChartOptions, "Total Revenue", [])
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart5 as any, state.totalExpensesChartOptions, 'Total Expenses', [])
                                }
                            </div>
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart6 as any, state.pretaxIncomeChartOptions, 'Pre-Tax Income', [])
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart7 as any, state.roatceChartOptions, 'ROATCE', [
                                        {
                                            x: "Q1'23",
                                            y: state.roatceChartOptions[0].y[0],
                                            text: percentageFormatter(state.roatceChartOptions[0].y[0]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q2'23",
                                            y: state.roatceChartOptions[0].y[1],
                                            text: percentageFormatter(state.roatceChartOptions[0].y[1]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q3'23",
                                            y: state.roatceChartOptions[0].y[2],
                                            text: percentageFormatter(state.roatceChartOptions[0].y[2]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q4'23",
                                            y: state.roatceChartOptions[0].y[3],
                                            text: percentageFormatter(state.roatceChartOptions[0].y[3]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        }
                                    ])
                                }
                            </div>
                            <div className="col-lg-12 col-xl-6 border-on-top">
                                {!state.isChartLoading &&
                                    genericPlot(plotlyRefChart8 as any, state.assetsLiabilitiesEquityChartOptions, 'Balance Sheet', [
                                        {
                                            x: "Q1'23",
                                            y: state.assetsLiabilitiesEquityChartOptions[0].y[0] + state.assetsLiabilitiesEquityChartOptions[1].y[0],
                                            text: totalGenerator(state.assetsLiabilitiesEquityChartOptions[0].y[0] + state.assetsLiabilitiesEquityChartOptions[1].y[0]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q2'23",
                                            y: state.assetsLiabilitiesEquityChartOptions[0].y[1] + state.assetsLiabilitiesEquityChartOptions[1].y[1],
                                            text: totalGenerator(state.assetsLiabilitiesEquityChartOptions[0].y[1] + state.assetsLiabilitiesEquityChartOptions[1].y[1]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q3'23",
                                            y: state.assetsLiabilitiesEquityChartOptions[0].y[2] + state.assetsLiabilitiesEquityChartOptions[1].y[2],
                                            text: totalGenerator(state.assetsLiabilitiesEquityChartOptions[0].y[2] + state.assetsLiabilitiesEquityChartOptions[1].y[2]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        },
                                        {
                                            x: "Q4'23",
                                            y: state.assetsLiabilitiesEquityChartOptions[0].y[3] + state.assetsLiabilitiesEquityChartOptions[1].y[3],
                                            text: totalGenerator(state.assetsLiabilitiesEquityChartOptions[0].y[3] + state.assetsLiabilitiesEquityChartOptions[1].y[3]),
                                            xanchor: 'center',
                                            yanchor: 'bottom',
                                            showarrow: false,
                                            font: {
                                                size: 16,
                                                }
                                        }
                                    ])
                                }
                            </div>
                            <div className="source-text">
                                Source: Gallus Insights
                            </div>
                            <div style={{ display: "none" }}>
                                <Plot
                                    ref={plotlyRefChart9 as any}
                                    data={[{
                                        type: 'table',
                                        header: {
                                            values: [["<b>Parameter</b>"], ["<b>Q1'23</b>"],
                                            ["<b>Q2'23</b>"]],
                                            align: "center",
                                            line: { width: 1, color: 'black' },
                                            fill: { color: "grey" },
                                            font: { family: "Arial", size: 12, color: "white" }
                                        },
                                        cells: {
                                            values: [
                                                ['Purchase', 'Refinance', 'Market Share DTC', 'Market Share Partner', 'GoS DTC', 'GoS Partner', 'GoS PO/Amm'],
                                                ["$" + state.pdfValues[0] + "B", "$" + state.pdfValues[1] + "B", state.pdfValues[4] + "%", state.pdfValues[5] + "%", state.pdfValues[8] + "%", state.pdfValues[9] + "%", state.pdfValues[10] + "%"],
                                                ["$" + state.pdfValues[2] + "B", "$" + state.pdfValues[3] + "B", state.pdfValues[6] + "%", state.pdfValues[7] + "%", state.pdfValues[11] + "%", state.pdfValues[12] + "%", state.pdfValues[13] + "%"]],
                                            align: "center",
                                            line: { color: "black", width: 1 },
                                            font: { family: "Arial", size: 11, color: ["black"] }
                                        }
                                    }] as any}
                                    layout={{
                                        ...PlotlyDefaultMargin,
                                        barmode: "stack",
                                        title: 'Parameters',
                                        yaxis: {
                                            visible: true,
                                            showticklabels: false
                                        },
                                        autosize: true
                                    }}
                                    useResizeHandler
                                    config={{ responsive: true, displayModeBar: false }}
                                    className="w-100"
                                />
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    );
}
