//Callback component used during normal login process.

import axios from "axios";
import * as React from "react";
import { getUserSessionItem, updateUserSession } from "..";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "../app/hooks";
import { updateUserAuthContext } from "../features/console/consoleSlice";
import { CognitoOidcConfig } from "../CognitoOidcConfig";
import Bugsnag from '@bugsnag/js'

const Callback = (props: any) => {
    //const tokens = useAppSelector(selectUserAuthContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isValidJwt, setIsValidJwt] = React.useState<boolean>(true);
    React.useEffect(() => {
        const getToken = async () => {
            let params = new URLSearchParams(window.location.search);
            const state = params.get('state');
            let statePayload: { [key: string]: any } = {};
            if (state) {
                statePayload = JSON.parse(window.atob(state));
            }
            try {
                let bodyForm = new URLSearchParams();
                bodyForm.append("grant_type", "authorization_code");
                bodyForm.append("client_id", CognitoOidcConfig.clientId);
                bodyForm.append("code", params.get("code") ?? "");
                bodyForm.append("redirect_uri", CognitoOidcConfig.callbackUri);
                if (CognitoOidcConfig.clientSecret) {
                    bodyForm.append("client_secret", CognitoOidcConfig.clientSecret);
                }

                let response = await axios.post<{
                    access_token: string;
                    expires_in: number;
                    id_token: string;
                    refresh_token: string;
                    token_type: string;
                }>(CognitoOidcConfig.tokenEndpoint, bodyForm, {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded",
                        },
                    }
                );
                
                API.post("ServerlessAPI", "/jwt/", {
                    body: {
                        clientId: CognitoOidcConfig.clientId,
                        idToken: response.data.id_token,
                    },
                })
                    .then((data: { isValid: boolean; payload: any }) => {
                        setIsValidJwt(data.isValid);
                        if (data.isValid) {
                            //save payload into session storage and redirect to landing page
                            updateUserSession({
                                idTokenPayload: data.payload,
                                username: data.payload["cognito:username"],
                                idToken: response.data.id_token,
                                accessToken: response.data.access_token,
                                refreshToken: response.data.refresh_token
                            });
                            dispatch(updateUserAuthContext({
                                idTokenPayload: data.payload,
                                idToken: response.data.id_token,
                                accessToken: response.data.access_token,
                                refreshToken: response.data.refresh_token
                            }));
                            navigate(statePayload.redirectUri ?? '/landingpage');
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        Bugsnag.notify(JSON.stringify(err), function (event) {
                            event.severity = 'error';
                            event.context = 'ServerlessAPI/jwt/ error';
                            event.setUser(getUserSessionItem("userName") ?? "User not in UserSession");
                            event.addMetadata('Params', { 
                                parameters: params,  
                                grant_type: "authorization_code",
                                client_id: CognitoOidcConfig.clientId,
                                code: params.get("code") ?? "",
                                redirect_uri: CognitoOidcConfig.callbackUri,
                                client_secret: CognitoOidcConfig.clientSecret ?? "No client secret",
                                url: window.location
                            });
                          })
                    });
            } catch (e) {
                console.error(e);
                Bugsnag.notify(JSON.stringify(e), function (event) {
                    event.severity = 'error';
                    event.context = 'getToken try/catch error';
                    event.setUser(getUserSessionItem("userName") ?? "User not in UserSession");
                    event.addMetadata('Params', { 
                        parameters: params,  
                        grant_type: "authorization_code",
                        client_id: CognitoOidcConfig.clientId,
                        code: params.get("code") ?? "",
                        redirect_uri: CognitoOidcConfig.callbackUri,
                        client_secret: CognitoOidcConfig.clientSecret ?? "No client secret",
                        url: window.location
                    });
                  })
                window.location.assign('login');
            }
        };

        getToken()
            .then(() => {
                console.log('Login done');
            })
            .catch(reason => {
                console.error(reason);
                Bugsnag.notify(JSON.stringify(reason), function (event) {
                    event.severity = 'error';
                    event.context = 'getToken error';
                    event.setUser(getUserSessionItem("userName") ?? "User not in UserSession");
                    event.addMetadata('Params', { 
                        url: window.location
                    });
                  })
            });
    }, []);

    return (
        <>
            {isValidJwt && (<div className="loading-div">Initiating session...</div>)}
            {!isValidJwt && (
                <Container>
                    <Row>
                        <Col>
                            <Alert color="danger" className="mt-2">Error occurred. Please login again.</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button type="button" color="success" onClick={() => navigate("/login")}><FontAwesomeIcon icon={faUser} /> Login</Button>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
}

export default Callback;
