import React from "react";
import { Link } from "react-router-dom";
import Logo from '../assets/white-icon-large.png';
import './HeaderSm.scss';
import { UserProfile } from "./UserProfile";
import { URLCopyButton } from "./URLCopyButton";

interface HeaderSmProps {
    contactUsPath?: string;
    signOutPath?: string;
}

/**
 * App Header component for individual layouts. If the user is located in the landing page, clicking the title will return the user to the main site. Otherwise, it will link the user to the landing page
 * @returns Returns site header.
 */
const HeaderSm = (props: HeaderSmProps) => {
    const [isMounted, setIsMounted] = React.useState<boolean>(false);
    React.useEffect(() => {
        setIsMounted(true);
    }, []);
    
    return (
        <>
            <div className={"banner " + (isMounted && "sm")}>
                <div className="banner-text">
                    {window.location.pathname === "/landingpage" && <Link className="title" to="/">GALLUS INSIGHTS</Link>}
                    {window.location.pathname !== "/landingpage" && <Link className="title" to="/landingpage">GALLUS INSIGHTS</Link>}
                </div>
                <div className="banner-image">
                    <div className="d-flex align-items-center">
                        {!(window.location.pathname === "/landingpage" || window.location.pathname === "/" || window.location.href.includes("/contactus")) && <URLCopyButton />}
                        <UserProfile contactUsPath={props.contactUsPath} signOutPath={props.signOutPath} />
                        <Link className="title" to="/"><img className="logo" src={Logo} alt="Gallus Insights" /></Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderSm;
