import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import 'ag-grid-community/styles/ag-grid.css';
import { OptionsMenu } from "../../../features/OptionsMenu";
import { agGridObject } from "../../../features/ExportToCSV";
import Plot from "react-plotly.js"
import { TSToAgGrid } from "../../../features/TSToAgGrid";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { DataEncoding, tsDataObject } from "../../../services/types/gallus";
import { GrateBenchmarkQ323 } from "./static-benchmark-info-q3-23";
import { GrateBenchmarkQ423 } from "./static-benchmark-info-q4-23";
import { GrateBenchmarkQ124 } from "./static-benchmark-info-q1-24";
import { GrateBenchmarkQ224 } from "./static-benchmark-info-q2-24";
import { GrateBenchmarkFy } from "./static-benchmark-info-fy";
import { formatterWrapper } from "../../../features/PlotlyFormatter";
import { Link } from "react-router-dom";
import pdfFileQ323 from "../../../assets/Gallus-Insights-Report-PPB-Q323.pdf"
import pdfFileQ423 from "../../../assets/Gallus-Insights-Report-PPB-Q423.pdf"
import pdfFileQ124 from "../../../assets/Gallus-Insights-Report-PPB-Q124.pdf"
import pdfFileQ224 from "../../../assets/Gallus-Insights-Report-PPB-Q224.pdf"
import pdfFileFY from "../../../assets/Gallus-Insights-Report-PPB-FY.pdf"

interface PublicPlayersBenchmarkProps {
    period: "Q3 2023" | "Q4 2023" | "Q1 2024" | "Q2 2024" | "FY";
    overrideTitle?: string;
}

export const PublicPlayersBenchmark = (props: PublicPlayersBenchmarkProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [csvData, setCSVData] = React.useState<agGridObject<string>[]>([]);

    const [tableArray, setTableArray] = React.useState<JSX.Element[]>([]);

    useEffect(() => {
        switch (props.period) {
            case "Q3 2023":
                var tableArray: JSX.Element[] = [renderTable(GrateBenchmarkQ323)];
                break;
            case "Q4 2023":
                var tableArray: JSX.Element[] = [renderTable(GrateBenchmarkQ423)];
                break;
            case "Q1 2024":
                var tableArray: JSX.Element[] = [renderTable(GrateBenchmarkQ124)];
                break;
            case "Q2 2024":
                var tableArray: JSX.Element[] = [renderTable(GrateBenchmarkQ224)];
                break;
            case "FY":
                var tableArray: JSX.Element[] = [renderTable(GrateBenchmarkFy)];
                break;
        }
        setTableArray(tableArray);
        setLoading(false);
    }, [props.period])

    const renderLineChart = (data: (number | string)[], encoding: DataEncoding) => {
        let tickFormat: string = ".1%";

        switch (encoding) {
            case "currency":
                tickFormat = "$.1f";
                break;
            case "%1d":
            case "%0d":
            case "$%1d":
            case "$%2d":
            case "%2d":
                tickFormat = ".1%"
                break;
            default:
                tickFormat = "";
                break;
        }
        return <Plot
            data={[{
                x: ["Rocket", "Guild", "UWM", "Loan Depot"],
                y: data,
                mode: "text+lines+markers",
                type: 'scatter',
                textposition: 'top center',
                textfont: {
                    size: 16,
                },
                text: data.map((value) => { return formatterWrapper(value.toString(), encoding) }),

            }]}
            layout={{
                margin: {
                    t: 10
                },
                legend: { "orientation": "h" },
                autosize: true,
                yaxis: {
                    tickformat: tickFormat
                }
            }}
            useResizeHandler
            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
            className="w-100 h-100"
        />
    }

    const renderBarChart = (q2: (number | string)[], q3: (number | string)[], encoding: DataEncoding) => {
        var traceNames: string[] = [];
        switch (props.period) {
            case "Q3 2023":
                traceNames = ["Q2 2023", "Q3 2023"];
                break;
            case "Q4 2023":
                traceNames = ["Q3 2023", "Q4 2023"];
                break;
            case "Q1 2024":
                traceNames = ["Q4 2023", "Q1 2024"];
                break;
            case "Q2 2024":
                traceNames = ["Q1 2024", "Q2 2024"];
                break;
            case "FY":
                traceNames = ["2022", "2023"];
                break;
        }
        return <Plot
            data={[{
                name: traceNames[0],
                x: ["Rocket", "Guild", "UWM", "Loan Depot"],
                y: q2,
                type: 'bar',
                textfont: {
                    size: 12,
                },
                textposition: "inside",
                hoverinfo: 'none',
                text: q2.map((value) => { return formatterWrapper(value.toString(), encoding) }),
                marker: {
                    color: q2.map((value) => {
                        if (parseFloat(value.toString()) > 0) {
                            return "rgb(50, 80, 168)"
                        } else {
                            return "rgb(168, 50, 50)"
                        }
                    })
                },
            },
            {
                name: traceNames[1],
                x: ["Rocket", "Guild", "UWM", "Loan Depot"],
                y: q3,
                type: 'bar',
                textfont: {
                    size: 12,
                },
                textposition: "inside",
                hoverinfo: 'none',
                text: q3.map((value) => { return formatterWrapper(value.toString(), encoding) }),
                marker: {
                    color: q3.map((value) => {
                        if (parseFloat(value.toString()) > 0) {
                            return "rgb(3, 102, 252)"
                        } else {
                            return "rgb(168, 50, 50)"
                        }
                    })
                },
            }
            ]}
            layout={{
                barmode: "group",
                margin: {
                    t: 10
                },
                legend: { "orientation": "h" },
                yaxis: {
                    visible: false,
                    showticklabels: false
                },
                autosize: true,
                bargap: 0.05
            }}
            useResizeHandler
            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
            className="w-100 h-100"
        />
    }

    const renderTableAndLine = (titleBar: string, titleScatter: string, q2ValueArray: (number | string)[], q3ValueArray: (number | string)[], barsFormatting: DataEncoding, qoqValueArray: (number | string)[], qoqFormatting: DataEncoding) => {
        return <div style={{ display: "flex", minHeight: "500px" }}>
            <div style={{ width: "100%", height: "100%" }}>
                <div className="table-title">
                    {titleBar}
                </div>
                {renderBarChart(q2ValueArray, q3ValueArray, barsFormatting)}
            </div>
            <div style={{ width: "100%", height: "100%" }}>
                <div className="table-title">
                    {titleScatter}
                </div>
                {renderLineChart(qoqValueArray, qoqFormatting)}
            </div>
        </div>
    }

    const renderTable = (data: tsDataObject) => {
        var rowsWithFormat: any[] = [];
        var rowsWithoutFormat: any[] = [];
        var result;

        result = TSToAgGrid(data, true, undefined, undefined, ["RowName"]);

        result.rows.forEach((element) => {
            rowsWithFormat.push(element.formatted)
        })

        result.rows.forEach((element) => {
            rowsWithoutFormat.push(element.notFormatted)
        })

        let tmpcsv = csvData;
        let newData = { title: "PublicPlayersBenchmark", columnDefs: result.columns, rowDefs: rowsWithoutFormat };
        if (!csvData.find((e) => e === newData)) {
            tmpcsv.push(newData);
        }
        setCSVData(tmpcsv);
        console.log("Generated CSV");

        return <div style={{ paddingTop: "15px" }}>
            <div style={{ padding: "20px", fontSize: "medium", fontWeight: "bold" }}>
            </div>
            <AgGridReact
                domLayout={"autoHeight"}
                rowClassRules={result.rowStyles}
                rowData={rowsWithFormat}
                columnDefs={result.columnsWithGrouping}
                containerStyle={{ paddingLeft: "15px", paddingBottom: "15px" }}
                suppressFieldDotNotation={true}
            />
        </div>
    }

    const graphsRendererWrapper = () => {
        var columnOne: (number | string)[][] = [];
        var columnTwo: (number | string)[][] = [];
        var columnThree: (number | string)[][] = [];
        const periodOverPeriod: string = (props.period === "FY") ? "YoY" : "QoQ";

        switch (props.period) {
            case "Q3 2023":
                columnOne = [[22330333000, 4549345000, 31846800000, 6273543000],
                [0.0267, 0.031, 0.009, 0.0275],
                [503693198000, 82030408000, 294945929000, 142479870000],
                [4.99, 4.8, "NA", 5.1],
                [19607671000, 3347745000, 12425919000, 6203504000],
                [7116848000, 1066571000, 2947122000, 797344000],
                [1236227000, 236811000, 587520000, 271833000],
                [132293000, 36936000, 228794000, -49759000],
                [0.107013517743909, 0.155972484386283, 0.389423338779956, -0.183049887246949],
                [0.252192521998151, 0.282949866253254, 0.189127258917429, 0.175277069217655],
                [0.362962434447212, 0.318593859448674, 0.237175375117124, 0.128531230091896],
                [0.0743548267435247, 0.138522423729878, 0.310532105559254, -0.249623750852831]];
                columnTwo = [[22191440000, 4347306000, 29721633000, 6083143000],
                [0.0276, 0.0377, 0.0097, 0.0274],
                [506083328000, 83705731000, 281373662000, 143959705000],
                [5.11, 5, "NA", 5.2],
                [19406521000, 3310450000, 12204137000, 6078529000],
                [7264155000, 1090794000, 3092111000, 768935000],
                [1203168000, 257257000, 677116000, 265661000],
                [109266000, 54243000, 300993000, -34262000],
                [0.0908152477459507, 0.210851405403935, 0.444522061212554, -0.128968873865566],
                [0.247992517566647, 0.310842332613391, 0.221929989805916, 0.174819269596312],
                [0.374315159321962, 0.329500219003459, 0.253365805382224, 0.126500177921336],
                [0.0601672183481768, 0.19891198521444, 0.389368945681445, -0.178230929792505]];
                columnThree = [[-0.0062199251574081, -0.0444105689939981, -0.0667309431402842, -0.0303496764109212],
                [0.000899999999999998, 0.0067, 0.000699999999999999, -9.99999999999994E-05],
                [0.00474520999983796, 0.0204231947743085, -0.0460161191104284, 0.0103862742154384],
                [0.12, 0.2, "NA", 0.100000000000001],
                [-0.0102587400614789, -0.0111403347626536, -0.0178483378171063, -0.0201458723972773],
                [0.0206983484823617, 0.0227110994017277, 0.0491968096332627, -0.0356295400730426],
                [-0.0267418524267794, 0.0863388947303969, 0.152498638344227, -0.0227051167444718],
                [-0.174060607893086, 0.468567251461988, 0.315563345192619, "NM"],
                [-0.0161982699979588, 0.0548789210176519, 0.055098722432598, 0.0540810133813839],
                [-0.0042000044315039, 0.0278924663601369, 0.0328027308884868, -0.000457799621343208],
                [0.0113527248747499, 0.0109063595547851, 0.0161904302650999, -0.00203105217056057],
                [-0.0141876083953479, 0.0603895614845618, 0.0788368401221914, 0.0713928210603262],
                ];
                break;
            case "Q4 2023":
                columnOne = [[22191440000, 4347306000, 29721633000, 6083143000],
                [0.0276, 0.0377, 0.0097, 0.0274],
                [506083328000, 83705731000, 281373662000, 143959705000],
                [5.11, 5, "NA", 5.2],
                [19406521000, 3310450000, 12204137000, 6078529000],
                [7264155000, 1090794000, 3092111000, 768935000],
                [1203168000, 257257000, 677116000, 265661000],
                [114945000, 54243000, 300993000, -34262000],
                [0.0955352868427352, 0.210851405403935, 0.444522061212554, -0.128968873865566],
                [0.247992517566647, 0.310842332613391, 0.221929989805916, 0.174819269596312],
                [0.374315159321962, 0.329500219003459, 0.253365805382224, 0.126500177921336],
                [0.0632943542641918, 0.19891198521444, 0.389368945681445, -0.178230929792505]];
                columnTwo = [[17260889000, 3319289000, 24372436000, 5370708000],
                [0.0268, 0.033, 0.0092, 0.0243],
                [509105421000, 85033899000, 299456189000, 145090199000],
                [4.94, 4.5, "NA", 5],
                [17994975000, 3490539000, 11871854000, 6151048000],
                [7064945000, 997312000, 2474671000, 707484000],
                [693806000, 57234000, -114583000, 228626000],
                [-232694000, -93121000, -460956000, -59771000],
                [-0.335387702037745, -1.62702239927316, "NM", -0.2614357072249],
                [0.154222164798784, 0.0655875783081066, "NM", 0.148674502296194],
                [0.392606547105511, 0.285718623971828, 0.208448570880336, 0.11501844888871],
                [-0.131745682379693, -0.373487935570814, -0.74507843668916, -0.337935557553245]];
                columnThree = [[-0.222182562285278, -0.236472196804181, -0.179976551086544, -0.117116267034985],
                [-0.000799999999999999, -0.0047, -0.0005, -0.0031],
                [0.00597153241926196, 0.0158671095053218, 0.0642651727651751, 0.00785285021249527],
                [-0.17, -0.5, "NA", -0.2],
                [-0.0727356541649068, 0.054400157078343, -0.0272270788176174, 0.0119303535444182],
                [-0.0274236989711811, -0.0857008747756223, -0.199682352929762, -0.0799170280973033],
                [-0.423350687518285, -0.777522088806135, -1.169222112607, -0.13940698860578],
                [-3.02439427552308, -2.71673764356691, -2.53145089752918, "NM"],
                [-0.430922988880481, -1.83787380467709, "NA", -0.132466833359335],
                [-0.0937703527678627, -0.245254754305284, "NA", -0.0261447673001178],
                [0.0182913877835495, -0.0437815950316309, -0.0449172345018881, -0.0114817290326262],
                [-0.195040036643885, -0.572399920785254, -1.13444738237061, -0.15970462776074]];
                break;
            case "Q1 2024":
                columnOne = [[17.260889 * 1000000000, 3.319289 * 1000000000, 24.372436 * 1000000000, 5.370708 * 1000000000],
                [0.0268, 0.033, 0.0092, 0.0243],
                [509.105421 * 1000000000, 85.033899 * 1000000000, 299.456189 * 1000000000, 145.090199 * 1000000000],
                [4.94, 4.5, "NA", 5],
                [17994.975 * 1000000, 3490.539 * 1000000, 11871.854 * 1000000, 6151.048 * 1000000],
                [7064.945 * 1000000, 997.312 * 1000000, 2474.671 * 1000000, 707.484 * 1000000],
                [693.806 * 1000000, 57.234 * 1000000, -114.583 * 1000000, 228.626 * 1000000],
                [-232.694 * 1000000, -93.121 * 1000000, -460.956 * 1000000, -59.771 * 1000000],
                [-0.335387702037745, -1.62702239927316, 4.0229004302558, -0.2614357072249],
                [0.154222164798784, 0.0655875783081066, -0.0386066068534872, 0.148674502296194],
                [0.392606547105511, 0.285718623971828, 0.208448570880336, 0.11501844888871],
                [-0.131745682379693, -0.373487935570814, -0.74507843668916, -0.337935557553245]];
                columnTwo = [[20.205236 * 1000000000, 3.852539 * 1000000000, 27.630535 * 1000000000, 4.558351 * 1000000000],
                [0.0311, 0.029, 0.0108, 0.0284],
                [510.697615 * 1000000000, 86.319074 * 1000000000, 259.557321 * 1000000000, 142.337251 * 1000000000],
                [5.11, 4.7, "NA", 5],
                [20973.268 * 1000000, 3723.171 * 1000000, 12797.334 * 1000000, 6193.27 * 1000000],
                [7363.544 * 1000000, 981.269 * 1000000, 2457.058 * 1000000, 637.342 * 1000000],
                [1383.716 * 1000000, 231.782 * 1000000, 569.956 * 1000000, 222.785 * 1000000],
                [290.714 * 1000000, 28.4 * 1000000, 180.531 * 1000000, -71.504 * 1000000],
                [0.210096580512186, 0.122528928044456, 0.316745503161648, -0.320955181004107],
                [0.263900885641665, 0.249015691194415, 0.178148354962057, 0.143888446652576],
                [0.351091875619956, 0.263557327879917, 0.191997645759656, 0.102908802619618],
                [0.157920696881828, 0.115768459005635, 0.293897824145787, -0.448763772040757]];
                columnThree = [[0.170579105166599, 0.160651874543012, 0.133679661729341, -0.151256966493058],
                [0.0043, -0.004, 0.0016, 0.0041],
                [0.00312743477936772, 0.0151136783696111, -0.133237747175097, -0.0189740452420222],
                [0.17, 0.2, "NA", 0],
                [0.165506926239131, 0.0666464405640503, 0.0779558104403912, 0.00686419614998957],
                [0.0422648725503172, -0.0160862398126164, -0.00711730973531422, -0.0991428781428273],
                [0.994384597423487, 3.04972568752839, -5.97417592487542, -0.0255482753492604],
                [-2.24934033537607, -1.30497954274546, -1.39164475568167, "NM"],
                [0.545484282549931, 1.74955132731762, -3.70615492709415, -0.0595194737792071],
                [0.109678720842881, 0.183428112886308, 0.216754961815544, -0.004786055643618],
                [-0.0415146714855556, -0.0221612960919104, -0.0164509251206801, -0.012109646269092],
                [0.28966637926152, 0.489256394576449, 1.03897626083495, -0.110828214487512]];
                break;
            case "Q2 2024":
                columnOne = [[20.205236 * 1000000000, 3.852539 * 1000000000, 27.630535 * 1000000000, 4.558351 * 1000000000],
                [0.0311, 0.029, 0.0108, 0.0284],
                [510.697615 * 1000000000, 86.319074 * 1000000000, 259.557321 * 1000000000, 142.337251 * 1000000000],
                [5.11, 4.7, "NA", 5],
                [20973.268 * 1000000, 3723.171 * 1000000, 12797.334 * 1000000, 6193.27 * 1000000],
                [7363.544 * 1000000, 981.269 * 1000000, 2457.058 * 1000000, 637.342 * 1000000],
                [1383.716 * 1000000, 231.782 * 1000000, 569.956 * 1000000, 222.785 * 1000000],
                [290.714 * 1000000, 28.4 * 1000000, 180.531 * 1000000, -71.504 * 1000000],
                [0.210096580512186, 0.122528928044456, 0.316745503161648, -0.320955181004107],
                [0.263900885641665, 0.249015691194415, 0.178148354962057, 0.143888446652576],
                [0.351091875619956, 0.263557327879917, 0.191997645759656, 0.102908802619618],
                [0.157920696881828, 0.115768459005635, 0.293897824145787, -0.448763772040757]];
                columnTwo = [[24.66169 * 1000000000, 6.525898 * 1000000000, 33.628993 * 1000000000, 6.090634 * 1000000000],
                [0.0299, 0.0315, 0.0106, 0.0306],
                [534.557627 * 1000000000, 89.092933 * 1000000000, 189.482798 * 1000000000, 114.278549 * 1000000000],
                [5.18, 4.9, "NA", 4.9],
                [22408.895 * 1000000, 4349.392 * 1000000, 12921.641 * 1000000, 5942.777 * 1000000],
                [7574.102 * 1000000, 991.984 * 1000000, 2329.012 * 1000000, 578.938 * 1000000],
                [1300.722 * 1000000, 285.685 * 1000000, 507.094 * 1000000, 265.39 * 1000000],
                [177.925 * 1000000, 37.591 * 1000000, 76.286 * 1000000, -65.853 * 1000000],
                [0.136789413879369, 0.131581987153683, 0.150437591452472, -0.248136704472663],
                [0.23217958761465, 0.262735573155972, 0.156975108656865, 0.178630293547949],
                [0.337995336226976, 0.228074176804482, 0.180241193823602, 0.0974187656713351],
                [0.0939649347209742, 0.151579057726738, 0.13101864653338, -0.454991726229752]];
                columnThree = [[0.220559363919333, 0.693921333437507, 0.217095253494006, 0.336148532660166],
                [-0.0012, 0.0025, -0.000200000000000001, 0.0022],
                [0.0467204296616894, 0.032134948528294, -0.269977062215094, -0.197128311828925],
                [0.0699999999999994, 0.2, "NA", -0.0999999999999996],
                [0.0684503244797139, 0.168195605305263, 0.00971350751648736, -0.0404460002551157],
                [0.0285946549650549, 0.0109195337873713, -0.052113543921226, -0.0916368292062974],
                [-0.0599790708497986, 0.232559042548602, -0.110292724350652, 0.191238189285634],
                [-0.387972371471618, 0.32362676056338, -0.577435454298708, "NM"],
                [-0.0733071666328175, 0.00905305910922768, -0.166307911709175, 0.0728184765314443],
                [-0.031721298027015, 0.0137198819615575, -0.0211732463051921, 0.034741846895373],
                [-0.0130965393929799, -0.0354831510754353, -0.011756451936054, -0.00549003694828261],
                [-0.0639557621608534, 0.035810598721103, -0.162879177612408, -0.00622795418899491]];
                break;
            case "FY":
                columnOne = [[133129283000, 19123199000, 127285461000, 53778456000],
                [0.0282, 0.0368, 0.0077, 0.0163],
                [534704602000, 78892987000, 312454025000, 141170931000],
                [4.98, 4.9, "NA", 5.2],
                [18823284000, 3062822000, 13600625000, 6609934000],
                [7216621000, 1072518000, 3171693000, 921473000],
                [5838493000, 1164821000, 2372626000, 1255796000],
                [699933000, 328630000, 931858000, -610385000],
                [0.119882476522623, 0.282129185514341, 0.392753851639491, -0.486054263590583],
                [1.24069593807329, 1.52123890973749, 0.697799108496852, 0.759944653002587],
                [0.383387989045907, 0.350173140979136, 0.233202003584394, 0.139407292115171],
                [0.387956080830627, 1.2256391034929, 1.17521840859125, -2.64960557715744]];
                columnTwo = [[78711994000, 14959070000, 108275883000, 22671731000],
                [0.0263, 0.034, 0.0092, 0.026],
                [509105421000, 85033899000, 299456189000, 145090199000],
                [4.94, 4.5, "NA", 5],
                [17994975000, 3490539000, 11871854000, 6151048000],
                [7064945000, 997312000, 2474671000, 704484000],
                [3799269000, 655187000, 1311327000, 974022000],
                [-390080000, -39137000, -69782000, -235512000],
                [-0.102672382503055, -0.0597340911831279, -0.0532147969194564, -0.241793306516691],
                [0.844517761208337, 0.750814702256586, 0.441827199020473, 0.633402308029461],
                [0.392606547105511, 0.285718623971828, 0.208448570880336, 0.114530727121622],
                [-0.220853807071393, -0.156969935185779, -0.112793983523466, -1.33721702693035]
                ];
                columnThree = [[-0.408755217287544, -0.217752741055511, -0.149346027823241, -0.578423541947727],
                [-0.0019, -0.0028, 0.0015, 0.0097],
                [-0.0478753706331483, 0.0778385029330935, -0.0415991952736087, 0.0277625710352509],
                [-0.04, -0.4, "NA", -0.2],
                [-0.0440044893335297, 0.139648010886692, -0.127109673268692, -0.069423688648026],
                [-0.0210175925824566, -0.0701209676667431, -0.219763388196777, -0.235480583804409],
                [-0.349272320785518, -0.437521301556205, -0.447309858359472, -0.224378800378405],
                [-1.55731048543218, -1.11909137936281, -1.07488480004464, "NM"],
                [-0.222554859025678, -0.341863276697469, -0.445968648558948, 0.244260957073893],
                [-0.396178176864955, -0.770424207480905, -0.255971909476379, -0.126542344973126],
                [0.0092185580596042, -0.0644545170073084, -0.0247534327040581, -0.0248765649935487],
                [-0.60880988790202, -1.38260903867868, -1.28801239211471, 1.31238855022709]];
                break;
        }
        return <div>
            {renderTableAndLine("Originations", "Originations " + periodOverPeriod + " (%)", columnOne[0], columnTwo[0], "$skb1d", columnThree[0], "$%1d")}
            {renderTableAndLine("GoS Margins", "GoS Margins " + periodOverPeriod + " Delta", columnOne[1], columnTwo[1], "%2d", columnThree[1], "$%2d")}
            {renderTableAndLine("Servicing UPB", "Servicing UPB " + periodOverPeriod + " (%)", columnOne[2], columnTwo[2], "$skb1d", columnThree[2], "$%1d")}
            {renderTableAndLine("MSR FV Multiple", "MSR FV Multiple " + periodOverPeriod + " Delta", columnOne[3], columnTwo[3], "x", columnThree[3], "x")}
            {renderTableAndLine("Tangible Assets", "Tangible Assets " + periodOverPeriod + " (%)", columnOne[4], columnTwo[4], "$sk0d", columnThree[4], "$%1d")}
            {renderTableAndLine("Tangible Equity", "Tangible Equity " + periodOverPeriod + " (%)", columnOne[5], columnTwo[5], "$sk0d", columnThree[5], "$%1d")}
            {renderTableAndLine("Revenue", "Revenue " + periodOverPeriod + " (%)", columnOne[6], columnTwo[6], "$sk0d", columnThree[6], "$%1d")}
            {renderTableAndLine("GAAP Net Income", "GAAP Net Income " + periodOverPeriod + " (%)", columnOne[7], columnTwo[7], "$sk0d", columnThree[7], "$%1d")}
            {renderTableAndLine("Net Margin", "Net Margin " + periodOverPeriod + " Delta", columnOne[8], columnTwo[8], "$%1d", columnThree[8], "$%1d")}
            {renderTableAndLine("Asset Turnover", "Asset Turnover " + periodOverPeriod + " Delta", columnOne[9], columnTwo[9], "$%1d", columnThree[9], "$%1d")}
            {renderTableAndLine("TCE / TA", "TCE / TA " + periodOverPeriod + " Delta", columnOne[10], columnTwo[10], "$%1d", columnThree[10], "$%1d")}
            {renderTableAndLine("ROTCE (Annualized)", "ROTCE (Annualized) " + periodOverPeriod + " Delta", columnOne[11], columnTwo[11], "$%1d", columnThree[11], "$%1d")}
        </div>
    }

    var pdfFile;

    switch (props.period) {
        case "Q3 2023":
            pdfFile = pdfFileQ323;
            break;
        case "Q4 2023":
            pdfFile = pdfFileQ423;
            break;
        case "Q1 2024":
            pdfFile = pdfFileQ124;
            break;
        case "Q2 2024":
            pdfFile = pdfFileQ224;
            break;
        case "FY":
            pdfFile = pdfFileFY;
            break;
    }

    return (
        <div className="module-master" style={{ padding: '10px 10px 0 20px' }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="module-title">
                    Public Players Benchmark ({props.period})
                </div>
            </div>
            <div>
                {loading &&
                    (
                        <div className="loading-div">
                            <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                        </div>
                    )}
                {!loading && (
                    <div>
                        <OptionsMenu
                            exportToCSV={{
                                filename: `${typeof (props.overrideTitle) !== "undefined" ? (props.overrideTitle) : ("PublicPlayersBenchmark")}`,
                                CSVData: csvData,
                            }}
                            customObject={<div className="option-in-card">
                                <div style={{ paddingLeft: "5px" }}>
                                    <button className="btn">
                                        <Link to={pdfFile} target="_blank" download>Save To PDF</Link>
                                    </button>
                                </div>
                            </div>}
                        />
                        {graphsRendererWrapper()}
                        <div style={{ height: "100%", width: "100%" }}>
                            {tableArray}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
