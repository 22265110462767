import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ['Size', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Originations', 'text-underlined',{value: 20205236000, overrideEncoding: "$skb1d"}, {value: 17260889000, overrideEncoding: "$skb1d"}, 0.170579105166599, {value: 3852539000, overrideEncoding: "$skb1d"}, {value: 3319289000, overrideEncoding: "$skb1d"}, 0.160651874543012, {value: 27630535000, overrideEncoding: "$skb1d"}, {value: 24372436000, overrideEncoding: "$skb1d"}, 0.133679661729341, {value: 4558351000, overrideEncoding: "$skb1d"}, {value: 5370708000, overrideEncoding: "$skb1d"}, -0.151256966493058],
  ['Servicing', 'CSSCLASSGOESHERE',{value: 510697615000, overrideEncoding: "$-skb1d"}, {value: 509105421000, overrideEncoding: "$-skb1d"}, 0.00312743477936772, {value: 86319074000, overrideEncoding: "$-skb1d"}, {value: 85033899000, overrideEncoding: "$-skb1d"}, 0.0151136783696111, {value: 259557321000, overrideEncoding: "$-skb1d"}, {value: 299456189000, overrideEncoding: "$-skb1d"}, -0.133237747175097, {value: 142337251000, overrideEncoding: "$-skb1d"}, {value: 145090199000, overrideEncoding: "$-skb1d"}, -0.0189740452420222],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Balance Sheet', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Tangible Assets', 'CSSCLASSGOESHERE', 20973268000, 17994975000, 0.165506926239131, 3723171000, 3490539000, 0.0666464405640503, 12797334000, 11871854000, 0.0779558104403912, 6193270000, 6151048000, 0.00686419614998957],
  ['Tangible Equity', 'text-underlined', {value: 7363544000, overrideEncoding: "$-sk0d"}, {value:  7064945000, overrideEncoding: "$-sk0d"}, 0.0422648725503172, {value:  981269000, overrideEncoding: "$-sk0d"}, {value:  997312000, overrideEncoding: "$-sk0d"}, -0.0160862398126164, {value:  2457058000, overrideEncoding: "$-sk0d"}, {value:  2474671000, overrideEncoding: "$-sk0d"}, -0.00711730973531422, {value:  637342000, overrideEncoding: "$-sk0d"}, {value:  707484000, overrideEncoding: "$-sk0d"}, -0.0991428781428273],
  ['TCE / TA', 'CSSCLASSGOESHERE', {value: 0.351091875619956, overrideEncoding: "$%1d"}, {value:  0.392606547105511, overrideEncoding: "$%1d"}, {value:  -0.0415146714855556, overrideEncoding: "$%1d"}, {value:  0.263557327879917, overrideEncoding: "$%1d"}, {value:  0.285718623971828, overrideEncoding: "$%1d"}, {value:  -0.0221612960919104, overrideEncoding: "$%1d"}, {value:  0.191997645759656, overrideEncoding: "$%1d"}, {value:  0.208448570880336, overrideEncoding: "$%1d"}, {value:  -0.0164509251206801, overrideEncoding: "$%1d"}, {value:  0.102908802619618, overrideEncoding: "$%1d"}, {value:  0.11501844888871, overrideEncoding: "$%1d"}, {value:  -0.012109646269092, overrideEncoding: "$%1d"}],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Income Statement', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Revenue', 'CSSCLASSGOESHERE',1383716000, 693806000, 0.994384597423487, 231782000, 57234000, 3.04972568752839, 569956000, -114583000, -5.97417592487542, 222785000, 228626000, -0.0255482753492604],
  ['Expense', 'CSSCLASSGOESHERE', {value: 1085346000, overrideEncoding: "$-sk0d"}, {value:  936711000, overrideEncoding: "$-sk0d"}, 0.158677543020206, {value:  193239000, overrideEncoding: "$-sk0d"}, {value:  176533000, overrideEncoding: "$-sk0d"}, 0.0946338644899256, {value:  385692000, overrideEncoding: "$-sk0d"}, {value:  353825000, overrideEncoding: "$-sk0d"}, 0.0900642973221228, {value:  307949000, overrideEncoding: "$-sk0d"}, {value:  302571000, overrideEncoding: "$-sk0d"}, 0.0177743405679989],
  ['Net Income', 'SubTitleOrange',290714000, -232694000, -2.24934033537607, 28400000, -93121000, -1.30497954274546, 180531000, -460956000, -1.39164475568167, -71504000, -59771000, 0.196299208646334],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Profitability', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['GoS Margins','CSSCLASSGOESHERE', {value: 0.0311, overrideEncoding: "$%2d"}, {value: 0.0268, overrideEncoding: "$%2d"}, {value: 0.0043, overrideEncoding: "$%2d"}, {value: 0.029, overrideEncoding: "$%2d"}, {value: 0.033, overrideEncoding: "$%2d"}, {value: -0.004, overrideEncoding: "$%2d"}, {value: 0.0108, overrideEncoding: "$%2d"}, {value: 0.0092, overrideEncoding: "$%2d"}, {value: 0.0016, overrideEncoding: "$%2d"}, {value: 0.0284, overrideEncoding: "$%2d"}, {value: 0.0243, overrideEncoding: "$%2d"}, {value: 0.0041, overrideEncoding: "$%2d"}],
  ['Efficiency Ratio','CSSCLASSGOESHERE', {value: 0.784370492210829, overrideEncoding: "$%1d"}, {value: 1.35010507259955, overrideEncoding: "$%1d"}, {value: -0.565734580388716, overrideEncoding: "$%1d"}, {value: 0.8337101241684, overrideEncoding: "$%1d"}, {value: 3.08440786944823, overrideEncoding: "$%1d"}, {value: -2.25069774527983, overrideEncoding: "$%1d"}, {value: 0.676704868445985, overrideEncoding: "$%1d"}, {value: -3.08793625581456, overrideEncoding: "$%1d"}, {value: 3.76464112426055, overrideEncoding: "$%1d"}, {value: 1.38226990147452, overrideEncoding: "$%1d"}, {value: 1.32343215557286, overrideEncoding: "$%1d"}, {value: 0.0588377459016589, overrideEncoding: "$%1d"}],
  ['Net Margin','CSSCLASSGOESHERE', {value: 0.210096580512186, overrideEncoding: "$%1d"}, {value: -0.335387702037745, overrideEncoding: "$%1d"}, {value: 0.545484282549931, overrideEncoding: "$%1d"}, {value: 0.122528928044456, overrideEncoding: "$%1d"}, {value: -1.62702239927316, overrideEncoding: "$%1d"}, {value: 1.74955132731762, overrideEncoding: "$%1d"}, {value: 0.316745503161648, overrideEncoding: "$%1d"}, {value: 4.0229004302558, overrideEncoding: "$%1d"}, {value: -3.70615492709415, overrideEncoding: "$%1d"}, {value: -0.320955181004107, overrideEncoding: "$%1d"}, {value: -0.2614357072249, overrideEncoding: "$%1d"}, {value: -0.0595194737792071, overrideEncoding: "$%1d"}],
  ['ROTCE','SubTitleOrange', {value: 0.157920696881828, overrideEncoding: "$%1d"}, {value: -0.131745682379693, overrideEncoding: "$%1d"}, {value: 0.28966637926152, overrideEncoding: "$%1d"}, {value: 0.115768459005635, overrideEncoding: "$%1d"}, {value: -0.373487935570814, overrideEncoding: "$%1d"}, {value: 0.489256394576449, overrideEncoding: "$%1d"}, {value: 0.293897824145787, overrideEncoding: "$%1d"}, {value: -0.74507843668916, overrideEncoding: "$%1d"}, {value: 1.03897626083495, overrideEncoding: "$%1d"}, {value: -0.448763772040757, overrideEncoding: "$%1d"}, {value: -0.337935557553245, overrideEncoding: "$%1d"}, {value: -0.110828214487512, overrideEncoding: "$%1d"}]
];

const columnFormattingFormatted : DataEncoding[]= [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
]; 

export const GrateBenchmarkQ124: tsDataObject = {
  "data": dataArrayFormatted,
  "columnNames": [
    "RowName",
    "CSSClass",
    "Q1 2024 Rocket",
    "Q4 2023 Rocket",
    "QoQ Rocket",
    "Q1 2024 Guild",
    "Q4 2023 Guild",
    "QoQ Guild",
    "Q1 2024 UWM",
    "Q4 2023 UWM",
    "QoQ UWM",
    "Q1 2024 Loan Depot",
    "Q4 2023 Loan Depot",
    "QoQ Loan Depot",
  ],
  "columnDisplayName": [
    "RowName",
    "CSSClass",
    "Q1 2024",
    "Q4 2023",
    "QoQ",
    "Q1 2024",
    "Q4 2023",
    "QoQ",
    "Q1 2024",
    "Q4 2023",
    "QoQ",
    "Q1 2024",
    "Q4 2023",
    "QoQ",
  ],
  "columnShow": [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ],
  "columnGroupName": [{
    nameOfGroup: "Rocket",
    headerClass: 'grate-group',
    columnsToGroup: ["Q1 2024 Rocket",
      "Q4 2023 Rocket",
      "QoQ Rocket"]
  },
  {
    nameOfGroup: "Guild",
    headerClass: 'grate-group',
    columnsToGroup: ["Q1 2024 Guild",
      "Q4 2023 Guild",
      "QoQ Guild"]
  },
  {
    nameOfGroup: "UWM",
    headerClass: 'grate-group',
    columnsToGroup: ["Q1 2024 UWM",
      "Q4 2023 UWM",
      "QoQ UWM"]
  },
  {
    nameOfGroup: "Loan Depot",
    headerClass: 'grate-group',
    columnsToGroup: ["Q1 2024 Loan Depot",
      "Q4 2023 Loan Depot",
      "QoQ Loan Depot"]
  }
  ],
  "columnFormatting": columnFormattingFormatted,
  "columnHeaderClasses": [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column"
  ],
  "columnDataClasses": [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right"
  ],
  "tableTitle": ""
}



