import { Link, Navigate, useParams } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { SignUp } from './pages/sign-up/SignUp';
import { ContactUs } from './pages/contactus/ContactUs';
import Layout from './components/Layout';
import { SignOut } from './pages/sign-out/SignOut';
import { FinancialModeling } from './pages/demos/rocket-demo/FinancialModeling';
import { FinancialModelingCeleri } from './pages/demos/celeri-demo/FinancialModelingCeleri';
import { Test } from './Test';
import { EbitdaFinancialModeling } from './pages/demos/celeri-demo/EbitdaFinancialModeling';
import { IncomeStatement } from './pages/demos/celeri-demo/IncomeStatement';
import { EbitdaCovenantSummary } from './pages/demos/celeri-demo/EbitdaCovenantSummary'
import { EbitdaRegCapital } from './pages/demos/celeri-demo/EbitdaRegCapital';
import { BalanceSheet } from './pages/demos/celeri-demo/BalanceSheet';
import { LandingPage } from './pages/landing-page/LandingPage';
import Callback from './pages/callback';
import SecureRoute from './components/SecureRoute';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrows, faBalanceScale, faBarChart, faBriefcase, faBuilding, faCamera, faChartArea, faCompass, faDiagramProject, faEnvelope, faFax, faFile, faGlasses, faHomeUser, faHouse, faLocationPinLock, faMap, faMoneyBill1, faPrint, faRoad, faSearch, faTableCells, faTableColumns, faTableList, faTimes, faUser, faUserCircle, faUserClock, faEye, faCircleDollarToSlot, faArrowTurnUp, faChartSimple, faChartLine, faChartColumn, faTable, faExclamationTriangle, faBullseye, faHome, faArrowRightFromBracket, faBoltLightning, faChartBar, faClipboard, faLineChart, faTowerObservation, faPerson, faArrowUp, faMoneyBill, faSquareArrowUpRight, faPaperclip, faArchive, faPlus, faArrowDown, faCalendar, faDatabase, faPieChart, faArrowLeft, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons';
import { UserProfile } from './components/UserProfile';
import { AppEmbedWrapper } from './components/AppEmbed/AppEmbedWrapper';
import { DefaultSidebarBreakpoint } from './components/SidebarToggle';
import { Action, Page } from '@thoughtspot/visual-embed-sdk';
import { RocketIncomeStatement } from './pages/demos/rocket-demo/RocketIncomeStatement';
import { RocketBalanceSheet } from './pages/demos/rocket-demo/RocketBalanceSheet';
import { CashFlowWalk } from './pages/demos/rocket-demo/CashFlowWalk';
import { ProfitabilityWalk } from './pages/demos/rocket-demo/ProfitabilityWalk';
import { QuickSightDashboardEmbedWrapper } from './components/QuickSightEmbed/QuickSightDashboardEmbedWrapper';
import Footer from './components/Footer';
import { TSLandingPage } from './components/TSLandingPage';
import { NewLogInPage } from './pages/log-in/NewLogInPage';
import { GenericLiveboardEmbed } from './components/GenericLiveboardEmbed';
import { UserCheckTool } from './pages/demos/chm/UserCheckTool';
import { LiveboardPermissionTool } from './pages/demos/chm/LiveboardPermissionTool';
import { ShareLiveboards } from './pages/demos/chm/ShareLiveboards';
import { HeatmapPerformanceMetrics } from './pages/demos/chm/HeatmapPerformanceMetrics';
import { TablePerformanceMetrics } from './pages/demos/chm/TablePerformanceMetrics'
import { FinancialModelingPanda } from './pages/demos/panda/FinancialModelingPanda';
import { ProductSubscriptionGuard } from './components/ProductSubscriptionGuard';
import { getUserSessionItem } from '.';
import { CognitoJwtPayload } from './features/console/consoleSlice';
import { QuickSightQEmbedWrapper } from './components/QuickSightQEmbedWrapper';
import { B1 } from './pages/demos/mba/B1';
import { Redirect } from './Redirect';
import { GrateTable } from './pages/demos/grate/grateTable';
import { FullScreenPerformanceMetrics } from './pages/demos/chm/FullScreenPerformanceMetrics';
import { GallusAgilityRedirect } from './pages/demos/gallus-agility/GallusAgilityRedirect';
import { ForecastVariance } from './pages/demos/grate/ForecastVariance';
import { MBABenchmark } from './pages/demos/grate/MBABenchmark';
import { DisableMFA } from './pages/log-in/DisableMFA';
import { PublicPlayersBenchmark } from './pages/demos/grate/PublicPlayersBenchmark';
import { MFASetUp } from './pages/log-in/MFASetUp';
import { WaterfallPlots } from './pages/demos/grate/WaterfallPlots';
import { TSRedirect } from './TSRedirect';
import { RouterError } from './components/RouterError';

interface SubdomainOrgIDObject {
  orgID: number,
  path: string,
  externalInfo?: { subdomain: string, productURL: string },
  editableRightsGroup?: string
}

enum Subdomains {
  celeriSubDomain = 'servdemo',
  hmdaSubDomain = 'dashboard',
  rocketSubDomain = 'rocket',
  apexSubDomain = 'apex',
  panoramaSubDomain = 'pmg',
  demoSubDomain = 'demo',
  chmSubDomain = 'cambio',
  reliantSubDomain = 'reliant',
  gRateSubDomain = 'gr',
  revolutionSubDomain = 'revolution',
  celinkSubDomain = 'celink',
  agilitySubDomain = 'agility',
  fcmSubDomain = 'fcm',
  radiusSubDomain = 'radius',
  prmgUATModeSubDomain = 'prmgUAT2',
  flexpointSubDomain = 'flexpoint',
  prmgProdSubDomain = 'prmgprod',
  kindLendingSubDomain = 'kindlending'
}

enum OrgIds {
  chmOrgId = 74610152,
  panoramaOrgId = 505086958,
  apexOrgId = 1119088413,
  demoOrgId = 1977935901,
  marketingOrgId = 1588809781,
  rocketOrgId = 888221964,
  reliantOrgId = 1459934658,
  gRateOrgId = 818396742,
  testTSOrg = 346771419,
  celinkOrgId = 1865786808,
  primaryOrgId = 0,
  frsOrgId = 193492375,
  hmdaOrgId = 741418449,
  revolutionOrgId = 1094129358,
  agilityOrgId = 184485983,
  radiusOrgId = 1622018741,
  fcmOrgId = 1275652438,
  prmgUATModeOrgId = 581621867,
  flexpointOrgId = 1694387707,
  prmgProdOrgId = 428892831,
  kindLendingOrgId = 1270257552
}

const celeriObject: SubdomainOrgIDObject = { orgID: -1, path: Subdomains.celeriSubDomain };
const hmdaObject: SubdomainOrgIDObject = { orgID: OrgIds.hmdaOrgId, path: Subdomains.hmdaSubDomain };
const rocketObject: SubdomainOrgIDObject = { orgID: OrgIds.rocketOrgId, path: Subdomains.rocketSubDomain };
const apexObject: SubdomainOrgIDObject = { orgID: OrgIds.apexOrgId, path: Subdomains.apexSubDomain };
const panoramaObject: SubdomainOrgIDObject = { orgID: OrgIds.panoramaOrgId, path: Subdomains.panoramaSubDomain };
const demoObject: SubdomainOrgIDObject = { orgID: OrgIds.demoOrgId, path: Subdomains.demoSubDomain };
const reliantObject: SubdomainOrgIDObject = { orgID: OrgIds.reliantOrgId, path: Subdomains.reliantSubDomain };
const revolutionObject: SubdomainOrgIDObject = { orgID: OrgIds.revolutionOrgId, path: Subdomains.revolutionSubDomain };
const celinkObject: SubdomainOrgIDObject = { orgID: OrgIds.celinkOrgId, path: Subdomains.celinkSubDomain };
const agilityObject: SubdomainOrgIDObject = { orgID: OrgIds.agilityOrgId, path: Subdomains.agilitySubDomain };
const fcmObject: SubdomainOrgIDObject = { orgID: OrgIds.fcmOrgId, path: Subdomains.fcmSubDomain };
export const radiusObject: SubdomainOrgIDObject = { orgID: OrgIds.radiusOrgId, path: Subdomains.radiusSubDomain, editableRightsGroup: "RADIUSEDITINGRIGHTS" };
const marketingObject: SubdomainOrgIDObject = { orgID: OrgIds.marketingOrgId, path: Subdomains.demoSubDomain };
const flexpointObject: SubdomainOrgIDObject = { orgID: OrgIds.flexpointOrgId, path: Subdomains.flexpointSubDomain };

export const chmObject: SubdomainOrgIDObject = { orgID: OrgIds.chmOrgId, path: Subdomains.chmSubDomain, externalInfo: { subdomain: "chm", productURL: "chm" } };
const gRateObject: SubdomainOrgIDObject = { orgID: OrgIds.gRateOrgId, path: Subdomains.gRateSubDomain, externalInfo: { subdomain: "g-rate", productURL: "grate" } };
export const prmgProdObject: SubdomainOrgIDObject = { orgID: OrgIds.prmgProdOrgId, path: Subdomains.prmgProdSubDomain, externalInfo: { subdomain: "prmg", productURL: "prmgprod" }, editableRightsGroup: "PRMGEDITINGRIGHTS" };
export const prmgUATModeObject: SubdomainOrgIDObject = { orgID: OrgIds.prmgUATModeOrgId, path: Subdomains.prmgUATModeSubDomain, externalInfo: { subdomain: "prmg", productURL: "prmgUAT2" }, editableRightsGroup: "PRMGUATEDITINGRIGHTS" };
const kindLendingObject: SubdomainOrgIDObject = { orgID: OrgIds.kindLendingOrgId, path: Subdomains.kindLendingSubDomain, externalInfo: { subdomain: "kindlending", productURL: "kindlending" } };

export const SubdomainOrgIDArray: SubdomainOrgIDObject[] = [
  celeriObject,
  hmdaObject,
  rocketObject,
  apexObject,
  panoramaObject,
  demoObject,
  chmObject,
  reliantObject,
  gRateObject,
  revolutionObject,
  celinkObject,
  agilityObject,
  fcmObject,
  radiusObject,
  marketingObject,
  prmgUATModeObject,
  flexpointObject,
  prmgProdObject,
  kindLendingObject
];

const appEmbedWrapperCssRules = {
  '.bk-primary-nav-home-logo': {
    'display': 'none !important'
  },
  '.bk-primary-nav-logo': {
    'display': 'none !important'
  },
  '.bk-primary-nav-home': {
    'display': 'none !important'
  },
  '.bk-primary-nav-admin': {
    'display': 'none !important'
  },
  '.bk-primary-nav-manage-data': {
    'display': 'none !important'
  },
  '.bk-primary-nav-insights': {
    'display': 'none !important'
  },
  '.bk-primary-nav-setup': {
    'display': 'none !important'
  },
  '.pinboard-edit-header-module__headerLogo': {
    'display': 'none !important'
  },
  '.pinboard-header-module__avatarDiv': {
    'display': 'none !important'
  },
  '.bk-logo-wide-customizable': {
    'display': 'none !important'
  },
  '.wizard-module__headerLogo': {
    'display': 'none !important',
  },
  '.bk-powered-by-ts-ribbon': {
    'display': 'none !important'
  },
  '.wizard-module__wizardHeader': {
    'display': 'none !important'
  },
  '.classic-nav-module__homeLink': {
    'display': 'none !important'
  },
  '.classic-nav-module__navItem[href="/#/insights"]': {
    'display': 'none !important'
  },
  '.classic-nav-module__navItem[href="/#/insights/home/monitor-alerts"]': {
    'display': 'none !important'
  },
  '.classic-nav-module__navItem[href="/#/data"]': {
    'display': 'none !important'
  },
  '.classic-nav-module__navItem[href="/#/admin"]': {
    'display': 'none !important'
  },
  //'.pinboard-header-module__shareButton': {
  //  'display': 'none !important'
  //}
};

const prmgProdNonEditableLiveboards = [
  "1711b5fa-3822-4946-b87a-ea7574e9f3b0",
  "6ee104e1-99d7-4441-9e6d-f6de079a18c2",
  "73a97608-88e1-4b77-b39c-740de369189a",
  "63eceeb6-2df7-4fe9-9f9b-afd08555cd4b",
  "1e0d1f64-d733-4f72-8abb-44a1e4d803d0",
  "0596fe93-fb24-432b-b0a2-ff9a7781f4ed",
  "aac2907a-6f4e-4664-83b4-5b8008e6b52f",
  "c6051f3c-561b-49e9-824e-8554e73f692a",
  "35c591c5-141b-4246-bce5-e50a5a0649d1",
  "4a9c1ecc-4d68-4c54-b5fd-d63abe54f6f7",
  "9be5e6c8-5892-4a09-85fe-bcde0cba3052",
  "a5419de1-52c8-45cb-be77-f2585e11f9ea",
  "288b3e13-97bb-4fa5-ae50-a7b8067a9370",
  "ec947278-0200-4247-8fde-7007bf8a2258",
  "57495ad4-dd22-4413-afe3-2c31345d36fc",
  "9ae98bb8-68bb-40b7-8e93-953cb25e6a71",
  "50323ebc-10de-415a-b04e-6c5557f98ef1",
  "a596a179-f7ad-4a1d-b47b-f7f460701909",
  "fb837204-c6f1-4522-9e4d-9a79730d6fc0",
  "15dde2bc-cbc7-4191-bb20-c11398ea0d1b",
  "adf45573-f614-4399-8edb-47798001a436",
  "4f5f1ea6-2e2b-4e54-8af6-b22051a784e5",
  "2613020a-9175-45d9-b929-2b2904295769",
  "cf72e436-5e2a-49fc-b1ae-ea4c4d7cf3f4",
  "233c0070-e34a-49af-bacf-0249dbf431bc",
  "52dbf268-8701-44cd-ba42-7d64e6070553",
  "e549b774-3e72-42f7-ad46-f74b111711fb",
  "d2ad0e67-337f-4b0a-83a8-37b5c8ecd6b7",
  "02e25a30-a061-46cb-bf20-3929042daf1f",
  "820b9fcb-9158-4b05-af76-46ead6be96d8",
  "5c554a1a-e5a0-4c5e-894e-bedffbfa93a2",
  "45f4377c-457f-4d6b-b7b0-eeb06cea6ef8",
  "1fdb974f-5380-4f7a-8787-26641b0c5e17",
  "55a0a1a5-4ff2-43fe-913a-ce3fc7592723",
  "3895514e-412c-438d-9c16-975057d446ed",
  "e2d017a4-7978-43f6-8b56-5b4e8a6e86fe",
  "286d544a-8456-4fcb-bd2c-8f1fddf6ea34",
  "9519e3c5-3a67-4a77-9be0-a3c171855b51",
  "f7706944-5a60-4ed4-8cc8-07a98fc6f84f",
  "c38dc596-2c02-47d6-85c1-19028054ceb5"
]

const prmgUATModeNonEditableLiveboards = [
  "937bbb74-254e-425f-8732-99011df319c4",
  "3eb13a02-b476-4806-86bd-ddcebbd2055e",
  "5928af7e-3012-4193-903f-2c6bd4b26d28",
  "de99d132-7b28-4fff-8bc0-46125ee21091",
  "249abc71-5f81-4eea-b04f-4700c3c3288d",
  "63ba6082-94f5-4c6e-b011-106a7e4f7d33",
  "be535cdb-1495-47f6-a77b-86dbd9cc8819",
  "9d903f22-6bb7-44f1-9da4-62349541a2a9",
  "886bd401-3a37-423a-abe7-bb29a439938d",
  "0c085907-7820-4501-809e-b4d977363046",
  "bbb06cd7-b46e-4ea5-9f43-de3241484541",
  "a4abe299-6772-428b-89fd-f430a579f4c2",
  "62f3c346-bb9f-434a-8c07-7ccbd79651a3",
  "ba36e770-dcb6-498e-a2ca-36afae1a9c81",
  "209f8535-06b2-4c1a-a673-49b466d6f47a",
  "b3338b4d-5fd4-4413-a1ff-f2c11a26bfdd",
  "50921a32-ae65-4fcd-b5a1-ba5f9faed600",
  "edc66a11-3838-43a1-94d7-ccb9871c7899",
  "2794d17b-2b02-4fcf-bc68-ed248775e307",
  "ba32098c-0d93-4fb3-9d94-3797cab10ebd",
  "aa22fbee-b662-4819-ab01-91d866c31d08",
  "9abb8751-feb9-45a9-8775-4dc35cb586b0",
  "39908653-f075-4d20-9b23-bf18f39bc6f3",
  "798647e5-435d-42f8-bc63-236ebbd304e0",
  "751452d0-517e-469e-ba73-7b119f26b8bd",
  "e729999b-947a-435b-b56a-1e37d51c7480",
  "cc37497b-e825-4b48-b884-e497ba43b956",
  "27991ad5-ae7d-489b-b983-4fbea2d4eeba",
  "1b583257-a7a8-4ec4-8f72-f4d2065f76d5",
  "a7c66e99-a095-4f1d-9a87-4fd0a06e4f36",
  "831b46d7-5af2-4f3a-845f-7479e3f526c4",
  "403e0803-f605-4722-be3e-7ad11e301c3d",
  "ed2d5ae7-cc6f-4da4-ae65-1c4216475729",
  "1b522001-ebb6-4bce-acb3-c8cd27f3888f",
  "d610c8ff-1638-4f1b-acd7-5309aaecafb0",
  "e42d662c-b6c2-499a-a7e7-384e38945b4b",
  "a4498a6b-294e-4eee-bae0-853470c177ce",
  "9a1a01a8-3be1-4e25-9472-a5e4c2ae3e84",
  "53613f2c-da5a-4bab-aca0-b50c33bc67b2"
]

const radiusNonEditableLiveboards = [
  "a150f297-d669-46ee-aa7c-00df60269294",
  "134f6cf8-9220-40d6-9bbe-0eea740b2dd5",
  "63b7bbec-f1eb-48c8-8546-e73d2f511bd4",
  "a150f297-d669-46ee-aa7c-00df60269294",
  "c6cf27aa-948d-4a1e-abd4-82d2aa3d3c6f",
  "d68c6e67-7a38-4e09-a704-9d2d35c3fbb6",
  "c408f361-a9ee-43a1-bb76-d480dc7f5ec4",
  "202f87f1-4928-4ab1-941b-d62629f54e9a",
  "5d3ac5e4-a7cb-40fe-9df3-a795d6d89ccf",
  "217a9026-88b0-494c-9f2f-bad2a52457c3",
  "f2e85da6-a388-4744-83ed-c2a1216e2c5b",
  "c1d644b4-3cab-41c1-bfa1-07a291b560e4",
  "ac730227-72a2-407d-ba07-32f74d4ffc50",
  "598efe96-fc33-4c00-bd23-dd8229d5e9e5",
  "5bebdc5a-f456-40e9-881b-fed7aa415dd9",
  "0a625779-812d-4674-86cf-d3f7bd2c24f7",
  "916e036a-2d25-4970-aa1e-285928f7a7c9",
  "be969d40-92ca-4dd4-81f4-8330a4bdaa89",
  "8cc510c0-7cf8-42a0-8caf-4f5d426d5e13",
  "223c8545-2261-4ee9-936a-4f9151156040",
  "742d1bc8-df54-463d-a119-4e7c6eb53ae9",
  "7eca149a-73bc-4a55-8a4e-15e2022a066f",
  "751acab1-775c-4f68-9353-156cb22a43fb",
  "242ec0a4-79ae-48d5-b4b9-be93061e4c85",
  "8f95f06f-2620-4316-9646-097364566f24",
  "213d9c45-6798-413d-877e-79cd95f15516",
  "6649fe38-710e-46d9-ab4b-dee9059bafba",
  "134f6cf8-9220-40d6-9bbe-0eea740b2dd5",
  "4b5c6faa-efca-45ff-b3d7-986de7f027a2",
  "0f5e71aa-2009-4aee-9f79-28b961847a90",
  "ba229734-26a9-4e87-906d-5e6ff201fd10",
  "22ee8894-bc83-4331-a477-86cbea8d6d13",
  "c777a30d-cc99-44eb-aea2-70e31781bfcd",
  "177e0020-18ca-42cb-bc64-ec3f8bea1d1b",
  "a41d5f0a-86ef-44c6-b862-666344625c81",
  "5744afe1-e0fc-4f04-b18a-160565e3a808",
  "42b58a63-cafe-4502-8c32-6e276334c43a",
  "de9de8ce-764a-479a-9492-909a77c94696",
  "205d74fb-55f4-46f5-92e3-03efd9505f6f",
  "bbfc8707-161f-4dff-88d6-807ad728db37",
  "760873ce-3402-4f69-90d4-baf287aae5e5",
  "4e579d0b-7c1c-40a5-8f36-910ac8aa9ab8",
  "35f281fd-ac4a-416e-8d71-d41accf326c1",
  "ab5b6fd0-3046-408e-962e-fc1338c8970f",
  "8df840d4-8eac-4560-a9df-1432f3afd1b9",
  "153b44ae-69b9-4c51-8406-89017ec38c2a",
  "d9ef26d5-fe10-433e-a6f5-788ff875b1b0",
  "debc23a4-b208-4b73-bb63-32bdfa1624fe",
  "4afb1144-a4e0-4be0-bd53-4617b7a38cf4",
  "f55cf41d-6e34-4e53-88f2-c8659588de69",
  "df36eca2-7b07-409e-958a-ba89a62dd043",
  "75dc5bc0-3c0d-47c8-be80-69306bce2d25",
  "8a72158a-8b28-41c8-8621-ed0c02b8e7b9",
  "3676e8b2-cd23-4587-8716-ff09e8b03e81",
  "b9f9d094-2e77-497c-ab5d-c5e156860fa4",
  "effa0423-bb77-4c80-ba0e-a33416f5fbf8",
  "3999e49e-8b2f-4c8e-b583-ed46e7792ef9",
  "e71fd9d9-dd64-4fd2-bb1f-36d4e8214090",
  "b68304f5-ba21-481d-b0bd-82907b9d043f",
  "ff883867-05f2-4d86-9426-ccc81de32b8f",
  "b79968db-ec9f-448a-a715-e60e3190c934",
  "c90f4904-5adc-4af2-a377-5908f721756b",
  "3bcdd45c-6b9f-44b5-866a-dbe82f2376dd",
  "c2516482-1b38-45ed-9528-206b92b7be9d",
  "446e317f-6887-4b22-90a2-88cf4dba35cc",
  "887f789c-a6f2-486a-85e4-49aabd076884",
  "38f5e6d8-513c-401f-907a-e8e27fd7b52e",
  "2bd7eccb-f870-4ac4-908c-6a0c83c5fe68",
  "9fd20744-fa1d-4af9-9c1e-fa673a013269",
  "c341699f-a37c-4ca4-9b6b-2a9e063af072"
]

const appEmbedWrapperCssRulesNoSaveView = {
  ...appEmbedWrapperCssRules,
  '.personalised-views-module__saveViewButton': {
    'display': 'none !important'
  }
};


const InSessionSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to="landingpage"><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrows} />Product</div></Link></MenuItem>
      <MenuItem><Link to="contactus"><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to="signout"><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const PMGSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${panoramaObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${panoramaObject.path}/financialmodel`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />Financial Model</div></Link></MenuItem>
      <MenuItem><Link to={`/${panoramaObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${panoramaObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const CelinkSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${celinkObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${celinkObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${celinkObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const ReliantSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${reliantObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${reliantObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${reliantObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const CeleriSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`${celeriObject.path}`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/incomestatement`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faTable} />Income Statement</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/balancesheet`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faTableColumns} />Balance Sheet</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/financialmodel`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBarChart} />Financial Model</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/covenantsummary`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartColumn} />Covenant Summary</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/debtcovenant`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartLine} />Debt Covenant</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/regcapitalcov`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartSimple} />Reg. Capital Cov.</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/contactus`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`${celeriObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const HMDASidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`${hmdaObject.path}/marketoverview`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faUser} />Market Overview</div></Link></MenuItem>
      <MenuItem><Link to={`${hmdaObject.path}/individuallender`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faEye} />Individual Lender </div></Link></MenuItem>
      <MenuItem><Link to={`${hmdaObject.path}/contactus`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`${hmdaObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const RadiusSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${radiusObject.path}/?productURL=pinboards&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${radiusObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${radiusObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const FlexpointSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${flexpointObject.path}/?productURL=pinboards&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${flexpointObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${flexpointObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const PRMGProdSidebarMenu = () => {
  return (
    <>
      <SubMenu title={"PRMG"} icon={<FontAwesomeIcon className="me-2" icon={faUser} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Search"} icon={<FontAwesomeIcon className="me-2" icon={faLocationPinLock} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=answer&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faSearch} />Search</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Executive"} icon={<FontAwesomeIcon className="me-2" icon={faChartArea} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/1711b5fa-3822-4946-b87a-ea7574e9f3b0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faSquareArrowUpRight} />AM Wholesale Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/73a97608-88e1-4b77-b39c-740de369189a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPaperclip} />Doc Drawer Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/63eceeb6-2df7-4fe9-9f9b-afd08555cd4b&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableColumns} />Executive Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/9643af96-d335-4672-ac90-416adb37b48e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBoltLightning} />Executive Retail Stats MTD</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/1e0d1f64-d733-4f72-8abb-44a1e4d803d0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCalendar} />Historical Data</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/0596fe93-fb24-432b-b0a2-ff9a7781f4ed&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Leakage Ops Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/aac2907a-6f4e-4664-83b4-5b8008e6b52f&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArchive} />Loan Processor Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/c6051f3c-561b-49e9-824e-8554e73f692a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/35c591c5-141b-4246-bce5-e50a5a0649d1&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrows} />Processing Metrics</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/4a9c1ecc-4d68-4c54-b5fd-d63abe54f6f7&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBuilding} />PROC - RPT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/9be5e6c8-5892-4a09-85fe-bcde0cba3052&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/a5419de1-52c8-45cb-be77-f2585e11f9ea&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnDown} />UW Metrics</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/288b3e13-97bb-4fa5-ae50-a7b8067a9370&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />UW Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/ec947278-0200-4247-8fde-7007bf8a2258&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/57495ad4-dd22-4413-afe3-2c31345d36fc&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartSimple} />UW Vendor Metrics</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Operations"} icon={<FontAwesomeIcon className="me-2" icon={faBuilding} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/9ae98bb8-68bb-40b7-8e93-953cb25e6a71&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />CD Drawer Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/50323ebc-10de-415a-b04e-6c5557f98ef1&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrows} />Doc Drawer Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/a596a179-f7ad-4a1d-b47b-f7f460701909&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartBar} />Funder Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/fb837204-c6f1-4522-9e4d-9a79730d6fc0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartBar} />Investor Aging – FC Escalation Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/c6051f3c-561b-49e9-824e-8554e73f692a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/15dde2bc-cbc7-4191-bb20-c11398ea0d1b&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartColumn} />Loan Submissions</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/adf45573-f614-4399-8edb-47798001a436&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />PCCD Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/4f5f1ea6-2e2b-4e54-8af6-b22051a784e5&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBuilding} />Post Closing Shipping Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/2613020a-9175-45d9-b929-2b2904295769&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />SetUp Registration</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/9be5e6c8-5892-4a09-85fe-bcde0cba3052&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/288b3e13-97bb-4fa5-ae50-a7b8067a9370&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />UW Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/ec947278-0200-4247-8fde-7007bf8a2258&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Retail"} icon={<FontAwesomeIcon className="me-2" icon={faFile} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/cf72e436-5e2a-49fc-b1ae-ea4c4d7cf3f4&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEye} />AFS Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/233c0070-e34a-49af-bacf-0249dbf431bc&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBullseye} />Branch Manager Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/52dbf268-8701-44cd-ba42-7d64e6070553&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableList} />Leader Board</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/e549b774-3e72-42f7-ad46-f74b111711fb&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through - Retail</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/d2ad0e67-337f-4b0a-83a8-37b5c8ecd6b7&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPieChart} />Pipeline View - Retail</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/02e25a30-a061-46cb-bf20-3929042daf1f&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBoltLightning} />Regional Manager Leaderboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/820b9fcb-9158-4b05-af76-46ead6be96d8&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faClipboard} />Retail MTD Stats</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/5c554a1a-e5a0-4c5e-894e-bedffbfa93a2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through - Retail</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/ec947278-0200-4247-8fde-7007bf8a2258&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Wholesale Correspondent"} icon={<FontAwesomeIcon className="me-2" icon={faMoneyBill} />}>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/45f4377c-457f-4d6b-b7b0-eeb06cea6ef8&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />AE Scorecard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/1fdb974f-5380-4f7a-8787-26641b0c5e17&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through - Wholesale</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/55a0a1a5-4ff2-43fe-913a-ce3fc7592723&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPieChart} />Pipeline View - Wholesale</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/3895514e-412c-438d-9c16-975057d446ed&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />Pull Through Report</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/e2d017a4-7978-43f6-8b56-5b4e8a6e86fe&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faClipboard} />TPO Scorecard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/ec947278-0200-4247-8fde-7007bf8a2258&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/286d544a-8456-4fcb-bd2c-8f1fddf6ea34&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faMap} />Wholesale - Correspondent Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/9519e3c5-3a67-4a77-9be0-a3c171855b51&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBarChart} />Wholesale - Correspondent Lock Expiration</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/f7706944-5a60-4ed4-8cc8-07a98fc6f84f&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPlus} />Wholesale - Correspondent Potential Fundings</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgProdObject.path}/product?productURL=pinboard/c38dc596-2c02-47d6-85c1-19028054ceb5&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArchive} />Wholesale - Correspondent Stats MTD</div></Link></MenuItem>
      </SubMenu>
    </>
  );
}

const RocketSidebarMenu = () => {
  let userSession = getUserSessionItem('user')!;
  let user = JSON.parse(userSession) as CognitoJwtPayload; //get user info
  return (
    <>
      <>
        {user && user["cognito:groups"]?.includes("Administrator") && <MenuItem><Link to={`${rocketObject.path}/liveboardmanager`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faHome} />Liveboard Manager</div></Link></MenuItem>}
      </>
      <MenuItem><Link to={`${rocketObject.path}/ceosummary`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faEye} />CEO Summary</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/cfosummary`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBullseye} />CFO Summary</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/servicing`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faExclamationTriangle} />Servicing Overview</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/segmentanalytics`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartLine} />Segment Analytics</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/financialmodeling`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBarChart} />Financial Modeling</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/incomestatement`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />Income Statement</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/balancesheet`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faCamera} />Balance Sheet</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/cashflowwalk`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faCircleDollarToSlot} />Cash Flow Walk</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/profitabilitywalk`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartColumn} />Profitability Walk</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/search`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faSearch} />Search</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/contactus`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`${rocketObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const DemoSidebarMenu = () => {
  return (
    <>
      <SubMenu title={"Demo"} icon={<FontAwesomeIcon className="me-2" icon={faTowerObservation} />}>
        <MenuItem><Link to={`${demoObject.path}/`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faHome} />Menu</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Financials"} icon={<FontAwesomeIcon className="me-2" icon={faUser} />}>
        <MenuItem><Link to={`${demoObject.path}/ceosummary`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBuilding} />CEO Summary</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/cfosummary`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBullseye} />CFO Summary</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/channelpl`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faExclamationTriangle} />Channel P&L</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/incomestatement`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />Income Statement</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/cashflow`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faCircleDollarToSlot} />Cashflow</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/balancesheet`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faLineChart} />Balance Sheet</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/profitabilitywalk`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faChartColumn} />Profitability Walk</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Originations"} icon={<FontAwesomeIcon className="me-2" icon={faEye} />}>
        <MenuItem><Link to={`${demoObject.path}/retaillendinganalytics`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faUserCircle} />Retail Lending Analytics</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/aeheatmap`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faFile} />AE Summary</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/aescoreboard`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faClipboard} />AE Scoreboard</div></Link></MenuItem>
        <MenuItem><Link to={`${demoObject.path}/branchdemo`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrows} />Branch Demo</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Marketing"} icon={<FontAwesomeIcon className="me-2" icon={faBoltLightning} />}>
        <MenuItem><Link to={`${demoObject.path}/marketingagencyanalyticsdemo`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowRightFromBracket} />Marketing Agency Analytics Demo</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Forecasting"} icon={<FontAwesomeIcon className="me-2" icon={faChartBar} />}>
        <MenuItem><Link to={`${demoObject.path}/model`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faBarChart} />Model</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Gallus Search"} icon={<FontAwesomeIcon className="me-2" icon={faGlasses} />}>
        <MenuItem><Link to={`${demoObject.path}/Search`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faSearch} />Search</div></Link></MenuItem>
      </SubMenu>
    </>
  );
}

const APEXSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${apexObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${apexObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${apexObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const FCMSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${fcmObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${fcmObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${fcmObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const GRateSidebarMenu = () => {
  return (
    <>
      <SubMenu title={"GRate"} icon={<FontAwesomeIcon className="me-2" icon={faFile} />}>
        <MenuItem><Link to={`/${gRateObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
        <MenuItem><Link to={`/${gRateObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
        <MenuItem><Link to={`/${gRateObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Analyses"} icon={<FontAwesomeIcon className="me-2" icon={faChartArea} />}>
        <MenuItem><Link to={`/${gRateObject.path}/forecast-variance`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />Forecast Variance</div></Link></MenuItem>
        <SubMenu title={"MBA Benchmark"} icon={<FontAwesomeIcon className="me-2" icon={faTableCells} />}>
          <MenuItem><Link to={`/${gRateObject.path}/mba-benchmark-q3`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableCells} />Q3 2023</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/mba-benchmark-q4`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArchive} />Q4 2023</div></Link></MenuItem>
        </SubMenu>
        <MenuItem><Link to={`/${gRateObject.path}/product?productURL=pinboard/de4276a9-1c75-4d2e-9921-e4c7cce70740&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faMap} />Performance Summary Trends (Last 30 Days)</div></Link></MenuItem>
        <MenuItem><Link to={`/${gRateObject.path}/product?productURL=pinboard/2c8c538a-cb4a-4ff2-9635-91989faac562&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartColumn} />Pipeline Analytics</div></Link></MenuItem>
        <SubMenu title={"Public Players Benchmark"} icon={<FontAwesomeIcon className="me-2" icon={faMoneyBill} />}>
          <MenuItem><Link to={`/${gRateObject.path}/public-players-benchmark-q2-24`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />Q2 2024</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/public-players-benchmark-q1-24`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartColumn} />Q1 2024</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/public-players-benchmark-q4-23`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />Q4 2023</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/public-players-benchmark-q3-23`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartColumn} />Q3 2023</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/public-players-benchmark-fy`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartBar} />FY 2023</div></Link></MenuItem>
          <MenuItem><Link to={`/${gRateObject.path}/guild-expenses`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartSimple} />Guild Expenses</div></Link></MenuItem>
        </SubMenu>
      </SubMenu>
    </>
  );
}

const RevolutionSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${revolutionObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${revolutionObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${revolutionObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const AgilitySidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${agilityObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${agilityObject.path}/chat`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBullseye} />Chat with Agility</div></Link></MenuItem>
      <MenuItem><Link to={`/${agilityObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${agilityObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

const CambioSidebarMenu = () => {
  return (
    <>
      <SubMenu title={"Cambio"} icon={<FontAwesomeIcon className="me-2" icon={faUser} />}>
        <MenuItem><Link to={`/${chmObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Homepage</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboards&fromMenu=true`} state={{ isLiveboard: false, link: "pinboards/" }}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />Liveboards</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Search"} icon={<FontAwesomeIcon className="me-2" icon={faLocationPinLock} />}>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=answer&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faSearch} />Search</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Liveboards"} icon={<FontAwesomeIcon className="me-2" icon={faDiagramProject} />}>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/858d8b5b-3db8-4a5f-bcea-cdf06c05cca0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faUserCircle} />Retail Channel Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/67eba66c-e1f6-4b3e-990d-0c624873b1e3&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faUserClock} />Retail Channel Summary v2</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/afd42ebe-656c-4796-832f-4a68b2d5bc28&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faRoad} />Wholesale Channel Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/373e550b-85b2-4531-9f12-938e069efbdd&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTimes} />Lock Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/4d398d38-0467-4e18-8e38-8ee170fbfa8e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHomeUser} />Lock Report Month to Date</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/dfe579f9-cfb0-4371-95a0-b1ec3568939b&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faFax} />Daily Average Volume by Month</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/04949638-d30c-4528-9167-e7dc94a0040a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />Daily Lock Report</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Scoreboards"} icon={<FontAwesomeIcon className="me-2" icon={faChartArea} />}>
        <MenuItem><Link to={`/${chmObject.path}/aeperformancesummary`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faMap} />AE Performance Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/aescoreboard`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableColumns} />AE Performance Scoreboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/5b856bf6-e181-4854-81bf-d3ae60362070&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTable} />Wholesale Ranking</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/wholesalesale`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableColumns} />Wholesale Executive Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/retail`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableColumns} />Retail Executive Dashboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/branchesperformancesummary`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />Branches Performance Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/branchesscoreboard`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableCells} />Branches Performance Scoreboard</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/loperformancesummary`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDiagramProject} />LO Performance Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/loscoreboard`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableList} />LO Performance Scoreboard</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Other"} icon={<FontAwesomeIcon className="me-2" icon={faArrows} />}>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/cef8c0f9-9398-49c8-a0ea-e65edffbfb59&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPrint} />Pipeline Overview</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/916c0c86-21f8-4287-8876-09586ce68297&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faUserCircle} />Originations Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/b06a5cce-2347-4e93-bf8f-c3fbb2319aa2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faGlasses} />AE Summary</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/e7219ea4-c11e-472b-a072-aafef848ab64&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCamera} />Originations Snapshot</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/c08ef7be-fffe-4f9f-9ba2-712ce22c1e68&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faFile} />Retail Region</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/963ac30c-233f-4a6b-84ab-6fa63f76d83a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBuilding} />Retail Branch</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/803e939d-6381-45ae-90f6-6706ac66cd9a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />Retail LO</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/6cf561fd-d685-44cc-b20d-f718903e091e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faFile} />Wholesale Region</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/39bd4c30-2495-4581-9d3a-9de4138bb6a6&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBarChart} />Wholesale AE</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/b831d1cb-26f9-42c6-b2c2-6102be2f2ba8&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBalanceScale} />Wholesale Broker</div></Link></MenuItem>
        <MenuItem><Link to={`/${chmObject.path}/product?productURL=pinboard/b53cb3b0-f03d-4d08-b145-6259aae5279e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faMoneyBill1} />Fulfillment Productivity</div></Link></MenuItem>
      </SubMenu>
    </>
  );
}

const PRMGUATModeSidebarMenu = () => {
  return (
    <>
      <SubMenu title={"PRMG UAT"} icon={<FontAwesomeIcon className="me-2" icon={faUser} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Search"} icon={<FontAwesomeIcon className="me-2" icon={faLocationPinLock} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=answer&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faSearch} />Search</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Executive"} icon={<FontAwesomeIcon className="me-2" icon={faChartArea} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/937bbb74-254e-425f-8732-99011df319c4&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faSquareArrowUpRight} />AM Wholesale Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/5928af7e-3012-4193-903f-2c6bd4b26d28&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPaperclip} />Doc Drawer Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/de99d132-7b28-4fff-8bc0-46125ee21091&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableColumns} />Executive Dashboard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/5eb5b7d3-de90-4d87-a1b1-6fe7a7c24a9c&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBoltLightning} />Executive Retail Stats MTD_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/249abc71-5f81-4eea-b04f-4700c3c3288d&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCalendar} />Historical Data_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/63ba6082-94f5-4c6e-b011-106a7e4f7d33&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowLeft} />Leakage Ops Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/be535cdb-1495-47f6-a77b-86dbd9cc8819&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArchive} />Loan Processor Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/9d903f22-6bb7-44f1-9da4-62349541a2a9&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/886bd401-3a37-423a-abe7-bb29a439938d&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrows} />Processing Metrics_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/0c085907-7820-4501-809e-b4d977363046&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBuilding} />PROC - RPT_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/a4abe299-6772-428b-89fd-f430a579f4c2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnDown} />UW Metrics_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/62f3c346-bb9f-434a-8c07-7ccbd79651a3&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />UW Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/209f8535-06b2-4c1a-a673-49b466d6f47a&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartSimple} />UW Vendor Metrics_UAT</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Operations"} icon={<FontAwesomeIcon className="me-2" icon={faBuilding} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/b3338b4d-5fd4-4413-a1ff-f2c11a26bfdd&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartArea} />CD Drawer Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/50921a32-ae65-4fcd-b5a1-ba5f9faed600&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrows} />Doc Drawer Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/edc66a11-3838-43a1-94d7-ccb9871c7899&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartBar} />Funder Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/2794d17b-2b02-4fcf-bc68-ed248775e307&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartBar} />Investor Aging – FC Escalation Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/9d903f22-6bb7-44f1-9da4-62349541a2a9&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ba32098c-0d93-4fb3-9d94-3797cab10ebd&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faChartColumn} />Loan Submissions_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/aa22fbee-b662-4819-ab01-91d866c31d08&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />PCCD Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/9abb8751-feb9-45a9-8775-4dc35cb586b0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBuilding} />Post Closing Shipping Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/39908653-f075-4d20-9b23-bf18f39bc6f3&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />SetUp Registration_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/62f3c346-bb9f-434a-8c07-7ccbd79651a3&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowTurnUp} />UW Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times_UAT</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Retail"} icon={<FontAwesomeIcon className="me-2" icon={faFile} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/798647e5-435d-42f8-bc63-236ebbd304e0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEye} />AFS Dashboard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/751452d0-517e-469e-ba73-7b119f26b8bd&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBullseye} />Branch Manager Dashboard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/e729999b-947a-435b-b56a-1e37d51c7480&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faTableList} />Leader Board_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/cc37497b-e825-4b48-b884-e497ba43b956&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through - Retail_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/27991ad5-ae7d-489b-b983-4fbea2d4eeba&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPieChart} />Pipeline View - Retail_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/923c94c1-a31e-479b-8391-8357aed18299&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBoltLightning} />Regional Manager Leaderboard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/1b583257-a7a8-4ec4-8f72-f4d2065f76d5&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faClipboard} />Retail MTD Stats_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/a7c66e99-a095-4f1d-9a87-4fd0a06e4f36&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through - Retail_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times_UAT</div></Link></MenuItem>
      </SubMenu>
      <SubMenu title={"Wholesale Correspondent"} icon={<FontAwesomeIcon className="me-2" icon={faMoneyBill} />}>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/831b46d7-5af2-4f3a-845f-7479e3f526c4&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBriefcase} />AE Scorecard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faDatabase} />Branch Code Information_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/403e0803-f605-4722-be3e-7ad11e301c3d&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowUp} />Loan Pull-Through - Wholesale_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ed2d5ae7-cc6f-4da4-ae65-1c4216475729&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPieChart} />Pipeline View - Wholesale_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />TBD Pull Through_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/1b522001-ebb6-4bce-acb3-c8cd27f3888f&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faCompass} />Pull Through Report_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/d610c8ff-1638-4f1b-acd7-5309aaecafb0&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faClipboard} />TPO Scorecard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArrowDown} />UW Turn Times_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/e42d662c-b6c2-499a-a7e7-384e38945b4b&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faMap} />Wholesale - Correspondent Dashboard_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/a4498a6b-294e-4eee-bae0-853470c177ce&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faBarChart} />Wholesale - Correspondent Lock Expiration_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/9a1a01a8-3be1-4e25-9472-a5e4c2ae3e84&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faPlus} />Wholesale - Correspondent Potential Fundings_UAT</div></Link></MenuItem>
        <MenuItem><Link to={`/${prmgUATModeObject.path}/product?productURL=pinboard/53613f2c-da5a-4bab-aca0-b50c33bc67b2&fromMenu=true`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faArchive} />Wholesale - Correspondent Stats MTD_UAT</div></Link></MenuItem>
      </SubMenu>
    </>
  );
}

const KindLendingSidebarMenu = () => {
  return (
    <>
      <MenuItem><Link to={`/${kindLendingObject.path}`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faHouse} />Home</div></Link></MenuItem>
      <MenuItem><Link to={`/${kindLendingObject.path}/contactus`}><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
      <MenuItem><Link to={`/${kindLendingObject.path}/signout`}><div className="sidebar-item"><FontAwesomeIcon className="me-2" icon={faArrowAltCircleLeft} />Log Out</div></Link></MenuItem>
    </>
  );
}

export const mainRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} />,
  errorElement:
    <RouterError />,
  children: [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "callback",
      element: <Callback />
    },
    {
      path: "signup",
      element: <SignUp />
    },
    {
      path: "contactus",
      element: <ContactUs />
    },
    {
      path: "test",
      element: <SecureRoute groups={["Administrator"]} element={<Test />} />
    },
    {
      path: "login",
      element: <NewLogInPage />
    }
  ]
}

export const landingPageRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} sidebarMenu={<InSessionSidebarMenu />} navMenu={<UserProfile />} footer={<Footer displayVersion={true} />} />,
  children: [
    {
      path: "landingpage",
      element: <LandingPage />
    },
    {
      path: "signout",
      element: <SignOut />
    },
    {
      path: "mfasetup",
      element: <MFASetUp onSetupSuccess={() => { }} alreadyAcceptedMFA={true} />
    },
    {
      path: "disablemfa",
      element: <DisableMFA />
    }
  ]
}

export const hmdaRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<HMDASidebarMenu />} contactUsPath={`/${hmdaObject.path}/contactus`} signOutPath={`/${hmdaObject.path}/signout`} />,
  children: [
    {
      path: `/${hmdaObject.path}/`,
      element: <ProductSubscriptionGuard product='HMDA'><SecureRoute productName='HMDA' groups={["enduser", "Administrator"]} element={<QuickSightDashboardEmbedWrapper initialDashboardId="da384f57-767e-48b3-be4d-d398a98aef0f" authorizedResourceArns={["arn:aws:quicksight:us-east-1:482021788001:dashboard/da384f57-767e-48b3-be4d-d398a98aef0f"]} />} /></ProductSubscriptionGuard>
    },
    {
      path: `/${hmdaObject.path}/marketoverview`,
      element: <ProductSubscriptionGuard product='HMDA'><SecureRoute productName='HMDA' groups={["enduser", "Administrator"]} element={<QuickSightDashboardEmbedWrapper initialDashboardId="da384f57-767e-48b3-be4d-d398a98aef0f" authorizedResourceArns={["arn:aws:quicksight:us-east-1:482021788001:dashboard/da384f57-767e-48b3-be4d-d398a98aef0f"]} />} /></ProductSubscriptionGuard>
    },
    {
      path: `/${hmdaObject.path}/individuallender`,
      element: <ProductSubscriptionGuard product='HMDA'><SecureRoute productName='HMDA' groups={["enduser", "Administrator"]} element={<QuickSightDashboardEmbedWrapper initialDashboardId="c7b36e46-fd24-4eec-a0ca-d6a16386807c" authorizedResourceArns={["arn:aws:quicksight:us-east-1:482021788001:dashboard/c7b36e46-fd24-4eec-a0ca-d6a16386807c"]} />} /></ProductSubscriptionGuard>
    },
    {
      path: `/${hmdaObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${hmdaObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const rocketRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<RocketSidebarMenu />} contactUsPath={`/${rocketObject.path}/contactus`} signOutPath={`/${rocketObject.path}/signout`} />,
  children: [
    {
      path: `/${rocketObject.path}`,
      element: <SecureRoute productName='Rocket' element={
        <TSLandingPage smallButtons={false} parentURL={`/${rocketObject.path}`} buttons={
          [
            [
              { title: "CEO Summary", description: "", link: "ceosummary", internal: true },
              { title: "CFO Summary", description: "", link: "cfosummary", internal: true },
              { title: "Segment Analytics", description: "", link: "segmentanalytics", internal: true },
              { title: "Rocket Servicing Overview", description: "", link: "servicing", internal: true }
            ],
            [
              { title: "Income Statement", description: "", link: "incomestatement", internal: true },
              { title: "Balance Sheet", description: "", link: "balancesheet", internal: true },
              { title: "Cash Flow Walk", description: "", link: "cashflowwalk", internal: true },
              { title: "Profitability Walk", description: "", link: "profitabilitywalk", internal: true }
            ],
            [
              { title: "Search", description: "", link: "Search", internal: true },
              { title: "Financial Modeling", description: "", link: "financialmodeling", internal: true }
            ]
          ]
        } />
      } groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/liveboardmanager`,
      element: <SecureRoute productName='Rocket' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" orgId={rocketObject.orgID} />} groups={["Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/ceosummary`,
      element: <SecureRoute productName='Rocket' element={<GenericLiveboardEmbed enablePremiumFeatures={false} description='CEO Summary' guid='dc9fe8a3-cdbd-4a72-a0d7-2fb0eb1eb045' orgID={rocketObject.orgID} />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/cfosummary`,
      element: <SecureRoute productName='Rocket' element={<GenericLiveboardEmbed enablePremiumFeatures={false} description='CFO Summary' guid='afb363b8-7adc-4fb2-972b-3331c686d878' orgID={rocketObject.orgID} />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/segmentanalytics`,
      element: <SecureRoute productName='Rocket' element={<GenericLiveboardEmbed enablePremiumFeatures={false} description='Segment Analytics' guid='0c9e29f5-d22a-4480-b6e8-b3f38e01b3ce' orgID={rocketObject.orgID} />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/servicing`,
      element: <SecureRoute productName='Rocket' element={<GenericLiveboardEmbed enablePremiumFeatures={false} description='Rocket Servicing Overview' guid='15d99cc5-4f08-4c2e-b0b4-a816e93f77e4' orgID={rocketObject.orgID} />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/search`,
      element: <SecureRoute productName='Rocket' element={<GenericLiveboardEmbed enablePremiumFeatures={false} description='Search' guid='' orgID={rocketObject.orgID} search={{
        enableSearch: true, searchDataSource: [
          "d7cc62fd-8881-4ac9-83a0-b1236721b32d",
          "733e6ead-666a-4429-8f51-f04e3da69e05",
          "73556010-c9ee-4ef6-bac7-2756bac0944c",
          "15a1ee2a-f19e-430f-9db0-98b987ae0f63",
          "d724fc06-fd86-40ee-86f4-81b9f1d2ae55",
          "d2efaaa2-e91e-466a-b766-e4b17422f8c3",
          "bbcd7477-c52b-4f8c-ab18-1b10db4c7dac",
          "9f33e000-a7e9-4c87-aa50-141ce9ea25f1",
          "ae892e7a-b477-4b1d-b2a9-3d499903ea05",
          "339ad157-b865-499f-8b90-442d3bbc79d6",
        ]
      }} />} groups={["Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${rocketObject.path}/incomestatement`,
      element: <SecureRoute productName='Rocket' element={<RocketIncomeStatement />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/balancesheet`,
      element: <SecureRoute productName='Rocket' element={<RocketBalanceSheet />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/cashflowwalk`,
      element: <SecureRoute productName='Rocket' element={<CashFlowWalk />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/profitabilitywalk`,
      element: <SecureRoute productName='Rocket' element={<ProfitabilityWalk />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/financialmodeling`,
      element: <SecureRoute productName='Rocket' element={<FinancialModeling />} groups={["enduser", "Administrator"]} />
    },
    {
      path: `/${rocketObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const celeriRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<CeleriSidebarMenu />} contactUsPath={`/${celeriObject.path}/contactus`} signOutPath={`/${celeriObject.path}/signout`} />,
  children: [
    {
      path: `/${celeriObject.path}`,
      element: <SecureRoute productName='Serv demo' element={<IncomeStatement />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/incomestatement`,
      element: <SecureRoute productName='Serv demo' element={<IncomeStatement />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/balancesheet`,
      element: <SecureRoute productName='Serv demo' element={<BalanceSheet />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/financialmodel`,
      element: <SecureRoute productName='Serv demo' element={<FinancialModelingCeleri />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/debtcovenant`,
      element: <SecureRoute productName='Serv demo' element={<EbitdaFinancialModeling />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/covenantsummary`,
      element: <SecureRoute productName='Serv demo' element={<EbitdaCovenantSummary />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/regcapitalcov`,
      element: <SecureRoute productName='Serv demo' element={<EbitdaRegCapital />} groups={["celeri", "Administrator"]} />
    },
    {
      path: `/${celeriObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${celeriObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const pmgRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<PMGSidebarMenu />} contactUsPath={`/${panoramaObject.path}/contactus`} signOutPath={`/${panoramaObject.path}/signout`} />,
  children: [
    {
      path: `/${panoramaObject.path}`,
      element: <Redirect redirectTo={`/${panoramaObject.path}/product`} />
    },
    {
      path: `/${panoramaObject.path}/product`,
      element: <SecureRoute productName='Panda' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={panoramaObject.orgID} />} groups={["PMG", "Administrator"]} />
    },
    {
      path: `/${panoramaObject.path}/financialmodel`,
      element: <SecureRoute productName='Panda' element={<FinancialModelingPanda />} groups={["PMG", "Administrator"]} />
    },
    {
      path: `/${panoramaObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${panoramaObject.path}/signout`,
      element: <SignOut />
    },
  ]
}

export const reliantRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<ReliantSidebarMenu />} contactUsPath={`/${reliantObject.path}/contactus`} signOutPath={`/${reliantObject.path}/signout`} />,
  children: [
    {
      path: `/${reliantObject.path}`,
      element: <Redirect redirectTo={`/${reliantObject.path}/product`} />
    },
    {
      path: `/${reliantObject.path}/product`,
      element: <SecureRoute productName='RLT' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={reliantObject.orgID} />} groups={["Reliant", "Administrator"]} />
    },
    {
      path: `/${reliantObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${reliantObject.path}/signout`,
      element: <SignOut />
    },
  ]
}

export const chmRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<CambioSidebarMenu />} contactUsPath={`/${chmObject.path}/contactus`} signOutPath={`/${chmObject.path}/signout`} />,
  children: [
    {
      path: `/${chmObject.path}`,
      element: <SecureRoute productName='Cambio' element={
        <TSLandingPage smallButtons={false} parentURL={`/${chmObject.path}/product`} buttons={
          [
            [{ title: "Search", description: "", link: "answer" }, { title: "Liveboards", description: "", link: "pinboards/" }, { title: "Other Liveboards", description: "", link: `/${chmObject.path}/other`, internal: true, overrideParentURL: true }],
            [{ title: "Retail Channel Summary", description: "", link: "pinboard/858d8b5b-3db8-4a5f-bcea-cdf06c05cca0" }, { title: "Retail Channel Summary v2", description: "", link: "pinboard/67eba66c-e1f6-4b3e-990d-0c624873b1e3" }, { title: "Branch Performance Summary", description: "", link: `/${chmObject.path}/branchesperformancesummary`, internal: true, overrideParentURL: true }, { title: "Branch Performance Scoreboard", description: "", link: `/${chmObject.path}/branchesscoreboard`, internal: true, overrideParentURL: true }, { title: "LO Performance Summary", description: "", link: `/${chmObject.path}/loperformancesummary`, internal: true, overrideParentURL: true }, { title: "LO Performance Scoreboard", description: "", link: `/${chmObject.path}/loscoreboard`, internal: true, overrideParentURL: true }],
            [{ title: "Wholesale Ranking", description: "", link: "pinboard/5b856bf6-e181-4854-81bf-d3ae60362070" }, { title: "Wholesale Channel Summary", description: "", link: "pinboard/afd42ebe-656c-4796-832f-4a68b2d5bc28" }, { title: "AE Performance Summary", description: "", link: `/${chmObject.path}/aeperformancesummary`, internal: true, overrideParentURL: true }, { title: "AE Performance Scoreboard", description: "", link: `/${chmObject.path}/aescoreboard`, internal: true, overrideParentURL: true }, { title: "Wholesale Executive Dashboard", description: "", link: `/${chmObject.path}/wholesalesale`, internal: true, overrideParentURL: true }, { title: "Retail Executive Dashboard", description: "", link: `/${chmObject.path}/retail`, internal: true, overrideParentURL: true }],
            [{ title: "Lock Summary", description: "", link: "pinboard/373e550b-85b2-4531-9f12-938e069efbdd" }, { title: "Lock Report Month to Date", description: "", link: "pinboard/4d398d38-0467-4e18-8e38-8ee170fbfa8e" }, { title: "Daily Average Volume by Month", description: "", link: "pinboard/dfe579f9-cfb0-4371-95a0-b1ec3568939b" }, { title: "Daily Lock Report", description: "", link: "pinboard/04949638-d30c-4528-9167-e7dc94a0040a" }],
          ]
        } />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/other`,
      element: <SecureRoute productName='Cambio' element={
        <TSLandingPage smallButtons={false} parentURL={`/${chmObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${chmObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "Retail Region", description: "", link: "pinboard/c08ef7be-fffe-4f9f-9ba2-712ce22c1e68" }, { title: "Retail Branch", description: "", link: "pinboard/963ac30c-233f-4a6b-84ab-6fa63f76d83a" }, { title: "Retail LO", description: "", link: "pinboard/803e939d-6381-45ae-90f6-6706ac66cd9a" }],
            [{ title: "Originations Snapshot", description: "", link: "pinboard/e7219ea4-c11e-472b-a072-aafef848ab64" }, { title: "Fulfillment Productivity", description: "", link: "pinboard/b53cb3b0-f03d-4d08-b145-6259aae5279e" }],
            [{ title: "Wholesale Region", description: "", link: "pinboard/6cf561fd-d685-44cc-b20d-f718903e091e" }, { title: "Wholesale AE", description: "", link: "pinboard/39bd4c30-2495-4581-9d3a-9de4138bb6a6" }, { title: "Wholesale Broker", description: "", link: "pinboard/b831d1cb-26f9-42c6-b2c2-6102be2f2ba8" }],
            [{ title: "Pipeline Overview", description: "", link: "pinboard/cef8c0f9-9398-49c8-a0ea-e65edffbfb59" }, { title: "Originations Summary", description: "", link: "pinboard/916c0c86-21f8-4287-8876-09586ce68297" }]
          ]
        } />} groups={["CHM", "Administrator"]} />
    },
    /*     {
          path: `/${chmObject.subdomain}/admintools`,
          element: <SecureRoute productName='Cambio' element={<TSLandingPage smallButtons={false} parentURL={`/${chmObject.subdomain}/admintools`} buttons={
            [
              [{ title: "Go Back", description: "", link: `/${chmObject.subdomain}`, internal: true, overrideParentURL: true }],
              [
                { title: "Group Tool", description: "Manage user groups.", link: "permission", internal: true }
              ],
              [
                { title: "Board Sharing Tool", description: "Check the current permission status of any board, and share boards with groups.", link: "share", internal: true }
              ],
              [
                { title: "User Permission Tool", description: "Explore each user's individual group and liveboard access.", link: "checkuser", internal: true }
              ]
            ]
          } />} groups={["CHM", "Administrator"]} />
        }, */
    {
      path: `/${chmObject.path}/product`,
      element: <SecureRoute productName='Cambio' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" orgId={chmObject.orgID} />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}product`,
      element: <Redirect redirectTo={`/${chmObject.path}/product`} />
    },
    {
      path: `/${chmObject.path}/aeperformancesummary`,
      element: <SecureRoute productName='Cambio' element={<HeatmapPerformanceMetrics scope='Account Executive' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/aescoreboard`,
      element: <SecureRoute productName='Cambio' element={<TablePerformanceMetrics scope='Account Executive' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/branchesperformancesummary`,
      element: <SecureRoute productName='Cambio' element={<HeatmapPerformanceMetrics scope='Branches' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/branchesscoreboard`,
      element: <SecureRoute productName='Cambio' element={<TablePerformanceMetrics scope='Branches' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/loperformancesummary`,
      element: <SecureRoute productName='Cambio' element={<HeatmapPerformanceMetrics scope='Loan Officers' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/loscoreboard`,
      element: <SecureRoute productName='Cambio' element={<TablePerformanceMetrics scope='Loan Officers' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/wholesalesale`,
      element: <SecureRoute productName='Cambio' element={<FullScreenPerformanceMetrics scope='Wholesale' overrideTitle='Wholesale Executive Dashboard' />} groups={["CHM", "Administrator"]} />
    },
    {
      path: `/${chmObject.path}/retail`,
      element: <SecureRoute productName='Cambio' element={<FullScreenPerformanceMetrics scope='Retail' overrideTitle='Retail Executive Dashboard' />} groups={["CHM", "Administrator"]} />
    },
    /*     {
          path: `/${chmObject.subdomain}/admintools/checkuser`,
          element: <SecureRoute productName='Cambio' element={<UserCheckTool orgId={testTSOrg} userFilter='' />} groups={["Administrator"]} />
        },
        {
          path: `/${chmObject.subdomain}/admintools/permission`,
          element: <SecureRoute productName='Cambio' element={<LiveboardPermissionTool orgId={testTSOrg} userFilter='' />} groups={["Administrator"]} />
        },
        {
          path: `/${chmObject.subdomain}/admintools/share`,
          element: <SecureRoute productName='Cambio' element={<ShareLiveboards orgId={testTSOrg} />} groups={["Administrator"]} />
        }, */
    {
      path: `/${chmObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${chmObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const demoRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<DemoSidebarMenu />} contactUsPath={`/${demoObject.path}/contactus`} signOutPath={`/${demoObject.path}/signout`} />,
  children: [
    {
      path: `/${demoObject.path}`,
      element: <SecureRoute productName='Demo' element={
        <TSLandingPage parentURL={`/${demoObject.path}`} smallButtons={true} buttons={
          [
            [
              { title: "Financials", titleSettings: { isTitle: true, isBigTitle: true } },
              { title: "CEO Summary", backgroundImageName: "/CFO.png", description: "", link: "ceosummary", internal: true },
              { title: "CFO Summary", backgroundImageName: "/Money.png", description: "", link: "cfosummary", internal: true },
              { title: "Channel P&L", backgroundImageName: "/PL.png", description: "", link: "channelpl", internal: true },
              { title: "Income Statement", backgroundImageName: "/Income.png", description: "", link: "incomestatement", internal: true },
              { title: "Cash Flow", backgroundImageName: "/CashFlow.png", description: "", link: "cashflow", internal: true },
              { title: "Balance Sheet", backgroundImageName: "/Balance.png", description: "", link: "balancesheet", internal: true },
              { title: "Profitability Walk", backgroundImageName: "/ProfitabilityWalk.png", description: "", link: "profitabilitywalk", internal: true }
            ],
            [
              { title: "Originations", titleSettings: { isTitle: true, isBigTitle: true } },
              { title: "Retail Lending Analytics", backgroundImageName: "", description: "", link: "retaillendinganalytics", internal: true },
              { title: "AE Summary", backgroundImageName: "", description: "", link: "aeheatmap", internal: true },
              { title: "AE Scoreboard", backgroundImageName: "", description: "", link: "aescoreboard", internal: true },
              { title: "Branch Demo", backgroundImageName: "", description: "", link: "branchdemo", internal: true },
            ],
            [
              { title: "Forecasting", titleSettings: { isTitle: true, isBigTitle: true } },
              { title: "Model", backgroundImageName: "/Chart1.png", description: "", link: "model", internal: true }
            ],
            [
              { title: "Gallus Search", titleSettings: { isTitle: true, isBigTitle: true } },
              { title: "Search", backgroundImageName: "/MagnifyingGlass.png", description: "", link: "search", internal: true }
            ]
          ]
        } />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/ceosummary`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='CEO Summary' guid='0f6b5102-d7ba-439d-ade2-f103153c358a' orgID={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/cfosummary`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='CFO Summary' guid='1797d4e7-4059-4c65-9755-b7fd65427a7f' orgID={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/channelpl`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='Channel P&L' guid='f49a8a3b-d623-4920-914c-743226903850' orgID={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/incomestatement`,
      element: <SecureRoute productName='Demo' element={<RocketIncomeStatement />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/cashflow`,
      element: <SecureRoute productName='Demo' element={<CashFlowWalk />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/balancesheet`,
      element: <SecureRoute productName='Demo' element={<RocketBalanceSheet />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/profitabilitywalk`,
      element: <SecureRoute productName='Demo' element={<ProfitabilityWalk />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/model`,
      element: <SecureRoute productName='Demo' element={<FinancialModeling />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/retaillendinganalytics`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='Retail Lending Analytics' guid='31f70534-e614-4832-8888-5b99f92c4e16' orgID={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/aeheatmap`,
      element: <SecureRoute productName='Demo' element={<HeatmapPerformanceMetrics scope='Account Executive' useDemoData={true} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/aescoreboard`,
      element: <SecureRoute productName='Demo' element={<TablePerformanceMetrics scope='Account Executive' useDemoData={true} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/marketingagencyanalyticsdemo`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='Marketing Agency Analytics Demo' guid='645ac39f-52fa-41e0-854f-0802c213974c' orgID={marketingObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/branchdemo`,
      element: <SecureRoute productName='Demo' element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='Branch Demo' guid='3de8ef23-3478-4308-83e9-ad1059267cb9' orgID={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/search`,
      element: <SecureRoute productName='Demo' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} enableFullFeatures={true} showPrimaryNavbar={true} defaultPage={Page.Search} disableProfileAndHelp={true} className="ts-full-app h-100" orgId={demoObject.orgID} />} groups={["Administrator", "Demo"]} />
    },
    {
      path: `/${demoObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${demoObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const apexRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<APEXSidebarMenu />} contactUsPath={`/${apexObject.path}/contactus`} signOutPath={`/${apexObject.path}/signout`} />,
  children: [
    {
      path: `/${apexObject.path}`,
      element: <Redirect redirectTo={`/${apexObject.path}/product`} />
    },
    {
      path: `/${apexObject.path}/product`,
      element: <SecureRoute productName='APX' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={apexObject.orgID} />} groups={["APEX", "Administrator"]} />
    },
    {
      path: `/${apexObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${apexObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const grateRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<GRateSidebarMenu />} contactUsPath={`/${gRateObject.path}/contactus`} signOutPath={`/${gRateObject.path}/signout`} />,
  children: [
    {
      path: `/${gRateObject.path}`,
      element: <Redirect redirectTo={`/${gRateObject.path}/product`} />
    },
    {
      path: `/${gRateObject.path}/product`,
      element: <SecureRoute productName='GR' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} enableFullFeatures={true} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={gRateObject.orgID} />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/forecast-variance`,
      element: <SecureRoute productName="GR" element={<ForecastVariance />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/mba-benchmark-q3`,
      element: <SecureRoute productName="GR" element={<MBABenchmark period='Q3' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/mba-benchmark-q4`,
      element: <SecureRoute productName="GR" element={<MBABenchmark period='Q4' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/public-players-benchmark-q3-23`,
      element: <SecureRoute productName="GR" element={<PublicPlayersBenchmark period='Q3 2023' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/public-players-benchmark-q4-23`,
      element: <SecureRoute productName="GR" element={<PublicPlayersBenchmark period='Q4 2023' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/public-players-benchmark-q1-24`,
      element: <SecureRoute productName="GR" element={<PublicPlayersBenchmark period='Q1 2024' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/public-players-benchmark-q2-24`,
      element: <SecureRoute productName="GR" element={<PublicPlayersBenchmark period='Q2 2024' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/public-players-benchmark-fy`,
      element: <SecureRoute productName="GR" element={<PublicPlayersBenchmark period='FY' />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/guild-expenses`,
      element: <SecureRoute productName="GR" element={<WaterfallPlots />} groups={["GRate", "Administrator"]} />
    },
    {
      path: `/${gRateObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${gRateObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const revolutionRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<RevolutionSidebarMenu />} contactUsPath={`/${revolutionObject.path}/contactus`} signOutPath={`/${revolutionObject.path}/signout`} />,
  children: [
    {
      path: `/${revolutionObject.path}`,
      element: <Redirect redirectTo={`/${revolutionObject.path}/product`} />
    },
    {
      path: `/${revolutionObject.path}/product`,
      element: <SecureRoute productName='Rev' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} enableFullFeatures={true} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={revolutionObject.orgID} />} groups={["Revolution", "Administrator"]} />
    },
    {
      path: `/${revolutionObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${revolutionObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const celinkRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<CelinkSidebarMenu />} contactUsPath={`/${celinkObject.path}/contactus`} signOutPath={`/${celinkObject.path}/signout`} />,
  children: [
    {
      path: `/${celinkObject.path}`,
      element: <Redirect redirectTo={`/${celinkObject.path}/product`} />
    },
    {
      path: `/${celinkObject.path}/product`,
      element: <SecureRoute productName='CK' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={celinkObject.orgID} />} groups={["Celink", "Administrator"]} />
    },
    {
      path: `/${celinkObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${celinkObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const agilityRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<AgilitySidebarMenu />} contactUsPath={`/${agilityObject.path}/contactus`} signOutPath={`/${agilityObject.path}/signout`} />,
  children: [
    {
      path: `/${agilityObject.path}`,
      element: <Redirect redirectTo={`/${agilityObject.path}/product`} />
    },
    {
      path: `/${agilityObject.path}/product`,
      element: <SecureRoute productName='Agility' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} enableFullFeatures={true} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={agilityObject.orgID} />} groups={["Agility", "GallusAgility", "Administrator"]} />
    },
    {
      path: `/${agilityObject.path}/chat`,
      element: <SecureRoute productName='Agility' groups={["Administrator, Agility, GallusAgility"]} element={<GallusAgilityRedirect />} />
    },
    {
      path: `/${agilityObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${agilityObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const fcmRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<FCMSidebarMenu />} contactUsPath={`/${fcmObject.path}/contactus`} signOutPath={`/${fcmObject.path}/signout`} />,
  children: [
    {
      path: `/${fcmObject.path}`,
      element: <Redirect redirectTo={`/${fcmObject.path}/product`} />
    },
    {
      path: `/${fcmObject.path}/product`,
      element: <SecureRoute productName='FM' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={fcmObject.orgID} />} groups={["FCM", "Administrator"]} />
    },
    {
      path: `/${fcmObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${fcmObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const radiusRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<RadiusSidebarMenu />} contactUsPath={`/${radiusObject.path}/contactus`} signOutPath={`/${radiusObject.path}/signout`} />,
  children: [
    {
      path: `/${radiusObject.path}`,
      element: <Redirect redirectTo={`/${radiusObject.path}/product`} />
    },
    {
      path: `/${radiusObject.path}/product`,
      element: <SecureRoute productName='RD' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} disableEditButtonArray={radiusNonEditableLiveboards} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={radiusObject.orgID} />} groups={["Radius", "Administrator"]} />
    },
    {
      path: `/${radiusObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${radiusObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const flexpointRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<FlexpointSidebarMenu />} contactUsPath={`/${flexpointObject.path}/contactus`} signOutPath={`/${flexpointObject.path}/signout`} />,
  children: [
    {
      path: `/${flexpointObject.path}`,
      element: <Redirect redirectTo={`/${flexpointObject.path}/product`} />
    },
    {
      path: `/${flexpointObject.path}/product`,
      element: <SecureRoute productName='Flx' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={flexpointObject.orgID} />} groups={["Flexpoint", "Administrator"]} />
    },
    {
      path: `/${flexpointObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${flexpointObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const kindLendingRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<KindLendingSidebarMenu />} contactUsPath={`/${kindLendingObject.path}/contactus`} signOutPath={`/${kindLendingObject.path}/signout`} />,
  children: [
    {
      path: `/${kindLendingObject.path}`,
      element: <Redirect redirectTo={`/${kindLendingObject.path}/product`} />
    },
    {
      path: `/${kindLendingObject.path}/product`,
      element: <SecureRoute productName='KL' element={<AppEmbedWrapper cssRules={appEmbedWrapperCssRules} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={kindLendingObject.orgID} />} groups={["KindLending", "Administrator"]} />
    },
    {
      path: `/${kindLendingObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${kindLendingObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const prmgUATModeRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<PRMGUATModeSidebarMenu />} contactUsPath={`/${prmgUATModeObject.path}/contactus`} signOutPath={`/${prmgUATModeObject.path}/signout`} />,
  children: [
    {
      path: `/${prmgUATModeObject.path}`,
      element: <SecureRoute productName='PRMG UAT' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgUATModeObject.path}/product`} buttons={
          [
            [{ title: "Search", description: "", link: "answer" }, { title: "Liveboards", description: "", link: "pinboards/" }],
            [{ title: "Executive", description: "", link: `/${prmgUATModeObject.path}/executivedashboards`, internal: true, overrideParentURL: true }],
            [{ title: "Operations", description: "", link: `/${prmgUATModeObject.path}/operations`, internal: true, overrideParentURL: true }],
            [{ title: "Retail", description: "", link: `/${prmgUATModeObject.path}/retail`, internal: true, overrideParentURL: true }],
            [{ title: "Wholesale - Correspondent", description: "", link: `/${prmgUATModeObject.path}/wholesalecorrespondent`, internal: true, overrideParentURL: true }],
          ]
        } />} groups={["PRMGUAT", "Administrator"]} />
    },
    {
      path: `/${prmgUATModeObject.path}/executivedashboards`,
      element: <SecureRoute productName='PRMG UAT' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgUATModeObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgUATModeObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AM Wholesale Report_UAT", description: "", link: "pinboard/937bbb74-254e-425f-8732-99011df319c4" }, { title: "Executive Dashboard_UAT", description: "", link: "pinboard/de99d132-7b28-4fff-8bc0-46125ee21091" }, { title: "Leakage Ops Report_UAT", description: "", link: "pinboard/63ba6082-94f5-4c6e-b011-106a7e4f7d33" }, { title: "Processing Metrics_UAT", description: "", link: "pinboard/886bd401-3a37-423a-abe7-bb29a439938d" }, { title: "UW Metrics_UAT", description: "", link: "pinboard/a4abe299-6772-428b-89fd-f430a579f4c2" }, { title: "UW Vendor Metrics_UAT", description: "", link: "pinboard/209f8535-06b2-4c1a-a673-49b466d6f47a" }],
            [{ title: "Branch Code Information_UAT", description: "", link: "pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e" }, { title: "Executive Retail Stats MTD_UAT", description: "", link: "pinboard/5eb5b7d3-de90-4d87-a1b1-6fe7a7c24a9c" }, { title: "Loan Processor Report_UAT", description: "", link: "pinboard/be535cdb-1495-47f6-a77b-86dbd9cc8819" }, { title: "PROC - RPT_UAT", description: "", link: "pinboard/0c085907-7820-4501-809e-b4d977363046" }, { title: "UW Stats_UAT", description: "", link: "pinboard/62f3c346-bb9f-434a-8c07-7ccbd79651a3" }],
            [{ title: "Doc Drawer Report_UAT", description: "", link: "pinboard/5928af7e-3012-4193-903f-2c6bd4b26d28" }, { title: "Historical Data_UAT", description: "", link: "pinboard/249abc71-5f81-4eea-b04f-4700c3c3288d" }, { title: "Loan Pull-Through_UAT", description: "", link: "pinboard/9d903f22-6bb7-44f1-9da4-62349541a2a9" }, { title: "TBD Pull Through_UAT", description: "", link: "pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541" }, { title: "UW Turn Times_UAT", description: "", link: "pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81" }]
          ]
        } />} groups={["PRMGUAT", "Administrator"]} />
    },
    {
      path: `/${prmgUATModeObject.path}/operations`,
      element: <SecureRoute productName='PRMG UAT' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgUATModeObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgUATModeObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "Branch Code Information_UAT", description: "", link: "pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e" }, { title: "Funder Stats_UAT", description: "", link: "pinboard/edc66a11-3838-43a1-94d7-ccb9871c7899" }, { title: "Loan Submissions_UAT", description: "", link: "pinboard/ba32098c-0d93-4fb3-9d94-3797cab10ebd" }, { title: "SetUp Registration_UAT", description: "", link: "pinboard/39908653-f075-4d20-9b23-bf18f39bc6f3" }, { title: "UW Turn Times_UAT", description: "", link: "pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81" }],
            [{ title: "CD Drawer Stats_UAT", description: "", link: "pinboard/b3338b4d-5fd4-4413-a1ff-f2c11a26bfdd" }, { title: "Investor Aging – FC Escalation Report_UAT", description: "", link: "pinboard/2794d17b-2b02-4fcf-bc68-ed248775e307" }, { title: "PCCD Report_UAT", description: "", link: "pinboard/aa22fbee-b662-4819-ab01-91d866c31d08" }, { title: "TBD Pull Through_UAT", description: "", link: "pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541" }],
            [{ title: "Doc Drawer Stats_UAT", description: "", link: "pinboard/50921a32-ae65-4fcd-b5a1-ba5f9faed600" }, { title: "Loan Pull-Through_UAT", description: "", link: "pinboard/9d903f22-6bb7-44f1-9da4-62349541a2a9" }, { title: "Post Closing Shipping Report_UAT", description: "", link: "pinboard/9abb8751-feb9-45a9-8775-4dc35cb586b0" }, { title: "UW Stats_UAT", description: "", link: "pinboard/62f3c346-bb9f-434a-8c07-7ccbd79651a3" }]
          ]
        } />} groups={["PRMGUAT", "Administrator"]} />

    },
    {
      path: `/${prmgUATModeObject.path}/retail`,
      element: <SecureRoute productName='PRMG UAT' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgUATModeObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgUATModeObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AFS Dashboard_UAT", description: "", link: "pinboard/798647e5-435d-42f8-bc63-236ebbd304e0" }, { title: "Leader Board_UAT", description: "", link: "pinboard/e729999b-947a-435b-b56a-1e37d51c7480" }, { title: "Regional Manager Leaderboard_UAT", description: "", link: "pinboard/923c94c1-a31e-479b-8391-8357aed18299" }, { title: "UW Turn Times_UAT", description: "", link: "pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81" }],
            [{ title: "Branch Code Information_UAT", description: "", link: "pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e" }, { title: "Loan Pull-Through - Retail_UAT", description: "", link: "pinboard/cc37497b-e825-4b48-b884-e497ba43b956" }, { title: "Retail MTD Stats_UAT", description: "", link: "pinboard/1b583257-a7a8-4ec4-8f72-f4d2065f76d5" }],
            [{ title: "Branch Manager Dashboard_UAT", description: "", link: "pinboard/751452d0-517e-469e-ba73-7b119f26b8bd" }, { title: "Pipeline View - Retail_UAT", description: "", link: "pinboard/27991ad5-ae7d-489b-b983-4fbea2d4eeba" }, { title: "TBD Pull Through - Retail_UAT", description: "", link: "pinboard/a7c66e99-a095-4f1d-9a87-4fd0a06e4f36" }]
          ]
        } />} groups={["PRMGUAT", "Administrator"]} />
    },
    {
      path: `/${prmgUATModeObject.path}/wholesalecorrespondent`,
      element: <SecureRoute productName='PRMG UAT' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgUATModeObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgUATModeObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AE Scorecard_UAT", description: "", link: "pinboard/831b46d7-5af2-4f3a-845f-7479e3f526c4" }, { title: "Pipeline View - Wholesale_UAT", description: "", link: "pinboard/ed2d5ae7-cc6f-4da4-ae65-1c4216475729" }, { title: "TPO Scorecard_UAT", description: "", link: "pinboard/d610c8ff-1638-4f1b-acd7-5309aaecafb0" }, { title: "Wholesale - Correspondent Lock Expiration_UAT", description: "", link: "pinboard/a4498a6b-294e-4eee-bae0-853470c177ce" }],
            [{ title: "Branch Code Information_UAT", description: "", link: "pinboard/3eb13a02-b476-4806-86bd-ddcebbd2055e" }, { title: "TBD Pull Through_UAT", description: "", link: "pinboard/bbb06cd7-b46e-4ea5-9f43-de3241484541" }, { title: "UW Turn Times_UAT", description: "", link: "pinboard/ba36e770-dcb6-498e-a2ca-36afae1a9c81" }, { title: "Wholesale - Correspondent Potential Fundings_UAT", description: "", link: "pinboard/9a1a01a8-3be1-4e25-9472-a5e4c2ae3e84" }],
            [{ title: "Loan Pull-Through - Wholesale_UAT", description: "", link: "pinboard/403e0803-f605-4722-be3e-7ad11e301c3d" }, { title: "Pull Through Report_UAT", description: "", link: "pinboard/1b522001-ebb6-4bce-acb3-c8cd27f3888f" }, { title: "Wholesale - Correspondent Dashboard_UAT", description: "", link: "pinboard/e42d662c-b6c2-499a-a7e7-384e38945b4b" }, { title: "Wholesale - Correspondent Stats MTD_UAT", description: "", link: "pinboard/53613f2c-da5a-4bab-aca0-b50c33bc67b2" }]
          ]
        } />} groups={["PRMGUAT", "Administrator"]} />
    },
    {
      path: `/${prmgUATModeObject.path}/product`,
      element: <SecureRoute productName='PRMG UAT' element={<AppEmbedWrapper disabledActions={[Action.DownloadAsCsv]} disableEditButtonArray={prmgUATModeNonEditableLiveboards} cssRules={appEmbedWrapperCssRulesNoSaveView} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={prmgUATModeObject.orgID} />} groups={["PRMGUAT", "Administrator"]} />
    },
    {
      path: `/${prmgUATModeObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${prmgUATModeObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const PRMGProdRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<PRMGProdSidebarMenu />} contactUsPath={`/${prmgProdObject.path}/contactus`} signOutPath={`/${prmgProdObject.path}/signout`} />,
  children: [
    {
      path: `/${prmgProdObject.path}`,
      element: <SecureRoute productName='PRMG' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgProdObject.path}/product`} buttons={
          [
            [{ title: "Search", description: "", link: "answer" }, { title: "Liveboards", description: "", link: "pinboards/" }],
            [{ title: "Executive", description: "", link: `/${prmgProdObject.path}/executivedashboards`, internal: true, overrideParentURL: true }],
            [{ title: "Operations", description: "", link: `/${prmgProdObject.path}/operations`, internal: true, overrideParentURL: true }],
            [{ title: "Retail", description: "", link: `/${prmgProdObject.path}/retail`, internal: true, overrideParentURL: true }],
            [{ title: "Wholesale - Correspondent", description: "", link: `/${prmgProdObject.path}/wholesalecorrespondent`, internal: true, overrideParentURL: true }],
          ]
        } />} groups={["PRMG", "Administrator"]} />
    },
    {
      path: `/${prmgProdObject.path}/executivedashboards`,
      element: <SecureRoute productName='PRMG' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgProdObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgProdObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AM Wholesale Report", description: "", link: "pinboard/1711b5fa-3822-4946-b87a-ea7574e9f3b0" }, { title: "Executive Dashboard", description: "", link: "pinboard/63eceeb6-2df7-4fe9-9f9b-afd08555cd4b" }, { title: "Leakage Ops Report", description: "", link: "pinboard/0596fe93-fb24-432b-b0a2-ff9a7781f4ed" }, { title: "Processing Metrics", description: "", link: "pinboard/35c591c5-141b-4246-bce5-e50a5a0649d1" }, { title: "UW Metrics", description: "", link: "pinboard/a5419de1-52c8-45cb-be77-f2585e11f9ea" }, { title: "UW Vendor Metrics", description: "", link: "pinboard/57495ad4-dd22-4413-afe3-2c31345d36fc" }],
            [{ title: "Branch Code Information", description: "", link: "pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2" }, { title: "Executive Retail Stats MTD", description: "", link: "pinboard/9643af96-d335-4672-ac90-416adb37b48e" }, { title: "Loan Processor Report", description: "", link: "pinboard/aac2907a-6f4e-4664-83b4-5b8008e6b52f" }, { title: "PROC - RPT", description: "", link: "pinboard/4a9c1ecc-4d68-4c54-b5fd-d63abe54f6f7" }, { title: "UW Stats", description: "", link: "pinboard/288b3e13-97bb-4fa5-ae50-a7b8067a9370" }],
            [{ title: "Doc Drawer Report", description: "", link: "pinboard/73a97608-88e1-4b77-b39c-740de369189a" }, { title: "Historical Data", description: "", link: "pinboard/1e0d1f64-d733-4f72-8abb-44a1e4d803d0" }, { title: "Loan Pull-Through", description: "", link: "pinboard/c6051f3c-561b-49e9-824e-8554e73f692a" }, { title: "TBD Pull Through", description: "", link: "pinboard/9be5e6c8-5892-4a09-85fe-bcde0cba3052" }, { title: "UW Turn Times", description: "", link: "pinboard/ec947278-0200-4247-8fde-7007bf8a2258" }]
          ]
        } />} groups={["PRMG", "Administrator"]} />
    },
    {
      path: `/${prmgProdObject.path}/operations`,
      element: <SecureRoute productName='PRMG' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgProdObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgProdObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "Branch Code Information", description: "", link: "pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2" }, { title: "Funder Stats", description: "", link: "pinboard/a596a179-f7ad-4a1d-b47b-f7f460701909" }, { title: "Loan Submissions", description: "", link: "pinboard/15dde2bc-cbc7-4191-bb20-c11398ea0d1b" }, { title: "SetUp Registration", description: "", link: "pinboard/2613020a-9175-45d9-b929-2b2904295769" }, { title: "UW Turn Times", description: "", link: "pinboard/ec947278-0200-4247-8fde-7007bf8a2258" }],
            [{ title: "CD Drawer Stats", description: "", link: "pinboard/9ae98bb8-68bb-40b7-8e93-953cb25e6a71" }, { title: "Investor Aging – FC Escalation Report", description: "", link: "pinboard/fb837204-c6f1-4522-9e4d-9a79730d6fc0" }, { title: "PCCD Report", description: "", link: "pinboard/adf45573-f614-4399-8edb-47798001a436" }, { title: "TBD Pull Through", description: "", link: "pinboard/9be5e6c8-5892-4a09-85fe-bcde0cba3052" }],
            [{ title: "Doc Drawer Stats", description: "", link: "pinboard/50323ebc-10de-415a-b04e-6c5557f98ef1" }, { title: "Loan Pull-Through", description: "", link: "pinboard/c6051f3c-561b-49e9-824e-8554e73f692a" }, { title: "Post Closing Shipping Report", description: "", link: "pinboard/4f5f1ea6-2e2b-4e54-8af6-b22051a784e5" }, { title: "UW Stats", description: "", link: "pinboard/288b3e13-97bb-4fa5-ae50-a7b8067a9370" }]
          ]
        } />} groups={["PRMG", "Administrator"]} />

    },
    {
      path: `/${prmgProdObject.path}/retail`,
      element: <SecureRoute productName='PRMG' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgProdObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgProdObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AFS Dashboard", description: "", link: "pinboard/cf72e436-5e2a-49fc-b1ae-ea4c4d7cf3f4" }, { title: "Leader Board", description: "", link: "pinboard/52dbf268-8701-44cd-ba42-7d64e6070553" }, { title: "Regional Manager Leaderboard", description: "", link: "pinboard/02e25a30-a061-46cb-bf20-3929042daf1f" }, { title: "UW Turn Times", description: "", link: "pinboard/ec947278-0200-4247-8fde-7007bf8a2258" }],
            [{ title: "Branch Code Information", description: "", link: "pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2" }, { title: "Loan Pull-Through - Retail", description: "", link: "pinboard/e549b774-3e72-42f7-ad46-f74b111711fb" }, { title: "Retail MTD Stats", description: "", link: "pinboard/820b9fcb-9158-4b05-af76-46ead6be96d8" }],
            [{ title: "Branch Manager Dashboard", description: "", link: "pinboard/233c0070-e34a-49af-bacf-0249dbf431bc" }, { title: "Pipeline View - Retail", description: "", link: "pinboard/d2ad0e67-337f-4b0a-83a8-37b5c8ecd6b7" }, { title: "TBD Pull Through - Retail", description: "", link: "pinboard/5c554a1a-e5a0-4c5e-894e-bedffbfa93a2" }]]
        } />} groups={["PRMG", "Administrator"]} />
    },
    {
      path: `/${prmgProdObject.path}/wholesalecorrespondent`,
      element: <SecureRoute productName='PRMG' element={
        <TSLandingPage smallButtons={false} parentURL={`/${prmgProdObject.path}/product`} buttons={
          [
            [{ title: "Go Back", description: "", link: `/${prmgProdObject.path}`, internal: true, overrideParentURL: true }],
            [{ title: "AE Scorecard", description: "", link: "pinboard/45f4377c-457f-4d6b-b7b0-eeb06cea6ef8" }, { title: "Pipeline View - Wholesale", description: "", link: "pinboard/55a0a1a5-4ff2-43fe-913a-ce3fc7592723" }, { title: "UW Turn Times", description: "", link: "pinboard/ec947278-0200-4247-8fde-7007bf8a2258" }, { title: "Wholesale - Correspondent Potential Fundings", description: "", link: "pinboard/f7706944-5a60-4ed4-8cc8-07a98fc6f84f" }],
            [{ title: "Branch Code Information", description: "", link: "pinboard/6ee104e1-99d7-4441-9e6d-f6de079a18c2" }, { title: "Pull Through Report", description: "", link: "pinboard/3895514e-412c-438d-9c16-975057d446ed" }, { title: "Wholesale - Correspondent Dashboard", description: "", link: "pinboard/286d544a-8456-4fcb-bd2c-8f1fddf6ea34" }, { title: "Wholesale - Correspondent Stats MTD", description: "", link: "pinboard/c38dc596-2c02-47d6-85c1-19028054ceb5" }],
            [{ title: "Loan Pull-Through - Wholesale", description: "", link: "pinboard/1fdb974f-5380-4f7a-8787-26641b0c5e17" }, { title: "TPO Scorecard", description: "", link: "pinboard/e2d017a4-7978-43f6-8b56-5b4e8a6e86fe" }, { title: "Wholesale - Correspondent Lock Expiration", description: "", link: "pinboard/9519e3c5-3a67-4a77-9be0-a3c171855b51" }]
          ]
        } />} groups={["PRMG", "Administrator"]} />
    },
    {
      path: `/${prmgProdObject.path}/product`,
      element: <SecureRoute productName='PRMG' element={<AppEmbedWrapper disableEditButtonArray={prmgProdNonEditableLiveboards} cssRules={appEmbedWrapperCssRulesNoSaveView} breakPoint={DefaultSidebarBreakpoint} showPrimaryNavbar={true} disableProfileAndHelp={true} className="ts-full-app h-100" pageId={Page.Liveboards} orgId={prmgProdObject.orgID} />} groups={["PRMG", "Administrator"]} />
    },
    {
      path: `/${prmgProdObject.path}/contactus`,
      element: <ContactUs />
    },
    {
      path: `/${prmgProdObject.path}/signout`,
      element: <SignOut />
    }
  ]
}

export const miscRoutes = {
  element: <Layout breakPoint={DefaultSidebarBreakpoint} smallHeader={true} sidebarMenu={<InSessionSidebarMenu />} footer={<Footer displayVersion={true} />} />,
  children: [
    {
      path: "topic",
      element: <SecureRoute groups={["Administrator"]} element={<QuickSightQEmbedWrapper />} />
    },
    {
      path: "mb/analysis",
      element: <SecureRoute groups={["Administrator"]} element={<B1 />} />
    },
    {
      path: "markettable1",
      element: <SecureRoute groups={["Administrator"]} element={<GrateTable table='Hedge' title='Capital Market Dashboard' />} />
    },
    {
      path: "markettable2",
      element: <SecureRoute groups={["Administrator"]} element={<GrateTable table='AggregateTable' title='Capital Market Dashboard' />} />
    },
    {
      path: "retailanalyticsdemo",
      element: <SecureRoute groups={["DemoAnalytics", "Administrator"]} element={<GenericLiveboardEmbed enablePremiumFeatures={true} description='Retail Lending Analytics' guid='31f70534-e614-4832-8888-5b99f92c4e16' orgID={demoObject.orgID} />} />
    },
    {
      path: "tsid/:LiveboardId",
      element: <TSRedirect />
    }
  ]
}
