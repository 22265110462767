/**
 * Interface detailing the structure of a agGrid table.
 */

export interface agGridObject<K extends string> {
    title: string,
    columnDefs: { field: K, maxWidth?: number, sortable?: boolean }[],
    rowDefs: { [P in K]: string | number }[];
}

/**
 * Wrapper function for the "Export to CSV" feature.
 * @param filename Name of the CSV File to be downloaded.
 * @param agGridObject An array of agGridObjects to be converted into CSV data.
 */

function exportToCSVWrapper(filename: string, agGridObject: agGridObject<string>[]) {
    saveCSV(filename, ArrayToCSV(ArrayMerge(agGridObject.map((element) => { return agGridToArray(element) }))))
}

/**
 * Saves a CSV file by creating a blob file, by creating a DOM element and downloading the data embedded in it.
 * @param filename Filename of the file.
 * @param csv Content of the file.
 */

function saveCSV(filename: string, csv: string) {

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

/**
 * Converts an agGridObject to an Array.
 * @param agGridObject Object to be converted.
 * @returns A two dimensional array of the agGridObject.
 */

function agGridToArray(agGridObject: agGridObject<string>) {
    var resultArray: string[][] = [];
    var tmp: string[] = [];

    tmp.push(agGridObject.title);
    for (var i = 0; i < Object.keys(agGridObject.rowDefs[0]).length - 1; i++) {
        tmp.push("") //This empty string is necessary to make the resulting array rectangular.
    }

    resultArray.push(tmp);
    tmp = [];

    agGridObject.columnDefs.forEach(function (rowArray) {
        tmp.push(rowArray.field.toString().replaceAll('"', '""')); //Adding the names of the columns.
    });

    resultArray.push(tmp);
    tmp = [];

    agGridObject.rowDefs.forEach(function (rowArray: any) {
        agGridObject.columnDefs.forEach(element => {
            if (rowArray[element.field] !== null){
                tmp.push(rowArray[element.field].toString().replaceAll('"', '""')); //Adding the content of each row.
            } else {
                tmp.push("");
            }
        })
        resultArray.push(tmp);
        tmp = [];
    });

    return resultArray;
}

/**
 * Merges multiple arrays into one, and adds an empty column between them.
 * @param chartsArray Array of charts to merge.
 * @returns Merged array.
 */

function ArrayMerge(chartsArray: string[][][]) {
    var resultArray: string[][] = [];
    
    var maxRowAmount: number = 0;

    chartsArray.forEach((array) => {
        maxRowAmount = Math.max(maxRowAmount, array.length)
    })

    var normalizedArray: string[][][] = [];
    
    //Pad every array so it has the same amount of rows as the biggest one. This will make it so ArrayToCSV has no problems when converting the
    //arrays to text, as the function is not prepared for tables with different dimensions.

    chartsArray.forEach((array) => {
        let lengthOfRow = array[0].length; //as array is square, any array member will return the same length
        let arrayOfDashes = Array(lengthOfRow).fill('-');
        if (array.length !== maxRowAmount){
            let tmpArray: string[][] = array;
            let arrayLength: number = array.length;
            for (let j = 0; j < (maxRowAmount - arrayLength); j++){
                tmpArray.push(arrayOfDashes);
            }
            normalizedArray.push(tmpArray)
        } else {
            normalizedArray.push(array)
        }
    })

    resultArray = normalizedArray.reduce((previousValue, currentValue) => previousValue.map((value, index) => value.concat([""], currentValue[index])));
    
    return resultArray;
}

/**
 * Converts an 2 dimensional array into a CSV
 * @param array Array to be converted.
 * @returns A CSV string.
 */

export function ArrayToCSV(array: string[][]) {

    var csvContent: string = "";

    array.forEach((row, rowIndex) => {
        row.forEach((column, columnIndex) => {
            if (typeof(array[rowIndex][columnIndex]) !== "undefined" && (array[rowIndex][columnIndex] !== "-")){
                csvContent += array[rowIndex][columnIndex].toString().replaceAll('"', '""') + ",";
            } else {
                csvContent += ",";
            }
        })
        csvContent += "\n";
    })

    return csvContent;
}

export function SaveToCSVButton(filename: string, CSVData: agGridObject<string>[], disableCondition?: () => boolean) {
    return <div className="option-in-card">
        <div style={{ paddingLeft: "5px" }}>
            <button className="btn" disabled={(typeof(disableCondition) === 'function') ? (disableCondition() || CSVData.length === 0) : (CSVData.length === 0)} onClick={() => {
                const today = new Date();
                let month = today.getUTCMonth() + 1; //months from 1-12
                let day = today.getUTCDate();
                let year = today.getUTCFullYear();
                exportToCSVWrapper(`Gallus_Report_${month + "_" + day + "_" + year}` + `${filename}`, CSVData)
            }}>
                Export to CSV
            </button>
        </div>
    </div>

}