import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ['Size', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Originations', 'text-underlined',{value: 78711994000, overrideEncoding: "$skb1d"}, {value: 133129283000, overrideEncoding: "$skb1d"}, -0.408755217287544, {value: 14959070000, overrideEncoding: "$skb1d"}, {value: 19123199000, overrideEncoding: "$skb1d"}, -0.217752741055511, {value: 108275883000, overrideEncoding: "$skb1d"}, {value: 127285461000, overrideEncoding: "$skb1d"}, -0.149346027823241, {value: 22671731000, overrideEncoding: "$skb1d"}, {value: 53778456000, overrideEncoding: "$skb1d"}, -0.578423541947727],
  ['Servicing', 'CSSCLASSGOESHERE',{value: 509105421000, overrideEncoding: "$-skb1d"}, {value: 534704602000, overrideEncoding: "$-skb1d"}, -0.0478753706331483, {value: 85033899000, overrideEncoding: "$-skb1d"}, {value: 78892987000, overrideEncoding: "$-skb1d"}, 0.0778385029330935, {value: 299456189000, overrideEncoding: "$-skb1d"}, {value: 312454025000, overrideEncoding: "$-skb1d"}, -0.0415991952736087, {value: 145090199000, overrideEncoding: "$-skb1d"}, {value: 141170931000, overrideEncoding: "$-skb1d"}, 0.0277625710352509],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Balance Sheet', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Tangible Assets', 'CSSCLASSGOESHERE',17994975000, 18823284000, -0.0440044893335297, 3490539000, 3062822000, 0.139648010886692, 11871854000, 13600625000, -0.127109673268692, 6151048000, 6609934000, -0.069423688648026],
  ['Tangible Equity', 'text-underlined', {value: 7064945000, overrideEncoding: "$-sk0d"}, {value:  7216621000, overrideEncoding: "$-sk0d"}, -0.0210175925824566, {value:  997312000, overrideEncoding: "$-sk0d"}, {value:  1072518000, overrideEncoding: "$-sk0d"}, -0.0701209676667431, {value:  2474671000, overrideEncoding: "$-sk0d"}, {value:  3171693000, overrideEncoding: "$-sk0d"}, -0.219763388196777, {value:  707484000, overrideEncoding: "$-sk0d"}, {value:  921473000, overrideEncoding: "$-sk0d"}, -0.232224926829109],
  ['TCE / TA', 'CSSCLASSGOESHERE', {value: 0.392606547105511, overrideEncoding: "$%1d"}, {value:  0.383387989045907, overrideEncoding: "$%1d"}, {value:  0.0092185580596042, overrideEncoding: "$%1d"}, {value:  0.285718623971828, overrideEncoding: "$%1d"}, {value:  0.350173140979136, overrideEncoding: "$%1d"}, {value:  -0.0644545170073084, overrideEncoding: "$%1d"}, {value:  0.208448570880336, overrideEncoding: "$%1d"}, {value:  0.233202003584394, overrideEncoding: "$%1d"}, {value:  -0.0247534327040581, overrideEncoding: "$%1d"}, {value:  0.11501844888871, overrideEncoding: "$%1d"}, {value:  0.139407292115171, overrideEncoding: "$%1d"}, {value:  -0.0243888432264612, overrideEncoding: "$%1d"}],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Income Statement', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['Revenue', 'CSSCLASSGOESHERE', 3799269000, 5838493000, -0.349272320785518, 655187000, 1164821000, -0.437521301556205, 1311327000, 2372626000, -0.447309858359472, 974022000, 1255796000, -0.224378800378405],
  ['Expense', 'CSSCLASSGOESHERE', {value: 4202166000, overrideEncoding: "$-sk0d"}, {value:  5096582000, overrideEncoding: "$-sk0d"}, -0.175493301196763, {value:  701318000, overrideEncoding: "$-sk0d"}, {value:  744802000, overrideEncoding: "$-sk0d"}, -0.0583833018708329, {value: 1387620000, overrideEncoding: "$-sk0d"}, {value: 1437957000, overrideEncoding: "$-sk0d"}, -0.0350059146413977, {value: 1252330000, overrideEncoding: "$-sk0d"}, {value:  1945773000, overrideEncoding: "$-sk0d"}, -0.35638432643479],
  ['Net Income', 'SubTitleOrange',-390080000, 699933000, -1.55731048543218, -39137000, 328630000, -1.11909137936281, -69782000, 931858000, -1.07488480004464, -235512000, -610385000, -0.614158277152944],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Profitability', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['GoS Margins','CSSCLASSGOESHERE', {value: 0.0263, overrideEncoding: "$%2d"}, {value: 0.0282, overrideEncoding: "$%2d"}, {value: -0.0019, overrideEncoding: "$%2d"}, {value: 0.034, overrideEncoding: "$%2d"}, {value: 0.0368, overrideEncoding: "$%2d"}, {value: -0.0028, overrideEncoding: "$%2d"}, {value: 0.0092, overrideEncoding: "$%2d"}, {value: 0.0077, overrideEncoding: "$%2d"}, {value: 0.0015, overrideEncoding: "$%2d"}, {value: 0.026, overrideEncoding: "$%2d"}, {value: 0.0163, overrideEncoding: "$%2d"}, {value: 0.0097, overrideEncoding: "$%2d"}],
  ['Efficiency Ratio','CSSCLASSGOESHERE', {value: 1.10604592620317, overrideEncoding: "$%1d"}, {value: 0.872927654447817, overrideEncoding: "$%1d"}, {value: 0.233118271755355, overrideEncoding: "$%1d"}, {value: 1.07040890615962, overrideEncoding: "$%1d"}, {value: 0.639413266072641, overrideEncoding: "$%1d"}, {value: 0.430995640086974, overrideEncoding: "$%1d"}, {value: 1.05817999629383, overrideEncoding: "$%1d"}, {value: 0.606061385148776, overrideEncoding: "$%1d"}, {value: 0.452118611145054, overrideEncoding: "$%1d"}, {value: 1.28573071244797, overrideEncoding: "$%1d"}, {value: 1.54943398450067, overrideEncoding: "$%1d"}, {value: -0.263703272052694, overrideEncoding: "$%1d"}],
  ['Net Margin','CSSCLASSGOESHERE', {value: -0.102672382503055, overrideEncoding: "$%1d"}, {value: 0.119882476522623, overrideEncoding: "$%1d"}, {value: -0.222554859025678, overrideEncoding: "$%1d"}, {value: -0.0597340911831279, overrideEncoding: "$%1d"}, {value: 0.282129185514341, overrideEncoding: "$%1d"}, {value: -0.341863276697469, overrideEncoding: "$%1d"}, {value: -0.0532147969194564, overrideEncoding: "$%1d"}, {value: 0.392753851639491, overrideEncoding: "$%1d"}, {value: -0.445968648558948, overrideEncoding: "$%1d"}, {value: -0.241793306516691, overrideEncoding: "$%1d"}, {value: -0.486054263590583, overrideEncoding: "$%1d"}, {value: 0.244260957073893, overrideEncoding: "$%1d"}],
  ['ROTCE','SubTitleOrange', {value: -0.220853807071393, overrideEncoding: "$%1d"}, {value: 0.387956080830627, overrideEncoding: "$%1d"}, {value: -0.60880988790202, overrideEncoding: "$%1d"}, {value: -0.156969935185779, overrideEncoding: "$%1d"}, {value: 1.2256391034929, overrideEncoding: "$%1d"}, {value: -1.38260903867868, overrideEncoding: "$%1d"}, {value: -0.112793983523466, overrideEncoding: "$%1d"}, {value: 1.17521840859125, overrideEncoding: "$%1d"}, {value: -1.28801239211471, overrideEncoding: "$%1d"}, {value: -1.33154672049121, overrideEncoding: "$%1d"}, {value: -2.64960557715744, overrideEncoding: "$%1d"}, {value: 1.31805885666624, overrideEncoding: "$%1d"}],
  [null, 'Spacing', null, null, null, null, null, null, null, null, null, null, null, null],
  ['Production Income', 'Title', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['P-T Income (A - B)', 'CSSCLASSGOESHERE', -402.897*1000000, 741.911*1000000, {value: -1.54305300770578, overrideEncoding: "$%1d"}, -46.131*1000000, 420.019*1000000, {value: -1.10983074575198, overrideEncoding: "$%1d"}, -76.2929999999999*1000000, 934.669*1000000, {value: -1.08162568781034, overrideEncoding: "$%1d"}, -278.308*1000000, -689.977*1000000, {value: "NM", overrideEncoding: "string"}],
  ['(-) Servicing Income', 'CSSCLASSGOESHERE', {value:-700.798*1000000, overrideEncoding: "$-sk0d"}, {value:-1643.673*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}, {value:-100.363*1000000, overrideEncoding: "$-sk0d"}, {value:-408.986*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}, {value:-280.707*1000000, overrideEncoding: "$-sk0d"}, {value:-201.687*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}, {value:-280.707*1000000, overrideEncoding: "$-sk0d"}, {value:-201.687*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}],
  ['Production Income', 'SubTitleRed', -1103.695*1000000, -901.762*1000000, {value: "NM", overrideEncoding: "string"}, -146.494*1000000, 11.0329999999999*1000000, {value: -14.2778029547722, overrideEncoding: "$%1d"}, -357*1000000, 732.982*1000000, {value: -1.48705152377548, overrideEncoding: "$%1d"}, -559.015*1000000, -891.664*1000000, {value: "NM", overrideEncoding: "string"}],
  ['Production Income (bps)', 'SubTitleRed', {value:-140.21941814865*1000000, overrideEncoding: "$-sk0d"}, {value:-67.7358113616521*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}, {value: -97.9298846786598*1000000, overrideEncoding: "$-sk0d"}, {value: 5.76943219594164*1000000, overrideEncoding: "$-sk0d"}, {value: -17.9739207174574, overrideEncoding: "$%1d"}, {value: -32.9713312058605*1000000, overrideEncoding: "$-sk0d"}, {value: 57.5856813685893*1000000, overrideEncoding: "$-sk0d"}, {value: -1.57256127603702, overrideEncoding: "$%1d"}, {value: -246.56917462544*1000000, overrideEncoding: "$-sk0d"}, {value: -165.803198217517*1000000, overrideEncoding: "$-sk0d"}, {value: "NM", overrideEncoding: "string"}]
];

const columnFormattingFormatted : DataEncoding[]= [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
]; 

export const GrateBenchmarkFy: tsDataObject = {
  "data": dataArrayFormatted
  ,
  "columnNames": [
    "RowName",
    "CSSClass",
    "2023 Rocket",
    "2022 Rocket",
    "YoY Rocket",
    "2023 Guild",
    "2022 Guild",
    "YoY Guild",
    "2023 UWM",
    "2022 UWM",
    "YoY UWM",
    "2023 Loan Depot",
    "2022 Loan Depot",
    "YoY Loan Depot",
  ],
  "columnDisplayName": [
    "RowName",
    "CSSClass",
    "2023",
    "2022",
    "YoY",
    "2023",
    "2022",
    "YoY",
    "2023",
    "2022",
    "YoY",
    "2023",
    "2022",
    "YoY",
  ],
  "columnShow": [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ],
  "columnGroupName": [{
    nameOfGroup: "Rocket",
    headerClass: 'grate-group',
    columnsToGroup: ["2023 Rocket",
      "2022 Rocket",
      "YoY Rocket"]
  },
  {
    nameOfGroup: "Guild",
    headerClass: 'grate-group',
    columnsToGroup: ["2023 Guild",
      "2022 Guild",
      "YoY Guild"]
  },
  {
    nameOfGroup: "UWM",
    headerClass: 'grate-group',
    columnsToGroup: ["2023 UWM",
      "2022 UWM",
      "YoY UWM"]
  },
  {
    nameOfGroup: "Loan Depot",
    headerClass: 'grate-group',
    columnsToGroup: ["2023 Loan Depot",
      "2022 Loan Depot",
      "YoY Loan Depot"]
  }
  ],
  "columnFormatting": columnFormattingFormatted,
  "columnHeaderClasses": [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column"
  ],
  "columnDataClasses": [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right"
  ],
  "tableTitle": ""
}
