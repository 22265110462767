export function sortObjectArrayBy(array: any[], valueToSortBy: string, ascending: boolean, lessIsBetter: boolean){
    var arrayTMP: any[] = [];
    array.forEach((element) => {
        arrayTMP.push(element)
    })
    return arrayTMP.sort(
        function (a, b) {
            if (a[valueToSortBy] === b[valueToSortBy]) {
                return 0;
            }
            if (ascending){
                if (a[valueToSortBy] === null) {
                    return -1;
                }
                if (b[valueToSortBy] === null) {
                    return 1;
                }
                return (lessIsBetter) ? (b[valueToSortBy] - a[valueToSortBy]) : (a[valueToSortBy] - b[valueToSortBy]);
            } else {
                if (a[valueToSortBy] === null) {
                    return 1;
                }
                if (b[valueToSortBy] === null) {
                    return -1;
                }
                return (lessIsBetter) ? (a[valueToSortBy] - b[valueToSortBy]) : (b[valueToSortBy] - a[valueToSortBy]);
            }
            
        })
}