import { DataEncoding } from "../services/types/gallus";

export function plotlyFormatter(value: number, type: string) {
    let returnvalue: string = "Error";
    switch (type) {
        case "financials":
            if (value >= 0) {
                returnvalue = "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                returnvalue = "($" + (value * (-1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")";
            }
    }
    return returnvalue;
}

export const noDataSymbol = "-";

function nFormatter(number: number, digits: number, thousandsSeparator: boolean, billions: boolean) {
    const regularLookupValues = [{ value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" }
    ]
    
    const lookup = (billions) ? [...regularLookupValues, { value: 1e9, symbol: "B" }] : regularLookupValues;

    let item = lookup.slice().reverse().find(function (item) {
        return (number >= item.value);
    });


    var zeroDecimals: string = "";

    if (item && parseFloat((number / item.value).toFixed(digits)) % 1 === 0 && digits > 0) {
        zeroDecimals = ".";
        for (let i = 0; i < digits; i++) {
            zeroDecimals += "0";
        }
    }

    if (item) {
        return (thousandsSeparator ? parseFloat((number / item.value).toFixed(digits)).toLocaleString('en') + zeroDecimals + item.symbol : parseFloat((number / item.value).toFixed(digits)) + zeroDecimals + item.symbol)
    } else {
        return "0"
    }
}

export function currencySymbolFormatter(value: string | number | null) {
    let returnValue: string = ""
    if ((value !== null) && value !== undefined) {
        let valueToTransform: number = parseFloat(value.toString());
        if (valueToTransform < 0) {
            valueToTransform = valueToTransform * -1;
            returnValue = "-"
        }
        if ((valueToTransform) <= 100000000) returnValue += nFormatter((valueToTransform), 1, false, false);
        if (valueToTransform > 100000000) returnValue += nFormatter((valueToTransform), 1, false, true);
    }
    else {
        returnValue = noDataSymbol;
    }
    return returnValue;
}

export function multiplesFormatter(value: string | number | null) {
    let returnValue: string = ""
    if ((value !== null) && value !== undefined) {
        returnValue = parseFloat(value.toString()).toFixed(1) + "x";
    }
    else {
        returnValue = noDataSymbol;
    }
    return returnValue;
}

export function currencySymbolFormatterForArray(array: number[]) {
    let resultArray: string[] = []

    array.map((element) => {
        if (element !== null) {
            if (element <= 100000) {
                resultArray.push(nFormatter(element, 1, false, false));
            } else {
                resultArray.push(nFormatter(element, 1, false, true));
            }
        } else {
            resultArray.push(noDataSymbol);
        }
    })
    return resultArray;
}

export function financialSymbolFormatter(value: string | number | null, includeDollarSign: boolean, decimals: boolean, thousandsSeparator: boolean, billionsSign: boolean) {
    let returnValue: string = "";
    if ((value !== null) && value !== undefined) {
        let originalValue: number = parseFloat(value.toString());
        let transformedValue: number = originalValue;

        if (originalValue < 0) {
            transformedValue = transformedValue * -1;
        }

        //if comma'd millions is Required we could include a function here to deal with that
        returnValue += nFormatter(transformedValue, decimals ? 1 : 0, thousandsSeparator, billionsSign);

        if (includeDollarSign) {
            returnValue = "$" + returnValue;
        };
        if (originalValue < 0) {
            returnValue = "(" + returnValue + ")";
        }
    }
    else {
        returnValue = noDataSymbol;
    }
    return returnValue;
}

export function numberWithoutSymbol(value: string | number | null, includeDollarSign: boolean, decimals: number, thousandsSeparator: boolean) {
    let returnValue: string = "";
    if ((value !== null) && value !== undefined) {
        let originalValue: number = parseFloat(value.toString());
        let transformedValue: number = originalValue;
        if (originalValue < 0) {
            transformedValue = transformedValue * -1;
        }
        returnValue += parseFloat(transformedValue.toFixed(decimals)).toLocaleString('en');

        if (includeDollarSign) {
            returnValue = "$" + returnValue;
        };
        if (originalValue < 0) {
            returnValue = "(" + returnValue + ")";
        }
    }
    else {
        returnValue = noDataSymbol;
    }
    return returnValue;
}

export function displayArrayAsPercentage(array: number[]) {
    let percentageArray: string[] = [];
    array.forEach((element) => {
        if (element !== null) {
            percentageArray.push((element * 100).toFixed(1) + "%")
        } else {
            percentageArray.push(noDataSymbol)
        }
    })
    return percentageArray;
}

export function displayAsPercentage(value: number | string, financial: boolean, decimals: number) {
    var result: string = "NoData";
    if (value !== null) {
        if (financial && (parseFloat(value.toString()) < 0)) {
            result = "(" + (parseFloat(value.toString()) * -100).toLocaleString("en", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) + "%)"
        } else {
            result = (parseFloat(value.toString()) * 100).toLocaleString("en", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) + "%"
        }
    } else {
        result = noDataSymbol
    }
    return result;
}

export function removeDecimalFromArray(array: number[]) {
    let arrayNoDecimal: string[] = [];
    array.forEach((element) => {
        if (element !== null) {
            arrayNoDecimal.push(element.toString())
        } else {
            arrayNoDecimal.push(noDataSymbol)
        }

    })
    return arrayNoDecimal;
}

export function arrayToFixed(array: number[]) {
    let arrayFixed: string[] = [];
    array.forEach((element) => {
        if (element !== null) {
            arrayFixed.push(parseInt(element.toString()).toFixed(1))
        } else {
            arrayFixed.push(noDataSymbol)
        }

    })
    return arrayFixed;
}

export function getAverageOfArray(array: number[], type: "amount" | "currency" | "percentage", zerosDontCount?: boolean) {
    let sum: number = 0;
    let numbersOfZeroes: number = 0;
    array.forEach((element) => {
        if (element !== null) {
            sum += parseFloat(element.toString())
            if (element === 0) {
                numbersOfZeroes += 1;
            }
        } else {
            numbersOfZeroes += 1;
        }
    });

    let avg;

    if (zerosDontCount) {
        if (array.length === numbersOfZeroes) {
            avg = 0;
        } else {
            avg = sum / (array.length - numbersOfZeroes);
        }
    } else {
        avg = sum / (array.length);
    }

    let returnValue: string = "Error"

    switch (type) {
        case "amount":
            returnValue = avg.toFixed(1).toString()
            break;
        case "currency":
            returnValue = ((avg > 100000) ? nFormatter(avg, 1, false, false) : nFormatter(avg, 1, false, true));
            break;
        case "percentage":
            returnValue = (avg * 100).toFixed(1) + "%"
            break;
    }

    return returnValue
}

enum FormatPattern  
{  
    numberTwoDecimals = "n2d",
    numberOneDecimal = "n1d",
    numberNoDecimal = "n0d",
    percentageTwoDecimals = "%2d",
    percentageOneDecimal = "%1d",
    percentageNoDecimal = "%0d",
    financialWithSign = "$s",
    financialWithoutSign = "$-s",
    financialWithoutSignNoDecimals = "$-s0d",
    financialPercentageOneDecimal = "$%1d",
    financialPercentageTwoDecimals = "$%2d",
    multiples = "x",
    financialWithSignThousandsSeparator = "$sk",
    financialWithSignAndBillions = "$skb1d",
    financialWithoutSignAndBillions = "$-skb1d",
    financialWithSignThousandsSeparatorNoDecimals = "$sk0d",
    financialWithoutSignThousandsSeparatorNoDecimals = "$-sk0d",
    financialWithSignThousandsSeparatorNoDecimalsNoSymbols = "$sk0d-S",
    financialWithoutSignThousandsSeparatorNoDecimalsNoSymbols = "$-sk0d-S"
}  

export const formatterWrapper = (value: string, encoding: DataEncoding) => {
    var result: string = "-";
    if (value !== null && value !== "") {
        switch (encoding) {
            case "numberTwoDecimals":
            case FormatPattern.numberTwoDecimals:
                result = parseFloat(value).toFixed(2);
                break;
            case "numberOneDecimal":
            case FormatPattern.numberOneDecimal:
                result = parseFloat(value).toFixed(1);
                break;
            case "numberNoDecimal":
            case FormatPattern.numberNoDecimal:
                result = parseFloat(value).toFixed(0);
                break;
            case "percentageTwoDecimals":
            case FormatPattern.percentageTwoDecimals:
                result = displayAsPercentage(value.toString(), false, 2);
                break;
            case "percentageOneDecimal":
            case FormatPattern.percentageOneDecimal:
                result = displayAsPercentage(value.toString(), false, 1);
                break;
            case "percentageNoDecimal":
            case FormatPattern.percentageNoDecimal:
                result = displayAsPercentage(value.toString(), false, 0);
                break;
            case "currency":
                result = currencySymbolFormatter(value.toString());
                break;
            case "string":
                result = value;
                break;
            case "financialWithSign":
            case FormatPattern.financialWithSign:
                result = financialSymbolFormatter(value.toString(), true, true, false, false);
                break;
            case "financialWithoutSign":
            case FormatPattern.financialWithoutSign:
                result = financialSymbolFormatter(value.toString(), false, true, false, false);
                break;
            case "financialWithoutSignNoDecimals":
            case FormatPattern.financialWithoutSignNoDecimals:
                result = financialSymbolFormatter(value.toString(), false, false, false, false);
                break;
            case "financialPercentageOneDecimal":
            case FormatPattern.financialPercentageOneDecimal:
                result = displayAsPercentage(value.toString(), true, 1);
                break;
            case "financialPercentageTwoDecimals":
            case FormatPattern.financialPercentageTwoDecimals:
                result = displayAsPercentage(value.toString(), true, 2);
                break;
            case "multiples":
            case FormatPattern.multiples:
                result = multiplesFormatter(value.toString());
                break;
            case "financialWithSignThousandsSeparator":
            case FormatPattern.financialWithSignThousandsSeparator:
                result = financialSymbolFormatter(value.toString(), true, true, true, false);
                break;
            case "financialWithSignAndBillions":
            case FormatPattern.financialWithSignAndBillions:
                result = financialSymbolFormatter(value.toString(), true, true, true, true);
                break;
            case "financialWithoutSignAndBillions":
            case FormatPattern.financialWithoutSignAndBillions:
                result = financialSymbolFormatter(value.toString(), false, true, true, true);
                break;
            case "financialWithSignThousandsSeparatorNoDecimals":
            case FormatPattern.financialWithSignThousandsSeparatorNoDecimals:
                result = financialSymbolFormatter(value.toString(), true, false, true, false);
                break;
            case "financialWithoutSignThousandsSeparatorNoDecimals":
            case FormatPattern.financialWithoutSignThousandsSeparatorNoDecimals:
                result = financialSymbolFormatter(value.toString(), false, false, true, false);
                break;
            case "financialWithSignThousandsSeparatorNoDecimalsNoSymbols":
            case FormatPattern.financialWithSignThousandsSeparatorNoDecimalsNoSymbols:
                result = numberWithoutSymbol(value.toString(), true, 0, true);
                break;
            case "financialWithoutSignThousandsSeparatorNoDecimalsNoSymbols":
            case FormatPattern.financialWithoutSignThousandsSeparatorNoDecimalsNoSymbols:
                result = numberWithoutSymbol(value.toString(), false, 0, true);
                break;
        }
    }
    return result;
}