import React from "react";
import { SaveToCSVButton } from "./ExportToCSV";
import { exportHTMLToPdfButton } from "./SaveAsPdf";
import { ButtonGroups } from "./ButtonGroup";
import { PeriodSelectors } from "./PeriodSelectors";
import { ButtonGroup, ExportToCSVInterface, PeriodSelectorInterface, SaveAsPDFInterface } from "./Interfaces";

interface OptionsMenuProps {
    customObject?: JSX.Element;
    buttonGroups?: ButtonGroup[];
    periodSelector?: PeriodSelectorInterface;
    saveAsPDF?: SaveAsPDFInterface;
    exportToCSV?: ExportToCSVInterface;
    onButtonClicked?: (idOfClickedButton: string) => void;
}

export const OptionsMenu = (props: OptionsMenuProps) => {
    const [openOptionsMenu, setOpenOptionsMenu] = React.useState<boolean>(false);
    const [clickedButton, setClickedButton] = React.useState<string>("");

    React.useEffect(() => {
        setClickedButton("");
    }, [window.location.href]); //Forced to use this since all other lifecycle hooks don't seem to work.

    const handleClick = (idOfClickedButton: string) => {
        setClickedButton(idOfClickedButton);
        props.onButtonClicked && props.onButtonClicked(idOfClickedButton);
    }

    return (
        <div className={`card my-2`}>
            <div className="card-body">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button className="btn me-2" onClick={() => setOpenOptionsMenu(!openOptionsMenu)}>{(openOptionsMenu ? 'X' : 'V')}</button>
                    <h5 className="card-title mb-0" style={{ cursor: 'pointer' }} onClick={() => setOpenOptionsMenu(!openOptionsMenu)}>Options</h5>
                </div>
                <div style={{ display: (openOptionsMenu ? 'flex' : 'none'), width: "100", padding: "5px" }}>
                    {props.customObject && props.customObject}
                    {props.periodSelector && (
                        <PeriodSelectors
                            handleClickHighlight={handleClick}
                            clickedButtonID={clickedButton}
                            setMonth={props.periodSelector.setMonthFunction}
                            setYear={props.periodSelector.setYearFunction}
                            onClickFunctionality={props.periodSelector.onClickFunctionality}
                            upToCurrentMonth={props.periodSelector.upToCurrentMonth}
                            startingYear={props.periodSelector.startingYear}
                            buttonText={props.periodSelector.buttonText}
                            disableCondition={props.periodSelector.disableCondition}
                            blockYear={props.periodSelector.blockYear}
                            hideYear={props.periodSelector.hideYear}
                            setAsDefault={props.periodSelector.setAsDefault}
                        />
                    )}

                    {props.buttonGroups && (
                        <ButtonGroups
                            buttonGroups={props.buttonGroups}
                            onClick={handleClick}
                            clickedButtonID={clickedButton}
                        />
                    )}
                    {props.saveAsPDF && exportHTMLToPdfButton(props.saveAsPDF.title, props.saveAsPDF.subtitle, props.saveAsPDF.landscape, props.saveAsPDF.setProgressFlagFunction, props.saveAsPDF.reasonableFit, props.saveAsPDF.disableCondition)}
                    {props.exportToCSV && SaveToCSVButton(props.exportToCSV.filename, props.exportToCSV.CSVData, props.exportToCSV.disableCondition)}
                </div>
            </div>
        </div>
    );
}
