import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LiveboardInfo, RelevantTSGroupInfo, RelevantTSUserInfo, groupPermissions } from "./types/thoughtSpot";
import { environmentConfig } from "../environment";

const tsV1ApiBaseURL = {
    baseUrl: `${environmentConfig.thoughtspotHost}/callosum/v1/tspublic/v1`
};

/**
 * Trims a list of TS objects so only users with a certain email address will be returned.
 * @param unTrimmedUserList
 * @param filter
 * @returns
 */

const trimUsers = (unTrimmedUserList: RelevantTSUserInfo[], filter: string) => {
    let trimmedUserList: RelevantTSUserInfo[] = [];
    unTrimmedUserList.forEach((element) => {
        if (element.name.includes(filter) && ((element.principalTypeEnum === "LOCAL_USER") || (element.principalTypeEnum === "OIDC_USER"))) {
            trimmedUserList.push(element);
        }
    });
    return trimmedUserList;
}

/**
 * Takes a list of groups and discards some of them. The ones discarded are the default groups in the CHM Org.
 * @param unTrimmedGroupList
 */

const trimGroups = (unTrimmedGroupList: RelevantTSGroupInfo[]) => {
    var ignoreGroups = new RegExp(["b5887fd4-ebc6-4e45-adc8-2f9faf8d7b27", "7d0da5ce-bbdb-4f84-9e16-0c96604fb2f3",
        "b8a738a7-1f2f-43f3-a0fe-5e6ec6cd5553", "008d5125-4ebc-40ac-b909-5dea3fdb370a", "fdb170d6-2f36-4857-9da5-3d90270eba4c"].join('|'));
    let trimmedGroupList: RelevantTSGroupInfo[] = [];
    unTrimmedGroupList.forEach((element) => {
        if (element.principalTypeEnum.includes("LOCAL_GROUP") && !ignoreGroups.test(element.id)) {
            trimmedGroupList.push(element);
        }
    });
    return trimmedGroupList;
}

export const thoughtspotV1Api = createApi({
    baseQuery: fetchBaseQuery(tsV1ApiBaseURL),
    reducerPath: 'thoughtspotV1Api',
    endpoints: (builder) => ({
        switchThoughtSpotOrg: builder.mutation<void, { orgId: number, thoughtSpotAccessToken: string }>({ 
            query: (arg) => {
                return {
                    url: `/session/orgs`,
                    method: "put",
                    body: new URLSearchParams({
                        'orgid': `${arg.orgId}`
                    }),
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    },
                    credentials: 'include'
                }
            }
        }),
        listLiveboards: builder.query<LiveboardInfo[], { thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/metadata/list?type=PINBOARD_ANSWER_BOOK&sort=NAME&showhidden=false`,
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json'
                    }
                }
            },
            transformResponse(response: any): LiveboardInfo[] {
                return response.headers;
            }
        }),
        listUsers: builder.query<RelevantTSUserInfo[], { filter: string, thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/user/list`,
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json'
                    }
                }
            },
            transformResponse(response: RelevantTSUserInfo[], _meta, arg): RelevantTSUserInfo[] {
                return trimUsers(response, arg.filter).sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }),
        listGroups: builder.query<RelevantTSGroupInfo[], { thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/user/list`,
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json'
                    }
                }
            },
            transformResponse(response: RelevantTSGroupInfo[]): RelevantTSGroupInfo[] {
                return trimGroups(response).sort((a, b) => {
                    const nameA = a.displayName.toUpperCase();
                    const nameB = b.displayName.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
            }
        }),
        createGroup: builder.mutation<number, { groupName: string, groupDisplayName: string, description: string, thoughtSpotAccessToken: string }>({ 
            query: (arg) => {
                return {
                    url: `/group/`,
                    method: "post",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        'name': `${arg.groupName}`,
                        'display_name': `${arg.groupDisplayName}`,
                        'description': `${arg.description}`
                    })
                }
            }
        }),
        deleteGroup: builder.query<boolean, { groupId: string, thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/group/${arg.groupId}`,
                    method: "delete",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            }
        }),
        updateUsersInGroup: builder.mutation<any, { groupId: string, userIdArray: string[], thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/group/${arg.groupId}/users`,
                    method: "put",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json',
                    },
                    body: new URLSearchParams({
                        'groupid': `${arg.groupId}`,
                        'userids': `[${arg.userIdArray}]`
                    })
                }
            }
        }),
        getGroupsIDsFromUser: builder.query<string[], { userId: string, thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/user/?userid=${arg.userId}`,
                    method: "get",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json',
                    },
                }
            },
            transformResponse(response: any): string[] {
                return response.assignedGroups;
            }
        }),
        getGroupsWithLiveboardAccess: builder.query<groupPermissions, { liveboardId: string, thoughtSpotAccessToken: string }>({
            query: (arg) => {
                return {
                    url: `/security/metadata/${arg.liveboardId}/permissions?type=PINBOARD_ANSWER_BOOK&dependentshare=false&permissiontype=DEFINED`,
                    method: "get",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                }
            },
            transformResponse(response: any, _meta, arg): groupPermissions {
                let results: groupPermissions = {};
                const objectIDs = Object.keys(response[arg.liveboardId].permissions);
                objectIDs.forEach((element: string) => {
                    results[element] = response[arg.liveboardId].permissions[element].shareMode;
                })

                return results;
            }
        }),
        removeUserFromGroup: builder.query<any, { userId: string, groupId: string, thoughtSpotAccessToken: string }>({
            query: (arg) => {
                let urlSearchParams = new URLSearchParams();
                urlSearchParams.set('groupids', JSON.stringify([arg.groupId]));
                return {
                    url: `/user/${arg.userId}/groups?${urlSearchParams.toString()}`,
                    method: "delete",
                    headers: {
                        'Authorization': `Bearer ${arg.thoughtSpotAccessToken}`,
                        'content-type': 'application/json',
                        'Accept': 'application/json'
                    },
                }
            }
        })
    }),
});

export const {
    useSwitchThoughtSpotOrgMutation,
    useUpdateUsersInGroupMutation,
    useCreateGroupMutation,
    useLazyListLiveboardsQuery,
    useLazyDeleteGroupQuery,
    useLazyGetGroupsIDsFromUserQuery,
    useLazyGetGroupsWithLiveboardAccessQuery,
    useLazyListGroupsQuery,
    useLazyListUsersQuery,
    useLazyRemoveUserFromGroupQuery,
} = thoughtspotV1Api;
