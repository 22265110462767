import React from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';

interface DateSelectorProps {
    buttonObject: { text: string, functionality: (arg0: Date[] | undefined) => void }
}

export const DateSelector = (props: DateSelectorProps) => {
    const [dates, setDates] = React.useState<Date[]>();
    const currentDate = new Date();

    const orderDates = (datesArray: Date[]) => {
        return datesArray.sort((a: Date, b: Date) => {
            return a.getTime() - b.getTime();
        })
    }

    const showSelectedOrderedDates = (datesArray: Date[]) => {
        var message : string = "Dates Selected: "
        var orderedDates = orderDates(datesArray);
        for (let i = 0; i<orderedDates.length; i++){
            message += format(orderedDates[i], 'PP') + ", "
        }
        return message.substring(0, message.length - 2);
    }

    const footer = dates && dates.length > 1 ? (
        <div style={{fontWeight: "bold"}}>
            <button className='bordered-object' style={{ width: "100%" }} onClick={() => props.buttonObject.functionality(orderDates(dates))}>{props.buttonObject.text}</button>
            {showSelectedOrderedDates(dates)}
        </div>
    ) : (
        <p>Select 2 dates to compare.</p>
    );


    var unavailableDays = [];
    for (var i = 0; i <= new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 0).getDate(); i++) {
        var dateIterator = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, i);

        if (dateIterator.getDay() == 6 || dateIterator.getDay() == 0) {
            unavailableDays.push(dateIterator);
        }
    };

    return <div className='bordered-object'>
        <div className='date-selector-title'>
            Available dates for comparison:
        </div>
        <DayPicker
            mode="multiple"
            min={0}
            max={2}
            selected={dates}
            onSelect={setDates}
            footer={footer}
            fromDate={new Date(new Date().setDate(new Date().getDate() - 45))}
            toDate={new Date()}
            disabled={unavailableDays}
        />
    </div>
}