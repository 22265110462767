import * as React from 'react';
import { SearchEmbed, useEmbedRef } from '@thoughtspot/visual-embed-sdk/react';
import { EmbedProps } from '@thoughtspot/visual-embed-sdk/lib/src/react/util';
import { SearchViewConfig } from '@thoughtspot/visual-embed-sdk';
import { getUserSessionItem, initTs } from '..';
import { useAppSelector } from '../app/hooks';
import { selectUserAuthContext } from '../features/console/consoleSlice';
import { Alert } from 'reactstrap';
import { useSwitchThoughtSpotOrgMutation } from '../services/thoughtSpotV1';
import { Error } from './Error';
import { useLazyGetTSAccessTokenQuery } from '../services/serverless';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionChecker } from '../helpers/session';

interface SearchProps extends EmbedProps, SearchViewConfig {
    orgId?: number;
}

export const SearchEmbedWrapper = (props: SearchProps) => {
    const userAuthContext = useAppSelector(selectUserAuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const embedRef = useEmbedRef() as any;
    const onLiveboardRendered = () => {
        console.log('liveboard rendered');
    };

    const [hasNoCookieAccess, setHasNoCookieAccess] = React.useState<boolean>(false);
    const [rendering, setRendering] = React.useState<JSX.Element>();
    const [getTSAccessToken, getTSAccessTokenResult] = useLazyGetTSAccessTokenQuery();

    const noCookieAccessHandler = () => {
        console.log('no cookie access');
        setHasNoCookieAccess(true);
    };

    const handleNoCookieAccess = () => {
        console.log('no cookie access');
        setHasNoCookieAccess(true);
    }

    async function handleAuthExpire() {
        await sessionChecker(() => {
            navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
        });
    }

    async function handleAuthFailure() {
        await sessionChecker(() => {
            navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
        });
    }

    React.useEffect(() => {
        const render = async () => {
            try {
                await sessionChecker(() => {
                    navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
                });
                await getTSAccessToken(props.orgId ?? 0).unwrap();
                await initTs('TOKEN', userAuthContext?.username ?? getUserSessionItem('userName'), props.orgId ?? 0);
                setRendering(
                    <SearchEmbed
                        {...props}
                        className="h-100"
                        ref={embedRef}
                        onLiveboardRendered={onLiveboardRendered}
                        onNoCookieAccess={handleNoCookieAccess}
                        onAuthExpire={handleAuthExpire}
                        onAuthFailure={handleAuthFailure}
                    />
                );
            }
            catch (e) {
                setRendering(<Error code='500' type='External' message='' error={e} />);
            }
        }
        render();
    }, [props]);

    return (
        <>
            {/* {!hasNoCookieAccess && renderTs()}
            <TsSearchEmbedCookieChecker dataSources={props.dataSources} onNoCookieAccess={noCookieAccessHandler} /> */}
            {hasNoCookieAccess && (
                <Alert color="info">
                    Please allow third party cookies from the browser settings.
                </Alert>
            )}
            {!hasNoCookieAccess && rendering}
        </>
    );
}
