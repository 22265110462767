import React, { useEffect, useState } from "react";
import './B1.scss';
import Plot from "react-plotly.js";
import Switch from "react-switch";
import { Col, Container, Row } from "reactstrap";

interface B1Props { }

interface B1State {
    dataLoaded: boolean;
    showcharts: boolean;
    sankey2021: boolean;
}

export const B1 = (props: B1Props) => {
    const [state, setState] = useState<B1State>({
        dataLoaded: false,
        sankey2021: false,
        showcharts: false
    });

    async function initializeValues() {
        
        setState({ ...state, dataLoaded: true });
    }

    useEffect(() => {
        initializeValues();
    }, []);

    return (
        <>
            <div style={{ padding: 20 }}>
                <div className="m-title">
                    Quarterly Mortgage Bankers Performance Report, $ per Loan
                    <div style={{ display: "flex" }}>
                        <div className="m-button">
                            <div>Charts</div>
                            <div><Switch onChange={(e) => setState({ ...state, showcharts: !state.showcharts })} checked={state.showcharts} checkedIcon={false} uncheckedIcon={false} /></div>
                        </div>
                        <div className="m-button">
                            <div>2021</div>
                            <div><Switch onChange={(e) => setState({ ...state, sankey2021: !state.sankey2021 })} checked={state.sankey2021} checkedIcon={false} uncheckedIcon={false} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <Container fluid>
                {!state.showcharts && <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="6">
                        {!state.sankey2021 && <Plot
                            data={[
                                {
                                    type: "sankey",
                                    orientation: "h",
                                    arrangement: "snap",
                                    node: {
                                        pad: 15,
                                        thickness: 30,
                                        line: {
                                            color: "black",
                                            width: 0.0
                                        },
                                        label: ["Fees", "NII", "Capital Markets", "Total Revenue", "Expenses", "Income", "Personnel", "Occ. & Equip", "Tech.", "Other", "Corporate"],
                                        color: ["#38cbe8", "#38cbe8", "#38cbe8", "#00a0c4", "#b04600", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#e87e38"],
                                        x: [0.1, 0.1, 0.1, 0.0, 0.0, 0.1],
                                        y: [0.1, 0.2, 0.3, 0.0, 0.0, 0.3],
                                    },

                                    link: {
                                        source: [0, 1, 2, 3, 5, 4, 4, 4, 4, 4],
                                        target: [3, 3, 3, 4, 4, 6, 7, 8, 9, 10],
                                        value: [2959.51, 267.48, 7164.55, 10391.56, 624.09, 7325.17, 564.98, 384.80, 2106.20, 634.49],
                                        color: ["rgba(184, 223, 255, 0.5)", "rgba(184, 223, 255, 0.5)", "rgba(184, 223, 255, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)"]
                                    }
                                } as any
                            ]}
                            layout={{ height: 600, title: 'Quarterly Summary P&L, Q3', autosize: true }}
                            useResizeHandler
                            config={{ responsive: true }}
                            className="w-100"
                        />}
                        {state.sankey2021 && <Plot
                            data={[
                                {
                                    type: "sankey",
                                    orientation: "h",
                                    arrangement: "snap",
                                    node: {
                                        pad: 15,
                                        thickness: 30,
                                        line: {
                                            color: "black",
                                            width: 0.0
                                        },
                                        label: ["Fees", "NII", "Capital Markets", "Total Revenue", "Expenses", "Personnel", "Occ. & Equip", "Tech.", "Other", "Corporate", "Oper. Income"],
                                        color: ["#38cbe8", "#38cbe8", "#38cbe8", "#00a0c4", "#b04600", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#e87e38", "#38cbe8"],
                                    },

                                    link: {
                                        source: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4],
                                        target: [3, 3, 3, 4, 5, 6, 7, 8, 9, 10],
                                        value: [2365.38, 68.83, 9299.53, 11733.75, 6184.71, 319.99, 230.73, 1859.45, 544.65, 2594.19],
                                        color: ["rgba(184, 223, 255, 0.5)", "rgba(184, 223, 255, 0.5)", "rgba(184, 223, 255, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(255, 159, 130, 0.5)", "rgba(184, 223, 255, 0.5)"]
                                    }
                                } as any
                            ]}
                            layout={{ title: 'Quarterly Summary P&L, Q3', autosize: true }}
                            useResizeHandler
                            config={{ responsive: true }}
                            className="w-100"
                        />}
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="3">
                        <Plot
                            data={[{
                                x: ["2021", "2022"],
                                y: [11733.75, 10391.56],
                                type: 'bar',
                                text: [11733.75, 10391.56].map(number => '<b>$' + number + '</b>'),
                                textfont: {
                                    size: 16,
                                },
                                textposition: 'auto',
                                hoverinfo: 'none',
                                marker: {
                                    color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                }
                            }]}
                            layout={{
                                title: 'Total Revenue',
                                autosize: true
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            className="w-100"
                        />
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="3" xl="3">
                        <Plot
                            data={[{
                                x: ["2021", "2022"],
                                y: [2594.19, -624.09],
                                type: 'bar',
                                text: [2594.19, -624.09].map(number => '<b>$' + number + '</b>'),
                                textfont: {
                                    size: 16,
                                },
                                textposition: 'auto',
                                hoverinfo: 'none',
                                marker: {
                                    color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                }
                            }]}
                            layout={{
                                title: 'Operating Income',
                                autosize: true
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            className="w-100"
                        />
                    </Col>
                </Row>}
                {state.showcharts && <>
                    <Row>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [68.83, 267.48],
                                    type: 'bar',
                                    text: [68.83, 267.48].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Net Interest Income',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [9299.53, 7164.55],
                                    type: 'bar',
                                    text: [9299.53, 7164.55].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Net Secondary Marketing Income',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [11733.75, 10391.56],
                                    type: 'bar',
                                    text: [11733.75, 10391.56].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Total Loan Production Revenue',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [3144.20, 3250.81],
                                    type: 'bar',
                                    text: [3144.20, 3250.81].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Sales Personnel',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [1323.12, 1753.99],
                                    type: 'bar',
                                    text: [1323.12, 1753.99].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Fulfillment Personnel',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [6184.71, 7325.17],
                                    type: 'bar',
                                    text: [6184.71, 7325.17].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Personnel',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [2410.18, 3055.99],
                                    type: 'bar',
                                    text: [2410.18, 3055.99].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Other direct expenses',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [8594.89, 10381.16],
                                    type: 'bar',
                                    text: [8594.89, 10381.16].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Direct Loan Production Expenses',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                        <Col md="4">
                            <Plot
                                data={[{
                                    x: ["Q3'21", "Q3'22"],
                                    y: [2594.19, -624.09],
                                    type: 'bar',
                                    text: [2594.19, -624.09].map(number => '<b>$' + number + '</b>'),
                                    textfont: {
                                        size: 16,
                                    },
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                                    }
                                }]}
                                layout={{
                                    title: 'Total Net Production Income',
                                    autosize: true
                                }}
                                useResizeHandler
                                config={{ responsive: true }}
                                className="w-100"
                            />
                        </Col>
                    </Row>
                </>}
            </Container>
        </>
    );
}
