import React, { createRef, useEffect, useState } from "react";
import '../Module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { exportPlotlyToPdf } from "../../../features/SaveAsPdf";
import Plot from "react-plotly.js";
import { plotlyFormatter } from "../../../features/PlotlyFormatter";
import { useXlsxProcessorQuery } from "../../../services/serverless";
import { Error, tryAgainLaterMessage } from "../../../components/Error";

interface CashFlowWalkProps { }

interface CashFlowWalkState {
    chartsLoadedFlag: boolean;
    cashFlowWalkChartOptions: any[];
    errorFlag: boolean;
    errorCode: string;
    errorMessage: string;
    errorType: string;
}

export const CashFlowWalk = (props: CashFlowWalkProps) => {
    const [state, setState] = useState<CashFlowWalkState>({
        chartsLoadedFlag: false,
        cashFlowWalkChartOptions: [],
        errorFlag: false,
        errorCode: "",
        errorMessage: "",
        errorType: "",
    });

    const chartData = useXlsxProcessorQuery({
        "DataSource": "https://docs.google.com/spreadsheets/d/1PWu461r9p_NonDSQLtkwSlRNFtAEWftp/",
        "InputSheetName": null,
        "Inputs": [],
        "OutputSheetName": "Webpage",
        "Outputs": [
            { "RowIndex": 91, "CellIndex": 24, "Value": null },
            { "RowIndex": 92, "CellIndex": 24, "Value": null },
            { "RowIndex": 93, "CellIndex": 24, "Value": null },
            { "RowIndex": 94, "CellIndex": 24, "Value": null },
            { "RowIndex": 95, "CellIndex": 24, "Value": null },
            { "RowIndex": 96, "CellIndex": 24, "Value": null },
            { "RowIndex": 97, "CellIndex": 24, "Value": null },
            { "RowIndex": 98, "CellIndex": 24, "Value": null },
            { "RowIndex": 99, "CellIndex": 24, "Value": null },
            { "RowIndex": 100, "CellIndex": 24, "Value": null },
            { "RowIndex": 101, "CellIndex": 24, "Value": null },
            { "RowIndex": 102, "CellIndex": 24, "Value": null },
            { "RowIndex": 103, "CellIndex": 24, "Value": null }
        ]
    })

    useEffect(() => {
        if (chartData.isSuccess && chartData.status === "fulfilled") {
            setState((prevState) => ({
                ...prevState,
                cashFlowWalkChartOptions: [
                    {
                        name: "Q2 Cash Flow Walk",
                        type: "waterfall",
                        orientation: "v",
                        measure: [
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "total",
                            "relative",
                            "relative",
                            "relative",
                            "relative",
                            "total"
                        ],
                        x: [
                            "BoP Cash",
                            "Net Income",
                            "OMSR",
                            "MSR FV Change",
                            "Gain on Sale <br> of Loans",
                            "Net Proceeds from <br> Sale of LHFS",
                            "Other Oper.",
                            "Cash before Inv. <br> & Fin. Activities", //total
                            "Purchase of PP&E <br> & Other",
                            "Net Pmt on <br> Funding Facilities",
                            "Net Pmt on <br> EBO Facility",
                            "Other Financing",
                            "End of Period Cash" //total
                        ],
                        textposition: 'auto',
                        text: [
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[0].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[1].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[2].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[3].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[4].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[5].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[6].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[7].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[8].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[9].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[10].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[11].Value.toString()), "financials") + "</b>",
                            "<b>" + plotlyFormatter(Math.round(chartData.data.Outputs[12].Value.toString()), "financials") + "</b>",
                        ],
                        y: [
                            Math.round(chartData.data.Outputs[0].Value),
                            Math.round(chartData.data.Outputs[1].Value),
                            Math.round(chartData.data.Outputs[2].Value),
                            Math.round(chartData.data.Outputs[3].Value),
                            Math.round(chartData.data.Outputs[4].Value),
                            Math.round(chartData.data.Outputs[5].Value),
                            Math.round(chartData.data.Outputs[6].Value),
                            0,
                            Math.round(chartData.data.Outputs[8].Value),
                            Math.round(chartData.data.Outputs[9].Value),
                            Math.round(chartData.data.Outputs[10].Value),
                            Math.round(chartData.data.Outputs[11].Value),
                            0,
                        ],
                        connector: {
                            line: {
                                color: "rgb(63, 63, 63)"
                            }
                        },
                        decreasing: {
                            marker: {
                                color: "red",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        increasing: {
                            marker: {
                                color: "rgb(124, 181, 236)",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                        totals: {
                            marker: {
                                color: "#D3D3D3",
                                line:
                                {
                                    color: "black",
                                    width: 1
                                }
                            }
                        },
                    }
                ]
            }));

            setState((prevState) => ({ ...prevState, chartsLoadedFlag: true,
                errorFlag: false }));
        } else if (chartData.isError) {
            setState((prevState) => ({
                ...prevState,
                errorFlag: true,
                errorCode: "500",
                errorMessage: tryAgainLaterMessage,
                errorType: "InternalAPI"
            }));
        }
    }, [chartData])

    const plotlyRefChart = createRef<Plot>();

    function exportToPdfWrapper() {
        exportPlotlyToPdf("Q4 Cash Flow Walk", [plotlyRefChart], false);
    }

    return (
        <div className="module-master">
            {!state.chartsLoadedFlag &&
                <div className="loading-div">
                    <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                </div>
            }
            {state.errorFlag &&
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            }
            {state.chartsLoadedFlag && !state.errorFlag &&
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="module-title">
                            Cash Flow Walk
                        </div>
                        <div className="module-subtitle">
                            (in Millions)
                        </div>
                    </div>
                    <div className="btn-group w-100" style={{ paddingBottom: "5px" }}>
                        <button className="btn btn-primary" style={{ width: "100%" }} onClick={exportToPdfWrapper}>Export to PDF</button>
                    </div>
                    <div className="module-chart-container border-on-top">
                        <Plot
                            data={state.cashFlowWalkChartOptions}
                            layout={{
                                title: {
                                    text: "<b> <span style='text-decoration:underline;'>" + "Q4 Cash Flow Walk" + "</span> </b>",
                                    y: 0.97, x: 0.05
                                },
                                autosize: true,
                                xaxis: {
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    tickformat: '$~s',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true, displayModeBar: false, staticPlot: true }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ display: "none" }}>
                        <Plot
                            ref={plotlyRefChart}
                            data={state.cashFlowWalkChartOptions}
                            layout={{
                                title: 'Q2 Cash Flow Walk',
                                autosize: true,
                                xaxis: {
                                    tickfont: { size: 8 },
                                    title: {
                                        font: {
                                            size: 18,
                                        }
                                    }
                                },
                                yaxis: {
                                    tickformat: '$~s',
                                    title: {
                                        text: "",
                                        font: {
                                            size: 18,
                                        }
                                    }
                                }
                            }}
                            useResizeHandler
                            config={{ responsive: true }}
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
