import { useEffect, useState } from "react";
import { Projections } from '../../../components/financial-modeling-celeri/Projections';
import { DCF } from "../../../components/financial-modeling-celeri/DCF";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import '../Module.scss';
import Switch from "react-switch";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useLazyXlsxProcessorQuery, useXlsxProcessorQuery } from "../../../services/serverless";
import { Error } from "../../../components/Error";

interface FinancialModelingCeleriProps { }

interface FinancialModelingCeleriState {
    projectionsFirstStartingValue: number;
    projectionsSecondStartingValue: number;
    projectionsThirdStartingValue: number;
    projectionsFourthStartingValue: number;
    projectionsFifthStartingValue: number;
    projectionsSixthStartingValue: number;
    projectionsSeventhStartingValue: number;
    projectionsEigthStartingValue: number;
    projectionsNinthStartingValue: number;
    projectionsFirstValue: number;
    projectionsSecondValue: number;
    projectionsThirdValue: number;
    projectionsFourthValue: number;
    projectionsFifthValue: number;
    projectionsSixthValue: number;
    projectionsSeventhValue: number;
    projectionsEigthValue: number;
    projectionsNinthValue: number;
    dcfFirstValue: number;
    dcfSecondValue: number;
    dcfFirstStartingValue: number;
    dcfSecondStartingValue: number;
    firstCallFlag: boolean;
    secondCallFlag: boolean;
    initialDataLoadedFlag: boolean;
    chartsLoadedFlag: boolean;
    revenueChartOptions: Highcharts.Options;
    EBITDAChartOptions: Highcharts.Options;
    ebitChartOptions: Highcharts.Options;
    unleveredFreeCashFlowChartOptions: Highcharts.Options;
    EBITDAMarginChartOptions: Highcharts.Options;
    ebitMarginChartOptions: Highcharts.Options;
    waterfallChartOptions: Highcharts.Options;
    modeFlag: boolean;
    heatMap2024EBITDAChartOptions: Highcharts.Options;
    heatmap2024NetIncomeChartOptions: Highcharts.Options;
    heatmapDCFEquityMinus5ChartOptions: Highcharts.Options;
    heatmapDCFEquityEigthChartOptions: Highcharts.Options;
    isChartLoading: boolean;
    errorFlag: boolean;
    errorCode: string;
    errorType: string;
    errorMessage: string;
}

export const FinancialModelingCeleri = (props: FinancialModelingCeleriProps) => {
    const [state, setState] = useState<FinancialModelingCeleriState>({
        projectionsFirstStartingValue: 0,
        projectionsSecondStartingValue: 0,
        projectionsThirdStartingValue: 0,
        projectionsFourthStartingValue: 0,
        projectionsFifthStartingValue: 0,
        projectionsSixthStartingValue: 0,
        projectionsSeventhStartingValue: 0,
        projectionsEigthStartingValue: 0,
        projectionsNinthStartingValue: 0,
        projectionsFirstValue: 0,
        projectionsSecondValue: 0,
        projectionsThirdValue: 0,
        projectionsFourthValue: 0,
        projectionsFifthValue: 0,
        projectionsSixthValue: 0,
        projectionsSeventhValue: 0,
        projectionsEigthValue: 0,
        projectionsNinthValue: 0,
        dcfFirstStartingValue: 0,
        dcfSecondStartingValue: 0,
        dcfFirstValue: 0,
        dcfSecondValue: 0,
        firstCallFlag: false,
        secondCallFlag: false,
        initialDataLoadedFlag: false,
        chartsLoadedFlag: false,
        revenueChartOptions: {},
        EBITDAChartOptions: {},
        ebitChartOptions: {},
        unleveredFreeCashFlowChartOptions: {},
        EBITDAMarginChartOptions: {},
        ebitMarginChartOptions: {},
        waterfallChartOptions: {},
        modeFlag: false,
        heatMap2024EBITDAChartOptions: {},
        heatmap2024NetIncomeChartOptions: {},
        heatmapDCFEquityMinus5ChartOptions: {},
        heatmapDCFEquityEigthChartOptions: {},
        isChartLoading: false,
        errorFlag: false,
        errorCode: "0",
        errorType: "",
        errorMessage: "",
    });
    const [xlsxProcessorQuery, xlsxProcessorQueryResult] = useLazyXlsxProcessorQuery();
    const initialData = useXlsxProcessorQuery({
        "DataSource": "Core.Celeris_Financial_Model_v_Live.xlsx",
        "InputSheetName": null,
        "Inputs": [],
        "OutputSheetName": null,
        "Outputs": [
            { "SheetName": "Inputs", "RowIndex": 3, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 4, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 5, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 6, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 7, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 8, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 9, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 10, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 11, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 14, "CellIndex": 2, "Value": null },
            { "SheetName": "Inputs", "RowIndex": 15, "CellIndex": 2, "Value": null },
        ]
    })

    useEffect(() => {
        if (!state.firstCallFlag) {
            if (initialData.isSuccess && initialData.status === "fulfilled") {
                setState({
                    ...state,
                    firstCallFlag: true,
                    initialDataLoadedFlag: true,
                    projectionsFirstStartingValue: Math.round(initialData.data.Outputs[0].Value * 10000) / 100,
                    projectionsSecondStartingValue: Math.round(initialData.data.Outputs[1].Value * 10000) / 100,
                    projectionsThirdStartingValue: Math.round(initialData.data.Outputs[2].Value * 10000) / 100,
                    projectionsFourthStartingValue: Math.round(initialData.data.Outputs[3].Value * 10000) / 100,
                    projectionsFifthStartingValue: Math.round(initialData.data.Outputs[4].Value * 10000) / 100,
                    projectionsSixthStartingValue: Math.round(initialData.data.Outputs[5].Value * 10000) / 10000,
                    projectionsSeventhStartingValue: Math.round(initialData.data.Outputs[6].Value * 10000) / 10000,
                    projectionsEigthStartingValue: Math.round(initialData.data.Outputs[7].Value * 10000) / 100,
                    projectionsNinthStartingValue: Math.round(initialData.data.Outputs[8].Value * 10000) / 100,
                    dcfFirstStartingValue: Math.round(initialData.data.Outputs[9].Value * 10000) / 10000,
                    dcfSecondStartingValue: Math.round(initialData.data.Outputs[10].Value * 10000) / 100,
                    projectionsFirstValue: Math.round(initialData.data.Outputs[0].Value * 10000) / 100,
                    projectionsSecondValue: Math.round(initialData.data.Outputs[1].Value * 10000) / 100,
                    projectionsThirdValue: Math.round(initialData.data.Outputs[2].Value * 10000) / 100,
                    projectionsFourthValue: Math.round(initialData.data.Outputs[3].Value * 10000) / 100,
                    projectionsFifthValue: Math.round(initialData.data.Outputs[4].Value * 10000) / 100,
                    projectionsSixthValue: Math.round(initialData.data.Outputs[5].Value * 10000) / 10000,
                    projectionsSeventhValue: Math.round(initialData.data.Outputs[6].Value * 10000) / 10000,
                    projectionsEigthValue: Math.round(initialData.data.Outputs[7].Value * 10000) / 100,
                    projectionsNinthValue: Math.round(initialData.data.Outputs[8].Value * 10000) / 100,
                    dcfFirstValue: Math.round(initialData.data.Outputs[9].Value * 10000) / 10000,
                    dcfSecondValue: Math.round(initialData.data.Outputs[10].Value * 10000) / 100,
                    errorFlag: false
                });
            } else if (initialData.isError) {
                console.log('FAILED to initialize parameters');
                console.log(initialData.error);
                setState({
                    ...state,
                    errorFlag: true,
                    errorCode: "500",
                    errorType: "Internal",
                    errorMessage: "FAILED to initialize parameters",
                });
            }
        }
    }, [initialData]);

    useEffect(() => {
        if (xlsxProcessorQueryResult.isSuccess && xlsxProcessorQueryResult.status === 'fulfilled') {
            const data = xlsxProcessorQueryResult.data;
            setState((prevState) => ({ ...prevState, secondCallFlag: true }));

            const revenue: any[] = [];
            for (let i = 0; i < 5; i++) {
                revenue.push(Math.round(data.Outputs[i].Value));
            }

            setState((prevState) => ({
                ...prevState,
                revenueChartOptions: {
                    chart: {
                        type: 'column',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'Revenue (in Thousands)'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true,

                    },
                    yAxis: {
                        visible: false,
                        title: {
                            useHTML: true,
                            text: ''
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<b>${point.y:..2f}</b> USD',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0,
                            color: "#5dd5de",
                        }
                    },
                    series: [{
                        showInLegend: false,
                        name: 'Revenue (in Thousands)',
                        type: 'column',
                        data: revenue,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.y!, 0, ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    }]
                }
            }));

            const EBITDA: any[] = [];
            for (let i = 7; i < 12; i++) {
                EBITDA.push(Math.round(data.Outputs[i].Value));
            }

            setState((prevState) => ({
                ...prevState,
                EBITDAChartOptions: {
                    chart: {
                        type: 'column',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'EBITDA (in Thousands)'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true
                    },
                    yAxis: {

                        visible: false,
                        title: {
                            useHTML: true,
                            text: ''
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<b>${point.y:..2f}</b> USD',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0,
                            color: "#4ba1f2",
                        }
                    },
                    series: [{

                        showInLegend: false,
                        name: 'EBITDA (in Thousands)',
                        type: 'column',
                        data: EBITDA,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.y!, 0, ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    }]
                }
            }));

            const EBIT: any[] = [];
            for (let i = 14; i < 19; i++) {
                EBIT.push(Math.round(data.Outputs[i].Value));
            }

            setState((prevState) => ({
                ...prevState,
                ebitChartOptions: {
                    chart: {
                        type: 'column',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'EBIT (in Thousands)'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true
                    },
                    yAxis: {

                        visible: false,
                        title: {
                            useHTML: true,
                            text: ''
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<b>${point.y:..2f}</b> USD',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0,
                            color: "#22c9b0",
                        }
                    },
                    series: [{

                        showInLegend: false,
                        name: 'EBIT (in Thousands)',
                        type: 'column',
                        data: EBIT,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.y!, 0, ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    }]
                }
            }));

            const unleveredFreeCashFlow: any[] = [];
            for (let i = 42; i < 47; i++) {
                unleveredFreeCashFlow.push(Math.round(data.Outputs[i].Value));
            }

            setState((prevState) => ({
                ...prevState,
                unleveredFreeCashFlowChartOptions: {
                    chart: {
                        type: 'column',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'Unlevered Free Cash Flow (in Thousands)'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true
                    },
                    yAxis: {
                        visible: false,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        plotLines: [{
                            value: 0,
                            width: 1,
                            color: '#aaa',
                            zIndex: 10
                        }],
                        stackLabels: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<b>${point.y:..2f}</b> USD',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0,
                            color: "#08805d",
                        }
                    },
                    series: [{

                        showInLegend: false,
                        name: 'Unlevered Free Cash Flow (in Thousands)',
                        type: 'column',
                        data: unleveredFreeCashFlow,
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.y!, 0, ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        }
                    }]
                }
            }));

            const EBITDAMargin: any[] = [];
            for (let i = 49; i < 54; i++) {
                EBITDAMargin.push(data.Outputs[i].Value * 100);
            }

            setState((prevState) => ({
                ...prevState,
                EBITDAMarginChartOptions: {
                    chart: {
                        type: 'line',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'EBITDA Margin'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true
                    },
                    yAxis: {
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        plotLines: [{
                            value: 0,
                            width: 1,
                            color: '#aaa',
                            zIndex: 10
                        }],
                        stackLabels: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        line: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return this.y!.toFixed(1) + "%";
                                }
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [{

                        showInLegend: false,
                        name: 'EBITDA Margin',
                        type: 'line',
                        data: EBITDAMargin,
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        }

                    }]
                }
            }));

            const ebitMargin: any[] = [];
            for (let i = 56; i < 61; i++) {
                ebitMargin.push(data.Outputs[i].Value * 100);
            }

            setState((prevState) => ({
                ...prevState,
                ebitMarginChartOptions: {
                    chart: {
                        type: 'line',
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'EBIT Margin'
                    },
                    xAxis: {
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                        categories: [
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024'
                        ],
                        crosshair: true
                    },
                    yAxis: {
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        plotLines: [{
                            value: 0,
                            width: 1,
                            color: '#aaa',
                            zIndex: 10
                        }],
                        stackLabels: {
                            enabled: true,
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key} :</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointWidth: 75,
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        line: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    return this.y!.toFixed(1) + "%";
                                }
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [{
                        showInLegend: false,
                        name: 'EBIT Margin',
                        type: 'line',
                        data: ebitMargin,
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                    }]
                }
            }));

            const equity: any[] = [];
            for (let i = 83; i < 88; i++) {
                equity.push(Math.round(data.Outputs[i].Value));
            }

            setState((prevState) => ({
                ...prevState,
                waterfallChartOptions: {
                    chart: {
                        type: 'waterfall',
                        backgroundColor: "#FFFFFF"
                    },

                    title: {
                        text: 'Equity'
                    },

                    xAxis: {
                        type: 'category',
                        lineWidth: 3,
                        lineColor: "#000000",
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    yAxis: {
                        visible: false,
                        title: {
                            text: 'USD'
                        }
                    },

                    legend: {
                        enabled: false
                    },

                    tooltip: {
                        pointFormat: '<b>${point.y:..2f}</b> USD'
                    },

                    series: [{
                        type: "waterfall",
                        upColor: "#4273E8",

                        data: [{
                            name: 'Terminal Value',
                            y: equity[0],
                        }, {
                            name: 'Free Cash Flow',
                            y: equity[1],
                        }, {
                            name: 'Enterprise Value',
                            isIntermediateSum: true,
                            color: "#9c9c9c",
                        }, {
                            name: 'Net Debt',
                            y: equity[3],
                            color: "#861bf2",
                        }, {
                            name: 'Market Value of Equity',
                            isSum: true,
                            color: "#9c9c9c",
                        }],
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.y!, 0, ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                        pointPadding: 0
                    }]
                }
            }));

            const EBITDASensitivity2024: any[] = [];
            for (let i = 88; i < 113; i++) {
                EBITDASensitivity2024.push(data.Outputs[i].Value);
                //console.log("EBITDASensitivity2024 " + i + ": " + data.Outputs[i].Value);
            }

            const NetIncomeSensitivity2024: any[] = [];
            for (let i = 113; i < 138; i++) {
                NetIncomeSensitivity2024.push(data.Outputs[i].Value);
                //console.log("NetIncomeSensitivity2024 " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityMinus5: any[] = [];
            for (let i = 138; i < 163; i++) {
                DCFEquityMinus5.push(data.Outputs[i].Value);
                //console.log("DCFEquityMinus5 " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityEigth: any[] = [];
            for (let i = 163; i < 188; i++) {
                DCFEquityEigth.push(data.Outputs[i].Value);
                //console.log("DCFEquityEigth " + i + ": " + data.Outputs[i].Value);
            }

            const EBITDASensitivity2024XLabels: any[] = [];
            for (let i = 188; i < 193; i++) {
                EBITDASensitivity2024XLabels.push((Math.round(data.Outputs[i].Value * 10000) / 100));
                //console.log("EBITDASensitivity2024XLabels " + i + ": " + data.Outputs[i].Value);
            }

            const EBITDASensitivity2024YLabels: any[] = [];
            for (let i = 193; i < 198; i++) {
                EBITDASensitivity2024YLabels.push((Math.round(data.Outputs[i].Value * 10000) / 100));
                //console.log("EBITDASensitivity2024YLabels " + i + ": " + data.Outputs[i].Value);
            }

            const NetIncomeSensitivity2024XLabels: any[] = [];
            for (let i = 198; i < 203; i++) {
                NetIncomeSensitivity2024XLabels.push(Math.round(data.Outputs[i].Value * 10000) / 100);
                //console.log("NetIncomeSensitivity2024XLabels " + i + ": " + data.Outputs[i].Value);
            }

            const NetIncomeSensitivity2024YLabels: any[] = [];
            for (let i = 203; i < 208; i++) {
                NetIncomeSensitivity2024YLabels.push(Math.round(data.Outputs[i].Value * 10000) / 100);
                //console.log("NetIncomeSensitivity2024YLabels " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityMinus5XLabels: any[] = [];
            for (let i = 208; i < 213; i++) {
                DCFEquityMinus5XLabels.push(data.Outputs[i].Value);
                //console.log("DCFEquityMinus5XLabels " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityMinus5YLabels: any[] = [];
            for (let i = 213; i < 218; i++) {
                DCFEquityMinus5YLabels.push(Math.round(data.Outputs[i].Value * 10000) / 100);
                //console.log("DCFEquityMinus5YLabels " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityEigthXLabels: any[] = [];
            for (let i = 218; i < 223; i++) {
                DCFEquityEigthXLabels.push(Math.round(data.Outputs[i].Value * 10000) / 100);
                //console.log("DCFEquityEigthXLabels " + i + ": " + data.Outputs[i].Value);
            }

            const DCFEquityEigthYLabels: any[] = [];
            for (let i = 223; i < 228; i++) {
                DCFEquityEigthYLabels.push(Math.round(data.Outputs[i].Value * 10000) / 100);
                //console.log("DCFEquityEigthYLabels " + i + ": " + data.Outputs[i].Value);
            }

            setState((prevState) => ({
                ...prevState,
                heatMap2024EBITDAChartOptions: {

                    chart: {
                        type: 'heatmap',
                        marginTop: 40,
                        marginBottom: 80,
                        plotBorderWidth: 1,
                        backgroundColor: "#FFFFFF"
                    },
                    tooltip: {
                        enabled: false
                    },

                    title: {
                        text: '2024 EBITDA Sensitivity (in Thousands)'
                    },

                    xAxis: {
                        categories: [EBITDASensitivity2024XLabels[0] + '%', EBITDASensitivity2024XLabels[1] + '%', EBITDASensitivity2024XLabels[2] + '%', EBITDASensitivity2024XLabels[3] + '%', EBITDASensitivity2024XLabels[4] + '%'],
                        title: {
                            text: 'Revenue Growth'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    yAxis: {
                        categories: [EBITDASensitivity2024YLabels[4] + '%', EBITDASensitivity2024YLabels[3] + '%', EBITDASensitivity2024YLabels[2] + '%', EBITDASensitivity2024YLabels[1] + '%', EBITDASensitivity2024YLabels[0] + '%'],
                        title: {
                            text: 'Opex (% of Rev.)'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    colorAxis: {
                        min: 0,
                        minColor: '#e1eaf9',
                        maxColor: '#3571c2'
                    },

                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },

                    series: [{
                        type: 'heatmap',
                        name: 'Revenue Growth',
                        borderWidth: 1,
                        data: [[0, 4, EBITDASensitivity2024[0]], [1, 4, EBITDASensitivity2024[1]], [2, 4, EBITDASensitivity2024[2]], [3, 4, EBITDASensitivity2024[3]], [4, 4, EBITDASensitivity2024[4]],
                        [0, 3, EBITDASensitivity2024[5]], [1, 3, EBITDASensitivity2024[6]], [2, 3, EBITDASensitivity2024[7]], [3, 3, EBITDASensitivity2024[8]], [4, 3, EBITDASensitivity2024[9]],
                        [0, 2, EBITDASensitivity2024[10]], [1, 2, EBITDASensitivity2024[11]], [2, 2, EBITDASensitivity2024[12]], [3, 2, EBITDASensitivity2024[13]], [4, 2, EBITDASensitivity2024[14]],
                        [0, 1, EBITDASensitivity2024[15]], [1, 1, EBITDASensitivity2024[16]], [2, 1, EBITDASensitivity2024[17]], [3, 1, EBITDASensitivity2024[18]], [4, 1, EBITDASensitivity2024[19]],
                        [0, 0, EBITDASensitivity2024[20]], [1, 0, EBITDASensitivity2024[21]], [2, 0, EBITDASensitivity2024[22]], [3, 0, EBITDASensitivity2024[23]], [4, 0, EBITDASensitivity2024[24]]],
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.point.value!, 0, '.', ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                    }]

                }
            }));

            setState((prevState) => ({
                ...prevState,
                heatmap2024NetIncomeChartOptions: {

                    chart: {
                        type: 'heatmap',
                        marginTop: 40,
                        marginBottom: 80,
                        plotBorderWidth: 1,
                        backgroundColor: "#FFFFFF"
                    },

                    tooltip: {
                        enabled: false
                    },

                    title: {
                        text: '2024 Net Income Sensitivity (in Thousands)'
                    },

                    xAxis: {
                        categories: [NetIncomeSensitivity2024XLabels[0] + '%', NetIncomeSensitivity2024XLabels[1] + '%', NetIncomeSensitivity2024XLabels[2] + '%', NetIncomeSensitivity2024XLabels[3] + '%', NetIncomeSensitivity2024XLabels[4] + '%'],
                        title: {
                            text: 'Revenue Growth'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    yAxis: {
                        categories: [NetIncomeSensitivity2024YLabels[4] + '%', NetIncomeSensitivity2024YLabels[3] + '%', NetIncomeSensitivity2024YLabels[2] + '%', NetIncomeSensitivity2024YLabels[1] + '%', NetIncomeSensitivity2024YLabels[0] + '%'],
                        title: {
                            text: 'Opex (% of Rev.)'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    colorAxis: {
                        min: 0,
                        minColor: '#e1eaf9',
                        maxColor: '#3571c2'
                    },

                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },

                    series: [{
                        type: 'heatmap',
                        name: 'Revenue Growth',
                        borderWidth: 1,
                        data: [[0, 4, NetIncomeSensitivity2024[0]], [1, 4, NetIncomeSensitivity2024[1]], [2, 4, NetIncomeSensitivity2024[2]], [3, 4, NetIncomeSensitivity2024[3]], [4, 4, NetIncomeSensitivity2024[4]],
                        [0, 3, NetIncomeSensitivity2024[5]], [1, 3, NetIncomeSensitivity2024[6]], [2, 3, NetIncomeSensitivity2024[7]], [3, 3, NetIncomeSensitivity2024[8]], [4, 3, NetIncomeSensitivity2024[9]],
                        [0, 2, NetIncomeSensitivity2024[10]], [1, 2, NetIncomeSensitivity2024[11]], [2, 2, NetIncomeSensitivity2024[12]], [3, 2, NetIncomeSensitivity2024[13]], [4, 2, NetIncomeSensitivity2024[14]],
                        [0, 1, NetIncomeSensitivity2024[15]], [1, 1, NetIncomeSensitivity2024[16]], [2, 1, NetIncomeSensitivity2024[17]], [3, 1, NetIncomeSensitivity2024[18]], [4, 1, NetIncomeSensitivity2024[19]],
                        [0, 0, NetIncomeSensitivity2024[20]], [1, 0, NetIncomeSensitivity2024[21]], [2, 0, NetIncomeSensitivity2024[22]], [3, 0, NetIncomeSensitivity2024[23]], [4, 0, NetIncomeSensitivity2024[24]]],
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.point.value!, 0, '.', ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                    }]

                }
            }));

            setState((prevState) => ({
                ...prevState,
                heatmapDCFEquityMinus5ChartOptions: {

                    chart: {
                        type: 'heatmap',
                        marginTop: 40,
                        marginBottom: 80,
                        plotBorderWidth: 1,
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'DCF Equity Value Sensitivity (in Thousands)'
                    },

                    tooltip: {
                        enabled: false
                    },

                    xAxis: {
                        categories: [DCFEquityMinus5XLabels[0] + 'x', DCFEquityMinus5XLabels[1] + 'x', DCFEquityMinus5XLabels[2] + 'x', DCFEquityMinus5XLabels[3] + 'x', DCFEquityMinus5XLabels[4] + 'x'],
                        title: {
                            text: 'EBITDA TV Multiple'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    yAxis: {
                        categories: [DCFEquityMinus5YLabels[4] + '%', DCFEquityMinus5YLabels[3] + '%', DCFEquityMinus5YLabels[2] + '%', DCFEquityMinus5YLabels[1] + '%', DCFEquityMinus5YLabels[0] + '%'],
                        title: {
                            text: 'Opex (% of Rev.)'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    colorAxis: {
                        min: 0,
                        minColor: '#e1eaf9',
                        maxColor: '#3571c2'
                    },

                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },

                    series: [{
                        type: 'heatmap',
                        name: 'Revenue Growth',
                        borderWidth: 1,
                        data: [[0, 4, DCFEquityMinus5[0]], [1, 4, DCFEquityMinus5[1]], [2, 4, DCFEquityMinus5[2]], [3, 4, DCFEquityMinus5[3]], [4, 4, DCFEquityMinus5[4]],
                        [0, 3, DCFEquityMinus5[5]], [1, 3, DCFEquityMinus5[6]], [2, 3, DCFEquityMinus5[7]], [3, 3, DCFEquityMinus5[8]], [4, 3, DCFEquityMinus5[9]],
                        [0, 2, DCFEquityMinus5[10]], [1, 2, DCFEquityMinus5[11]], [2, 2, DCFEquityMinus5[12]], [3, 2, DCFEquityMinus5[13]], [4, 2, DCFEquityMinus5[14]],
                        [0, 1, DCFEquityMinus5[15]], [1, 1, DCFEquityMinus5[16]], [2, 1, DCFEquityMinus5[17]], [3, 1, DCFEquityMinus5[18]], [4, 1, DCFEquityMinus5[19]],
                        [0, 0, DCFEquityMinus5[20]], [1, 0, DCFEquityMinus5[21]], [2, 0, DCFEquityMinus5[22]], [3, 0, DCFEquityMinus5[23]], [4, 0, DCFEquityMinus5[24]]],
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.point.value!, 0, '.', ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                    }]

                }
            }));

            setState((prevState) => ({
                ...prevState,
                heatmapDCFEquityEigthChartOptions: {

                    chart: {
                        type: 'heatmap',
                        marginTop: 40,
                        marginBottom: 80,
                        plotBorderWidth: 1,
                        backgroundColor: "#FFFFFF"
                    },
                    title: {
                        text: 'DCF Equity Value Sensitivity (in Thousands)'
                    },

                    tooltip: {
                        enabled: false
                    },

                    xAxis: {
                        categories: [DCFEquityEigthXLabels[0] + '%', DCFEquityEigthXLabels[1] + '%', DCFEquityEigthXLabels[2] + '%', DCFEquityEigthXLabels[3] + '%', DCFEquityEigthXLabels[4] + '%'],
                        title: {
                            text: 'Revenue Growth'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    yAxis: {
                        categories: [DCFEquityEigthYLabels[4] + '%', DCFEquityEigthYLabels[3] + '%', DCFEquityEigthYLabels[2] + '%', DCFEquityEigthYLabels[1] + '%', DCFEquityEigthYLabels[0] + '%'],
                        title: {
                            text: 'Opex (% of Rev.)'
                        },
                        labels: {
                            style: {
                                fontSize: "11",
                                fontWeight: "bold",
                            },
                        },
                    },

                    colorAxis: {
                        min: 0,
                        minColor: '#e1eaf9',
                        maxColor: '#3571c2'
                    },

                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },

                    series: [{
                        type: 'heatmap',
                        name: 'Revenue Growth',
                        borderWidth: 1,
                        data: [[0, 4, DCFEquityEigth[0]], [1, 4, DCFEquityEigth[1]], [2, 4, DCFEquityEigth[2]], [3, 4, DCFEquityEigth[3]], [4, 4, DCFEquityEigth[4]],
                        [0, 3, DCFEquityEigth[5]], [1, 3, DCFEquityEigth[6]], [2, 3, DCFEquityEigth[7]], [3, 3, DCFEquityEigth[8]], [4, 3, DCFEquityEigth[9]],
                        [0, 2, DCFEquityEigth[10]], [1, 2, DCFEquityEigth[11]], [2, 2, DCFEquityEigth[12]], [3, 2, DCFEquityEigth[13]], [4, 2, DCFEquityEigth[14]],
                        [0, 1, DCFEquityEigth[15]], [1, 1, DCFEquityEigth[16]], [2, 1, DCFEquityEigth[17]], [3, 1, DCFEquityEigth[18]], [4, 1, DCFEquityEigth[19]],
                        [0, 0, DCFEquityEigth[20]], [1, 0, DCFEquityEigth[21]], [2, 0, DCFEquityEigth[22]], [3, 0, DCFEquityEigth[23]], [4, 0, DCFEquityEigth[24]]],
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            formatter: function () {
                                return "$" + Highcharts.numberFormat(this.point.value!, 0, '.', ',');
                            },
                            style: {
                                fontWeight: 'bold'
                            }
                        },
                    }]

                }
            }));

            setState((prevState) => ({ ...prevState, chartsLoadedFlag: true,
                errorFlag: false }));
        }
        else if (xlsxProcessorQueryResult.isError) {
            console.log('FAILED to initialize parameters');
            console.log(xlsxProcessorQueryResult.error);
            setState({
                ...state,
                errorFlag: true,
                errorCode: "500",
                errorType: "Internal",
                errorMessage: "FAILED to initialize parameters",
            });
        }
        setState((prevState) => ({ ...prevState, isChartLoading: false }));
    }, [xlsxProcessorQueryResult])

    async function sendDataBuildCharts() {
        //if (!this.state.secondCallFlag) {
        setState({ ...state, chartsLoadedFlag: false, isChartLoading: true });
        Highcharts.setOptions({
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            }
        });
        // quarter format function 

        Highcharts.dateFormats = {
            q: function (timestamp) {
                var date = new Date(timestamp);
                const quarter = (Math.floor(date.getUTCMonth() / 3) + 1);
                return quarter.toString(10);
            }
        };

        HC_more(Highcharts);
        HighchartsHeatmap(Highcharts);
        xlsxProcessorQuery({
            "DataSource": "Core.Celeris_Financial_Model_v_Live.xlsx",
            "InputSheetName": null,
            "Inputs": [
                {
                    "SheetName": "Inputs",
                    "RowIndex": 3,
                    "CellIndex": 2,
                    "Value": state.projectionsFirstValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 4,
                    "CellIndex": 2,
                    "Value": state.projectionsSecondValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 5,
                    "CellIndex": 2,
                    "Value": state.projectionsThirdValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 6,
                    "CellIndex": 2,
                    "Value": state.projectionsFourthValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 7,
                    "CellIndex": 2,
                    "Value": state.projectionsFifthValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 8,
                    "CellIndex": 2,
                    "Value": state.projectionsSixthValue + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 9,
                    "CellIndex": 2,
                    "Value": state.projectionsSeventhValue + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 10,
                    "CellIndex": 2,
                    "Value": state.projectionsEigthValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 11,
                    "CellIndex": 2,
                    "Value": state.projectionsNinthValue / 100 + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 14,
                    "CellIndex": 2,
                    "Value": state.dcfFirstValue + .000001
                },
                {
                    "SheetName": "Inputs",
                    "RowIndex": 15,
                    "CellIndex": 2,
                    "Value": state.dcfSecondValue / 100 + .000001
                }
            ],
            "OutputSheetName": null,
            "Outputs": [
                {// Revenue
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 8,
                    "CellIndex": 31,
                    "Value": null
                },
                {// EBITDA
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 9,
                    "CellIndex": 31,
                    "Value": null
                },
                { // EBIT
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 10,
                    "CellIndex": 31,
                    "Value": null
                },
                {// D&A
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 11,
                    "CellIndex": 31,
                    "Value": null
                },
                {// CapEx
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 12,
                    "CellIndex": 31,
                    "Value": null
                },
                {// Changes in Working Capital
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 13,
                    "CellIndex": 31,
                    "Value": null
                },
                { // Unlevered Free Cash Flow
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 14,
                    "CellIndex": 31,
                    "Value": null
                },
                { // EBITDA Margin
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 15,
                    "CellIndex": 31,
                    "Value": null
                },
                {// EBIT Margin
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 29,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 30,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 16,
                    "CellIndex": 31,
                    "Value": null
                },
                { // Quick Ratio
                    "SheetName": "outputs",
                    "RowIndex": 17,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 17,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 17,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 17,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 17,
                    "CellIndex": 29,
                    "Value": null
                },
                { // Interest Coverage
                    "SheetName": "outputs",
                    "RowIndex": 18,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 18,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 18,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 18,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 18,
                    "CellIndex": 29,
                    "Value": null
                },
                { // Debt / EBITDA
                    "SheetName": "outputs",
                    "RowIndex": 19,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 19,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 19,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 19,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 19,
                    "CellIndex": 29,
                    "Value": null
                },
                {// ROE
                    "SheetName": "outputs",
                    "RowIndex": 20,
                    "CellIndex": 25,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 20,
                    "CellIndex": 26,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 20,
                    "CellIndex": 27,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 20,
                    "CellIndex": 28,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 20,
                    "CellIndex": 29,
                    "Value": null
                },
                { // Equity
                    "SheetName": "outputs",
                    "RowIndex": 26,
                    "CellIndex": 33,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 27,
                    "CellIndex": 33,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 28,
                    "CellIndex": 33,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 29,
                    "CellIndex": 33,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 30,
                    "CellIndex": 33,
                    "Value": null
                },
                { //2024 EBITDA Sensitivity
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 37,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 38,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 39,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 40,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 41,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 37,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 38,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 39,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 40,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 41,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 37,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 38,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 39,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 40,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 41,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 37,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 38,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 39,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 40,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 41,
                    "Value": null
                },
                {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 41,
                    "Value": null
                }, { //2024 Net Income Sensitivity
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 49,
                    "Value": null
                }, { //DCF Equity Value Sensitivity @ -5.0% YoY Rev Growth
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 41,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 41,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 41,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 41,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 41,
                    "Value": null
                }, { //DCF Equity Value Sensitivity @8.0x TV EBITDA Multiple
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 49,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 49,
                    "Value": null
                }, { //2024 EBITDA Sensitivity X Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 41,
                    "Value": null
                }, { //2024 EBITDA Sensitivity Y Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 36,
                    "Value": null
                }, { //2024 Net Income Sensitivity X Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 36,
                    "CellIndex": 49,
                    "Value": null
                }, { //2024 Net Income Sensitivity Y Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 37,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 38,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 39,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 40,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 41,
                    "CellIndex": 44,
                    "Value": null
                }, { //DCF Equity Sensitivity -5 X Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 37,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 38,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 39,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 40,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 41,
                    "Value": null
                }, { //DCF Equity Sensitivity -5 Y Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 36,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 36,
                    "Value": null
                }, { //DCF Equity Sensitivity +8 X Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 45,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 46,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 47,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 48,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 47,
                    "CellIndex": 49,
                    "Value": null
                }, { //DCF Equity Sensitivity +8 Y Axis Labels
                    "SheetName": "outputs",
                    "RowIndex": 48,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 49,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 50,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 51,
                    "CellIndex": 44,
                    "Value": null
                }, {
                    "SheetName": "outputs",
                    "RowIndex": 52,
                    "CellIndex": 44,
                    "Value": null
                },
            ]
        });
    }


    /*
        async function initializeInputs() {
            if (!state.firstCallFlag) {
                xlsxProcessor({
                    "DataSource": "Core.Celeris_Financial_Model_v_Live.xlsx",
                    "InputSheetName": null,
                    "Inputs": [],
                    "OutputSheetName": null,
                    "Outputs": [
                        { "SheetName": "Inputs", "RowIndex": 3, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 4, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 5, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 6, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 7, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 8, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 9, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 10, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 11, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 14, "CellIndex": 2, "Value": null },
                        { "SheetName": "Inputs", "RowIndex": 15, "CellIndex": 2, "Value": null },
                    ]
                }).then(data => {
                    setState({
                        ...state,
                        firstCallFlag: true,
                        initialDataLoadedFlag: true,
                        projectionsFirstStartingValue: Math.round(data.Outputs[0].Value * 10000) / 100,
                        projectionsSecondStartingValue: Math.round(data.Outputs[1].Value * 10000) / 100,
                        projectionsThirdStartingValue: Math.round(data.Outputs[2].Value * 10000) / 100,
                        projectionsFourthStartingValue: Math.round(data.Outputs[3].Value * 10000) / 100,
                        projectionsFifthStartingValue: Math.round(data.Outputs[4].Value * 10000) / 100,
                        projectionsSixthStartingValue: Math.round(data.Outputs[5].Value * 10000) / 10000,
                        projectionsSeventhStartingValue: Math.round(data.Outputs[6].Value * 10000) / 10000,
                        projectionsEigthStartingValue: Math.round(data.Outputs[7].Value * 10000) / 100,
                        projectionsNinthStartingValue: Math.round(data.Outputs[8].Value * 10000) / 100,
                        dcfFirstStartingValue: Math.round(data.Outputs[9].Value * 10000) / 10000,
                        dcfSecondStartingValue: Math.round(data.Outputs[10].Value * 10000) / 100,
                        projectionsFirstValue: Math.round(data.Outputs[0].Value * 10000) / 100,
                        projectionsSecondValue: Math.round(data.Outputs[1].Value * 10000) / 100,
                        projectionsThirdValue: Math.round(data.Outputs[2].Value * 10000) / 100,
                        projectionsFourthValue: Math.round(data.Outputs[3].Value * 10000) / 100,
                        projectionsFifthValue: Math.round(data.Outputs[4].Value * 10000) / 100,
                        projectionsSixthValue: Math.round(data.Outputs[5].Value * 10000) / 10000,
                        projectionsSeventhValue: Math.round(data.Outputs[6].Value * 10000) / 10000,
                        projectionsEigthValue: Math.round(data.Outputs[7].Value * 10000) / 100,
                        projectionsNinthValue: Math.round(data.Outputs[8].Value * 10000) / 100,
                        dcfFirstValue: Math.round(data.Outputs[9].Value * 10000) / 10000,
                        dcfSecondValue: Math.round(data.Outputs[10].Value * 10000) / 100,
                    });
                }).catch(err => {
                    console.log('FAILED to initialize parameters');
                    console.log(err);
                });
            }
        }
    */
    function handleProjectionsValues(firstValue: number, secondValue: number, thirdValue: number,
        fourthValue: number, fifthValue: number, sixthValue: number,
        seventhValue: number, eigthValue: number, ninthValue: number) {
        setState({
            ...state,
            projectionsFirstValue: firstValue,
            projectionsSecondValue: secondValue,
            projectionsThirdValue: thirdValue,
            projectionsFourthValue: fourthValue,
            projectionsFifthValue: fifthValue,
            projectionsSixthValue: sixthValue,
            projectionsSeventhValue: seventhValue,
            projectionsEigthValue: eigthValue,
            projectionsNinthValue: ninthValue,
        });
    }

    function handleDCFValues(firstValue: number, secondValue: number) {
        setState({
            ...state,
            dcfFirstValue: firstValue,
            dcfSecondValue: secondValue,
        });
    }

    return (
        <div className="module-master">
            <div className="module-title">Financial Model</div>
            <div className="module-row" style={{ display: (state.errorFlag) ? "block" : "none" }}>
                <Error code={state.errorCode} type={state.errorType} message={state.errorMessage} />
            </div>
            <div className="module-row" style={{ display: (state.errorFlag) ? "none" : "block" }}>
                {!state.initialDataLoadedFlag ? (
                    <div className="loading-div">
                        <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                    </div>
                ) : (
                    <div style={{ minWidth: "fit-content" }}>
                        <div style={{ textAlign: "center" }} data-toggle="buttons">
                            <button className="btn btn-primary" onClick={sendDataBuildCharts}>Run Model</button>
                        </div>
                        <div>
                            <Projections firstStartingValue={state.projectionsFirstStartingValue} secondStartingValue={state.projectionsSecondStartingValue} thirdStartingValue={state.projectionsThirdStartingValue} fourthStartingValue={state.projectionsFourthStartingValue} fifthStartingValue={state.projectionsFifthStartingValue} sixthStartingValue={state.projectionsSixthStartingValue} seventhStartingValue={state.projectionsSeventhStartingValue} eightStartingValue={state.projectionsEigthStartingValue} ninthStartingValue={state.projectionsNinthStartingValue} measure={"%"} symbol={"$"} title={"Projection Input"} firstLabel={"Revenue Growth"} secondLabel={"OpEx"} thirdLabel={"Tax"} fourthLabel={"CapEx"} fifthLabel={"D&A"} sixthLabel={"Min Cash"} seventhLabel={"QDR"} eigthLabel={"Debt Rate"} ninthLabel={"Cash Yield"} showLabels={true} onValuesChange={handleProjectionsValues} />
                        </div>
                        <div style={{ textAlign: "center" }} data-toggle="buttons">
                            <div>Enable DCF</div>
                            <div><Switch onChange={(e) => setState({ ...state, modeFlag: !state.modeFlag })} checked={state.modeFlag} checkedIcon={false} uncheckedIcon={false} /></div>
                        </div>
                        <div style={{ display: (state.modeFlag ? 'block' : 'none') }}>
                            <DCF firstStartingValue={state.dcfFirstStartingValue} secondStartingValue={state.dcfSecondStartingValue} measure={"%"} symbol={"x"} title={"DCF"} firstLabel={"Exit Multiple"} secondLabel={"WACC"} showLabels={true} onValueChange={handleDCFValues} />
                        </div>
                    </div>
                )
                }

                {!state.chartsLoadedFlag ? (
                    <div className="loading-div">
                        {state.isChartLoading && (
                            <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                        )}
                    </div>
                ) : (
                    <div style={{ width: "100%" }}>
                        <div className="module-row">
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.revenueChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />

                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.EBITDAChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div className="module-row">
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.ebitChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />

                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.unleveredFreeCashFlowChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div className="module-row">
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.EBITDAMarginChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />

                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.ebitMarginChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div style={{ display: (!state.modeFlag ? 'block' : 'none') }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.heatMap2024EBITDAChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div style={{ display: (!state.modeFlag ? 'block' : 'none') }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.heatmap2024NetIncomeChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div style={{ display: (state.modeFlag ? 'block' : 'none') }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.waterfallChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div style={{ display: (state.modeFlag ? 'block' : 'none') }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.heatmapDCFEquityMinus5ChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                        <div style={{ display: (state.modeFlag ? 'block' : 'none') }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={state.heatmapDCFEquityEigthChartOptions}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
