interface EnvConfig {
    /**
     * API endpoint
     */
    backendApiEndpoint: string;

    /**
     * BO endpoint
     */
    adminConsoleEndpoint: string;

    /**
     * Static website endpoint
     */
    staticSiteEndpoint: string;

    useCognitoOidcLogin?: boolean;

    amplifyApiEndpoint?: string;

    thoughtspotHost: string;
}

const thoughtSpotHostProd = 'https://thoughtspot.gallusanalytics.com';

const envs: {
    prod: EnvConfig;
    dev: EnvConfig;
    local: EnvConfig;
} = {
    prod: {
        backendApiEndpoint: 'https://api-v3.gallusanalytics.com',
        adminConsoleEndpoint: 'http://bo.gallusanalytics.com',
        staticSiteEndpoint: 'https://gallusinsights.co',
        amplifyApiEndpoint: 'https://gin-v2-amplify-api.gallusanalytics.com',
        thoughtspotHost: thoughtSpotHostProd
    },
    dev: {
        backendApiEndpoint: 'https://api-v3-stage.gallusanalytics.com',
        adminConsoleEndpoint: 'http://bo-stage.gallusanalytics.com/GallusBO/login.php',
        staticSiteEndpoint: 'https://gallusinsights.co',
        amplifyApiEndpoint: 'https://gin-v2-amplify-api-dev.gallusanalytics.com',
        thoughtspotHost: thoughtSpotHostProd
    },
    local: {
        backendApiEndpoint: 'https://api-v3-test.gallusanalytics.com',
        adminConsoleEndpoint: 'http://bo-v3.gallusanalytics.com/GallusBO/login.php',
        staticSiteEndpoint: 'https://gallusinsights.co',
        useCognitoOidcLogin: false,
        amplifyApiEndpoint: 'https://gin-v2-amplify-api-dev.gallusanalytics.com',
        thoughtspotHost: thoughtSpotHostProd
    }
}

const nodeEnvMapping = {
    'production': 'prod',
    'development': 'local',
    'test': 'local'
}

const finalEnv = (process.env.REACT_APP_ENV ?? nodeEnvMapping[process.env.NODE_ENV]) as 'prod' | 'dev' | 'local';

console.log(finalEnv, envs[finalEnv]);

export const environmentConfig = {
    useCognitoOidcLogin: process.env.REACT_APP_USE_COGNITO_OIDC_LOGIN?.toLowerCase() === 'true' ?? true,
    ...envs[finalEnv],
};
