import { tsDataObject } from "../../../services/types/gallus";

export const GrateMBAMoneyQ3: tsDataObject = {
  "data": [
    [
      null,
      "Spacing",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      {value: "10426", overrideEncoding: "$sk0d-S"},
      {value: "9608", overrideEncoding: "$sk0d-S"},
      {value: "10983", overrideEncoding: "$sk0d-S"},
      {value: "11229", overrideEncoding: "$sk0d-S"}
    ],
    [
      "Expenses",
      "Title",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Sales Personnel",
      null,
      null,
      {value: "3149", overrideEncoding: "$sk0d-S"},
      {value: "2986", overrideEncoding: "$sk0d-S"},
      {value: "3470", overrideEncoding: "$sk0d-S"},
      {value: "3542", overrideEncoding: "$sk0d-S"}
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      "1580",
      "1406",
      "1500",
      "1416"
    ],
    [
      "Production Support Employees",
      null,
      null,
      "823",
      "563",
      "871",
      "768"
    ],
    [
      "Management and Directors",
      null,
      null,
      "583",
      "537",
      "613",
      "596"
    ],
    [
      "Benefits",
      null,
      null,
      "959",
      "820",
      "1023",
      "1056"
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      {value: "7095", overrideEncoding: "$sk0d-S"},
      {value: "6313", overrideEncoding: "$sk0d-S"},
      {value: "7476", overrideEncoding: "$sk0d-S"},
      {value: "7378", overrideEncoding: "$sk0d-S"}
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      "645",
      "495",
      "677",
      "558"
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      "483",
      "412",
      "439",
      "414"
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      "2464",
      "2143",
      "2344",
      "1859"
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      {value: "10687", overrideEncoding: "$sk0d-S"},
      {value: "9363", overrideEncoding: "$sk0d-S"},
      {value: "10936", overrideEncoding: "$sk0d-S"},
      {value: "10209", overrideEncoding: "$sk0d-S"}
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      "754",
      "597",
      "690",
      "659"
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      {value: "11441", overrideEncoding: "$sk0d-S"},
      {value: "9960", overrideEncoding: "$sk0d-S"},
      {value: "11626", overrideEncoding: "$sk0d-S"},
      {value: "10868", overrideEncoding: "$sk0d-S"},
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      "-1015",
      "-352",
      "-643",
      "361"
    ],
    [
      null,
      "Spacing",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Memo",
      "Title",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      {value: "73.5", overrideEncoding: "string"},
      {value: "75.7", overrideEncoding: "string"},
      {value: "75.3", overrideEncoding: "string"},
      {value: "77.1", overrideEncoding: "string"}
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      {value: "2.9", overrideEncoding: "string"},
      {value: "3.4", overrideEncoding: "string"},
      {value: "2.8", overrideEncoding: "string"},
      {value: "3.1", overrideEncoding: "string"}
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      {value: "4.5", overrideEncoding: "string"},
      {value: "5.6", overrideEncoding: "string"},
      {value: "4.4", overrideEncoding: "string"},
      {value: "5.0", overrideEncoding: "string"}
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      {value: "9.2", overrideEncoding: "string"},
      {value: "15.4", overrideEncoding: "string"},
      {value: "8.2", overrideEncoding: "string"},
      {value: "15.3", overrideEncoding: "string"}
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      {value: "1.3", overrideEncoding: "string"},
      {value: "1.6", overrideEncoding: "string"},
      {value: "1.3", overrideEncoding: "string"},
      {value: "1.5", overrideEncoding: "string"}
    ]
  ],
  "columnNames": [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m"
  ],
  "columnDisplayName": [
    "($ per Loan)",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m"
  ],
  "columnShow": [
    true,
    false,
    true,
    true,
    true,
    true,
    true
  ],
  "columnGroupName": [{
    nameOfGroup: "Income Statement for Q3 2023 ($ per Loan)",
    headerClass: 'grate-group',
    columnsToGroup: ["G-Rate",
      "Total Industry",
      "Tot. Ind. >1B",
      "Retail / CD",
      "Ret. / CD > 500m"]
  }
  ],
  "columnFormatting": [
    "string",
    "string",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S"
  ],
  "columnHeaderClasses": [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column"
  ],  
  "columnDataClasses": [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right"
  ],
  "tableTitle": ""
}

export const GrateMBABasisPointsQ3: tsDataObject = {
  "data": [
    [
      null,
      "Spacing",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      "329",
      "300",
      "344",
      "343"
    ],
    [
      "Expenses",
      "Title",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Sales Personnel",
      null,
      null,
      "98",
      "94",
      "109",
      "108"
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      "51",
      "43",
      "48",
      "42"
    ],
    [
      "Production Support Employees",
      null,
      null,
      "27",
      "18",
      "29",
      "25"
    ],
    [
      "Management and Directors",
      null,
      null,
      "19",
      "16",
      "20",
      "18"
    ],
    [
      "Benefits",
      null,
      null,
      "30",
      "26",
      "32",
      "32"
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      "225",
      "198",
      "237",
      "225"
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      "20",
      "16",
      "22",
      "17"
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      "15",
      "13",
      "14",
      "13"
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      "78",
      "67",
      "75",
      "59"
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      "339",
      "294",
      "347",
      "314"
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      "23",
      "19",
      "21",
      "20"
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      "363",
      "313",
      "368",
      "334"
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      "(34)",
      "(13)",
      "(24)",
      "9"
    ],
    [
      null,
      "Spacing",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Memo",
      "Title",
      null,
      null,
      null,
      null,
      null
    ],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      "73.5",
      "75.7",
      "75.3",
      "77.1"
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      "2.9",
      "3.4",
      "2.8",
      "3.1"
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      "4.5",
      "5.6",
      "4.4",
      "5.0"
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      "9.2",
      "15.4",
      "8.2",
      "15.3"
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      "1.3",
      "1.6",
      "1.3",
      "1.5"
    ]
  ],
  "columnNames": [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m"
  ],
  "columnDisplayName": [
    "Basis Points",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m"
  ],
  "columnShow": [
    true,
    false,
    true,
    true,
    true,
    true,
    true
  ],
  "columnGroupName": [{
    nameOfGroup: "Income Statement for Q3 2023 (basis points)",
    headerClass: 'grate-group',
    columnsToGroup: ["G-Rate",
      "Total Industry",
      "Tot. Ind. >1B",
      "Retail / CD",
      "Ret. / CD > 500m"]
  }
  ],
  "columnFormatting": [
    "string",
    "string",
    "string",
    "string",
    "string",
    "string",
    "string"
  ],
  "columnHeaderClasses": [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column"
  ],  
  "columnDataClasses": [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right"
  ],
  "tableTitle": ""
}