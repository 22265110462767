import { Outlet, Link } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarContent } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/white-icon-large.png';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import SidebarToggle, { DefaultSidebarBreakpoint } from "./SidebarToggle";
import HeaderSm from './HeaderSm';
import { useLazyAddUserActivityQuery } from '../services/gallus';

//http://webcache.googleusercontent.com/search?q=cache:https%3A%2F%2Fwww.npmjs.com%2Fpackage%2Freact-pro-sidebar
//for sidebar API instructions

interface LayoutProps {
  sidebarMenu?: React.ReactNode;
  navMenu?: React.ReactNode;
  breakPoint?: "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
  smallHeader?: boolean;
  footer?: React.ReactNode;
  contactUsPath?: string;
  signOutPath?: string;
}

/**
 * Layout component for the main site.
 * @param props 
 * @returns Layout component.
 */
const Layout = (props: LayoutProps) => {
  const [addUserActivityQuery, addUserActivityQueryResult] = useLazyAddUserActivityQuery();
  const page = useLocation().pathname;
  const searchParams = useLocation().search;
  const [isShown, setIsShown] = useState(false);
  const [toggled, setToggled] = useState(false);
  const handleToggle = (value: boolean) => {
    setToggled(value);
    if (!value) {
      setIsShown(false);
    }
  };
  const handleToggleClick = () => {
    setIsShown(true);
    setToggled(true);
  }

  React.useEffect(() => {
    addUserActivityQuery({ URL: page+searchParams, ID_ActivityType: "5" });
  }, [page, searchParams]);

  //On page change, retract the menu
  /*
    React.useEffect(() => {
      setIsShown(false);
      setToggled(false);
    }, [page]);
  */
  return (
    <div className={'master ' + (props.smallHeader ? 'sm' : '')}>
      {props.smallHeader ? <HeaderSm contactUsPath={props.contactUsPath} signOutPath={props.signOutPath} /> : (
        <div className='banner sm'>
          <div className='banner-text'>
            <Link className='title' to="/">GALLUS INSIGHTS</Link>
          </div>
          <div className='banner-image'>
            <div className="d-flex align-items-center">
              {props.navMenu}
              <Link className='title' to="/"><img className='logo' src={Logo} alt="Gallus Insights" /></Link>
            </div>
          </div>
        </div>
      )}
      <div className='wrapper'>
        {/* <div className='contains-sidebar'> */}
        {props.breakPoint ? <SidebarToggle breakpoint={DefaultSidebarBreakpoint} onClick={() => handleToggleClick()} /> : <></>}
        <ProSidebar collapsed={!isShown && page !== '/'} //so sidebar won't be collapsed in the initial page, and only then
          breakPoint={props.breakPoint}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          toggled={props.breakPoint ? toggled : false}
          onToggle={handleToggle}
        >
          <SidebarContent >
            <Menu iconShape="square">
              {props.sidebarMenu ? props.sidebarMenu : (
                <>
                  <MenuItem><Link to="signUp"><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faUserPlus} />Sign Up</div></Link></MenuItem>
                  <MenuItem><Link to="login"><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faUser} />Login</div></Link></MenuItem>
                  <MenuItem><Link to="contactus"><div className='sidebar-item'><FontAwesomeIcon className="me-2" icon={faEnvelope} />Contact Us</div></Link></MenuItem>
                </>
              )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
        {/* </div> */}
        <div className='contains-outlet'>
          <Outlet />
        </div>
      </div>
      {props.footer ?? <Footer />}
    </div>
  );
}

export default Layout;
