import { faClipboard, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";

/**
 * Copies current URL to the Clipboard
 * @returns 
 */
export const URLCopyButton = () => {
    const [copiedURL, setCopiedURL] = useState(false);

    React.useEffect(() => {
        setTimeout(() => setCopiedURL(false), 3500);
    }, [copiedURL]);
    
    return (
        <button type="button" disabled={copiedURL} className="me-3 d-flex flex-row align-items-center px-2 py-1 btn btn-primary url-copy-button"
            onClick={(e) => {
                e.preventDefault();
                setCopiedURL(true);
                navigator.clipboard.writeText(window.location.href)
            }}
        >
            {!copiedURL &&
                <div>
                    <FontAwesomeIcon icon={faClipboard} size="lg" fixedWidth className="ms-1" />
                    <span className="ms-2 me-2">Copy URL</span>
                </div>
            }
            {copiedURL &&
                <div>
                    <FontAwesomeIcon icon={faThumbsUp} size="lg" fixedWidth className="ms-1" />
                    <span className="ms-2 me-2">URL Copied!</span>
                </div>
            }
        </button>
    );
}
