import { API } from "aws-amplify";
import { getUserSessionItem, refreshToken } from "..";
import { CognitoOidcConfig } from "../CognitoOidcConfig";

export async function sessionChecker(onSessionExpired: () => void) {
    let hasRetried = false;
    try {
         do {
            const accessToken = getUserSessionItem('accessToken');
            const data: { isValid: boolean; payload: any } = await API.post("ServerlessAPI", "/jwt/", {
                body: {
                    tokenUse: "access",
                    clientId: CognitoOidcConfig.clientId,
                    idToken: accessToken,
                },
            });
            if (data.isValid) {
                break;
            }
            else {
                if (hasRetried) {
                    console.error("Invalid token after retry");
                    throw new Error("Invalid token after retry");
                }
                const refreshTokenError = await refreshToken();
                if (refreshTokenError) {
                    console.error("Refresh token error");
                    throw new Error("Refresh token error");
                }
                hasRetried = true;
            }
        } while(hasRetried);
        console.debug("session is valid");
    }
    catch (e) {
        onSessionExpired();
        console.debug("session is invalid");
    }
}
