import React from "react";

export const Home = () => {    
    return (<>
        <div className='hero-image'>
            <h1 className="site-slogan">
                Harnessing Data.
                <br></br>
                Delivering Insights.
            </h1>
        </div>
    </>);
}
