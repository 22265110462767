import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JwtPayload } from 'jwt-decode';
import { RootState, AppThunk } from '../../app/store';

export interface CognitoJwtPayload extends JwtPayload {
    at_hash: string;
    email_verified: boolean;
    origin_jti: string;
    email: string;
    sub: string;
    "cognito:groups": string[];
    "cognito:username": string;
    given_name: string;
    family_name: string;
    preferred_username?: string;
}

export interface UserAuthContext {
    idTokenPayload: CognitoJwtPayload;
    idToken: string;
    accessToken: string;
    refreshToken: string;
}

export interface ConsoleState {
    userAuthContext?: UserAuthContext & {
        username: string;
    },
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ConsoleState = {
    status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const updateUserAuthContextAsync = createAsyncThunk(
    'console/fetchCount',
    async (amount: number) => {
        const response = { data: amount };
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);

export const consoleSlice = createSlice({
    name: 'console',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateUserAuthContext: (state, action: PayloadAction<UserAuthContext>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.userAuthContext = {
                 ...action.payload,
                 username: action.payload.idTokenPayload['preferred_username'] ?? action.payload.idTokenPayload['cognito:username']
            };
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(updateUserAuthContextAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserAuthContextAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(updateUserAuthContextAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { updateUserAuthContext } = consoleSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState) => state.console.status;
export const selectUserAuthContext = (state: RootState) => state.console.userAuthContext;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const updateUserAuthContextWhenIdle =
    (tokens: UserAuthContext): AppThunk =>
        (dispatch, getState) => {
            const currentStatus = selectStatus(getState());
            if (currentStatus === "idle") {
                dispatch(updateUserAuthContext(tokens));
            }
        };

export default consoleSlice.reducer;
