import * as React from 'react';
import { LiveboardEmbed, useEmbedRef } from "@thoughtspot/visual-embed-sdk/react";
import { EmbedProps } from "@thoughtspot/visual-embed-sdk/lib/src/react/util";
import { LiveboardViewConfig } from "@thoughtspot/visual-embed-sdk/lib/src/embed/liveboard";
import { getUserSessionItem, initTs } from '..';
import { useAppSelector } from '../app/hooks';
import { selectUserAuthContext } from '../features/console/consoleSlice';
import { Alert } from 'reactstrap';
import { useLazyGetTSAccessTokenQuery } from '../services/serverless';
import { Error } from './Error';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionChecker } from '../helpers/session';

interface LiveboardProps extends EmbedProps, LiveboardViewConfig {
  orgId?: number;
}

/**
 * Wrapper of a Thoughtspot liveboard
 * @param props 
 * @returns 
 */
export const PinboardEmbedWrapper = (props: LiveboardProps) => {
  const userAuthContext = useAppSelector(selectUserAuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const embedRef = useEmbedRef() as any;
  const onLiveboardRendered = () => {
    console.log('liveboard rendered');
  };

  const [hasNoCookieAccess, setHasNoCookieAccess] = React.useState<boolean>(false);
  const [rendering, setRendering] = React.useState<JSX.Element>();
  const [getTSAccessToken, getTSAccessTokenResult] = useLazyGetTSAccessTokenQuery();

  const handleNoCookieAccess = () => {
    console.log('no cookie access');
    setHasNoCookieAccess(true);
  }

  async function handleAuthExpire() {
    await sessionChecker(() => {
      navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
    });
  }

  async function handleAuthFailure() {
    await sessionChecker(() => {
        navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
    });
  }

  React.useEffect(() => {
    const render = async () => {
      try {
        await sessionChecker(() => {
          navigate(`/login?redirect_uri=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
        });
        await getTSAccessToken(props.orgId ?? 0).unwrap();
        await initTs('TOKEN', userAuthContext?.username ?? getUserSessionItem('userName'), props.orgId ?? 0);
        setRendering(
          <LiveboardEmbed
            {...props}
            ref={embedRef}
            onAuthExpire={handleAuthExpire}
            onAuthFailure={handleAuthFailure}
            onLiveboardRendered={onLiveboardRendered}
            onNoCookieAccess={handleNoCookieAccess}
            className="h-100"
          />);
      }
      catch (e) {
        setRendering(<Error code='500' type='External' message='' error={e} />);
      }
    }
    render();
  }, [props]);

  return (
    <>
      {hasNoCookieAccess && (
        <Alert color="info">
          Please allow third party cookies from the browser settings.
        </Alert>
      )}
      {!hasNoCookieAccess && rendering}
    </>
  );
};
