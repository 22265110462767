import React, { useEffect, useState } from "react";
import { Auth } from 'aws-amplify';
import { checkMatchingPasswords, validatePasswordLength, validateLowercase, validateUppercase, validateSpecial, validateNumber, passwordErrors } from '../UserValidationFunctions';
import "../UserValidation.scss";
import { CognitoUser } from "amazon-cognito-identity-js";
import { CognitoJwtPayload, updateUserAuthContext } from "../../features/console/consoleSlice";
import { useAppDispatch } from "../../app/hooks";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { updateUserSessionByCurrentSession } from "../..";
import { environmentConfig } from "../../environment";
import { CognitoOidcConfig } from "../../CognitoOidcConfig";
import { useLazyAddUserActivityQuery } from "../../services/gallus";
import { MFASetUp } from "./MFASetUp";

export const NewLogInPage = () => {
    const dispatch = useAppDispatch();
    const [searchParams, _setSearchParams] = useSearchParams();
    const redirectUri = searchParams.get('redirect_uri');

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [submitButtonState, setSubmitButtonState] = useState<boolean>(false);
    const [signInErrorMessage, setSignInErrorMessage] = useState<string>("");
    const [offerMFA, setOfferMFA] = useState<boolean>(false);
    const [mfaCode, setMFACode] = useState<string>("");
    const [lifecycle, setLifecycle] = useState<"Login" | "Error" | "ForgotPassword" | "ConfirmPassword" | "PasswordReset" | "NewPasswordRequired" | "MFAChallenge">("Login");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
    const [newPasswordCode, setNewPasswordCode] = useState<string>("");
    const [lastField, setLastField] = useState<boolean>(false);
    const [validPasswordLength, setValidPasswordLength] = useState<boolean>(false);
    const [validPasswordUppercase, setValidPasswordUppercase] = useState<boolean>(false);
    const [validPasswordLowercase, setValidPasswordLowercase] = useState<boolean>(false);
    const [validPasswordNumber, setValidPasswordNumber] = useState<boolean>(false);
    const [validPasswordSpecial, setValidPasswordSpecial] = useState<boolean>(false);
    const [validPasswordMatch, setValidPasswordMatch] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [navigationUrl, setNavigationUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);

    const [addUserActivityQuery, addUserActivityQueryResult] = useLazyAddUserActivityQuery();

    useEffect(() => {
        const init = async () => {
            if (environmentConfig.useCognitoOidcLogin) {
                let urlSearchParams = new URLSearchParams();
                urlSearchParams.append("client_id", CognitoOidcConfig.clientId);
                urlSearchParams.append("redirect_uri", CognitoOidcConfig.callbackUri);
                urlSearchParams.append('scope', 'openid profile email');
                urlSearchParams.append("response_type", "code");
                const statePayload = {
                    redirectUri: redirectUri
                };
                urlSearchParams.append("state", window.btoa(JSON.stringify(statePayload)));

                window.location.href = `${CognitoOidcConfig.authorizationTokenEndpoint}?${urlSearchParams.toString()}`;
            }
            else {
                try {
                    const session = await Auth.currentSession();
                    if (session.isValid()) {
                        updateUserSessionByCurrentSession(session);
                        setNavigationUrl(redirectUri ?? '/landingpage');
                        addUserActivityQuery({ URL: window.location.pathname, ID_ActivityType: "2" });
                    }
                }
                catch (e) {
                }
                setIsLoading(false);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        };
        init();
    }, []);

    useEffect(() => {
        if (lifecycle === "Login") {
            if (password.length === 0 || username.length === 0) {
                setSubmitButtonState(false);
            } else {
                setSubmitButtonState(true);
            }
        }

        if (lifecycle === "ForgotPassword") {
            if (username.length === 0) {
                setSubmitButtonState(false);
            } else {
                setSubmitButtonState(true);
            }
        }

        let hasErrorPassword = false;
        if (!checkPassword(newPassword, newPasswordConfirmation)) {
            hasErrorPassword = true;
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }

        if (lifecycle === "ConfirmPassword") {
            if ((newPasswordCode.length === 0) ||
                (newPassword.length === 0) || (newPasswordConfirmation.length === 0)
                || (hasErrorPassword)) {
                setSubmitButtonState(false);
            } else {
                setSubmitButtonState(true);
            }
        }
    }, [lifecycle, username, password, newPassword, newPasswordConfirmation, newPasswordCode]);

    const checkPassword = (password: string, passwordConfirmation: string) => {
        if (password.length !== 0) { //only validate if there's a password. If there's not, it won't validate, but it's ok since checkEmpty does it
            let isValidPasswordLength = validatePasswordLength(password);
            setValidPasswordLength(isValidPasswordLength);

            let isValidPasswordLowercase = validateLowercase(password);
            setValidPasswordLowercase(isValidPasswordLowercase);

            let isValidPasswordUppercase = validateUppercase(password);
            setValidPasswordUppercase(isValidPasswordUppercase);

            let isValidPasswordNumber = validateNumber(password);
            setValidPasswordNumber(isValidPasswordNumber);

            let isValidPasswordSpecial = validateSpecial(password);
            setValidPasswordSpecial(isValidPasswordSpecial);

            let isValidPasswordMatch = checkMatchingPasswords(password, passwordConfirmation);
            setValidPasswordMatch(isValidPasswordMatch);

            if (isValidPasswordLength && isValidPasswordLowercase && isValidPasswordUppercase
                && isValidPasswordNumber && isValidPasswordSpecial && isValidPasswordMatch) {
                return true;
            } else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    function handleError(error: unknown) {
        //error lists here: 
        //https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors

        let errorName = 'Unknown Error';

        setIsLoading(false);

        if (error instanceof Error) {
            errorName = error.name;
        }
        else {
            errorName = String(error);
        }

        switch (errorName) {
            case "NotAuthorizedException":
                setSignInErrorMessage("The entered credentials aren't valid. Please, check the information provided and try again.");
                break;
            case "InternalErrorException":
                setSignInErrorMessage("The application has encountered an internal error. Please try again later.");
                break;
            case "InvalidLambdaResponseException":
                setSignInErrorMessage("Invalid AWS Lambda response. Please contact a representative.");
                break;
            case "InvalidParameterException":
                setSignInErrorMessage("Invalid sign in parameter. Please contact a representative.");
                break;
            case "InvalidUserPoolConfigurationException":
                setSignInErrorMessage("Invalid User Pool Configuration. Please contact a representative.");
                break;
            case "PasswordResetRequiredException":
                setSignInErrorMessage("A password reset has been requested. Please, check your email, reset your password and try again.");
                break;
            case "TooManyRequestsException":
                setSignInErrorMessage("Too many log in requests have been detected from this IP address. Please try again later.");
                break;
            case "UnexpectedLambdaException":
                setSignInErrorMessage("Unexpected AWS Lambda response. Please contact a representative.");
                break;
            case "UserLambdaValidationException":
                setSignInErrorMessage("AWS Lambda Service user validation exception. Please contact a representative.");
                break;
            case "UserNotConfirmedException":
                setSignInErrorMessage("User has not been confirmed successfully. Please contact a representative.");
                break;
            case "UserNotFoundException":
                setSignInErrorMessage("The entered credentials aren't valid. Please, check the information provided and try again.");
                break;
            case "CodeMismatchException":
                setSignInErrorMessage("Invalid code provided, please try again.");
                break;
            default:
                setSignInErrorMessage("The application has encountered an internal error. Please try again later.");
        } //TODO: Test errors, specially NotAuthorizedException and UserNotFoundException. Find out if these are all of them

        setLifecycle("Error");
    }

    const [cognitoUser, setCognitoUser] = useState<CognitoUser>();

    const doLogin = (targetUser: CognitoUser) => {
        //set session storage
        const userSession = targetUser.getSignInUserSession();
        const idToken = targetUser.getSignInUserSession()?.getIdToken();
        if (idToken && userSession) {
            updateUserSessionByCurrentSession(userSession);
            dispatch(updateUserAuthContext({
                idTokenPayload: idToken.payload as CognitoJwtPayload,
                idToken: idToken.getJwtToken(),
                accessToken: userSession.getAccessToken().getJwtToken(),
                refreshToken: userSession.getRefreshToken().getToken()
            }));
        }
    }

    const redirect = () => {
        addUserActivityQuery({ URL: window.location.pathname, ID_ActivityType: "1" });
        setNavigationUrl(redirectUri ?? '/landingpage');
    }

    const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        switch (lifecycle) {
            case "Login":
                try {
                    setIsLoading(true);
                    const user = await Auth.signIn(username, password) as CognitoUser;
                    setCognitoUser(user);
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setIsLoading(false);
                        setLifecycle("NewPasswordRequired");
                        break;
                    }
                    if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                        setIsLoading(false);
                        setLifecycle("MFAChallenge");
                        break;
                    }
                    doLogin(user);
                    setIsLoading(false);
                    setOfferMFA(true);
                }
                catch (error) {
                    handleError(error);
                }
                break;
            case "Error":
                setSignInErrorMessage('');
                setLifecycle('Login');
                break;
            case "ForgotPassword":
                Auth.forgotPassword(username)
                    //.then((data) => console.log(data))
                    .then(() => setLifecycle("ConfirmPassword"))
                    .catch((error) => handleError(error));
                break;
            case "ConfirmPassword":
                Auth.forgotPasswordSubmit(username, newPasswordCode, newPassword)
                    //.then((data) => console.log(data))    
                    .then(() => setLifecycle("PasswordReset"))
                    .catch((error) => handleError(error));
                break;
            case "PasswordReset":
                setLifecycle('Login');
                break;
            case "NewPasswordRequired":
                try {
                    const loggedUser = await Auth.completeNewPassword(
                        cognitoUser, // the Cognito User Object
                        newPassword, // the new password
                    );
                    doLogin(loggedUser);
                    redirect();
                }
                catch (error) {
                    handleError(error);
                }
                break;
            case "MFAChallenge":
                try {
                    const loggedUser = await Auth.confirmSignIn(cognitoUser, mfaCode, "SOFTWARE_TOKEN_MFA");
                    doLogin(loggedUser);
                    redirect();
                }
                catch (error) {
                    handleError(error);
                }
                break;
        }
    }

    return (
        <>
            {navigationUrl && <Navigate to={navigationUrl} />}
            {(isLoading || environmentConfig.useCognitoOidcLogin) && !offerMFA && (
                <div className="loading-div">
                    Please wait
                </div>
            )}
            {(!isLoading && !environmentConfig.useCognitoOidcLogin) && offerMFA &&
                <MFASetUp onSetupSuccess={redirect} alreadyAcceptedMFA={false} />
            }
            {(!isLoading && !environmentConfig.useCognitoOidcLogin) && !offerMFA && (
                <form className="container-fluid col-md-4 roundedCorners" onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <div className="col-12 signup-text text-center-for-titles">
                            Log In
                        </div>
                        <div>
                            <div className="mb-2">
                                <label>Username</label><br></br>
                                <input type="text" className="form-control formSignUpUsername" value={username}
                                    onChange={(e) => { setUsername(e.target.value) }} />
                            </div>
                            <div className="mb-2">
                                <label>Password</label><br></br>
                                <input type="password" className="form-control formSignUpPassword" value={password}
                                    onChange={(e) => { setPassword(e.target.value) }} />
                                <div style={{ fontSize: "small" }}>
                                    <a href="#" onClick={() => setLifecycle("ForgotPassword")}>Forgot your password?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signup-end">
                        <button className="btn btn-primary btn-dark-rounded" type="submit" disabled={!submitButtonState}>Log In</button>
                        <div className="signup-need-account">
                            Need an account? <Link to="/signup">Sign up</Link>
                        </div>
                    </div>
                    <div className={lifecycle === "Error" ? "modal display-block modal-disable-padding-top" : "modal display-none"}>
                        <div className="error-popup-message">
                            {signInErrorMessage}
                        </div>
                        <div className="error-popup-button mt-2">
                            <button className="btn-dark-rounded btn btn-primary">Close</button>
                        </div>
                    </div>
                    <div className={lifecycle === "ForgotPassword" ? "modal display-block modal-disable-padding-top" : "modal display-none"}>
                        <div>
                            <div className="modal-title">
                                Forgot your password?</div>
                            <div className="modal-text">
                                Enter your Username below and we will send a message to reset your password
                            </div>

                        </div>
                        <div>
                            <input type="text" className="form-control formSignUpUsername" value={username}
                                onChange={(e) => { setUsername(e.target.value); }} />
                        </div>
                        <div className="d-flex">
                            <div className="error-popup-button mt-2">
                                <button className="btn-dark-rounded btn btn-primary" type="submit" disabled={!submitButtonState}>Submit</button>
                                <button className="btn-dark-rounded btn btn-primary ms-2" onClick={() => setLifecycle("PasswordReset")}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div className={lifecycle === "ConfirmPassword" ? "modal display-block modal-disable-padding-top" : "modal display-none"}>
                        <div>
                            We have sent a password reset code by email. Enter it below to reset your password.
                        </div>
                        <div>
                            Password Reset Code
                            <input type="text" className="form-control formSignUpUsername" value={newPasswordCode}
                                onChange={(e) => { setNewPasswordCode(e.target.value) }} />
                        </div>
                        <div>
                            New Password
                            <input type="password" className="form-control formSignUpPassword" value={newPassword}
                                onChange={(e) => { setNewPassword(e.target.value) }} />
                        </div>
                        <div>
                            Confirm New Password
                            <input type="password" className="form-control formSignUpPassword" value={newPasswordConfirmation}
                                onChange={(e) => { setNewPasswordConfirmation(e.target.value); setLastField(true); }} />
                        </div>
                        <div style={{ display: (errorPassword ? 'block' : 'none') }} className="mt-2">
                            {passwordErrors(lastField, validPasswordLength, validPasswordUppercase, validPasswordLowercase, validPasswordNumber, validPasswordSpecial, validPasswordMatch)}
                        </div>
                        <div className="error-popup-button mt-2">
                            <button className="btn-dark-rounded btn btn-primary" type="submit" disabled={!submitButtonState}>Submit</button>
                        </div>
                    </div>
                    {lifecycle === "NewPasswordRequired" && (<div className="modal display-block modal-disable-padding-top">
                        <div>
                            Please enter a new password.
                        </div>
                        <div>
                            New Password
                            <input type="password" className="form-control formSignUpPassword" value={newPassword}
                                onChange={(e) => { setNewPassword(e.target.value) }} />
                        </div>
                        <div>
                            Confirm New Password
                            <input type="password" className="form-control formSignUpPassword" value={newPasswordConfirmation}
                                onChange={(e) => { setNewPasswordConfirmation(e.target.value); setLastField(true); }} />
                        </div>
                        <div style={{ display: (errorPassword ? 'block' : 'none') }} className="mt-2">
                            {passwordErrors(true, validPasswordLength, validPasswordUppercase, validPasswordLowercase, validPasswordNumber, validPasswordSpecial, validPasswordMatch)}
                        </div>
                        <div className="error-popup-button mt-2">
                            <button className="btn-dark-rounded btn btn-primary" type="submit" disabled={!(validPasswordLength && validPasswordUppercase && validPasswordLowercase && validPasswordNumber && validPasswordSpecial && validPasswordMatch)}>Submit</button>
                            <button className="btn btn-danger ms-2" onClick={() => setLifecycle("Login")}>Cancel</button>
                        </div>
                    </div>)}
                    <div className={lifecycle === "PasswordReset" ? "modal display-block modal-disable-padding-top" : "modal display-none"}>
                        <div className="error-popup-message">
                            Your password has been successfully reset. Please login using your username and new password.
                        </div>
                        <div className="error-popup-button mt-2">
                            <button>Close</button>
                        </div>
                    </div>
                    {lifecycle === "MFAChallenge" && (<div className="modal display-block modal-disable-padding-top">
                        <div>
                            Please enter an MFA code to complete sign-in.
                        </div>
                        <div>
                            MFA code
                            <input className="form-control formSignUpPassword" maxLength={6} value={mfaCode}
                                onChange={(e) => { setMFACode(e.target.value) }} />
                        </div>
                        <div className="error-popup-button mt-2">
                            <button className="btn-dark-rounded btn btn-primary" type="submit" disabled={mfaCode.length === 0}>Submit</button>
                            <button className="btn btn-danger ms-2" onClick={() => setLifecycle("Login")}>Cancel</button>
                        </div>
                    </div>)}
                </form>
            )}
        </>
    );
}
