import React, { useEffect } from "react";
import { useGetGRateDatesQuery, useLazyGetGRateTableDataQuery, useLazyGetProductsQuery } from "./services/gallus";
import { useLazyGetTSAccessTokenFallbackQuery } from "./services/serverless";

interface TestProps { }

export const Test = (props: TestProps) => {
    const [lazyGetTSAccessTokenFallbackQueryQuery, lazyGetTSAccessTokenFallbackQueryResult] = useLazyGetTSAccessTokenFallbackQuery();

    function handleClick() {
        lazyGetTSAccessTokenFallbackQueryQuery({orgID: 428892831});
    }

    useEffect(() => {
        if (lazyGetTSAccessTokenFallbackQueryResult.isSuccess && lazyGetTSAccessTokenFallbackQueryResult.status === "fulfilled"){
            console.log(lazyGetTSAccessTokenFallbackQueryResult.data)
        }
    }, [lazyGetTSAccessTokenFallbackQueryResult])
    

    return (
        <div style={{ textAlign: "center" }}>
            <button onClick={handleClick}>Press</button>
        </div>
    );
}
