import React, { useEffect, useState } from "react";
import { InputSlider } from "./InputSlider";
import './MultipleInputSliders.scss';

interface DataItem {
    startingValue: number;
    firstSymbol: string;
    secondSymbol: string;
    label: string;
}

interface ResultItem {
    firstSymbol: string;
    secondSymbol: string;
    label: string;
}

interface ResultFromMultipleInputSlidersProps {
    /**
     * A collection of DataItem objects, which contain a starting value (loaded from an API, for example), a label, which is displayed on top of the input, and a couple of symbols, the first being displayed to the left of the input and the second to the right
     */
    data: DataItem[];
    /**
     * A boolean that, if true, shows the labels from Data. This is to hide redundant labels if multiple components are used, one next to the other
     */
    showLabels: boolean;
    /**
     * A function that processes the values of this component
     */
    onNewValuesChange: (values: number[]) => void;
    /**
     * A function that takes the values, applies a mathematical function, and returns a number. In case the result isn't needed, a blank function can be submitted.
     */
    resultResolver: (values: number[]) => number;
    /**
     * specifies how the result should be displayed. In case the result isn't needed, the fields can be left empty
     */
    result: ResultItem;
    /**
     * A boolean that specifies if the result should be displayed or not. 
     */
    showResult: boolean;
    /**
     * The column label
     */
    columnlabel: string;
    /**
     * A boolean that specifies the orientation of the input and sliders.
     */
    vertical: boolean;
}

interface ResultFromMultipleInputSlidersState {
    values: number[];
    resultValue: number;
}

/**
 * This component displays a combination of Inputs and Sliders. The amount of Inputs and Sliders, how they interact which each other, if there's a "result" field, and how they are displayed are all specified using the properties of the component.
 */

export const ResultFromMultipleInputSliders = (props: ResultFromMultipleInputSlidersProps) => {
    const [state, setState] = useState<ResultFromMultipleInputSlidersState>({
        values: [],
        resultValue: 0
    });

    useEffect(() => {
        let initTotal: number;

        let startingValues: number[] = [];
        startingValues = [...props.data.map(item => item.startingValue)];
        initTotal = initResult(startingValues);

        setState({
            values: startingValues, //for some reason, "values: this.props.startingvalues" somehow LINKS both values, changing the state also changes the props.
            resultValue: initTotal
        });
    }, []);

    /**
     * Updates the value of the changed input
     * @param value New value of the input slider.
     * @param indexOfChangedValue Index of the input slider.
     */
    const handleChange = (value: number, indexOfChangedValue: number) => {
        var changedValueArray: number[];
        changedValueArray = state.values;
        changedValueArray[indexOfChangedValue] = value;

        setState({...state, values: changedValueArray });
        handleResult();
    }

    /**
     * Assigns the initial value of the result. It's 0 if it's not shown
     * @param values Values to be used to calculate the result.
     * @returns Value of the total.
     */
    function initResult(values: number[]) {
        let totalValue = 0;
        if (props.showResult) {
            totalValue = props.resultResolver(values);
        }

        return totalValue;
    }

    /**
     * Assigns the value of the result. It's 0 if it's not shown.
     */
    function handleResult() { 
        if (props.showResult) {
            let totalValue = props.resultResolver(state.values);
            setState({...state, resultValue: totalValue });
        } else {
            setState({...state, resultValue: 0 });
        }
        props.onNewValuesChange(state.values);
    }    

    /**
     * Generates the InputSliders for each of the initial values
     * @returns Returns an array of InputSliders
     */
    function renderInputs() { 
        return state.values.map((_element, index) => {
            if (props.vertical) {
                return (
                    <div key={index}>
                        <InputSlider startingValue={props.data[index].startingValue} leadingSymbol={props.data[index].firstSymbol} trailingSymbol={props.data[index].secondSymbol} label={props.data[index].label} showLabel={props.showLabels} onValueChange={(value) => handleChange(value, index)} labelVerticality={props.vertical}/>
                    </div>
                );
            } else {
                return (
                    <div className="individual-is" key={index}>
                        <InputSlider startingValue={props.data[index].startingValue} leadingSymbol={props.data[index].firstSymbol} trailingSymbol={props.data[index].secondSymbol} label={props.data[index].label} showLabel={props.showLabels} onValueChange={(value) => handleChange(value, index)} labelVerticality={props.vertical}/>
                    </div>
                );
            }
        });
    }

    /**
     * Draws the Result box, if needed
     * @returns Returns a box with the label and value of the result.
     */
    function renderResult() { 
        if (props.showResult) {
            return (
                <div className="multiple-input-sliders-result-box">
                    <div className="multiple-input-sliders-result-label" style={{ visibility: (props.showLabels ? 'visible' : 'hidden') }}>
                        {props.result.label}
                    </div>
                    <div className="multiple-input-sliders-result-display">
                        {props.result.firstSymbol}
                        {state.resultValue}
                        {props.result.secondSymbol}
                    </div>
                </div>);
        }
    }

    return (
        <div className="multiple-input-sliders-master">
            <div className="multiple-input-sliders-column-label">
                {props.columnlabel}
            </div>
            {(props.vertical) ? (
                <div className="multiple-input-sliders-is-vertical">
                    {renderInputs()}
                </div>
            ) : (
                <div className="multiple-input-sliders-is-horizontal">
                    {renderInputs()}
                </div>)}
            {renderResult()}
        </div>
    );
}

