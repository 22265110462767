import { useNavigate, useParams } from "react-router-dom";
import { getUserSessionItem } from ".";
import { useAppSelector } from "./app/hooks";
import { CognitoJwtPayload, selectUserAuthContext, updateUserAuthContext } from "./features/console/consoleSlice";
import { useGetProductsQuery, useLazyGetSubscriptionsQuery, useLiveboardExternalSiteCheckQuery } from "./services/gallus";
import { useEffect, useState } from "react";
import { Subscriptions } from "./services/types/gallus";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { useIsLiveboardInOrgMutation } from "./services/thoughtSpotV2";
import { useLazyGetTSAccessTokenQuery } from "./services/serverless";
import { SubdomainOrgIDArray } from "./Routes";
import { Error } from "./components/Error";

interface TSRedirectProps {
}

export const TSRedirect = (props: TSRedirectProps) => {
    //const liveboardGUID = useLocation().pathname.slice(6);
    let { LiveboardId } = useParams();
    const userAuthContext = useAppSelector(selectUserAuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userSession = getUserSessionItem('user');
    const getProductsQuery = useGetProductsQuery();
    const liveboardInExternalSite = useLiveboardExternalSiteCheckQuery({ liveboardGUID: LiveboardId! });
    const [lazyGetSubscriptionsQuery, lazyGetSubscriptionsQueryResult] = useLazyGetSubscriptionsQuery();
    const [isLiveboardInOrgQuery, isLiveboardInOrgQueryResult] = useIsLiveboardInOrgMutation();
    const [getTsAccessToken, getTsAccessTokenResult] = useLazyGetTSAccessTokenQuery();

    const [availableOrgsForUser, setAvailableOrgsForUser] = useState<number[]>([]);
    const [orgToRedirectUserTo, setOrgToRedirectUserTo] = useState<number>();
    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<string>("");
    const [errorType, setErrorType] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        if (getProductsQuery.isSuccess && getProductsQuery.status === 'fulfilled'
            && lazyGetSubscriptionsQueryResult.isSuccess && lazyGetSubscriptionsQueryResult.status === 'fulfilled') {
            let listOfSubscribedProducts: Subscriptions[] = [...lazyGetSubscriptionsQueryResult.data];
            let tempAvailableOrgs: number[] = [];

            if (userSession) {
                //if user is logged in...
                for (let product of getProductsQuery.data) {
                    if (product.Enabled) {
                        if (product.Permissions.some(groupsOfProduct => userAuthContext?.idTokenPayload["cognito:groups"]?.includes(groupsOfProduct) ?? false)) { //we make sure the user can see the product
                            if ((listOfSubscribedProducts.some(subscription => subscription.DateEnd === null || new Date(subscription.DateEnd) >= new Date()) || product.IsFree) && product.OrgID !== null) {
                                tempAvailableOrgs.push(product.OrgID);
                            }
                        }
                    }
                }
                setAvailableOrgsForUser(tempAvailableOrgs);
            } else {
                //if user is not logged in...
                navigate(`/login`);
            }
        }
        else if (getProductsQuery.isError) {
            const error = JSON.parse(getProductsQuery.error as string);
            setErrorFlag(true);
            setErrorCode(error.retCode);
            setErrorMessage(error.retMessage);
            setErrorType("InternalAPI");
        }
        else if (lazyGetSubscriptionsQueryResult.isError) {
            const error = JSON.parse(lazyGetSubscriptionsQueryResult.error as string);
            setErrorFlag(true);
            setErrorCode(error.retCode);
            setErrorMessage(error.retMessage);
            setErrorType("InternalAPI");
        }
    }, [getProductsQuery, lazyGetSubscriptionsQueryResult]);

    useEffect(() => {
        if (liveboardInExternalSite.isSuccess && liveboardInExternalSite.status === "fulfilled") {
            if (typeof (liveboardInExternalSite.data) === "number") {
                let externalOrg = SubdomainOrgIDArray.find(SubdomainOrgIDObject =>
                    SubdomainOrgIDObject.orgID === Number(liveboardInExternalSite.data)
                )

                if (externalOrg?.externalInfo){
                    window.location.href = "https://" + externalOrg.externalInfo.subdomain + ".gallusanalytics.com/" + externalOrg.externalInfo.productURL + "/product?productURL=pinboard/" + LiveboardId;
                }
            }
        }
    }, [liveboardInExternalSite]);

    useEffect(() => {
        const getSession = async () => {
            try {
                if (!userAuthContext) {
                    const session = await Auth.currentSession();
                    const sessionIdTokenPayload = session.getIdToken().payload as CognitoJwtPayload
                    dispatch(updateUserAuthContext({
                        idTokenPayload: sessionIdTokenPayload,
                        idToken: session.getIdToken().getJwtToken(),
                        accessToken: session.getAccessToken().getJwtToken(),
                        refreshToken: session.getRefreshToken().getToken()
                    }));
                    lazyGetSubscriptionsQuery({
                        cognitoSubject: sessionIdTokenPayload["sub"]
                    });
                }
            }
            catch (e) {
                navigate('/login');
            }
        }
        getSession();
    }, []);

    useEffect(() => {
        const getOrgId = async () => {
            for (let i = 0; i < availableOrgsForUser.length; i++) {
                const tsAccessToken = await getTsAccessToken(availableOrgsForUser[i]).unwrap();
                const result = await isLiveboardInOrgQuery({ liveboardId: LiveboardId!, thoughtSpotAccessToken: tsAccessToken.token }).unwrap();
                if (result) {
                    setOrgToRedirectUserTo(availableOrgsForUser[i]);
                    break;
                }
                if (!result && i === availableOrgsForUser.length - 1) {
                    setErrorFlag(true);
                    setErrorCode("500");
                    setErrorMessage("Invalid link.");
                    setErrorType("ExternalAPI");
                }
            }
        }
        getOrgId();
    }, [availableOrgsForUser]);

    useEffect(() => {
        if (typeof (orgToRedirectUserTo) !== "undefined") {
            let subdomainToRedirectTo = SubdomainOrgIDArray.find(SubdomainOrgIDObject =>
                SubdomainOrgIDObject.orgID === Number(orgToRedirectUserTo)
            );
            if (typeof (subdomainToRedirectTo?.path) !== "undefined") {
                navigate(`/${subdomainToRedirectTo?.path}/product?productURL=pinboard/${LiveboardId}`);
            }
        }
    }, [orgToRedirectUserTo]);

    return (
        <div>
            {!errorFlag && (
                <div className="loading-div">
                    Redirecting, please wait.
                </div>
            )}
            {errorFlag && (
                <div>
                    <Error code={errorCode} type={errorType} message={errorMessage} />
                </div>
            )}
        </div>
    )
}
