import * as React from "react";
import '../Module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Plot from "react-plotly.js"
import { useEffect, useState } from "react";

interface EbitdaCovenantSummaryProps { }

interface EbitdaCovenantSummaryState {
    netDebtCreditEBITDAChartOptions: any[];
    netWorthChartOptions: any[];
    liquidityChartOptions: any[];
    gnmaRatiosNWAssetsChartOptions: any[];
    gnmaRatiosLiquidityChartOptions: any[];
    chartsLoadedFlag: boolean;
}

export const EbitdaCovenantSummary = (props: EbitdaCovenantSummaryProps) => {
    const [state, setState] = useState<EbitdaCovenantSummaryState>({
        netDebtCreditEBITDAChartOptions: [],
        netWorthChartOptions: [],
        liquidityChartOptions: [],
        gnmaRatiosNWAssetsChartOptions: [],
        gnmaRatiosLiquidityChartOptions: [],
        chartsLoadedFlag: false,
    });

    useEffect(() => {
        getValues();
    }, []);

    function getValues() {
        let data = [2.1, 2.8, 11.1, 13.1, 13.1, 2.5, 8.8, 5.0, 11.5, 11.5, 0.5, 1.0, 0.295, 2.3, 0.06, 0.2];

        setState((prevState) => ({
            ...prevState,
            netDebtCreditEBITDAChartOptions: [{
                x: ['Actual', 'Covenant'],
                y: [data[0], data[1]],
                type: 'bar',
                text: [data[0].toString(), data[1]].map(number => '<b>' + number + "x" + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                }
            }]
        }));

        setState((prevState) => ({
            ...prevState,
            netWorthChartOptions: [{
                x: ["HUD/FHA", "NY", "GNMA"],
                y: [data[2], data[3], data[4]],
                name: "Actual",
                type: 'bar',
                text: [data[2], data[3], data[4]].map(number => '<b>' + "$" + number + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(28, 64, 202)'
                }
            }, {
                x: ["HUD/FHA", "NY", "GNMA"],
                y: [data[5], data[6], data[7]],
                name: "Minimum",
                type: 'bar',
                text: [data[5], data[6], data[7]].map(number => '<b>' + "$" + number + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(0, 160, 234)',
                }
            }]
        }));

        setState((prevState) => ({
            ...prevState,
            liquidityChartOptions: [{
                x: ["HUD/FHA", "GNMA"],
                y: [data[8], data[9]],
                name: "Actual",
                type: 'bar',
                text: [data[8], data[9]].map(number => '<b>' + "$" + number + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(28, 64, 202)'
                }
            }, {
                x: ["HUD/FHA", "GNMA"],
                y: [data[10], data[11]],
                name: "Minimum",
                type: 'bar',
                text: [data[10], data[11]].map(number => '<b>' + "$" + number + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: 'rgb(0, 160, 234)'
                }
            }]
        }));

        setState((prevState) => ({
            ...prevState,
            gnmaRatiosNWAssetsChartOptions: [{
                x: ['Actual', 'Minimum'],
                y: [data[12] * 100, data[14] * 100],
                type: 'bar',
                text: [data[12] * 100, data[14] * 100].map(number => '<b>' + number + "%" + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                }
            }]
        }));

        setState((prevState) => ({
            ...prevState,
            gnmaRatiosLiquidityChartOptions: [{
                x: ['Actual', 'Minimum'],
                y: [data[13], data[15]],
                type: 'bar',
                text: [data[13], data[15]].map(number => '<b>' + number + "x" + '</b>'),
                textfont: {
                    size: 16,
                },
                textposition: 'auto',
                hoverinfo: 'none',
                marker: {
                    color: ['rgb(28, 64, 202)', 'rgb(0, 160, 234)']
                }
            }]
        }));

        setState((prevState) => ({
            ...prevState,
            chartsLoadedFlag: true
        }));
    }
    
    return (
        <div className="module-master">
            <div className="module-title">
                Covenant Summary
            </div>
            <div className="module-subtitle">
                ($ in M)
            </div>
            {!state.chartsLoadedFlag ? (
                <div className="loading-div">
                    <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} style={{ margin: "0 auto" }} />
                </div>
            ) : (
                <div>
                    <div className="module-row">
                        <div className="module-chart-container">
                            <Plot
                                data={state.netDebtCreditEBITDAChartOptions}
                                layout={{
                                    title: 'Net Debt / Credit EBITDA <br> (LTM Q2 2022)',
                                    /*
                                    xaxis: {
                                        showgrid: false
                                    },
                                    yaxis: {
                                        showgrid: true
                                    }*/
                                }}
                                config={{ responsive: true }}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="module-chart-container">
                            <Plot
                                data={state.netWorthChartOptions}
                                layout={{
                                    title: 'Net Worth <br> (Q2 2022)',
                                }}
                                config={{ responsive: true }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="module-row">
                        <div className="module-chart-container">
                            <Plot
                                data={state.liquidityChartOptions}
                                layout={{
                                    title: 'Liquidity <br> (Cash & Cash Equivalents; Q2 2022)',
                                }}
                                config={{ responsive: true }}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="module-row">
                            <div className="module-chart-container">
                                <Plot
                                    data={state.gnmaRatiosNWAssetsChartOptions}
                                    layout={{
                                        title: 'GNMA Net Worth / Assets <br> (Q2 2022)',
                                    }}
                                    config={{ responsive: true }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className="module-chart-container">
                                <Plot
                                    data={state.gnmaRatiosLiquidityChartOptions}
                                    layout={{
                                        title: 'GNMA Liquidity <br> (Liquid Assets / Req. NW; Q2 2022)',
                                    }}
                                    config={{ responsive: true }}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}
